import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useFetch from "../../hooks/useFetch";
import useUploadImage from "../../hooks/useUploadImage";
import { randomId } from "../../utils/randomId";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { EditInvestorProfile } from "../../components/ui/modals/profile/editInvestorProfile";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import { PrevInvestmentModal } from "../../components/ui/modals/profile/prevInvestmentModal";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import { CameraIcon, DeleteIcon } from "../../icons/icons";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";

const InvestorProfile = () => {
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}display-investor-profile`;
  const editInvestorProfileApi = `${process.env.REACT_APP_API_URL}update-investor-profile`;
  const previousInvestmentApi = `${process.env.REACT_APP_API_URL}previous-investment`;
  const deletePrevInvestmentApi = `${process.env.REACT_APP_API_URL}delete-previous-investment`;

  const [investorDetails, setInvestorDetails] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [editData, setEditData] = useState([]);
  const [prevInvestments, setPrevInvestments] = useState([]);
  const [desc, setDesc] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [prevInvestmentModal, setPrevInvestmentModal] = useState(false);

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { updateProfile } = useUpdateProfile();
  const { uploadImage } = useUploadImage();
  const { displayIndustries } = useDisplayIndustries();
  const navigate = useNavigate();
  const ref = useRef(true);

  const getInvestorData = useCallback(() => {
    fetchApi(
      {
        url: investorDetailsApi,
        method: "POST",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setInvestorDetails(data.investor_profile);
        setEditData(data.investor_profile);
        setProfileImg(data.investor_profile[0].logo);
        setPrevInvestments(data.investor_profile[0].previous_investment);
        setDesc(data.investor_profile[0].description);
      }
    );
  }, [investorDetailsApi, fetchApi, token]);

  const editInvestorDetails = (data, industry) => {
    let dataObj = {
      name: data.investorName,
      phone: data.investorPhone,
      companies_invested_count: data.investedCompanyCount,
      invested_amount: data.fillTotalInvestment,
      future_investment_amount: data.futureInvestmentAmount,
      linked_in: data.investorLinkedIn,
    };
    if (industry.length > 0) {
      dataObj = { ...dataObj, investor_industries: industry };
    }
    updateProfile(editInvestorProfileApi, dataObj, getInvestorData, "investor");
  };

  const updatePrevInvestments = (data) => {
    fetchApi(
      {
        url: previousInvestmentApi,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          company_name: data.prevCompanyName,
          company_url: data.prevCompanyUrl,
        },
      },
      () => {
        getInvestorData();
      }
    );
  };

  const deletePrevInvestment = (id) => {
    fetchApi(
      {
        url: deletePrevInvestmentApi,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      () => {
        getInvestorData();
      }
    );
  };

  useEffect(() => {
    if (userRole !== "Sw%aa") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getInvestorData();
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
    return () => {
      ref.current = false;
    };
  }, [getInvestorData, navigate, userRole, displayIndustries]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-3 md:tw-p-4">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <div>
              <div
                className="tw-relative tw-hidden md:tw-block tw-bg-cover tw-h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div>
                <div className="tw-grid tw-grid-cols-5 tw-px-3 tw-gap-x-1 tw-shadow-md tw-rounded-md">
                  <div className="tw-col-span-5 tw-ml-auto md:tw-hidden">
                    <button
                      type="button"
                      className="profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-text-xs md:tw-text-sm"
                      onClick={() => setOpenProfile(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="tw-col-span-5 md:tw-col-span-1 tw-relative tw-flex tw-items-end tw-justify-center">
                    <img
                      src={profileImg.length > 0 ? profileImg : FavIcon}
                      className="profile-img tw-shadow tw-w-[90px] tw-h-[90px] md:tw-w-[140px] md:tw-h-[140px]"
                      alt="profile-img"
                      width={140}
                    />
                    <div className="img-icon tw-bg-primary tw-rounded-full tw-p-1">
                      <label
                        htmlFor="upload-logo"
                        className="tw-mb-0 tw-cursor-pointer"
                      >
                        <CameraIcon fill="#fff" />
                      </label>
                      <input
                        type="file"
                        id="upload-logo"
                        hidden
                        onChange={(e) =>
                          uploadImage(e.target.files[0], getInvestorData)
                        }
                      />
                    </div>
                    {profileImg.length < 1 && (
                      <p className="tw-text-red-500 tw-font-medium tw-absolute tw-top-[100px] md:tw-top-[85px]">
                        Upload image
                      </p>
                    )}
                  </div>
                  <div className="tw-relative tw-col-span-5 md:tw-col-span-4 tw-pb-4 tw-mb-5 md:tw-pl-[3.5rem] 2xl:tw-pl-0">
                    <button
                      type="button"
                      className="tw-hidden md:tw-block profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-absolute tw-right-0 md:tw-top-unset md:tw-bottom-2.5 tw-text-xs md:tw-text-sm"
                      onClick={() => setOpenProfile(true)}
                    >
                      Edit
                    </button>
                    {investorDetails.map((investor) => (
                      <div
                        key={investor.user_id}
                        className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-mt-10 tw-gap-y-1 md:tw-mt-2"
                      >
                        <div className="md:tw-col-span-3 tw-grid tw-grid-cols-2">
                          <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                            Name :{" "}
                            <span className="tw-uppercase tw-font-medium">
                              {investor.name}
                            </span>
                          </p>
                          <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                            Email :{" "}
                            <span className="tw-font-medium">
                              {investor.email}
                            </span>
                          </p>
                        </div>
                        <p className=" tw-font-bold">
                          No. of Investments :{" "}
                          <span className="tw-font-medium">
                            {investor.companies_invested_count}
                          </span>
                        </p>
                        <p className="tw-flex tw-items-center tw-font-bold">
                          Address :{" "}
                          <span className="tw-flex tw-items-center tw-ms-1 tw-font-medium tw-capitalize">
                            {investor.country?.length > 0 ? (
                              <>
                                <span className="tw-pe-1">{investor.city}</span>
                                <img
                                  src={
                                    investor.flag_path.length > 0 &&
                                    `${process.env.REACT_APP_API_URL}` +
                                      investor.flag_path.slice(6)
                                  }
                                  alt="country-flag"
                                  width={30}
                                  title={investor.country}
                                />
                              </>
                            ) : (
                              <span className="tw-text-red-500 tw-text-sm">
                                Missing
                              </span>
                            )}
                          </span>
                        </p>
                        <p className="tw-font-bold">
                          LinkedIn :{" "}
                          <span className="tw-font-medium">
                            {investor.linked_in.length > 0 ? (
                              <a
                                href={investor.linked_in}
                                target="_blank"
                                rel="noreferrer"
                                className="tw-text-blue-500"
                              >
                                Profile
                              </a>
                            ) : (
                              <span className="tw-text-red-500 tw-text-sm">
                                Missing
                              </span>
                            )}
                          </span>
                        </p>
                        <div className="md:tw-col-span-3 tw-grid tw-grid-cols-2">
                          <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                            Previous Investment :{" "}
                            <span className="tw-capitalize tw-font-medium">
                              ₹{investor.invested_amount}
                            </span>
                          </p>
                          <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                            Investment Target :{" "}
                            <span className="tw-capitalize tw-font-medium">
                              ₹{investor.future_investment_amount}
                            </span>
                          </p>
                        </div>
                        <p className="md:tw-col-span-2 tw-font-bold">
                          Industries :{" "}
                          <span className="tw-font-medium tw-ms-1">
                            {investor.investor_industries.map((industry, i) => (
                              <span className="outline-tags" key={i}>
                                {industry.replace(new RegExp("_", "g"), " ")}
                              </span>
                            ))}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-mt-2 tw-mx-0 tw-px-0">
                  <div className="ideaContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-my-2 tw-relative">
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <h5 className="tw-font-semibold">About Me</h5>
                      <p
                        className="outline-btn tw-text-xs md:tw-text-sm"
                        type="button"
                        onClick={() => setDescriptionModal(true)}
                      >
                        Write here
                      </p>
                    </div>
                    {desc.length > 0 ? (
                      <p id="noIdea" className="mt-3 tw-text-sm tw-mt-0">
                        {desc}
                      </p>
                    ) : (
                      <p id="noIdea" className="mt-3 tw-text-sm tw-mt-0">
                        Tell us about yourself and your expertise.
                      </p>
                    )}
                  </div>
                  <div className="investmentContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-my-2 tw-relative">
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <h5 className="tw-font-semibold">Previous investments</h5>
                      <p
                        className="outline-btn tw-text-xs md:tw-text-sm"
                        type="button"
                        onClick={() => setPrevInvestmentModal(true)}
                      >
                        Add here
                      </p>
                    </div>
                    {prevInvestments.length > 0 ? (
                      <div className="tw-mt-5">
                        {prevInvestments.map((investment) => (
                          <div
                            key={investment._id}
                            className="profile-detail-card tw-relative tw-px-4 tw-py-2 tw-mt-2 tw-rounded-md tw-bg-gray-200"
                          >
                            <Link
                              to={"https://" + investment.company_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="tw-font-medium tw-me-2 tw-text-sm">
                                Company Name :
                              </span>
                              <span className="tw-text-sm">
                                {investment.company_name}
                              </span>
                            </Link>
                            <DeleteIcon
                              className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                              onClick={() =>
                                deletePrevInvestment(investment._id)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p id="noInvestment" className="tw-mt-3 tw-text-sm">
                        Add previous investment details
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <EditInvestorProfile
          key={randomId()}
          open={openProfile}
          onClose={() => setOpenProfile(false)}
          editInvestorDetails={editInvestorDetails}
          editData={editData}
          industries={industries}
        />
        <DescriptionModal
          key={new Date()}
          open={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          callBackFn={() => getInvestorData()}
          url={editInvestorProfileApi}
          data={desc}
        />
        <PrevInvestmentModal
          open={prevInvestmentModal}
          onClose={() => setPrevInvestmentModal(false)}
          updatePrevInvestments={updatePrevInvestments}
        />
      </div>
    </div>
  );
};

export default InvestorProfile;
