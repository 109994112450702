import { useCallback, useEffect, useRef } from "react";
import "./video.css";
import FavIcon from "../../images/favicon.png";

const LocalVideo = ({ localStream, userImages, userId, camera }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (localStream instanceof MediaStream && videoRef.current) {
      videoRef.current.srcObject = localStream;
    }
    return () => {
      if (videoRef.current && !camera) {
        videoRef.current.srcObject = null;
      }
    };
  }, [localStream, camera]);

  const getMyImage = useCallback(() => {
    return userImages && userImages[userId] ? userImages[userId] : FavIcon;
  }, [userId, userImages]);

  return (
    <div className="localVideo tw-relative" id="user-1">
      {localStream && camera ? (
        <video
          ref={videoRef}
          className="video-player tw-rounded"
          id="user-1-video"
          autoPlay
          playsInline
        ></video>
      ) : localStream && !camera ? (
        <>
          <video
            ref={videoRef}
            className="video-player tw-rounded tw-border tw-border-slate-400"
            id="user-1-video"
            autoPlay
            playsInline
          ></video>
          <img
            src={getMyImage()}
            alt="my-profile-img"
            width={120}
            className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2 tw-h-[120px] tw-rounded-full tw-p-1"
          />
        </>
      ) : (
        <img
          src={getMyImage()}
          alt="my-profile-img"
          width={120}
          className="tw-h-[120px] tw-rounded-full tw-p-1"
        />
      )}
    </div>
  );
};

export default LocalVideo;
