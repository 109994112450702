import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Pagination } from "../../components/ui/Button";

export const IncomingRequests = ({
  incomingRequest,
  acceptOrCancelRequest,
}) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const checkRole = (name, role, user_id) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name}/${user_id}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name}/${user_id}`);
    } else if (role === "Sw%aa") {
      navigate(`/view-investor/${name}/${user_id}`);
    } else {
      navigate(`/view-vendor/${name}/${user_id}`);
    }
  };

  return (
    <>
      <h4 className="tw-mb-1 md:tw-mb-4 tw-font-semibold md:tw-text-lg">
        Incoming Connection Requests
      </h4>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Requester Name
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Role
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Requested On
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {incomingRequest.length > 0 ? (
              incomingRequest.slice(page * 7 - 7, page * 7).map((data) => (
                <tr
                  key={data.sender_id}
                  className="tw-bg-white tw-border-b tw-border-slate-300"
                >
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.name}
                  </td>
                  <td>
                    <span
                      className={`tw-px-1 tw-text-xs md:tw-text-sm tw-rounded-md tw-border ${
                        data.role === "f@!3A"
                          ? "tw-border-blue-400 tw-text-blue-400"
                          : data.role === "R$7s2"
                          ? "tw-border-orange-400 tw-text-orange-400"
                          : data.role === "Sw%aa"
                          ? "tw-border-green-500 tw-text-green-500"
                          : "tw-border-indigo-500 tw-text-indigo-500"
                      }`}
                    >
                      {data.role === "f@!3A"
                        ? "Startup"
                        : data.role === "R$7s2"
                        ? "Mentor"
                        : data.role === "Sw%aa"
                        ? "Investor"
                        : "Vendor"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.date}
                  </td>
                  <td
                    id="requestorId"
                    className="tw-flex tw-p-2 tw-text-xs md:tw-text-sm"
                  >
                    <Button
                      className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs"
                      onClick={() =>
                        checkRole(data.name, data.role, data.sender_id)
                      }
                    >
                      Profile
                    </Button>
                    <Button
                      className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs"
                      bgColor="tw-bg-green-600"
                      onClick={() =>
                        acceptOrCancelRequest(
                          data.sender_id,
                          "accept",
                          "incoming"
                        )
                      }
                    >
                      Accept
                    </Button>
                    <Button
                      className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs"
                      bgColor="tw-bg-secondary"
                      onClick={() =>
                        acceptOrCancelRequest(
                          data.sender_id,
                          "cancel",
                          "incoming"
                        )
                      }
                    >
                      Decline
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan="4" className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No Incoming Requests
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {incomingRequest.length > 0 && (
        <Pagination page={page} setPage={setPage} data={incomingRequest} />
      )}
    </>
  );
};
