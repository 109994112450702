import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useSuspendApi from "../../../../hooks/useSuspendApi";
import { Button } from "../../Button";

export const SuspendModal = ({ open, onClose, id, callBackFn }) => {
  const { Suspend } = useSuspendApi();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data) => {
    Suspend(id, data.reason, callBackFn);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <form onSubmit={handleSubmit(submitModal)} className="tw-p-3 tw-mt-5">
          <textarea
            name="reason"
            id="reason"
            cols="30"
            rows="7"
            placeholder="State you reason of suspending here..."
            className="tw-border tw-p-1.5 tw-rounded-md"
            {...register("reason", {
              required: "Reason is required",
              minLength: {
                value: 20,
                message: "Reason must be atleast 20 characters",
              },
            })}
          ></textarea>
          {errors.reason && (
            <span className="tw-my-1 tw-text-secondary tw-text-xs">
              {errors.reason.message}
            </span>
          )}
          <div className="tw-flex tw-justify-end tw-p-2">
            <Button
              type="submit"
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
