import React, { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Button, Pagination } from "../../components/ui/Button";

export const WithdrawReqTable = ({
  withdrawReq,
  cancelWithdrawReq,
  walletDetails,
  processedAmount,
}) => {
  const { userRole } = useLocalStorage();

  const [page, setPage] = useState(1);

  return (
    <>
      <p className="tw-mb-0 tw-mt-2 md:tw-mt-0">
        <span className="tw-me-2 tw-font-bold">Total Amount : </span>
        {userRole === "V&iR8" ? walletDetails.total : processedAmount.total}
      </p>
      <p>
        <span className="tw-me-2 tw-font-bold">Balance Amount : </span>
        {userRole === "V&iR8" ? walletDetails.balance : processedAmount.balance}
      </p>
      {userRole === "V&iR8" && (
        <p>
          <span className="tw-me-2 tw-font-bold">Debited Amount : </span>
          {walletDetails.debited}
        </p>
      )}
      <div className="tw-flex tw-mt-2">
        <span className="tw-me-2 tw-font-semibold tw-text-xs tw-whitespace-nowrap">
          Note :
        </span>
        <span className="tw-mb-0 tw-font-medium tw-text-xs">
          Platform charges 10% of your Total amount as fee.
        </span>
      </div>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-5 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-ps-1 tw-font-medium tw-text-sm">Date</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Amount</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Status</th>
              <th className="tw-p-2 tw-pe-1 tw-font-medium tw-text-sm">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {withdrawReq.length > 0 ? (
              withdrawReq.slice(page * 7 - 7, page * 7).map((request) => (
                <tr
                  key={request.id}
                  className="tw-bg-white tw-border-b tw-border-slate-300"
                >
                  <td className="tw-p-2 tw-ps-1 tw-text-sm">{request.date}</td>
                  <td className="tw-p-2 tw-text-sm">
                    {request.withdraw_amount}
                  </td>
                  <td>
                    <span
                      className={`tw-p-1 tw-text-sm tw-font-medium tw-rounded-md ${
                        request.status === "0"
                          ? "tw-bg-red-400 tw-text-red-800"
                          : "tw-bg-green-400 tw-text-green-800"
                      }`}
                    >
                      {request.status === "0" ? "Pending" : "Processed"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-pe-1 tw-text-sm">
                    {request.status === "0" ? (
                      <Button
                        className="button tw-px-2 tw-py-1"
                        bgColor="tw-bg-secondary"
                        onClick={() => cancelWithdrawReq(request.id)}
                      >
                        Cancel Request
                      </Button>
                    ) : (
                      <span className="tw-px-2 tw-py-1 tw-rounded-md tw-bg-green-600 tw-text-white">
                        Processed
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan={4} className="tw-p-2">
                  No withdraw request
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {withdrawReq.length > 0 && (
        <Pagination page={page} setPage={setPage} data={withdrawReq} />
      )}
    </>
  );
};
