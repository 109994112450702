import { useState, useCallback } from "react";
import useToken from "./useLocalStorage";
import useFetch from "./useFetch";

export const useFreezeAmount = () => {
  const displayFreezePointsApi = `${process.env.REACT_APP_API_URL}display-freeze-points`;
  const [freezePoints, setFreezePoints] = useState(0);
  const { token } = useToken();
  const { fetchApi } = useFetch();

  const getFreezePoints = useCallback(() => {
    fetchApi(
      { url: displayFreezePointsApi, headers: { "x-token": token } },
      (data) => setFreezePoints(data.freeze_points)
    );
  }, [fetchApi, displayFreezePointsApi, token]);

  return { freezePoints, getFreezePoints };
};
