import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchCredits } from "../../../hooks/useFetchCredits";
import { Button, Pagination } from "../../../components/ui/Button";
import FavIcon from "../../../images/favicon.png";

export const WalletTable = ({ creditTransactions, usedCredits }) => {
  const { credits, getCredits } = useFetchCredits();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const ref = useRef(true);

  useEffect(() => {
    if (ref.current) {
      getCredits();
    }
    return () => {
      ref.current = false;
    };
  }, [getCredits]);

  const viewProfile = (name, role, connection_id) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name}/${connection_id}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name}/${connection_id}`);
    } else {
      navigate(`/view-investor/${name}/${connection_id}`);
    }
  };

  return (
    <>
      <p className="tw-mb-0 tw-mt-2 tw-text-xs md:tw-mt-0 md:tw-text-sm">
        <span className="tw-font-bold tw-me-1">TOKENS USED :</span>
        {usedCredits}
      </p>
      <p className="tw-text-xs md:tw-text-sm">
        <span className="tw-font-bold tw-me-1">TOKENS REMAINING :</span>
        {credits}
      </p>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-ps-3 tw-w-3/12 tw-font-medium tw-text-sm">
                Name
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Role</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Date</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Tokens</th>
              <th className="tw-p-2 tw-pe-1 tw-font-medium tw-text-sm">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {creditTransactions.length > 0 ? (
              creditTransactions
                .slice(page * 7 - 7, page * 7)
                .map((transaction) => (
                  <tr
                    key={transaction.id}
                    className="tw-bg-white tw-border-b tw-border-slate-300"
                  >
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      <div className="tw-flex tw-items-center tw-justify-start tw-ps-3 tw-capitalize">
                        <img
                          src={
                            transaction.logo.length > 0
                              ? transaction.logo
                              : FavIcon
                          }
                          alt="profile-img"
                          className="tw-w-[40px] tw-h-[40px] md:tw-w-[50px] md:tw-h-[50px] tw-me-3 tw-rounded-full"
                        />
                        {transaction.name}
                      </div>
                    </td>
                    <td>
                      <span
                        className={`tw-px-1 tw-text-xs md:tw-text-sm tw-rounded-md tw-border ${
                          transaction.role === "f@!3A"
                            ? "tw-border-blue-400 tw-text-blue-400"
                            : transaction.role === "R$7s2"
                            ? "tw-border-orange-400 tw-text-orange-400"
                            : "tw-border-green-500 tw-text-green-500"
                        }`}
                      >
                        {transaction.role === "f@!3A"
                          ? "Startup"
                          : transaction.role === "R$7s2"
                          ? "Mentor"
                          : "Investor"}
                      </span>
                    </td>
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      {transaction.date}
                    </td>
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      {transaction.freeze_points}
                    </td>
                    <td className="tw-p-2 tw-pe-1 tw-text-xs md:tw-text-sm">
                      <Button
                        className="button tw-px-2 tw-py-1"
                        onClick={() =>
                          viewProfile(
                            transaction.name,
                            transaction.role,
                            transaction.connection_id
                          )
                        }
                      >
                        Profile
                      </Button>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan="5" className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No Transaction Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {creditTransactions.length > 0 && (
        <Pagination page={page} setPage={setPage} data={creditTransactions} />
      )}
    </>
  );
};
