import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { updateStatus } from "../../../store/slices/ChatSlice";
import { useToastContext } from "../../../context/ToastContext";
import { ConnectedUsers } from "../connectedUsers";
import { IncomingRequests } from "../incomingRequests";
import { BlockedUsers } from "../blockedUsers";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import Navbar from "../../../components/layout/Navbar";
import Sidebar from "../../../components/layout/Sidebar";
import { Button } from "../../../components/ui/Button";

export const MentorConnections = () => {
  const displayConnectionsApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const blockListApi = `${process.env.REACT_APP_API_URL}block-list`;
  const incomingRequestApi = `${process.env.REACT_APP_API_URL}incoming-requests`;
  const acceptOrCancelReqApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;
  const blockApi = `${process.env.REACT_APP_API_URL}block`;
  const unBlockApi = `${process.env.REACT_APP_API_URL}unblock`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToastContext();

  const [connections, setConnections] = useState([]);
  const [incomingRequest, setIncomingRequest] = useState([]);
  const [blockedConnections, setBlockedConnections] = useState([]);
  const [tableType, setTableType] = useState("connected");
  const [openSidebar, setOpenSidebar] = useState(false);
  const ref = useRef(true);

  const displayFriends = useCallback(() => {
    setTableType("connected");
    fetchApi(
      {
        url: displayConnectionsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setConnections(data.response);
      }
    );
  }, [displayConnectionsApi, token, fetchApi]);

  const blockList = useCallback(() => {
    setTableType("block");
    fetchApi(
      {
        url: blockListApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setBlockedConnections(data.block_users);
      }
    );
  }, [blockListApi, token, fetchApi]);

  const getIncomingRequests = useCallback(() => {
    setTableType("incoming");
    fetchApi(
      { url: incomingRequestApi, headers: { "x-token": token } },
      (data) => setIncomingRequest(data.response)
    );
  }, [incomingRequestApi, fetchApi, token]);

  const block = (connection_id) => {
    fetchApi(
      {
        url: blockApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: connection_id,
        },
      },
      (data) => {
        sendNotification(
          connection_id,
          `You have been blocked by ${data.name}`,
          "connections"
        );
        blockList();
        dispatch(updateStatus("-2"));
      }
    );
  };

  const unBlock = (id) => {
    fetchApi(
      {
        url: unBlockApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: id,
        },
      },
      () => {
        sendNotification(id, `${name} has un-blocked you`, "connections");
        blockList();
        dispatch(updateStatus("1"));
      }
    );
  };

  const acceptOrCancelRequest = useCallback(
    (connection_id, type) => {
      fetchApi(
        {
          url: acceptOrCancelReqApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: connection_id,
            type: type,
          },
        },
        (data) => {
          if (data.message === "already cancelled") {
            toast.open("false", data.message);
            getIncomingRequests();
          }
          if (data.message === "cancelled") {
            sendNotification(
              connection_id,
              `Connection request declined by ${data.name}`,
              "connections"
            );
            getIncomingRequests();
          }
          if (data.message === "accepted") {
            sendNotification(
              connection_id,
              `Connection request accepted by ${data.name}`,
              "connections"
            );
            getIncomingRequests();
          }
        }
      );
    },
    [
      acceptOrCancelReqApi,
      fetchApi,
      token,
      getIncomingRequests,
      sendNotification,
    ]
  );

  useEffect(() => {
    if (userRole !== "R$7s2") {
      navigate("/");
    }
    if (ref.current) {
      displayFriends();
    }
    return () => {
      ref.current = false;
    };
  }, [displayFriends, navigate, userRole]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-4">
          <div className="tw-text-end">
            <Button
              onClick={displayFriends}
              className="tw-px-2 tw-py-1 tw-m-1 tw-text-xs md:tw-text-sm "
            >
              Connected users
            </Button>
            <Button
              onClick={blockList}
              className="tw-px-2 tw-py-1 tw-m-1 tw-text-xs md:tw-text-sm "
              bgColor="tw-bg-green-600"
            >
              Blocked users
            </Button>
            <Button
              onClick={getIncomingRequests}
              className="tw-px-2 tw-py-1 tw-m-1 tw-text-xs md:tw-text-sm "
              bgColor="tw-bg-[#F97316]"
            >
              Incoming requests
            </Button>
          </div>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading &&
            !error &&
            (tableType === "connected" ? (
              <ConnectedUsers
                connections={connections}
                displayFriends={displayFriends}
                block={block}
              />
            ) : tableType === "incoming" ? (
              <IncomingRequests
                incomingRequest={incomingRequest}
                acceptOrCancelRequest={acceptOrCancelRequest}
                getIncomingRequests={getIncomingRequests}
              />
            ) : (
              <BlockedUsers
                blockedConnections={blockedConnections}
                unBlock={unBlock}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
