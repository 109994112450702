import React from "react";

export const FormError = ({
  message = "",
  fontSize = "tw-text-xs",
  className = "",
}) => {
  return (
    <p className={`tw-text-secondary ${fontSize} ${className}`}>{message}</p>
  );
};
