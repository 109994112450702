import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, deleteUser } from "../../store/slices/LoginSlice";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import { LoginForm } from "./loginForm";
import { FrogotPasswordForm } from "./frogotPasswordForm";
import { EmailSuccess } from "./emailSuccess";
import "./Signin.css";
import "../register-page/Register.css";
import "../../custom.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import useWindowDimensions from "../../hooks/useWindowDimension";
import backgroundImg from "../../images/background.png";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [suspended, setSuspended] = useState(false);

  useEffect(() => {
    document.getElementById("forgotPasswordForm").classList.add("tw-hidden");
    document.getElementById("confirmationText").classList.add("tw-hidden");
    dispatch(deleteUser());
    dispatch(deleteChatRoom());
  }, [dispatch]);

  const postLogin = (data) => {
    if (data.message === "account suspended") {
      setSuspended(true);
      return;
    }
    dispatch(setUser({ id: data.jwt_token, role: data.role, name: data.name }));
    if (data.profile_completion === 30) {
      navigate("/profile-completion");
      return;
    }
    if (data.role === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (data.role === "R$7s2") {
      navigate("/mentor-dashboard", {
        state: { profile_completion: data.profile_completion },
      });
    } else if (data.role === "Sw%aa") {
      navigate("/investor-dashboard", {
        state: { profile_completion: data.profile_completion },
      });
    } else {
      navigate("/vendor-profile");
    }
  };

  const forgotPasswordBtn = () => {
    document.getElementById("forgotPasswordForm").classList.remove("tw-hidden");
    document.getElementById("loginForm").classList.add("tw-hidden");
  };

  const backToLogin = () => {
    document.getElementById("forgotPasswordForm").classList.add("tw-hidden");
    document.getElementById("confirmationText").classList.add("tw-hidden");
    document.getElementById("loginForm").classList.remove("tw-hidden");
  };

  return (
    <div className="authentication-wrapper authentication-cover">
      {width < 1024 ? (
        <p className="tw-text-center tw-pt-5">
          <Link to="/">
            <img
              src={pitchHereLogo}
              alt="skilldukan-logo"
              width={200}
              className="tw-inline"
            />
          </Link>
        </p>
      ) : (
        <p className="tw-text-left">
          <Link to="/" className="auth-cover-brand md:tw-ps-5">
            <img src={pitchHereLogo} alt="skilldukan-logo" width={140} />
          </Link>
        </p>
      )}
      <div className="md:tw-h-screen tw-w-full tw-relative tw-grid tw-grid-cols-7 lg:tw-bg-white tw-mt-[70px] md:tw-mt-0">
        <div
          className="tw-hidden lg:tw-flex tw-col-span-5 md:tw-items-center tw-justify-center tw-p-3"
          style={{
            backgroundColor: "#f3f3f3",
            background: `url(${backgroundImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <LoginForm
          postLogin={postLogin}
          forgotPasswordBtn={forgotPasswordBtn}
          suspended={suspended}
          setSuspended={setSuspended}
        />
        <FrogotPasswordForm backToLogin={backToLogin} />
        <EmailSuccess backToLogin={backToLogin} />
      </div>
    </div>
  );
};

export default Signin;
