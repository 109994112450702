import useLocalStorage from "./useLocalStorage";
import useFetch from "./useFetch";

const useNotificationApi = () => {
  const sendNotificationApi = `${process.env.REACT_APP_API_URL}add-notification`;
  const { token } = useLocalStorage();
  const { fetchApi } = useFetch();

  const sendNotification = (id, heading, type) => {
    fetchApi(
      {
        url: sendNotificationApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: id,
          heading: heading,
          notification_type: type,
        },
      },
      () => {}
    );
  };

  return { sendNotification };
};

export default useNotificationApi;
