import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import FavIcon from "../../images/favicon.png";
import { BarsIcon } from "../../icons/icons";

const AdminNavbar = (props) => {
  const { width } = useWindowDimensions();
  return (
    <div className="tw-sticky tw-top-0 tw-m-0 tw-z-[1] tw-px-4 tw-py-2 tw-bg-white md:tw-relative tw-shadow-[5px_1px_20px_-6px_rgba(0,0,0,0.4)] tw-flex tw-items-center tw-justify-between md:tw-block">
      {width < 768 && (
        <img src={pitchHereLogo} alt="pitchere-logo" width={100} />
      )}
      <div className="tw-hidden lg:tw-flex lg:tw-justify-end lg:tw-items-center">
        <div className="tw-relative tw-group tw-flex tw-justify-center lg:tw-justify-normal tw-cursor-pointer tw-ms-4">
          <img
            src={FavIcon}
            className="tw-rounded-full tw-h-[50px]"
            alt="profile-img"
            width={50}
          />
        </div>
      </div>
      <div
        className="tw-flex tw-items-center tw-justify-end lg:tw-hidden"
        id="sidebar-icon"
      >
        <BarsIcon onClick={props.handleSidebar} />
      </div>
    </div>
  );
};

export default AdminNavbar;
