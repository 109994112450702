import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import {
  bannedInputs,
  numberRegex,
  textWithSpaceRegex,
  urlRegex,
} from "../../../../constants";
import {
  LinkedInIcon,
  MoneyUpTrendIcon,
  PhoneIcon,
  UserIcon,
} from "../../../../icons/icons";
import Dropdown from "../../inputs/dropdown";

export const EditInvestorProfile = ({
  open,
  onClose,
  editInvestorDetails,
  editData,
  industries,
}) => {
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const ref = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      investorName: editData[0]?.name,
      investorPhone: editData[0]?.phone,
      investorLinkedIn: editData[0]?.linked_in,
      investedCompanyCount: editData[0]?.companies_invested_count,
      fillTotalInvestment: editData[0]?.invested_amount,
      futureInvestmentAmount: editData[0]?.future_investment_amount,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData[0]?.investor_industries) {
        setSelectedIndustry([...editData[0].investor_industries]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    document.getElementById("industry-error").classList.add("tw-hidden");
    document.getElementById("industry-limit").classList.add("tw-hidden");
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("tw-hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("tw-hidden");
      return;
    }
    editInvestorDetails(data, selectedIndustry);
    setSelectedIndustry([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[70%] md:tw-max-w-[70%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 tw-p-3 tw-mt-7"
        >
          <div>
            <label htmlFor="investorName" className="tw-mb-0 tw-text-sm">
              Edit Name
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="investorName"
                name="investorName"
                autoFocus
                {...register("investorName", {
                  validate: {
                    validLength: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? fieldValue.trim().length > 2 ||
                            "minimum required length is 3"
                        : null;
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.investorName && (
              <div className="tw-col-span-2 tw-ps-3">
                <FormError message={errors.investorName.message} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="investorPhone" className="tw-mb-0 tw-text-sm">
              Change Contact Number
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                maxLength="10"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="investorPhone"
                name="investorPhone"
                {...register("investorPhone", {
                  validate: {
                    minValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length > 6 ||
                            "Number should be atleast 7 digits"
                        : null;
                    },
                    maxValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length < 13 ||
                            "Number cannot be more than 12 digits"
                        : null;
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <PhoneIcon />
              </span>
            </div>
            {errors.investorPhone && (
              <div className="tw-col-span-2 tw-ps-3">
                <FormError message={errors.investorPhone.message} />
              </div>
            )}
          </div>
          <div>
            <label htmlFor="investorLinkedIn" className="tw-mb-0 tw-text-sm">
              LinkedIn
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="investorLinkedIn"
                name="investorLinkedIn"
                {...register("investorLinkedIn", {
                  validate: {
                    validUrl: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? urlRegex.test(fieldValue) ||
                            "Please enter a valid url"
                        : null;
                    },
                    bannedUrl: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? !bannedInputs.some((value) =>
                            fieldValue.includes(value)
                          ) || "Enter a different url"
                        : null;
                    },
                  },
                })}
              />
              <span className="input-icons icon-span">
                <LinkedInIcon />
              </span>
            </div>
            {errors.investorLinkedIn && (
              <div className="tw-col-span-2">
                <FormError message={errors.investorLinkedIn.message} />
              </div>
            )}
          </div>
          <div>
            <label
              htmlFor="investedCompanyCount"
              className="tw-mb-0 tw-text-sm"
            >
              Total Companies invested in ?
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="number"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="investedCompanyCount"
                name="investedCompanyCount"
                {...register("investedCompanyCount", {
                  pattern: {
                    value: numberRegex,
                    message: "Count must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <MoneyUpTrendIcon />
              </span>
            </div>
            {errors.investedCompanyCount && (
              <div className="tw-col-span-2 tw-ps-3">
                <FormError message={errors.investedCompanyCount.message} />
              </div>
            )}
          </div>
          <div className="tw-flex-col">
            <p className="tw-text-sm">
              Industry <span>({selectedIndustry.length + "/3"})</span>
              <span
                id="industry-limit"
                className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
              >
                Limit exceeded
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={selectedIndustry.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setSelectedIndustry(
                  selectedOptions.map((option) => option.value)
                )
              }
              isMulti
            />
            <small
              className="tw-text-red-500 tw-mb-2 tw-hidden"
              id="industry-error"
            >
              This field is mandatory.
            </small>
          </div>
          <div>
            <label htmlFor="fillTotalInvestment" className="tw-mb-0 tw-text-sm">
              Total Investment done till now
            </label>
            <div className="select-input tw-w-full tw-rounded-md tw-m-auto tw-border tw-border-slate-300">
              <select
                name="fillTotalInvestment"
                id="fillTotalInvestment"
                className="tw-border-0 tw-w-full"
                {...register("fillTotalInvestment")}
              >
                <option value="">Select Amount</option>
                <option value="Upto 5Lakh">Upto 5Lakh</option>
                <option value="5Lakh-10Lakh">5Lakh-10Lakh</option>
                <option value="10Lakh-25Lakh">10Lakh-25Lakh</option>
                <option value="25Lakh-50Lakh">25Lakh-50Lakh</option>
                <option value="More than 50Lakh">50Lakh+</option>
              </select>
            </div>
          </div>
          <div className="tw-flex-col">
            <label
              htmlFor="futureInvestmentAmount"
              className="tw-mb-0 tw-text-sm"
            >
              How much are you looking to invest
            </label>
            <div className="select-input tw-w-full tw-rounded-md tw-m-auto tw-border tw-border-slate-300">
              <select
                name="futureInvestmentAmount"
                id="futureInvestmentAmount"
                className="tw-border-0 tw-w-full"
                {...register("futureInvestmentAmount")}
              >
                <option value="">Select Amount</option>
                <option value="Upto 5Lakh">Upto 5Lakh</option>
                <option value="5Lakh-10Lakh">5Lakh-10Lakh</option>
                <option value="10Lakh-25Lakh">10Lakh-25Lakh</option>
                <option value="25Lakh-50Lakh">25Lakh-50Lakh</option>
                <option value="More than 50Lakh">50Lakh+</option>
              </select>
            </div>
          </div>
          <div className="md:tw-col-span-2 tw-flex tw-items-center tw-justify-end tw-p-3">
            <Button
              onClick={onClose}
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button
              type="submit"
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
