import React, { useState } from "react";

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="tw-py-2">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="tw-flex tw-justify-between tw-w-full"
      >
        <span className="tw-font-medium tw-text-left">{title}</span>
        <svg
          className="tw-fill-indigo-500 tw-shrink-0 tw-ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`tw-transform tw-origin-center tw-transition tw-duration-200 tw-ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`tw-transform tw-origin-center tw-rotate-90 tw-transition tw-duration-200 tw-ease-out ${
              accordionOpen && "!tw-rotate-180"
            }`}
          />
        </svg>
      </button>
      <div
        className={`tw-grid tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out tw-text-slate-600  tw-mt-2 tw-text-sm ${
          accordionOpen
            ? "tw-grid-rows-[1fr] tw-opacity-100"
            : "tw-grid-rows-[0fr] tw-opacity-0"
        }`}
      >
        <div className="tw-overflow-hidden">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;
