import axios from "axios";
import useLocalStorage from "./useLocalStorage";

const useUploadImage = () => {
  const uploadImageApi = `${process.env.REACT_APP_API_URL}upload-image`;

  const { token } = useLocalStorage();

  const uploadImage = (newFile, callBackFn) => {
    const formData = new FormData();
    formData.append("file", newFile);
    axios
      .post(uploadImageApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        callBackFn();
      })
      .catch((err) => {
        if (err.response.status) {
          alert("Image must be of type ( .png, .jpg, .jpeg )");
        }
      });
  };
  return { uploadImage };
};

export default useUploadImage;
