import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import { Button } from "../../Button";
import { alphanumericRegex, numberRegex } from "../../../../constants";
import { LockIcon, RupeeIcon } from "../../../../icons/icons";

export const ConfirmInvestment = ({
  open,
  onClose,
  confirmInvestmentDetails,
  agreementId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();
  const [proof, setProof] = useState();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;
  const submitModal = (data, e) => {
    e.preventDefault();
    confirmInvestmentDetails(agreementId, data, proof);
    setProof();
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[40%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Investment Confirmation
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-p-3 tw-text-left"
        >
          <p className="tw-mb-4">
            Enter the transaction details below once you have completed the
            payment process. Thank you.
          </p>
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="transactionType"
            >
              Mode of Transaction
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden tw-ps-2 tw-pe-3"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <select
                className="tw-w-full tw-p-4 tw-ps-0"
                id="transactionType"
                name="transactionType"
                {...register("transactionType", {
                  required: "Transaction type is required",
                })}
              >
                <option value="Net Banking">Net Banking</option>
                <option value="Cheque">Cheque</option>
              </select>
            </div>
            {errors.transactionType && (
              <div>
                <FormError message={errors.transactionType.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm required" htmlFor="amount">
              Transfered Amount
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="amount"
                name="amount"
                autoFocus
                {...register("amount", {
                  required: "amount is required",
                  pattern: {
                    value: numberRegex,
                    message: "invalid amount, must only contain numbers",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <RupeeIcon />
              </span>
            </div>
            {errors.amount && (
              <div>
                <FormError message={errors.amount.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="transactionId"
            >
              Transaction ID
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="transactionId"
                name="transactionId"
                {...register("transactionId", {
                  required: "transaction ID is required",
                  pattern: {
                    value: alphanumericRegex,
                    message: "invalid transaction ID",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <LockIcon />
              </span>
            </div>
            {errors.transactionId && (
              <div>
                <FormError message={errors.transactionId.message} />
              </div>
            )}
          </div>
          <div>
            <p className="tw-mb-0">Upload proof of transaction</p>
            <label
              htmlFor="transactionProof"
              className="tw-p-1 tw-rounded-md tw-bg-primary tw-text-white tw-text-sm tw-cursor-pointer"
            >
              Upload
            </label>
            <input
              type="file"
              id="transactionProof"
              name="transactionProof"
              accept="image/png,image/jpeg,application/pdf"
              className="tw-hidden"
              onChange={(e) => setProof(e.target.files[0])}
            />
            <span className="tw-ms-2">{proof && proof.name}</span>
          </div>
          <div className="modalFooter tw-p-3">
            <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
