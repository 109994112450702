import React from "react";
import FavIcon from "../../images/favicon.png";

const SingleConnection = ({ data, ViewProfile, userRole, userId }) => {
  return (
    <div
      className="tw-flex tw-justify-between tw-cursor-pointer"
      onClick={() =>
        ViewProfile(
          userId,
          data.role === "f@!3A" || data.role === "V&iR8"
            ? data.company_name
            : data.name,
          data.role
        )
      }
    >
      <div className="tw-flex tw-items-center">
        <img
          src={data.logo.length > 0 ? data.logo : FavIcon}
          alt="profile-img"
          width={45}
          className="tw-rounded-full tw-h-[45px]"
        />
        <div className="tw-ps-2">
          <span className="tw-text-sm tw-font-medium tw-capitalize">
            {data.name}
          </span>
          <p>
            {userRole === "f@!3A" ? (
              <span
                className={`tw-px-1 tw-text-xs tw-rounded-md tw-border ${
                  data.role === "R$7s2"
                    ? "tw-border-blue-500 tw-text-blue-500"
                    : data.role === "Sw%aa"
                    ? "tw-border-green-500 tw-text-green-500"
                    : "tw-border-orange-500 tw-text-orange-500"
                }`}
              >
                {data.role === "R$7s2"
                  ? "Mentor"
                  : data.role === "Sw%aa"
                  ? "Investor"
                  : "Service Provider"}
              </span>
            ) : (
              <span className="tw-px-1 tw-text-xs tw-border tw-border-blue-500 tw-text-blue-500 tw-rounded-md tw-capitalize">
                {data.company_name}
              </span>
            )}
          </p>
        </div>
      </div>
      <p className="tw-mb-0 tw-text-xs tw-font-medium">{data.date}</p>
    </div>
  );
};

export default SingleConnection;
