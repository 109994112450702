import { useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import "../../../../custom.css";

export const CertificateModal = ({
  open,
  onClose,
  addCertificates,
  documentType,
}) => {
  const [file, setFile] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      certificateType: "",
    },
  });

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    document.getElementById("mandatory-error").classList.add("tw-hidden");
    if (!file) {
      document.getElementById("mandatory-error").classList.remove("tw-hidden");
      return;
    }
    addCertificates(file, data.certificateType);
    setFile();
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Add New Certificate
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-p-3 tw-text-left"
        >
          <div className="tw-mb-2">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="certificateType"
            >
              Certificate Type
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              {documentType === "mandatory" ? (
                <select
                  className="form-select tw-border-0 tw-p-2 tw-w-full"
                  name="certificateType"
                  id="certificateType"
                  {...register("certificateType", {
                    required: "Please select a certificate type",
                  })}
                >
                  <option value="">Select</option>
                  <option value="incorporation">
                    Certificate of Incorporation
                  </option>
                  <option value="msme">MSME Registration</option>
                  <option value="gst">
                    Goods and Services Tax (GST) Registration
                  </option>
                </select>
              ) : (
                <select
                  className="form-select tw-border-0 tw-w-full tw-p-2"
                  name="certificateType"
                  id="certificateType"
                  {...register("certificateType", {
                    required: "Please select a certificate type",
                  })}
                >
                  <option value="">Select</option>
                  <option value="privacy policy">Privacy policy</option>
                  <option value="Terms of service">Terms of service</option>
                  <option value="Startup India Recognition">
                    Startup India Recognition
                  </option>
                  <option value="Team Information">Team Information</option>
                  <option value="Founder Agreement">Founder Agreement</option>
                  <option value="Trademark Registrations">
                    Trademark Registrations
                  </option>
                  <option value="Financial Documentation">
                    Financial Documentation
                  </option>
                  <option value="Business Plan">Business Plan</option>
                  <option value="Market Research Report">
                    Market Research Report
                  </option>
                  <option value="Licensing Agreement">
                    Licensing Agreement
                  </option>
                  <option value="Compliance Documents">
                    Compliance Documents
                  </option>
                </select>
              )}
            </div>
            {errors.certificateType && (
              <div>
                <FormError message={errors.certificateType.message} />
              </div>
            )}
          </div>
          <div>
            <label
              htmlFor="upload-certificate"
              className="tw-p-1 tw-mt-3 tw-rounded-md tw-bg-indigo-500 tw-text-white tw-text-sm tw-cursor-pointer"
            >
              Upload Certificate
            </label>
            <input
              type="file"
              id="upload-certificate"
              accept="image/jpeg,image/png,application/pdf"
              onChange={(e) => setFile(e.target.files[0])}
              required
              hidden
            />
            <span className="tw-ms-2">{file && file.name}</span>
          </div>
          <p
            className="tw-hidden tw-text-sm tw-text-red-500"
            id="mandatory-error"
          >
            File is missing
          </p>
          <div className="modalFooter tw-p-3">
            <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
