import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Button } from "../../components/ui/Button";
import successImg from "../../images/paymentSuccess.svg";
import FailedImg from "../../images/paymentFailed.svg";

const PaymentStatus = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  const { userRole } = useLocalStorage();

  const redirect = () => {
    if (userRole === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (userRole === "R$7s2") {
      navigate("/mentor-dashboard");
    } else if (userRole === "Sw%aa") {
      navigate("/investor-dashboard");
    } else {
      navigate("/vendor-profile");
    }
  };

  return (
    <section className="tw-w-full tw-h-screen tw-bg-[#F7F8F9] tw-flex tw-items-center tw-justify-center">
      <div className="tw-w-6/12 tw-p-5 tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-rounded-lg tw-shadow-md">
        {status === "success" ? (
          <>
            <img src={successImg} alt="payment-success-img" width={300} />
            <div className="tw-text-center tw-mt-4">
              <p className="tw-text-lg tw-font-semibold">Payment Successful</p>
              <p className="tw-py-3">
                Thank you for your payment. Confirmation mail has been sent to
                your registered email.
              </p>
              <Button
                onClick={redirect}
                className="tw-px-3 tw-py-1.5 tw-text-sm hover:tw-text-white"
              >
                Back to Dashboard
              </Button>
            </div>
          </>
        ) : (
          <>
            <img src={FailedImg} alt="payment-success-img" width={300} />
            <div className="tw-text-center tw-mt-4">
              <p className="tw-text-lg tw-font-semibold">Payment Failed</p>
              <p className="tw-py-3">
                Your payment has failed, Please try again later. If there is an
                issue of fund transfer please contact us by raising a ticket.
                Thank you.
              </p>
              <Button
                onClick={redirect}
                className="tw-px-3 tw-py-1.5 tw-text-sm hover:tw-text-white"
              >
                Back to Dashboard
              </Button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default PaymentStatus;
