import React, { useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Pagination } from "../../components/ui/Button";

export const WalletTable = ({ wallet, processedAmount, walletDetails }) => {
  const [page, setPage] = useState(1);

  const { userRole } = useLocalStorage();

  return (
    <>
      <p className="tw-mt-2 tw-mb-0 md:tw-mt-0">
        <span className="tw-me-2 tw-font-bold tw-text-xs md:tw-text-sm">
          Total Earning :{" "}
        </span>
        {userRole === "V&iR8" ? walletDetails.total : processedAmount.total}
      </p>
      <p>
        <span className="tw-me-2 tw-font-bold tw-text-xs md:tw-text-sm">
          Balance Amount :{" "}
        </span>
        {userRole === "V&iR8" ? walletDetails.balance : processedAmount.balance}
      </p>
      {userRole === "R$7s2" && (
        <p className="tw-mt-2 tw-mb-0 md:tw-mt-0">
          <span className="tw-me-2 tw-font-bold tw-text-xs md:tw-text-sm">
            Freezed Amount :{" "}
          </span>
          {processedAmount.freezed}
        </p>
      )}
      {userRole === "V&iR8" && (
        <p>
          <span className="tw-me-2 tw-font-bold tw-text-xs md:tw-text-sm">
            Debited Amount :{" "}
          </span>
          {walletDetails.debited}
        </p>
      )}
      <div className="tw-flex tw-items-center tw-mt-2">
        {userRole === "V&iR8" && (
          <>
            <span className="tw-me-2 tw-font-semibold tw-text-xs">Note :</span>
            <span className="tw-mb-0 tw-font-medium tw-text-xs">
              The awarded amount is freezed and is only transfered to your
              account once the Project is marked as completed by startup.
            </span>
          </>
        )}
      </div>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Name
              </th>
              {userRole === "V&iR8" && (
                <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                  Project Title
                </th>
              )}
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Date
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Amount
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Status
              </th>
              {userRole === "V&iR8" ? null : (
                <th className="tw-p-2 tw-pe-1 tw-font-medium tw-text-xs md:tw-text-sm">
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {wallet.length > 0 ? (
              wallet.slice(page * 7 - 7, page * 7).map((transaction) => (
                <tr
                  key={transaction.id}
                  className="tw-bg-white tw-border-b tw-border-slate-300"
                >
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm tw-capitalize">
                    {transaction.name}
                  </td>
                  {userRole === "V&iR8" && (
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm tw-capitalize">
                      {transaction.title}
                    </td>
                  )}

                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {transaction.date}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {userRole === "V&iR8"
                      ? transaction.amount
                      : transaction.freeze_points}
                  </td>
                  <td>
                    <span
                      className={`tw-p-1 tw-rounded tw-text-xs md:tw-text-sm tw-border ${
                        transaction.status === "0"
                          ? "tw-border-blue-500 tw-text-blue-500"
                          : "tw-border-green-500 tw-text-green-500"
                      }
                      `}
                    >
                      {transaction.status === "0" ? "Freezed" : "Completed"}
                    </span>
                  </td>
                  {userRole === "V&iR8" ? null : (
                    <td className="tw-p-2 tw-pe-1 tw-text-xs md:tw-text-sm">
                      <Link
                        to={`/view-startup/${transaction.name}/${transaction.startup_id}`}
                        className="button tw-bg-primary tw-text-white tw-rounded-md tw-px-2 tw-py-1"
                      >
                        Profile
                      </Link>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td
                  colSpan={userRole === "V&iR8" ? 6 : 5}
                  className="tw-text-xs md:tw-text-sm tw-p-2"
                >
                  No transaction found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {wallet.length > 0 && (
        <Pagination page={page} setPage={setPage} data={wallet} />
      )}
    </>
  );
};
