import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StartupSidebar } from "./startupSidebar";
import { MentorSidebar } from "./mentorSidebar";
import { InvestorSidebar } from "./investorSidebar";
import { VendorSidebar } from "./vendorSidebar";
import { TicketModal } from "../ui/modals/ticket/ticketModal";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";

export const RegularSidebar = ({ supportTicket, role, style }) => {
  const [open, setOpen] = useState(false);

  const currPath = window.location.hash;
  const activeStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-bg-primary tw-text-white hover:tw-text-white tw-rounded-md tw-cursor-pointer";
  const regularStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-rounded-md tw-cursor-pointer hover:tw-bg-customOrange hover:tw-text-white";

  return (
    <div
      className="left-half tw-h-full tw-bg-[#f0f8ff] tw-text-left tw-shadow-lg"
      id="sidebar"
      style={style}
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-py-2">
        <Link to="#">
          <img src={pitchHereLogo} alt="logo" width={200} />
        </Link>
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          {role === 1 ? (
            <StartupSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : role === 2 ? (
            <MentorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : role === 3 ? (
            <InvestorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : (
            <VendorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          )}
        </div>
      </nav>
      {!style ? (
        <div className="tw-absolute tw-bottom-0 tw-w-full tw-mb-2 tw-text-center">
          <span
            onClick={() => setOpen(!open)}
            className="tw-w-11/12 tw-py-2.5 tw-px-4 tw-text-sm tw-bg-customOrange tw-text-white tw-rounded-md tw-cursor-pointer"
          >
            Raise Support Ticket
          </span>
        </div>
      ) : null}
      <TicketModal
        open={open}
        onClose={() => setOpen(false)}
        supportTicket={supportTicket}
      />
    </div>
  );
};
