import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import socket from "../../utils/socketConnection";
import { servers } from "../../constants";
import LocalVideo from "./LocalVideo";
import RemoteVideo from "./RemoteVideo";
import Chat from "./Chat";
import "./video.css";
import {
  MicOffIcon,
  MicOnIcon,
  PhoneIcon,
  // ShareScreenIcon,
  VideoOffIcon,
  VideoOnIcon,
} from "../../icons/icons";
import { randomId } from "../../utils/randomId";
import { Button } from "../../components/ui/Button";

let newLocalStream;
let newRemoteStream;
let newPeerConnection;

const Video = () => {
  let candidates = useMemo(() => new Map(), []);
  const location = useLocation();
  const { roomId, meetingId } = useParams();
  const navigate = useNavigate();
  const { name } = useLocalStorage();
  const effectRef = useRef(false);
  const date = new Date();
  const msgTime =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  const [userCount, setUserCount] = useState(1);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [icons, setIcons] = useState({
    mic: true,
    camera: false,
    remoteVideo: false,
    // share: true,
  });
  const [socketIds, setSocketIds] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [userImages, setUserImages] = useState(null);

  const initiate = useCallback(async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        newLocalStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        let videoTrack = newLocalStream
          .getTracks()
          .find((track) => track.kind === "video");
        videoTrack.enabled = false;
        setLocalStream(newLocalStream);
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    } else {
      console.error("getUserMedia is not supported in this browser");
    }
  }, []);

  const createPeerConnection = useCallback(async (remoteSocketId) => {
    try {
      console.log(servers);
      newPeerConnection = new RTCPeerConnection(servers);
      newRemoteStream = new MediaStream();
      setRemoteStream(newRemoteStream);
      document.getElementById("user-1").style.display = "flex";
      setUserCount(2);

      newLocalStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: icons.mic,
      });

      let videoTrack = newLocalStream
        .getTracks()
        .find((track) => track.kind === "video");
      videoTrack.enabled = false;
      setLocalStream(newLocalStream);

      newLocalStream.getTracks().forEach(async (track) => {
        await newPeerConnection.addTrack(track, newLocalStream);
      });

      newPeerConnection.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          newRemoteStream.addTrack(track);
        });
      };

      newPeerConnection.onicecandidate = async (event) => {
        if (event.candidate) {
          socket.emit("user:call", {
            to: remoteSocketId,
            data: event.candidate,
            type: "candidates",
          });
        }
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  const createOffer = useCallback(async (data) => {
    await createPeerConnection(data.id);

    newPeerConnection.onnegotiationneeded = async () => {
      try {
        let offer = await newPeerConnection.createOffer();
        await newPeerConnection.setLocalDescription(offer);

        setSocketIds(data.userDetails);

        socket.emit("user:call", {
          to: data.id,
          data: offer,
          type: "offer",
        });
      } catch (error) {
        console.error("Error creating offer:", error);
      }
    };
  }, []);

  const createAnswer = useCallback(async (remoteSocketId, offer) => {
    await createPeerConnection(remoteSocketId);

    await newPeerConnection.setRemoteDescription(offer);

    let answer = await newPeerConnection.createAnswer();
    await newPeerConnection.setLocalDescription(answer);
    candidates.forEach(
      async (value) => await newPeerConnection.addIceCandidate(value)
    );
    socket.emit("user:call", {
      to: remoteSocketId,
      data: answer,
      type: "answer",
    });
  }, []);

  const getSocketId = useCallback(async () => {
    return Object.entries(socketIds)
      .filter(([key]) => key !== location.state.userId)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc[key];
      }, {});
  }, [location.state.userId, socketIds]);

  const handleIncomingMsg = useCallback(
    async (message) => {
      if (message.type === "offer") {
        await createAnswer(message.from, message.data);
      } else if (message.type === "answer") {
        await addAnswer(message.data);
      } else if (message.type === "disconnect") {
        setUserCount(1);
        document.getElementById("user-1").style.display = "none";
        setIcons((prev) => ({
          ...prev,
          camera: false,
          mic: true,
          remoteVideo: false,
        }));
        initiate();
      }
    },
    [createAnswer, initiate]
  );

  const addAnswer = async (answer) => {
    if (!newPeerConnection.currentRemoteDescription) {
      await newPeerConnection.setRemoteDescription(answer);
    }
  };

  const disconnectUser = useCallback(async () => {
    socket.emit("cancel", {
      to: await getSocketId(),
      userId: location.state.userId,
    });
    setSocketIds((prevSocketIds) => {
      const newSocketIds = { ...prevSocketIds };
      delete newSocketIds[location.state.userId];
      return newSocketIds;
    });
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    setLocalStream(null);
    navigate(`/meetings`);
  }, [navigate, localStream, location.state.userId, getSocketId]);

  const toggleCamera = async () => {
    let videoTrack = await localStream
      .getTracks()
      .find((track) => track.kind === "video");
    if (videoTrack.enabled) {
      videoTrack.enabled = false;
      setIcons((prev) => ({ ...prev, camera: false }));
      socket.emit("video:pause", {
        to: await getSocketId(),
        value: false,
      });
    } else {
      videoTrack.enabled = true;
      setIcons((prev) => ({ ...prev, camera: true }));
      socket.emit("video:pause", {
        to: await getSocketId(),
        value: true,
      });
    }
  };

  const toggleMic = async () => {
    let audioTrack = await localStream
      .getTracks()
      .find((track) => track.kind === "audio");
    if (audioTrack.enabled) {
      audioTrack.enabled = false;
      setIcons((prev) => ({ ...prev, mic: false }));
    } else {
      audioTrack.enabled = true;
      setIcons((prev) => ({ ...prev, mic: true }));
    }
  };

  // const shareScreen = async () => {
  //   try {
  //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
  //       video: { mediaSource: "screen" },
  //       cursor: true,
  //     });

  //     // Replace the video track in the peer connection with the screen track
  //     const videoSender = newPeerConnection
  //       .getSenders()
  //       .find((s) => s.track.kind === "video");

  //     if (videoSender) {
  //       videoSender.replaceTrack(screenStream.getTracks()[0]);
  //     } else {
  //       newPeerConnection.addTrack(screenStream.getTracks()[0], screenStream);
  //     }

  //     // Stop the previous video track
  //     if (localStream) {
  //       localStream.getVideoTracks().forEach((track) => track.stop());
  //     }
  //     console.log(screenStream);

  //     // Update localStream with the screen stream
  //     setLocalStream(screenStream);
  //   } catch (error) {
  //     console.error("Error sharing screen:", error);
  //   }
  // };

  const sendMessage = (msg) => {
    if (msg.trim() !== "") {
      socket.emit("send:message", {
        roomId,
        name,
        message: msg,
        time: msgTime,
      });
    }
  };

  const handleUsersId = useCallback((data) => {
    setSocketIds(data);
  }, []);

  const handleUserImages = useCallback((data) => {
    setUserImages(data);
  }, []);

  const handleUserJoined = useCallback(async (data) => {
    // socket.on("send:image", userImages);
    if (data.userId !== location.state.userId) {
      await createOffer(data);
    }
  }, []);

  const handleIncomingCall = useCallback(async (data) => {
    if (data.type === "candidates") {
      if (newPeerConnection && newPeerConnection.currentRemoteDescription) {
        await newPeerConnection.addIceCandidate(data.data);
      } else {
        candidates.set(randomId(), data.data);
      }
    }
    await handleIncomingMsg(data);
  }, []);

  const handleUserDisconnect = useCallback(async (data) => {
    await handleIncomingMsg(data);
  }, []);

  const handleReceiveMessage = useCallback((data) => {
    setMessageList((prev) => [...prev, data]);
  }, []);

  const toggleRemoteVideo = useCallback((data) => {
    setIcons((prev) => ({ ...prev, remoteVideo: data.value }));
  }, []);

  const meetingEnd = useCallback(() => {
    document.getElementById("timer-container").classList.remove("tw-hidden");
    document.getElementById("timer-container").classList.add("tw-flex");
    setTimeout(() => {
      navigate(`/meeting-summary/${meetingId}`);
    }, 120000);
  }, [meetingId, navigate]);

  useEffect(() => {
    let leftPage = false;

    if (!effectRef.current) {
      var peerConfiguration = {};

      (async () => {
        const response = await fetch(
          "https://kritik-kapoor.metered.live/api/v1/turn/credentials?apiKey=01e2e738bb49034fe2cccff15652d42a71a7&region=india"
        );
        const iceServers = await response.json();
        console.log("iceServers", iceServers);
        peerConfiguration.iceServers = await iceServers;
      })();

      initiate();
      socket.emit("room:join", {
        userId: location.state.userId,
        roomId: roomId,
      });
    }

    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave the call ?";
      leftPage = true;
    };

    const userLeft = async () => {
      if (leftPage) {
        await disconnectUser();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", userLeft);

    socket.on("meet:end", meetingEnd);
    socket.on("users:id", handleUsersId);
    socket.on("send:image", handleUserImages);
    socket.on("user:joined", handleUserJoined);
    socket.on("incoming:call", handleIncomingCall);
    socket.on("user:disconnect", handleUserDisconnect);
    socket.on("recieve:message", handleReceiveMessage);
    socket.on("video:pause:status", toggleRemoteVideo);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", userLeft);
      socket.removeAllListeners();
      effectRef.current = true;
    };
  }, [
    initiate,
    createOffer,
    meetingEnd,
    handleUsersId,
    handleUserImages,
    handleUserJoined,
    handleIncomingMsg,
    handleIncomingCall,
    handleUserDisconnect,
    handleReceiveMessage,
    toggleRemoteVideo,
    location.state.userId,
    roomId,
    candidates,
    disconnectUser,
  ]);

  return (
    <section className="tw-grid tw-grid-cols-4 mainSection">
      <div className="tw-relative tw-col-span-3">
        <LocalVideo
          localStream={localStream}
          userImages={userImages}
          userId={location.state.userId}
          camera={icons.camera}
        />
        <RemoteVideo
          remoteStream={remoteStream}
          localStream={localStream}
          userCount={userCount}
          userImages={userImages}
          userId={location.state.userId}
          camera={userCount === 1 ? icons.camera : icons.remoteVideo}
        />
        <div className="videoControls">
          {userCount !== 1 && (
            <>
              <span
                className={`controls ${
                  icons.mic ? "tw-bg-green-400 " : "tw-bg-[#ff4646]"
                }`}
                onClick={() => toggleMic()}
              >
                {icons.mic ? <MicOnIcon /> : <MicOffIcon />}
              </span>
              <span
                className={`controls ${
                  icons.camera ? "tw-bg-green-400 " : "tw-bg-[#ff4646]"
                }`}
                onClick={() => toggleCamera()}
              >
                {icons.camera ? <VideoOnIcon /> : <VideoOffIcon />}
              </span>
            </>
          )}
          {/* <span
            className={`controls ${
              icons.share ? "tw-bg-green-400" : "tw-bg-[#ff4646]"
            }`}
            onClick={() => shareScreen()}
          >
            {<ShareScreenIcon />}
          </span> */}
          <span
            className="controls tw-bg-[#ff4646]"
            onClick={() => disconnectUser()}
          >
            {<PhoneIcon fill="#fff" width="28px" height="28px" />}
          </span>
        </div>
        <div id="timer-container" className="tw-hidden">
          <p className="tw-font-medium tw-mb-3">
            Meeting will end in 2 minutes.
          </p>
          <Button
            onClick={() => {
              document
                .getElementById("timer-container")
                .classList.add("tw-hidden");
              document
                .getElementById("timer-container")
                .classList.remove("tw-flex");
            }}
            className="tw-px-2 tw-py-1"
          >
            Okay
          </Button>
        </div>
      </div>
      <Chat sendMessage={sendMessage} messageList={messageList} />
    </section>
  );
};

export default Video;
