import { Link } from "react-router-dom";
import FavIcon from "../../../../images/favicon.png";
import {
  CalenderIcon,
  CoinsIcon,
  FolderIcon,
  LocationIcon,
} from "../../../../icons/icons";

const InvestorDetails = ({ details, flag, handleSuspension }) => {
  return (
    <div className="tw-grid tw-grid-cols-6 tw-gap-2">
      <div className="tw-col-span-6 md:tw-col-span-2">
        <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-text-center">
          <div className="tw-flex tw-items-start tw-justify-between">
            <img
              src={details?.logo?.length < 1 ? FavIcon : details?.logo}
              className="tw-rounded-full tw-h-[90px] tw-w-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-1"
              alt="profile-img"
            />
            {details?.is_suspend === "0" ? (
              <button
                className="md:tw-hidden tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                id="suspend-btn"
                onClick={() => handleSuspension("suspend")}
              >
                Suspend
              </button>
            ) : (
              <button
                className="md:tw-hidden tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-green-500 tw-text-white"
                id="unsuspend-btn"
                onClick={() => handleSuspension("unsuspend")}
              >
                Un-Suspend
              </button>
            )}
          </div>
          <div className="tw-text-start">
            <p className="tw-flex tw-items-center tw-font-bold tw-text-lg tw-pt-2 tw-mb-0 tw-uppercase">
              {details.name}
            </p>
            <div className="tw-flex tw-items-center tw-mb-2">
              <CalenderIcon />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-font-bold tw-me-1">Joined On :</span>
                {details?.details?.date}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <CoinsIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-font-bold tw-me-1">Total Investment :</span>
                {details?.details?.invested_amount}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <CoinsIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-font-bold tw-me-1">
                  Investment Target :
                </span>
                {details?.details?.future_investment_amount}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <FolderIcon />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-font-bold tw-me-1">
                  Companies in portfolio :
                </span>{" "}
                {details?.details?.companies_invested_count}
              </p>
            </div>
            <div className="tw-flex tw-mb-4">
              <LocationIcon fill="#9c9c9c" />
              <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-font-bold tw-me-1">Address :</span>
                <div className="tw-flex tw-items-center">
                  <span className="tw-me-1">{details.city}</span>
                  <img
                    src={
                      flag && `${process.env.REACT_APP_API_URL}` + flag.slice(6)
                    }
                    alt={`${details.country}-flag`}
                    width={18}
                    title={details.country}
                  />
                </div>
              </div>
            </div>
            <div className="tw-border-t tw-py-2">
              <p className="tw-mb-0 tw-font-bold">Industries</p>
              {details?.details?.investor_industries?.map((industry, i) => (
                <span key={i} className="outline-tags">
                  {industry.replace(new RegExp("_", "g"), " ")}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="tw-col-span-6 md:tw-col-span-4">
        {details?.is_suspend === "0" ? (
          <button
            className="md:tw-hidden tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
            id="suspend-btn"
            onClick={() => handleSuspension("suspend")}
          >
            Suspend
          </button>
        ) : (
          <button
            className="md:tw-hidden tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-green-500 tw-text-white"
            id="unsuspend-btn"
            onClick={() => handleSuspension("unsuspend")}
          >
            Un-Suspend
          </button>
        )}
        {details?.details?.description?.length > 0 ? (
          <div className="tw-h-[270px]">
            <div className="tw-mt-4 tw-p-3 tw-pt-1 tw-shadow-md tw-rounded-md tw-h-full tw-overflow-auto">
              <h4 className="tw-my-3 tw-font-medium tw-text-lg">Bio</h4>
              <p>{details?.details?.description}</p>
            </div>
          </div>
        ) : (
          <div className="tw-rounded-md tw-bg-white tw-shadow-md tw-mt-3 tw-p-3">
            No Bio added
          </div>
        )}
        {details?.details?.prevInvestments?.length > 0 ? (
          <>
            <div className="tw-p-2 tw-rounded-md tw-bg-white tw-shadow tw-mt-3 tw-py-3">
              <h5 className="tw-font-medium tw-mb-3">
                Previously Invested Companies
              </h5>
              {details?.details?.prevInvestments?.map((investment) => (
                <div
                  key={investment._id}
                  className="tw-flex tw-items-center tw-justify-between tw-p-2 tw-my-1 tw-bg-gray-200 tw-rounded"
                >
                  <div>
                    <span className="tw-font-medium tw-me-2">
                      Company Name :
                    </span>
                    <span>{investment.company_name}</span>
                  </div>
                  <Link
                    to={"https://" + investment.company_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="tw-font-medium tw-me-2">
                      Website Url :
                    </span>
                    <span>{investment.company_url}</span>
                  </Link>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="tw-rounded-md tw-bg-white tw-shadow-md tw-mt-3 tw-p-3">
            No previous investments
          </div>
        )}
      </div>
    </div>
  );
};

export default InvestorDetails;
