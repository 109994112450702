import { Link } from "react-router-dom";
import "./Card.css";
import profileBgImg from "../../../images/border-img.png";
import FavIcon from "../../../images/favicon.png";
import { OutlineStarIcon } from "../../../icons/icons";

export const VendorCard = ({ data, status }) => {
  const trimLogo = data?.logo;

  return (
    <div className="tw-shadow-md tw-h-max user-card">
      <div className="tw-card tw-rounded-b-none tw-bg-[#EAE7DC] tw-cursor-pointer">
        <Link
          to={`/view-vendor/${data?.company_name}/${data?.id}`}
          state={{
            active: status === "active" ? true : false,
          }}
        >
          <div className="tw-flex tw-items-center tw-justify-between">
            <img
              src={
                data?.flag_path.length > 0 &&
                `${process.env.REACT_APP_API_URL}` + data?.flag_path.slice(6)
              }
              title={data?.country}
              alt="country-flag"
              width={40}
              loading="lazy"
            />
            {data.average_rating > 0 && (
              <div className="rating-tag tw-text-sm tw-p-1">
                {data.average_rating}
                <OutlineStarIcon fill="#fff" />
              </div>
            )}
          </div>
          <div className="tw-text-center tw-bg-transparent tw-border-0 tw-pt-4 tw-pb-2">
            <div
              className="cstm-circle-border"
              style={{ backgroundImage: `url(${profileBgImg})` }}
            >
              <img
                src={data?.logo.length > 0 ? trimLogo : FavIcon}
                className="tw-rounded-full"
                alt="vendor-logo"
                width={100}
                loading="lazy"
              />
            </div>
          </div>
          <div className="tw-p-2 tw-pt-0">
            <h5 className="tw-py-2 tw-text-center tw-font-semibold">
              {data?.company_name}
            </h5>
          </div>
        </Link>
      </div>
      {data?.expert_in.length > 0 && (
        <div className="tw-text-white tw-p-2 tw-bg-primary">
          <ul className="tw-text-center tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-gap-1">
            {data.expert_in
              .filter((_, i) => i < 2)
              .map((industry) => {
                return (
                  <li className="industry-tags" key={industry}>
                    {industry.replace(new RegExp("_", "g"), " ")}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};
