import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { deleteUser, setUser } from "../../store/slices/LoginSlice";
import useFetch from "../../hooks/useFetch";
import { Button } from "../../components/ui/Button";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { EnvelopeIcon, EyeIcon, SlashedEyeIcon } from "../../icons/icons";

const AdminLogin = () => {
  const loginApi = `${process.env.REACT_APP_API_URL}admin-login`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isLoading, error, fetchApi } = useFetch();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);
  const [show, setShow] = useState(false);

  const postLogin = (data) => {
    if (data.message === "invalid") {
      setIsError(true);
      return;
    }
    dispatch(setUser({ id: data.jwt_token, role: data.role, name: data.name }));
    navigate("/admin-dashboard");
  };

  const LoginApiCall = (data, e) => {
    e.preventDefault();
    setIsError(false);
    fetchApi(
      {
        url: loginApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: { email: data.loginEmail, password: data.password },
      },
      postLogin
    );
  };

  useEffect(() => {
    dispatch(deleteUser());
    return () => {};
  }, [dispatch]);

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-h-screen">
      <div className="tw-w-[90%] md:tw-w-[40%] tw-shadow-md tw-p-2 tw-rounded-md">
        {error && <Error />}
        <h4 className="tw-mb-4 tw-text-lg tw-font-semibold tw-text-center">
          Admin Login
        </h4>
        <form onSubmit={handleSubmit(LoginApiCall)}>
          <div className="tw-mb-1">
            <label htmlFor="loginEmail" className="tw-mb-0 tw-text-sm required">
              Email
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="email"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="loginEmail"
                name="loginEmail"
                placeholder="Registered email"
                {...register("loginEmail", {
                  required: true,
                  validate: {
                    matchPattern: (value) =>
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                        value
                      ) || "Please enter a valid email address",
                  },
                })}
                autoFocus
              />
              <span className="input-icons icon-span">
                <EnvelopeIcon />
              </span>
            </div>
            {errors.loginEmail && (
              <span className="tw-text-secondary tw-text-sm">
                Email is required
              </span>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="password" className="tw-mb-0 tw-text-sm required">
              Password
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={show ? "text" : "password"}
                id="password"
                className="tw-border-0 tw-w-full tw-ps-3"
                name="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: true,
                  minLength: 7,
                })}
              />
              <span
                className="input-icons tw-cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.password && (
              <span className="tw-text-secondary tw-text-sm">
                Password is required
              </span>
            )}
          </div>
          <div className="tw-pt-2">{isLoading && <Loader pt="10px" />}</div>
          {!error && isError && (
            <p className="tw-text-red-500 passwordError">
              Incorrect email or password.
            </p>
          )}
          <Button type="submit" className="tw-p-2 tw-mt-2">
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
