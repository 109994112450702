import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useFetch from "../../hooks/useFetch";
import useUploadImage from "../../hooks/useUploadImage";
import { ImportImage } from "../../utils/importImage";
import { randomId } from "../../utils/randomId";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { EditVendorProfile } from "../../components/ui/modals/profile/editVendorProfile";
import { DeleteModal } from "../../components/ui/modals/utility-modals/deleteModal";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import { AddPortfolioModal } from "../../components/ui/modals/profile/addPortfolio";
import { BankAccountModal } from "../../components/ui/modals/bank/bankAccountModal";
import { EditBankAccountModal } from "../../components/ui/modals/bank/editBankAccountModal";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import { CameraIcon, DeleteIcon, PenIcon } from "../../icons/icons";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";

const VendorProfile = () => {
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}display-vendor-profile`;
  const editVendorProfileApi = `${process.env.REACT_APP_API_URL}update-vendor-profile`;
  const vendorPortfolioApi = `${process.env.REACT_APP_API_URL}vendor-portfolio`;
  const deletePortfolioFileApi = `${process.env.REACT_APP_API_URL}delete-portfolio-file`;
  const deleteBankDetailsApi = `${process.env.REACT_APP_API_URL}delete-bank-details`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { updateProfile } = useUpdateProfile();
  const { uploadImage } = useUploadImage();
  const { displayIndustries } = useDisplayIndustries();
  const navigate = useNavigate();

  const [vendorDetails, setVendorDetails] = useState({
    name: "",
    email: "",
    phone: 0,
    companyName: "",
    address: "",
    logo: "",
    industry: [],
    website: "",
    documents_count: 0,
    documents: [],
  });
  const ref = useRef(true);
  const [desc, setDesc] = useState("");
  const [bank, setBank] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [portfolioModal, setPortfolioModal] = useState(false);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState({});
  const [singleBank, setSingleBank] = useState([]);
  const [openEditBank, setOpenEditBank] = useState(false);
  const [addBank, setAddBank] = useState(false);
  const [type, setType] = useState("portfolio");

  const getVendorData = useCallback(() => {
    fetchApi(
      {
        url: vendorDetailsApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setVendorDetails({
          name: data.name,
          email: data.email,
          phone: data.phone,
          companyName: data.company_name,
          city: data.city,
          country: data.country,
          industry: data.expert_in,
          logo: data.logo,
          website: data.website_url,
          documents_count: data.documents_count,
          documents: data.documents,
          flag: data.flag_path.slice(6),
        });
        setDesc(data.description);
        setEditData({
          name: data.name,
          phone: data.phone,
          companyName: data.company_name,
          industry: data.expert_in,
        });
        setBank(data.bank_details);
      }
    );
  }, [vendorDetailsApi, fetchApi, token]);

  const editVendorDetails = (data, expertise) => {
    let dataObj = {
      name: data.vendorName,
      phone: data.vendorPhone,
      company_name: data.vendorCompany,
    };
    if (expertise.length > 0) {
      dataObj = { ...dataObj, expert_in: expertise };
    }
    updateProfile(editVendorProfileApi, dataObj, getVendorData, "vendor");
  };

  useEffect(() => {
    if (userRole !== "V&iR8") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getVendorData();
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    }
    return () => {
      ref.current = false;
    };
  }, [getVendorData, navigate, userRole, displayIndustries]);

  const vendorPortfolio = (url, file, setPortfolioFile) => {
    setLimitError(false);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("website_url", url);
    axios
      .post(vendorPortfolioApi, formData, {
        headers: { "x-token": token },
      })
      .then((data) => {
        if (data.data.message === "over limit") {
          setLimitError(true);
        }
        setPortfolioFile();
        getVendorData();
      })
      .catch((err) => {
        if (err.response.status) {
          alert("File must be of type ( .png, .jpg, .jpeg, pdf )");
        }
      });
  };

  const deletePortfolioFile = (id) => {
    fetchApi(
      {
        url: deletePortfolioFileApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      () => {
        getVendorData();
      }
    );
  };

  const deleteBankDetails = (bankId) => {
    fetchApi(
      {
        url: deleteBankDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: bankId,
        },
      },
      () => getVendorData()
    );
  };

  const handleModal = (type, data) => {
    if (type === "edit") {
      setSingleBank(data);
      setOpenEditBank(true);
    } else {
      setAddBank(true);
    }
  };

  const handleDeleteModal = (id, type) => {
    setId(id);
    setType(type);
    setOpenDeleteModal(true);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-3 md:tw-p-4">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <div>
              <div
                className="tw-relative tw-hidden md:tw-block tw-bg-cover tw-h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div>
                <div className="tw-grid tw-grid-cols-5 tw-px-3 tw-gap-x-1 tw-shadow-md tw-rounded-md">
                  <div className="tw-col-span-5 tw-ml-auto md:tw-hidden">
                    <button
                      type="button"
                      className="profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-text-xs md:tw-text-sm"
                      onClick={() => setOpen(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="tw-col-span-5 md:tw-col-span-1 tw-relative tw-flex tw-items-end tw-justify-center">
                    <img
                      src={
                        vendorDetails.logo.length > 0
                          ? vendorDetails.logo
                          : FavIcon
                      }
                      className="profile-img tw-shadow tw-w-[90px] tw-h-[90px] md:tw-w-[140px] md:tw-h-[140px]"
                      alt="profile-img"
                    />
                    <div className="img-icon tw-bg-primary tw-rounded-full tw-p-1">
                      <label
                        htmlFor="uploadImg"
                        className="tw-mb-0 tw-cursor-pointer"
                      >
                        <CameraIcon fill="#fff" />
                      </label>
                      <input
                        type="file"
                        id="uploadImg"
                        name="uploadImg"
                        accept="image/png,image/jpeg"
                        className="tw-hidden"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], getVendorData)
                        }
                      />
                    </div>
                    {vendorDetails.logo.length < 1 && (
                      <p className="tw-text-red-500 tw-font-medium tw-absolute tw-top-[100px] md:tw-top-[85px]">
                        Upload image
                      </p>
                    )}
                  </div>
                  <div className="tw-relative tw-col-span-5 md:tw-col-span-4 tw-pb-4 tw-mb-5 md:tw-pl-[3.5rem] 2xl:tw-pl-0">
                    <button
                      type="button"
                      className="tw-hidden md:tw-block profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-absolute tw-right-0 md:tw-top-unset md:tw-bottom-2.5 tw-text-xs md:tw-text-sm"
                      onClick={() => setOpen(true)}
                    >
                      Edit
                    </button>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-mt-10 tw-gap-y-1 md:tw-mt-2">
                      <div className="md:tw-col-span-3 tw-grid tw-grid-cols-2">
                        <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                          Name :{" "}
                          <span className="tw-uppercase tw-font-medium">
                            {vendorDetails.name}
                          </span>
                        </p>
                        <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                          Email :{" "}
                          <span className="tw-font-medium">
                            {vendorDetails.email}
                          </span>
                        </p>
                        <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                          Company :{" "}
                          <span className="tw-font-medium">
                            {vendorDetails.companyName}
                          </span>
                        </p>
                      </div>
                      <p className="md:tw-col-span-2 tw-font-bold tw-flex tw-items-center">
                        Address :{" "}
                        <span className="tw-flex tw-items-center tw-ms-1 tw-font-medium tw-capitalize">
                          {vendorDetails.country?.length > 0 ? (
                            <>
                              <span className="tw-pe-1">
                                {vendorDetails.city}
                              </span>
                              <img
                                src={
                                  vendorDetails.flag.length > 0 &&
                                  `${process.env.REACT_APP_API_URL}` +
                                    vendorDetails.flag
                                }
                                alt="country-flag"
                                width={30}
                                title={vendorDetails.country}
                              />
                            </>
                          ) : (
                            <span className="tw-text-red-500 tw-text-sm">
                              Missing
                            </span>
                          )}
                        </span>
                      </p>
                      <p className="md:tw-col-span-3 tw-font-bold">
                        Industries :{" "}
                        <span className="tw-ms-1 tw-font-medium">
                          {vendorDetails.industry.map((industry, i) => (
                            <span className="outline-tags" key={i}>
                              {industry.replace(new RegExp("_", "g"), " ")}
                            </span>
                          ))}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-mt-2 tw-mx-0 tw-px-0">
                  <div className="ideaContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-my-2 tw-relative">
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <h5 className="tw-font-semibold">Company Bio</h5>
                      <p
                        className="outline-btn tw-text-xs md:tw-text-sm"
                        type="button"
                        onClick={() => setDescriptionModal(true)}
                      >
                        Write here
                      </p>
                    </div>
                    {!desc ? (
                      <p id="noIdea" className="tw-mt-3 tw-text-sm">
                        Tell us about your company.
                      </p>
                    ) : (
                      <div className="tw-mt-3 tw-text-sm">{desc}</div>
                    )}
                  </div>
                  <div className="portfolioContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-my-2 tw-relative">
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <h5 className="tw-font-semibold">Portfolio</h5>
                      <p
                        className="outline-btn tw-text-xs md:tw-text-sm"
                        type="button"
                        onClick={() => setPortfolioModal(true)}
                      >
                        Add
                      </p>
                    </div>
                    {vendorDetails.website.length < 1 &&
                    vendorDetails.documents_count < 1 ? (
                      <div id="noIdea" className="tw-mt-3 tw-text-sm">
                        Upload portfolio
                      </div>
                    ) : (
                      <div className="tw-mt-3 tw-text-sm">
                        <p className="tw-font-medium">
                          Website url :{" "}
                          <a
                            href={"https://" + vendorDetails.website}
                            target="_blank"
                            rel="noreferrer"
                            className="tw-font-normal"
                          >
                            {vendorDetails.website || (
                              <span className="tw-text-secondary tw-text-sm tw-font-medium">
                                Missing
                              </span>
                            )}
                          </a>
                        </p>
                        <div className="tw-mt-2">
                          <p className="tw-font-medium">
                            Files uploaded ({vendorDetails.documents_count}/5)
                          </p>
                          {limitError &&
                            vendorDetails.documents_count === 5 && (
                              <p className="tw-text-red-500 tw-text-xs">
                                You have already uploaded 5 files
                              </p>
                            )}
                          {vendorDetails.documents.length > 0 &&
                            vendorDetails.documents.map((file) => (
                              <div
                                key={file._id}
                                className="profile-detail-card tw-relative"
                              >
                                <Link
                                  to={file.path}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-rounded-md tw-bg-gray-200 hover:tw-opacity-50 tw-text-xs md:tw-text-sm"
                                >
                                  {file.file_name}
                                </Link>
                                <DeleteIcon
                                  className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                                  onClick={() =>
                                    handleDeleteModal(file._id, "portfolio")
                                  }
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="bankDetailsContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                    <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                      <h5 className="tw-font-semibold">Bank Details</h5>
                      {bank.length < 1 && (
                        <p
                          className="outline-btn tw-text-xs md:tw-text-sm"
                          onClick={() => handleModal("add")}
                          type="button"
                        >
                          Add new
                        </p>
                      )}
                    </div>
                    <div
                      className="tw-grid tw-grid-cols-1 tw-mt-3"
                      id="bank-details"
                    >
                      {bank.length > 0 ? (
                        bank.map((bank) => (
                          <div
                            className="profile-detail-card tw-relative"
                            key={bank._id}
                          >
                            <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-rounded-md tw-bg-gray-200 hover:tw-opacity-50">
                              <div>
                                <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                                  Account Holder:
                                  <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                                    {bank.name}
                                  </span>
                                </p>
                                <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                                  Account Number:
                                  <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                                    {bank.account_number}
                                  </span>
                                </p>
                              </div>
                              <ImportImage
                                imageName={bank.bank_name}
                                width={45}
                                alt={bank.bank_name}
                              />
                            </div>
                            <DeleteIcon
                              className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                              onClick={() =>
                                handleDeleteModal(bank._id, "bank")
                              }
                            />
                            <PenIcon
                              className="edit-account-details tw-absolute tw-right-[10%] tw-top-0 tw-cursor-pointer"
                              onClick={() => handleModal("edit", bank)}
                            />
                          </div>
                        ))
                      ) : (
                        <p id="noBankDetails">No Bank Account found.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <EditVendorProfile
        key={randomId()}
        open={open}
        onClose={() => setOpen(false)}
        editVendorDetails={editVendorDetails}
        editData={editData}
        industries={industries}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deletePortfolioFile={deletePortfolioFile}
        deleteBankDetails={deleteBankDetails}
        id={id}
        type={type}
      />
      <DescriptionModal
        key={randomId()}
        open={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        callBackFn={() => getVendorData()}
        url={editVendorProfileApi}
        data={desc}
      />
      <AddPortfolioModal
        key={randomId()}
        open={portfolioModal}
        onClose={() => setPortfolioModal(false)}
        vendorPortfolio={vendorPortfolio}
        url={vendorDetails.website}
      />
      <BankAccountModal
        open={addBank}
        onClose={() => setAddBank(false)}
        callBackFn={getVendorData}
      />
      <EditBankAccountModal
        key={singleBank._id}
        open={openEditBank}
        onClose={() => setOpenEditBank(false)}
        singleBank={singleBank}
        bankId={singleBank._id}
        callBackFn={getVendorData}
      />
    </div>
  );
};

export default VendorProfile;
