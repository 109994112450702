import { Link } from "react-router-dom";
import AdminSidebarMain from "./adminSidebarMain";
import "../../Pages/admin-portal/admin-dashboard/adminDashboard.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";

const RegularAdminSidebar = () => {
  const currPath = window.location.hash;
  const activeStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-bg-primary tw-text-white hover:tw-text-white tw-rounded-md tw-cursor-pointer";
  const regularStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-rounded-md tw-cursor-pointer hover:tw-bg-customOrange hover:tw-text-white";

  return (
    <div
      className="left-half tw-h-full tw-bg-[#f0f8ff] tw-text-left tw-shadow-lg"
      id="sidebar"
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-py-2">
        <Link to="#">
          <img src={pitchHereLogo} alt="logo" width={200} />
        </Link>
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          <AdminSidebarMain
            currPath={currPath}
            activeStyle={activeStyle}
            regularStyle={regularStyle}
          />
        </div>
      </nav>
      <Link
        to="/"
        className="tw-bg-customOrange tw-text-white tw-p-2 tw-rounded-md tw-w-full tw-block tw-text-center"
      >
        <span className="tw-ps-2 tw-tracking-normal">Logout</span>
      </Link>
    </div>
  );
};

export default RegularAdminSidebar;
