import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";
import { CircleCheck } from "../../../../icons/icons";

export const SuccessModal = ({ open, onClose, text }) => {
  const navigate = useNavigate();
  if (!open) return null;

  const closeModal = () => {
    navigate("/investor-investments");
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] sm:tw-w-[350px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={() => closeModal()} className="closeBtn">
          X
        </p>
        <div className="tw-p-3 tw-pb-0 tw-mt-2 tw-text-center">
          <CircleCheck
            width="70px"
            height="70px"
            fill="#1bcf1b"
            className="tw-mx-auto"
          />
          <p className="tw-text-lg tw-text-green-500 tw-mb-2">Success</p>
          <p>{text}</p>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
