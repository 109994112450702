import { useState, useCallback } from "react";
import useToken from "./useLocalStorage";
import useFetch from "./useFetch";

export const useFetchCredits = () => {
  const displayCreditsApi = `${process.env.REACT_APP_API_URL}display-wallet-credits`;
  const [credits, setCredits] = useState(0);
  const { token } = useToken();
  const { fetchApi } = useFetch();

  const getCredits = useCallback(() => {
    fetchApi(
      { url: displayCreditsApi, headers: { "x-token": token } },
      (data) => {
        setCredits(data.credits);
      }
    );
  }, [fetchApi, displayCreditsApi, token]);

  return { credits, getCredits };
};
