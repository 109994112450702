import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useFetchCredits } from "../../hooks/useFetchCredits";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckConnection from "../../hooks/useCheckConnection";
import useUnfollowConnection from "../../hooks/useUnfollowConnection";
import useVerificationApi from "../../hooks/useVerificationApi";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import { useToastContext } from "../../context/ToastContext";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { Stars } from "../../components/ui/modals/rating-modal/Stars";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import "./viewProfile.css";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import {
  BriefCaseIcon,
  BuildingIcon,
  LocationIcon,
  RupeeIcon,
} from "../../icons/icons";

//Before redirecting to chat call getRoomId api
const ViewMentorProfile = () => {
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}display-mentor-profile`;
  const sendConnectionRequestApi = `${process.env.REACT_APP_API_URL}send-connect-request`;
  const acceptOrCancelApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;
  const reviewsCountApi = `${process.env.REACT_APP_API_URL}reviews-count`;

  const { isLoading, error, fetchApi } = useFetch();
  const { credits, getCredits } = useFetchCredits();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const { checkConnection } = useCheckConnection();
  const { unFollow } = useUnfollowConnection();
  const { verification } = useVerificationApi();
  const { displayFiles } = useDisplayFiles();
  const toast = useToastContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const [mentorDetails, setMentorDetails] = useState([]);
  const [latestReviews, setLatestReviews] = useState([]);
  const [video, setVideo] = useState([]);
  const [mentorShortDesc, setMentorShortDesc] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(false);
  const ref = useRef(true);

  const reviewsCount = useCallback(() => {
    fetchApi(
      {
        url: reviewsCountApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          user_id: user_id,
        },
      },
      (data) => {
        setReviewCount(data.review_count);
      }
    );
  }, [reviewsCountApi, fetchApi, token, user_id]);

  const getMentorDetails = useCallback(async () => {
    if (!user_id) {
      toast.open("false", "Sorry, something went wrong. Please try again");
      console.log("user_id not found");
      return;
    }
    fetchApi(
      {
        url: mentorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          toast.open("false", "This accound has been suspended or Deactivated");
          navigate(NavigateToDashboard(userRole));
          return;
        }
        setMentorDetails(data.mentor_profile);
        setLatestReviews(data.latest_reviews);
        setProfileImg(data.mentor_profile[0]?.logo);
        setMentorShortDesc(data.mentor_profile[0]?.short_description);
        setAverageRating(data.mentor_profile[0]?.average_rating);
      }
    );
  }, [user_id, mentorDetailsApi, token, fetchApi, navigate, userRole, toast]);

  useEffect(() => {
    if (userRole === "V&iR8") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getMentorDetails();
      displayFiles((data) => setVideo(data.video), user_id);
      verification(user_id, "verify", setVerifiedStatus);
      checkConnection(user_id, setConnectionStatus);
      reviewsCount();
      getCredits();
    }
    return () => {
      ref.current = false;
    };
  }, [
    verification,
    getMentorDetails,
    displayFiles,
    checkConnection,
    reviewsCount,
    getCredits,
    navigate,
    userRole,
    user_id,
  ]);

  const acceptOrCancelReq = (type) => {
    fetchApi(
      {
        url: acceptOrCancelApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          type: type,
        },
      },
      (data) => {
        if (data.message === "already cancelled") {
          toast.open("warning", data.message);
          setConnectionStatus("disconnect");
        }
        if (data.message === "cancelled") {
          setConnectionStatus("disconnect");
          sendNotification(
            user_id,
            `Connection cancelled from ${data.name}`,
            "connections"
          );
        }
        if (data.message === "connected") {
          toast.open("warning", "User has already accepted this request");
          setConnectionStatus("connected");
        }
      }
    );
  };

  const sendConnectionRequest = useCallback(() => {
    fetchApi(
      {
        url: sendConnectionRequestApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
        },
      },
      (data) => {
        setConnectionStatus("pending");
        sendNotification(
          user_id,
          `Incoming connection request from ${data.name}`,
          "connections"
        );
      }
    );
  }, [sendConnectionRequestApi, fetchApi, token, user_id, sendNotification]);

  const routeToChat = () => {
    dispatch(deleteChatRoom());
    navigate("/chat");
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-3 md:tw-p-5">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <>
              <div className="tw-grid tw-grid-cols-7 tw-gap-2">
                {userRole === "f@!3A" && (
                  <div className="tw-col-span-7 tw-ml-auto md:tw-hidden">
                    {connectionStatus === "connected" ? (
                      <>
                        {verifiedStatus && (
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-[#F97316] tw-text-white"
                            id="review-btn"
                            onClick={() => setOpenReviewModal(true)}
                          >
                            Review
                          </button>
                        )}
                        <button
                          className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white"
                          id="msg-btn"
                          onClick={() => routeToChat()}
                        >
                          Message
                        </button>
                        <button
                          className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                          id="disconnect-btn"
                          onClick={() => unFollow(user_id, setConnectionStatus)}
                        >
                          Disconnect
                        </button>
                      </>
                    ) : connectionStatus === "pending" ? (
                      <button
                        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-orange-700 tw-text-white"
                        id="cancel-btn"
                        onClick={() => acceptOrCancelReq("cancel")}
                      >
                        Cancel
                      </button>
                    ) : null}
                    {connectionStatus === "disconnect" && credits < 1000 ? (
                      <Link
                        to="/subscription"
                        className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white"
                        title="Insufficient tokens, buy subscription"
                      >
                        Connect
                      </Link>
                    ) : null}
                    {connectionStatus === "disconnect" && credits > 999 && (
                      <button
                        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                        id="connect-btn"
                        onClick={() => sendConnectionRequest()}
                      >
                        Connect
                      </button>
                    )}
                    {connectionStatus === "block" && (
                      <p className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white">
                        Blocked
                      </p>
                    )}
                  </div>
                )}

                <div className="tw-col-span-7 tw-bg-white tw-shadow-md tw-p-3 tw-rounded-md md:tw-col-span-3">
                  <div className="tw-flex tw-justify-between tw-items-start md:tw-justify-center">
                    <img
                      src={profileImg.length < 1 ? FavIcon : profileImg}
                      className="tw-rounded-full tw-w-[90px] tw-h-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-2"
                      alt="profile-img"
                    />
                    <div className="tw-flex tw-items-center md:tw-hidden">
                      <Stars rating={averageRating} size={12} />
                      <span className="tw-px-1 md:tw-px-2">
                        ({reviewCount})
                      </span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "R$7s2",
                        }}
                        className="tw-text-blue-500 tw-text-sm"
                      >
                        Reviews
                      </Link>
                    </div>
                  </div>
                  {mentorDetails.map((mentor) => (
                    <div className="tw-text-start" key={mentor.user_id}>
                      <p className="tw-flex tw-items-center tw-pt-2 tw-mb-0 tw-font-bold tw-text-lg tw-uppercase">
                        {mentor.name}
                      </p>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <RupeeIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-font-bold tw-me-1">
                            Session Fees :
                          </span>
                          ₹ {mentor.fee}
                        </p>
                      </div>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <BriefCaseIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-font-bold tw-me-1">
                            Experience :
                          </span>
                          {mentor.work_experience}{" "}
                          <span className="tw-ms-1">years +</span>
                        </p>
                      </div>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <BuildingIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3 tw-capitalize">
                          <span className="tw-font-bold tw-me-1">
                            Previous/Current Company :
                          </span>
                          {mentor.previous_company}{" "}
                        </p>
                      </div>
                      <div className="tw-flex tw-mb-4">
                        <LocationIcon fill="#999999" />
                        <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-font-bold tw-me-1">
                            Address :
                          </span>
                          <div className="tw-flex tw-items-center">
                            <span className="tw-me-1 tw-capitalize">
                              {mentor.city}
                            </span>
                            <img
                              src={
                                mentor.flag_path &&
                                `${process.env.REACT_APP_API_URL}` +
                                  mentor.flag_path.slice(6)
                              }
                              alt={`${mentor.country}-flag`}
                              width={18}
                              title={mentor.country}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tw-border-t tw-py-2">
                        <p className="tw-mb-0 tw-font-bold">Industries</p>
                        {mentor.mentor_industry.map((industry, i) => (
                          <span key={i} className="outline-tags">
                            {industry.replace(new RegExp("_", "g"), " ")}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tw-col-span-7 md:tw-col-span-4">
                  <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between tw-p-3 tw-bg-white tw-shadow-md tw-rounded-md">
                    <div className="tw-flex tw-items-center">
                      <Stars rating={averageRating} size={"15px"} />
                      <span className="tw-px-2">({reviewCount})</span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "R$7s2",
                        }}
                        className="tw-text-blue-500 tw-text-sm"
                      >
                        Reviews
                      </Link>
                    </div>
                    {userRole === "f@!3A" && (
                      <div className="tw-flex tw-items-center">
                        {connectionStatus === "connected" ? (
                          <>
                            {verifiedStatus && (
                              <button
                                className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-[#F97316] tw-text-white"
                                id="review-btn"
                                onClick={() => setOpenReviewModal(true)}
                              >
                                Review
                              </button>
                            )}
                            <button
                              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white"
                              id="msg-btn"
                              onClick={() => routeToChat()}
                            >
                              Message
                            </button>
                            <button
                              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                              id="disconnect-btn"
                              onClick={() =>
                                unFollow(user_id, setConnectionStatus)
                              }
                            >
                              Disconnect
                            </button>
                          </>
                        ) : connectionStatus === "pending" ? (
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-orange-700 tw-text-white"
                            id="cancel-btn"
                            onClick={() => acceptOrCancelReq("cancel")}
                          >
                            Cancel
                          </button>
                        ) : null}
                        {connectionStatus === "disconnect" && credits < 1000 ? (
                          <Link
                            to="/subscription"
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white disabled"
                            title="Insufficient tokens, buy subscription"
                          >
                            Connect
                          </Link>
                        ) : null}
                        {connectionStatus === "disconnect" && credits > 999 && (
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="connect-btn"
                            onClick={() => sendConnectionRequest()}
                          >
                            Connect
                          </button>
                        )}
                        {connectionStatus === "block" && (
                          <p className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white">
                            Blocked
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="tw-col-span-7 tw-gap-3">
                    <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-mt-3 tw-h-full tw-overflow-auto">
                      <h5 className="tw-text-slate-400 tw-pb-3">About Me</h5>
                      <p className="tw-mb-0 tw-text-xs">{mentorShortDesc}</p>
                    </div>
                    <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-mt-3">
                      <h5 className="tw-text-slate-400 tw-pb-3">Video</h5>
                      {video.length > 0 ? (
                        <video
                          controls
                          className="tw-w-full"
                          id="videoFile"
                          src={video}
                          style={{ height: "200px" }}
                        >
                          Video uploaded
                        </video>
                      ) : (
                        <p>No video uploaded.</p>
                      )}
                    </div>
                    <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-2 tw-mt-3">
                      {latestReviews && latestReviews.length > 0 ? (
                        latestReviews.map((review) => (
                          <div
                            key={review.id}
                            className="tw-bg-gray-200 tw-rounded-md tw-p-2 tw-my-1"
                          >
                            <div className="tw-flex tw-justify-between tw-items-center tw-text-sm">
                              <span>{review.name}</span>
                              <span>{review.date}</span>
                            </div>
                            <Stars rating={review.rating} size={"14px"} />
                            <p>{review.review}</p>
                          </div>
                        ))
                      ) : (
                        <p className="tw-p-1 tw-bg-gray-200 tw-rounded-md">
                          This mentor has no reviews currently.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <RatingModal
        open={openReviewModal}
        onClose={() => setOpenReviewModal(false)}
        role="mentor"
        id={user_id}
      />
    </div>
  );
};

export default ViewMentorProfile;
