import React from "react";

export const Error = () => {
  return (
    <div className="tw-pt-10">
      <h5>
        Sorry, Something went wrong. Please try again, or contact us by raising
        a support ticket.
      </h5>
    </div>
  );
};
