import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import useFetch from "../hooks/useFetch";
import useUploadImage from "../hooks/useUploadImage";
import { Loader } from "../components/ui/Loader";
import { Error } from "../components/ui/Error";
import { Button } from "../components/ui/Button";
import Sidebar from "../components/layout/Sidebar";
import Navbar from "../components/layout/Navbar";
import { ResetPassword } from "../components/ui/modals/resetPassword";
import { ConfirmationModal } from "../components/ui/modals/utility-modals/confirmationModal";
import FavIcon from "../images/favicon.png";
import { useToastContext } from "../context/ToastContext";

const Settings = () => {
  const settingsApi = `${process.env.REACT_APP_API_URL}settings`;
  const deactivateApi = `${process.env.REACT_APP_API_URL}deactivate`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();
  const { uploadImage } = useUploadImage();
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [userData, setUserData] = useState({ name: "", email: "", logo: "" });
  const ref = useRef(true);
  const navigate = useNavigate();

  const getSettings = useCallback(() => {
    fetchApi(
      {
        url: settingsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setUserData({
          name: data.name,
          email: data.email,
          logo: data.logo,
        });
      }
    );
  }, [token, fetchApi, settingsApi]);

  const deactivate = () => {
    fetchApi(
      {
        url: deactivateApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        if (data.message === "account deactivated") {
          navigate("/");
        } else {
          toast.open("warning", data.message);
          return;
        }
      }
    );
  };

  useEffect(() => {
    if (ref.current) {
      getSettings();
    }
    return () => {
      ref.current = false;
    };
  }, [getSettings]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-4">
          <h3 className="tw-font-semibold tw-text-xl">Settings</h3>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && userData ? (
            <div className="tw-grid tw-grid-cols-4 tw-mt-5 tw-bg-white tw-shadow tw-rounded-md">
              <div className="tw-col-span-4 md:tw-col-span-1 tw-flex tw-flex-col tw-items-center tw-p-4 tw-border-r">
                <img
                  src={userData.logo.length > 0 ? userData.logo : FavIcon}
                  alt="profile-img"
                  width={150}
                  className="tw-h-[150px] tw-rounded-full tw-p-1 tw-border tw-border-slate-400"
                  loading="lazy"
                />
                <label
                  htmlFor="upload-profile"
                  className="tw-p-1 tw-mt-3 tw-bg-primary tw-text-white tw-rounded-md tw-cursor-pointer tw-text-sm"
                >
                  Upload
                </label>
                <input
                  type="file"
                  id="upload-profile"
                  className="tw-hidden"
                  accept="image/png, image/jpeg"
                  onChange={(e) => uploadImage(e.target.files[0], getSettings)}
                />
              </div>
              <div className="tw-col-span-4 md:tw-col-span-3 tw-p-3 tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                <div>
                  <div className="tw-flex tw-items-center tw-my-1">
                    <p className="tw-font-medium md:tw-text-base">Name :</p>
                    <p className="tw-ms-1 md:tw-text-base tw-uppercase">
                      {userData.name}
                    </p>
                  </div>
                  <div className="tw-flex tw-items-center tw-my-1">
                    <p className="tw-font-medium md:tw-text-base">Email :</p>
                    <p className="tw-ms-1 md:tw-text-base">{userData.email}</p>
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-between tw-my-1">
                    <p className="tw-flex tw-items-center tw-w-fit">
                      <span className="tw-font-medium md:tw-text-base">
                        Password :
                      </span>
                      <span className="tw-ms-1 md:tw-text-base">********</span>
                    </p>
                    <Button
                      onClick={() => setOpen(true)}
                      className="md:tw-hidden tw-p-0.5 tw-h-fit tw-text-xs"
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <Button
                    onClick={() => setOpen(true)}
                    className="tw-hidden md:tw-block tw-p-1 tw-h-fit tw-text-sm"
                  >
                    Change Password
                  </Button>
                  <Button
                    onClick={() => setOpenConfirmModal(true)}
                    className="tw-hidden md:tw-block tw-bg-secondary tw-p-1 tw-h-fit tw-text-sm"
                  >
                    Deactivate Account
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </div>
      <ResetPassword open={open} onClose={() => setOpen(false)} />
      <ConfirmationModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        message="Are you sure you want to deactivate your account ? For reactivation just login again."
        callBackFn={() => deactivate()}
      />
    </div>
  );
};

export default Settings;
