import React, { useEffect, useState } from "react";
import { useFilterContext } from "../../context/filterContext";
import { useToastContext } from "../../context/ToastContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import { useFetchCredits } from "../../hooks/useFetchCredits";
import useNotificationApi from "../../hooks/useNotificationApi";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import { AdCard } from "../../components/ui/cards/adCard";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { AddBidModal } from "../../components/ui/modals/market/addBidModal";
import { PostAdModal } from "../../components/ui/modals/market/postAdModal";
import { Button, Pagination } from "../../components/ui/Button";
import "./market.css";

const Market = () => {
  const postBidApi = `${process.env.REACT_APP_API_URL}post-bid`;
  const postAdApi = `${process.env.REACT_APP_API_URL}post-ad`;

  const { token, userRole, name } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const { filterAds } = useFilterContext();
  const { credits, getCredits } = useFetchCredits();
  const { sendNotification } = useNotificationApi();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [postModal, setPostModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [adDetails, setAdDetails] = useState({
    id: "",
    title: "",
  });

  const postBid = (id, startupId, data) => {
    fetchApi(
      {
        url: postBidApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          ad_id: id,
          bid_amount: data.bidAmount,
          bid_deadline: data.workDuration,
          bid_description: data.bidDescription,
        },
      },
      (data) => {
        if (data.message === "bid restricted") {
          toast.open(
            "false",
            "Bid failed: Insufficient credits (25 needed) or already submitted."
          );
          return;
        }
        sendNotification(
          startupId,
          `Recieved a new bid from ${name}`,
          "market"
        );
      }
    );
  };

  const handleBidModal = (id, title, min_budget, startup_id) => {
    setAdDetails({
      id: id,
      title: title,
      minAmount: min_budget,
      startupId: startup_id,
    });
    setOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getCredits();
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [getCredits, displayIndustries]);

  const postAd = (data, expertise) => {
    fetchApi(
      {
        url: postAdApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          title: data.title,
          max_budget: data.maxBudget,
          min_budget: data.minBudget,
          deadline: data.deadline,
          tags: expertise,
          description: data.description,
        },
      },
      () => {}
    );
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar lastUrl="market" handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="tw-p-3">
            <Error />
          </div>
        )}
        {!error && !isLoading && filterAds && (
          <>
            {userRole === "f@!3A" && (
              <div className="tw-p-3 tw-text-right">
                <Button
                  className="tw-p-1.5 tw-text-sm"
                  onClick={() => setPostModal(true)}
                >
                  Post Project
                </Button>
              </div>
            )}
            {!filterAds.data.length > 0 ? (
              <p className="tw-text-lg tw-font-medium tw-p-3">
                Currently no projects are available.
              </p>
            ) : (
              <>
                <div className="tw-p-6 tw-grid tw-gap-4">
                  {filterAds.data.length > 0 ? (
                    filterAds.data
                      .slice(page * 10 - 10, page * 10)
                      .map((ad) => (
                        <AdCard
                          ad={ad}
                          key={ad.ad_id}
                          role={userRole}
                          handleBidModal={handleBidModal}
                          credits={credits}
                        />
                      ))
                  ) : (
                    <p className="tw-font-medium tw-p-0">
                      Currently no projects are active.
                    </p>
                  )}
                  {filterAds.data.length > 0 && (
                    <Pagination
                      page={page}
                      setPage={setPage}
                      data={filterAds.data}
                      dataCount={10}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <PostAdModal
        open={postModal}
        onClose={() => setPostModal(false)}
        postAd={postAd}
        industries={industries}
      />
      <AddBidModal
        open={open}
        onClose={() => setOpen(false)}
        adDetails={adDetails}
        postBid={postBid}
      />
    </div>
  );
};

export default Market;
