import useLocalStorage from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import ErrorImg from "../../images/404.svg";
import { Button } from "../../components/ui/Button";

export const Error404 = () => {
  const { userRole } = useLocalStorage();
  const navigate = useNavigate();

  const checkRole = () => {
    if (userRole === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (userRole === "R$7s2") {
      navigate("/mentor-dashboard");
    } else if (userRole === "Sw%aa") {
      navigate("/investor-dashboard");
    } else {
      navigate("/vendor-profile");
    }
  };

  return (
    <div className="tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <img src={ErrorImg} alt="Error-403" width={450} />
      <Button onClick={() => checkRole()} className="tw-px-2 tw-py-1 tw-mx-2">
        Dashboard
      </Button>
    </div>
  );
};
