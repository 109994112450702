import { Pagination } from "../../../components/ui/Button";
import { DeleteIcon, PenIcon } from "../../../icons/icons";

const IndustryTable = ({
  page,
  setPage,
  industries,
  type,
  updateIndustry,
  handleIndustryModal,
  deleteIndustry,
}) => {
  let filterData = industries.filter((data) => data.role === type);

  return (
    <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
      <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
        <thead className="tw-border tw-border-gray-200 tw-bg-slate-50">
          <tr className="tw-divide-x tw-divide-gray-200 tw-text-left">
            <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
              SNo.
            </th>
            <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
              Industry
            </th>
            <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
              Status
            </th>
            <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="tw-divide-y tw-divide-gray-200">
          {filterData.length ? (
            filterData
              .slice(page * 15 - 15, page * 15)
              .map((industry, index) => (
                <tr
                  className="tw-divide-x tw-divide-gray-200"
                  key={industry._id}
                >
                  <td className="tw-p-2 tw-text-sm">
                    {(page - 1) * 15 + index + 1}
                  </td>
                  <td className="tw-p-2 tw-text-sm tw-uppercase">
                    {industry.industry_name.replace(new RegExp("_", "g"), " ")}
                  </td>
                  <td className="tw-p-2 tw-text-sm">
                    <label className="tw-mb-0 tw-cursor-pointer">
                      <input
                        type="checkbox"
                        className="tw-sr-only tw-peer"
                        checked={industry.status === "0" ? false : true}
                        onChange={() =>
                          updateIndustry(
                            industry._id,
                            industry.status === "0" ? "1" : "0",
                            "status"
                          )
                        }
                      />
                      <div className="tw-relative tw-w-9 tw-h-5 tw-bg-gray-200 tw-rounded-full tw-peer dark:tw-bg-gray-700 peer-checked:after:tw-translate-x-full rtl:peer-checked:after:-tw-translate-x-full peer-checked:after:tw-border-white after:content-[''] after:tw-absolute after:tw-top-[2px] after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-4 after:tw-w-4 after:tw-transition-all dark:tw-border-gray-600 peer-checked:tw-bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="tw-p-2 tw-text-sm tw-flex tw-items-center tw-gap-x-5">
                    <PenIcon
                      fill="#008000"
                      className="tw-cursor-pointer"
                      onClick={() =>
                        handleIndustryModal(
                          "edit",
                          industry.industry_name,
                          industry.role,
                          industry._id
                        )
                      }
                    />
                    <DeleteIcon
                      className="tw-cursor-pointer"
                      onClick={() => deleteIndustry(industry._id)}
                    />
                  </td>
                </tr>
              ))
          ) : (
            <tr className="tw-text-center">
              <td colSpan={4} className="tw-p-2 tw-text-sm">
                No Industries Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          data={filterData}
          dataCount={15}
        />
      )}
    </div>
  );
};

export default IndustryTable;
