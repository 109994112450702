import React, { useCallback, useEffect, useState, useRef } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import SubscriptionCard from "../../components/ui/cards/subscriptionCard";

const Subscription = () => {
  const displaySubscriptionPlansApi = `${process.env.REACT_APP_API_URL}display-subscription`;

  const { token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();

  const [planDetails, setPlanDetails] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const ref = useRef(false);

  const getSubscriptionPlans = useCallback(() => {
    fetchApi(
      { url: displaySubscriptionPlansApi, headers: { "x-token": token } },
      (data) => setPlanDetails(data.subscriptions)
    );
  }, [fetchApi, token, displaySubscriptionPlansApi]);

  useEffect(() => {
    if (!ref.current) {
      getSubscriptionPlans();
    }
    return () => (ref.current = true);
  }, [getSubscriptionPlans]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-text-center tw-p-2">
          {error && <Error />}
          {isLoading && <Loader />}
          {!error && !isLoading && (
            <div className="inner-container tw-py-3">
              <div className="tw-pb-3">
                <h3 className="tw-mb-4 tw-text-lg tw-text-center tw-font-medium">
                  Subscription Plan
                </h3>
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-3">
                  {planDetails?.map((plan) => {
                    return (
                      <SubscriptionCard
                        key={plan._id}
                        data={plan}
                        callbackUrl={getSubscriptionPlans}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
