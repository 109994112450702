const SingleMessage = ({ data }) => {
  return (
    <li className="tw-p-2 tw-rounded-md tw-bg-[#eaffff]">
      <p className="tw-font-medium tw-text-sm">{data.name}</p>
      <p className="tw-text-sm">{data.message}</p>
    </li>
  );
};

export default SingleMessage;
