import ReactDom from "react-dom";
import PlanForm from "./planForm";
import { useState } from "react";
import { useForm } from "react-hook-form";

const AddPlan = ({ open, onClose, addSubscription }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [features, setFeatures] = useState([]);

  if (!open) return null;

  const submitModal = (data) => {
    if (features.length < 1) {
      document.getElementById("feature-required").classList.remove("tw-hidden");
      return;
    } else {
      document.getElementById("feature-required").classList.add("tw-hidden");
    }
    addSubscription(data, features);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[750px] md:tw-max-w-[750px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <PlanForm
          submitModal={submitModal}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          features={features}
          setFeatures={setFeatures}
        />
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default AddPlan;
