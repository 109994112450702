import { useNavigate } from "react-router-dom";
import { Button, Pagination } from "../../components/ui/Button";
import FavIcon from "../../images/favicon.png";
import { DeleteIcon, EyeIcon, PenIcon } from "../../icons/icons";

const MeetingsTable = ({
  data,
  userId,
  acceptMeeting,
  cancelMeeting,
  handleModals,
  getRoomId,
  page,
  setPage,
  tableType,
}) => {
  const navigate = useNavigate();

  const currTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const currDate = new Date().toISOString().slice(0, 10);

  let filterData;
  if (tableType === "All") filterData = data;
  else filterData = data.filter((data) => data.status === "1");

  const viewProfile = (name, id, role) => {
    if (role === "f@!3A") navigate(`/view-startup/${name}/${id}`);
    else if (role === "R$7s2") navigate(`/view-mentor/${name}/${id}`);
    else navigate(`/view-investor/${name}/${id}`);
  };

  const checkTime = (time) => {
    const currentTime = currTime.split(":");
    const meetingEndTime = time.split(":");
    if (meetingEndTime[0] === "00") {
      return true;
    }
    if (parseInt(currentTime[0]) > parseInt(meetingEndTime[0])) {
      return false;
    } else if (parseInt(currentTime[0]) < parseInt(meetingEndTime[0])) {
      return true;
    } else if (parseInt(currentTime[0]) === parseInt(meetingEndTime[0])) {
      if (parseInt(currentTime[1]) < parseInt(meetingEndTime[1])) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkDate = (date) => {
    const currentDate = currDate.split("-");
    const meetingDate = date.split("-");
    if (parseInt(currentDate[0]) > parseInt(meetingDate[0])) {
      return false;
    } else if (parseInt(currentDate[0]) < parseInt(meetingDate[0])) {
      return true;
    } else if (parseInt(currentDate[0]) === parseInt(meetingDate[0])) {
      if (parseInt(currentDate[1]) < parseInt(meetingDate[1])) {
        return true;
      } else if (parseInt(currentDate[1]) > parseInt(meetingDate[1])) {
        return false;
      } else if (parseInt(currentDate[1]) === parseInt(meetingDate[1])) {
        if (parseInt(currentDate[2]) <= parseInt(meetingDate[2])) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  return (
    <>
      <div className="tw-overflow-auto tw-w-full">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-font-medium tw-text-sm">User</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                Schedule Date
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Meeting Time</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Title</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Type</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Status</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 7 - 7, page * 7).map((meeting) => (
                <tr
                  key={meeting.id}
                  className="tw-bg-white tw-border-b tw-border-slate-300"
                >
                  <td className="tw-p-2 tw-flex tw-items-center tw-capitalize">
                    <img
                      src={
                        userId === meeting.reciever_id
                          ? meeting.sender_logo.length > 0
                            ? meeting.sender_logo
                            : FavIcon
                          : meeting.reciever_logo.length > 0
                          ? meeting.reciever_logo
                          : FavIcon
                      }
                      alt="Logo"
                      className="tw-mx-3 tw-w-[40px] tw-h-[40px] md:tw-w-[50px] md:tw-h-[50px] tw-rounded-full"
                      loading="lazy"
                    />
                    {userId === meeting.reciever_id
                      ? meeting.sender_name
                      : meeting.reciever_name}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {meeting.date}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {meeting.time}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {meeting.title}
                  </td>
                  <td
                    className={`tw-p-2 tw-text-xs md:tw-text-sm ${
                      userId === meeting.reciever_id
                        ? "tw-text-green-600"
                        : "tw-text-blue-600"
                    }`}
                  >
                    {userId === meeting.reciever_id ? "Incoming" : "Outgoing"}
                  </td>
                  <td
                    className={`tw-p-2 tw-text-xs md:tw-text-sm ${
                      meeting.status === "0"
                        ? "tw-text-orange-600"
                        : meeting.status === "1"
                        ? "tw-text-blue-600"
                        : meeting.status === "2"
                        ? "tw-text-green-600"
                        : "tw-text-red-600"
                    }`}
                  >
                    {meeting.status === "0"
                      ? "Pending"
                      : meeting.status === "1"
                      ? "Accepted"
                      : meeting.status === "2"
                      ? "Completed"
                      : "Rejected"}
                  </td>
                  <td className="tw-align-middle tw-p-2 tw-text-xs md:tw-text-sm">
                    <div className="tw-flex tw-items-center tw-justify-between">
                      <span className="tw-flex tw-items-center">
                        <EyeIcon
                          fill="#17335E"
                          className="tw-mx-1 tw-cursor-pointer"
                          onClick={() =>
                            viewProfile(
                              userId === meeting.reciever_id
                                ? meeting.sender_name
                                : meeting.reciever_name,
                              userId === meeting.reciever_id
                                ? meeting.sender_id
                                : meeting.reciever_id,
                              userId === meeting.reciever_id
                                ? meeting.sender_role
                                : meeting.reciever_role
                            )
                          }
                        />
                        {meeting.status === "0" && (
                          <PenIcon
                            fill="#f97316"
                            className="tw-mx-1 tw-cursor-pointer"
                            onClick={() => handleModals("meeting", meeting)}
                          />
                        )}
                        {userId === meeting.sender_id &&
                          meeting.status === "0" && (
                            <DeleteIcon
                              className="tw-mx-1 tw-cursor-pointer"
                              onClick={() => cancelMeeting(meeting.id)}
                            />
                          )}
                      </span>
                      {userId === meeting.reciever_id ? (
                        meeting.status === "0" ? (
                          <span>
                            <Button
                              onClick={() =>
                                acceptMeeting(
                                  meeting.id,
                                  "accept",
                                  meeting.sender_role,
                                  meeting.reciever_role
                                )
                              }
                              className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                              bgColor="tw-bg-green-600"
                            >
                              Accept
                            </Button>
                            <Button
                              onClick={() =>
                                acceptMeeting(
                                  meeting.id,
                                  "reject",
                                  meeting.sender_role,
                                  meeting.reciever_role
                                )
                              }
                              className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                              bgColor="tw-bg-secondary"
                            >
                              Reject
                            </Button>
                          </span>
                        ) : meeting.status === "1" &&
                          (checkDate(meeting.date)
                            ? checkTime(meeting.exp_time)
                            : false) ? (
                          <Button
                            onClick={() =>
                              getRoomId(
                                userId === meeting.reciever_id //Connection user_id
                                  ? meeting.sender_id
                                  : meeting.reciever_id,
                                userId === meeting.reciever_id //Connection role
                                  ? meeting.sender_role
                                  : meeting.reciever_role,
                                meeting.id
                              )
                            }
                            className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                            bgColor="tw-bg-green-600"
                          >
                            Join
                          </Button>
                        ) : null
                      ) : meeting.status === "1" &&
                        (checkDate(meeting.date)
                          ? checkTime(meeting.exp_time)
                          : false) ? (
                        <Button
                          onClick={() =>
                            getRoomId(
                              userId === meeting.reciever_id //Connection user_id
                                ? meeting.sender_id
                                : meeting.reciever_id,
                              userId === meeting.reciever_id //Connection role
                                ? meeting.sender_role
                                : meeting.reciever_role,
                              meeting.id
                            )
                          }
                          className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                          bgColor="tw-bg-green-600"
                        >
                          Join
                        </Button>
                      ) : null}
                      {meeting.status === "2" ? (
                        meeting.summary_status === "0" ? (
                          <Button
                            className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                            bgColor="tw-bg-orange-600"
                            onClick={() =>
                              navigate(`/meeting-summary/${meeting.id}`)
                            }
                          >
                            Add Summary
                          </Button>
                        ) : (
                          <Button
                            className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                            onClick={() => handleModals("summary", meeting.id)}
                          >
                            View Summary
                          </Button>
                        )
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan={6} className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No Meeting Scheduled
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination page={page} setPage={setPage} data={filterData} />
      )}
    </>
  );
};

export default MeetingsTable;
