import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { bannedInputs } from "../../constants";
import { BuildingIcon, UploadIcon } from "../../icons/icons";
import { useToastContext } from "../../context/ToastContext";
import Dropdown from "../../components/ui/inputs/dropdown";

export const VendorDetailsForm = ({ token, industries }) => {
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}insert-vendor-details`;

  const navigate = useNavigate();
  const { isLoading, error } = useFetch();
  const toast = useToastContext();

  const [expertise, setExpertise] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const VendorDetailsApiCall = (data) => {
    document.getElementById("expertise-error").classList.add("tw-hidden");
    document.getElementById("limit").classList.add("tw-hidden");
    if (expertise.length < 1) {
      document.getElementById("expertise-error").classList.remove("tw-hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("limit").classList.remove("tw-hidden");
      return;
    }
    let logo = document.getElementById("companyLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("company_name", data.vendorCompany);
    formData.append("description", data.vendorBio);
    formData.append("expert_in", expertise);
    axios
      .post(vendorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/vendor-profile");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      id="vendorDetailsForm"
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-my-0 lg:tw-py-3 md:tw-col-start-2 md:tw-col-span-5 lg:tw-col-span-2"
    >
      <form
        onSubmit={handleSubmit(VendorDetailsApiCall)}
        className="tw-mx-auto tw-p-5 tw-rounded-md tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0"
      >
        <h4 className="tw-text-lg tw-font-medium">
          Some details about your business
        </h4>
        <div className="tw-mb-1">
          <label
            htmlFor="vendorCompany"
            className="tw-text-sm tw-mb-0 required"
          >
            Company Name
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="vendorCompany"
              name="vendorCompany"
              placeholder="Enter Company Name"
              {...register("vendorCompany", {
                required: "Company name is required",
                validate: {
                  minLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length > 1 ||
                      "Company name must be at least 2 characters"
                    );
                  },
                  maxLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length < 101 ||
                      "Cannot exceed 100 characters"
                    );
                  },
                  bannedValue: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different name"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <BuildingIcon />
            </span>
          </div>
          {errors.vendorCompany && (
            <div>
              <FormError message={errors.vendorCompany.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-3">
          <label htmlFor="companyLogo" className="tw-text-sm tw-mb-0">
            Company Logo
          </label>
          <div
            className="tw-flex tw-items-center tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/jpeg, image/png"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="companyLogo"
              name="companyLogo"
              placeholder="Upload Profile Image"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        <div className="tw-mb-1">
          <label htmlFor="vendorBio" className="tw-text-sm tw-mb-0 required">
            Short description about your company
          </label>
          <div className="tw-border tw-mb-3">
            <textarea
              name="vendorBio"
              id="vendorBio"
              cols="30"
              rows="4"
              placeholder="Write here..."
              className="tw-border-0 tw-w-full tw-ps-3 tw-pt-1 tw-rounded-md"
              {...register("vendorBio", {
                required: "Bio is required",
                validate: {
                  minLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length > 29 ||
                      "Bio must be at least 30 characters"
                    );
                  },
                  maxLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length < 1001 ||
                      "Cannot exceed 1000 characters"
                    );
                  },
                },
              })}
            ></textarea>
          </div>
          {errors.vendorBio && (
            <div>
              <FormError message={errors.vendorBio.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1 tw-flex-col">
          <p className="tw-text-sm">
            Company expertise{" "}
            <span className="required">({expertise.length + "/3"})</span>
            <span
              id="limit"
              className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
            >
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setExpertise(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small
            className="tw-text-red-500 tw-mb-2 tw-hidden"
            id="expertise-error"
          >
            This field is mandatory.
          </small>
        </div>
        {isLoading && !error ? <Loader pt="10px" /> : null}
        <Button type="submit" className="tw-mt-4 tw-py-1.5 tw-w-full">
          Next
        </Button>
      </form>
    </div>
  );
};
