import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Button, Pagination } from "../../components/ui/Button";
import useNotificationApi from "../../hooks/useNotificationApi";
import { useToastContext } from "../../context/ToastContext";

export const ConnectedUsers = ({ connections, displayFriends, block }) => {
  const unfollowConnectionApi = `${process.env.REACT_APP_API_URL}unfollow-connection`;

  const { fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [page, setPage] = useState(1);

  const unFollow = (friend_id) => {
    fetchApi(
      {
        url: unfollowConnectionApi,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          connection_id: friend_id,
        },
      },
      (data) => {
        sendNotification(
          friend_id,
          `Connection removed by ${data.name}`,
          "connections"
        );
        displayFriends();
      }
    );
  };

  const viewProfile = (name, role, user_id) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name}/${user_id}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name}/${user_id}`);
    } else if (role === "Sw%aa") {
      navigate(`/view-investor/${name}/${user_id}`);
    } else {
      navigate(`/view-vendor/${name}/${user_id}`);
    }
  };

  return (
    <>
      <h4 className="tw-mb-1 md:tw-mb-4 tw-font-semibold md:tw-text-lg">
        Connected Users
      </h4>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-ps-1 tw-font-medium tw-text-xs md:tw-text-sm">
                User Name
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Role
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-xs md:tw-text-sm">
                Connected On
              </th>
              <th className="tw-p-2 tw-pe-1 tw-font-medium tw-text-xs md:tw-text-sm">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {connections.length > 0 ? (
              connections.filter((user) => user.status === "1").length > 0 ? (
                connections
                  .filter((user) => user.status === "1")
                  .slice(page * 7 - 7, page * 7)
                  .map((user) => (
                    <tr
                      key={user.connection_id}
                      className="tw-bg-white tw-border-b tw-border-slate-300"
                    >
                      <td className="tw-p-2 tw-ps-1 tw-text-xs md:tw-text-sm">
                        {user.name}
                      </td>
                      <td>
                        <span
                          className={`tw-px-1 tw-text-xs md:tw-text-sm tw-rounded-md tw-border ${
                            user.role === "f@!3A"
                              ? "tw-border-blue-400 tw-text-blue-400"
                              : user.role === "R$7s2"
                              ? "tw-border-orange-400 tw-text-orange-400"
                              : user.role === "Sw%aa"
                              ? "tw-border-green-500 tw-text-green-500"
                              : "tw-border-indigo-500 tw-text-indigo-500"
                          }`}
                        >
                          {user.role === "f@!3A"
                            ? "Startup"
                            : user.role === "R$7s2"
                            ? "Mentor"
                            : user.role === "Sw%aa"
                            ? "Investor"
                            : "Vendor"}
                        </span>
                      </td>
                      <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                        {user.date}
                      </td>
                      <td
                        className="tw-p-2 tw-pe-1 tw-text-xs md:tw-text-sm"
                        id="connectedUserId"
                      >
                        {user.deactivate === "0" ? (
                          <button
                            onClick={() =>
                              viewProfile(
                                user.name,
                                user.role,
                                user.connection_id
                              )
                            }
                            className="tw-py-1 tw-px-2 tw-text-xs tw-bg-indigo-600 tw-text-white tw-mx-1 tw-rounded-md"
                          >
                            Profile
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              toast.open(
                                "warning",
                                "This profile has been deactivated"
                              )
                            }
                            className="tw-py-1 tw-px-2 tw-text-xs tw-bg-indigo-600 tw-text-white tw-mx-1 tw-rounded-md"
                          >
                            Profile
                          </button>
                        )}
                        <Link
                          to="/chat"
                          className="tw-py-1 tw-px-2 tw-text-xs tw-bg-green-600 tw-text-white tw-mx-1 tw-rounded-md hover:tw-text-white"
                        >
                          Chat
                        </Link>
                        {userRole === "R$7s2" ? (
                          <Button
                            onClick={() => block(user.connection_id)}
                            className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs"
                            bgColor="tw-bg-secondary"
                          >
                            Block
                          </Button>
                        ) : (
                          <Button
                            onClick={() => unFollow(user.connection_id)}
                            className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs"
                            bgColor="tw-bg-secondary"
                          >
                            Remove
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr className="tw-text-center">
                  <td colSpan="4" className="tw-p-2 tw-text-xs md:tw-text-sm">
                    No connected users found
                  </td>
                </tr>
              )
            ) : (
              <tr className="tw-text-center">
                <td colSpan="4" className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No connected users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {connections.length > 0 && (
        <Pagination page={page} setPage={setPage} data={connections} />
      )}
    </>
  );
};
