import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useUnSuspendApi from "../../../hooks/useUnSuspendApi";
import InvestorDetails from "./details/investorDetails";
import MentorDetails from "./details/mentorDetails";
import VendorDetails from "./details/vendorDetails";
import StartupDetails from "./details/startupDetails";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import { SuspendModal } from "../../../components/ui/modals/utility-modals/suspendModal";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { useToastContext } from "../../../context/ToastContext";

const UserDetails = () => {
  const userDetailsApi = `${process.env.REACT_APP_API_URL}user`;
  const platformVerificationApi = `${process.env.REACT_APP_API_URL}platform-verify`;

  const { fetchApi, isLoading, error } = useFetch();
  const { token } = useLocalStorage();
  const { unSuspend } = useUnSuspendApi();
  const location = useLocation();
  const { sendNotification } = useNotificationApi();
  const toast = useToastContext();

  const [details, setDetails] = useState({});
  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const getUserDetails = useCallback(() => {
    fetchApi(
      {
        url: userDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: location.state.id,
          role: location.state.role,
        },
      },
      (data) => {
        setDetails(data);
      }
    );
  }, [userDetailsApi, fetchApi, token, location]);

  const platformVerifyUser = (id) => {
    fetchApi(
      {
        url: platformVerificationApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      () => {
        toast.open("true", "Verification successful");
        sendNotification(
          id,
          `Your profile has been successfully verified by PitchHere`,
          "admin"
        );
        getUserDetails();
      }
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserDetails();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [getUserDetails]);

  const conditionalUserRender = () => {
    if (location.state.role === "f@!3A") {
      return (
        <StartupDetails
          details={details}
          flag={location.state.flag}
          unSuspend={() => unSuspend(location.state.id, getUserDetails)}
          handleSuspension={handleSuspension}
          platformVerifyUser={platformVerifyUser}
        />
      );
    }
    if (location.state.role === "R$7s2") {
      return (
        <MentorDetails
          details={details}
          flag={location.state.flag}
          unSuspend={() => unSuspend(location.state.id, getUserDetails)}
          handleSuspension={handleSuspension}
        />
      );
    }
    if (location.state.role === "Sw%aa") {
      return (
        <InvestorDetails
          details={details}
          flag={location.state.flag}
          unSuspend={() => unSuspend(location.state.id, getUserDetails)}
          handleSuspension={handleSuspension}
        />
      );
    } else {
      return (
        <VendorDetails
          details={details}
          flag={location.state.flag}
          unSuspend={() => unSuspend(location.state.id, getUserDetails)}
          handleSuspension={handleSuspension}
        />
      );
    }
  };

  const handleSuspension = (type) => {
    if (type === "suspend") {
      setOpen(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && details && conditionalUserRender()}
        </section>
      </div>
      <SuspendModal
        open={open}
        onClose={() => setOpen(false)}
        id={location.state.id}
        callBackFn={getUserDetails}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        message="Are you sure you want to un-suspend this user"
        callBackFn={() => unSuspend(location.state.id, getUserDetails)}
      />
    </div>
  );
};

export default UserDetails;
