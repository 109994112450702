import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { FormError } from "../FormError";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Loader } from "../Loader";
import { Button } from "../Button";
import "./rating-modal/modal.css";
import "../../../custom.css";
import { EyeIcon, HashtagIcon, SlashedEyeIcon } from "../../../icons/icons";

export const ResetPassword = ({ open, onClose }) => {
  const updatePasswordApi = `${process.env.REACT_APP_API_URL}update-password`;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  const { token } = useLocalStorage();
  const { fetchApi, isLoading } = useFetch();
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;
  const submitModal = (data, e) => {
    e.preventDefault();
    updatePassword(data);
  };
  const updatePassword = (data) => {
    fetchApi(
      {
        url: updatePasswordApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          current_password: data.currentPassword,
          new_password: data.newPassword,
        },
      },
      (data) => {
        if (data.message === "invalid password") {
          setIsError(true);
        } else {
          onClose();
        }
      }
    );
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[40%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Reset Password
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-p-3 tw-text-left"
        >
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="currentPassword"
            >
              Current Password
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="currentPassword"
                name="currentPassword"
                {...register("currentPassword", {
                  required: "Current Password is required",
                })}
                autoFocus
              />
              <span className="input-icons icon-span">
                <HashtagIcon />
              </span>
            </div>
            {errors.currentPassword && (
              <div>
                <FormError message={errors.currentPassword.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="newPassword"
            >
              New Password
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type={show ? "text" : "password"}
                className="tw-border-0 tw-w-full tw-ps-3"
                id="newPassword"
                name="newPassword"
                {...register("newPassword", {
                  required: "New Password is required",
                  minLength: {
                    value: 7,
                    message: "Password must be at least 7 characters",
                  },
                })}
              />
              <span
                className="input-icons tw-cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.newPassword && (
              <div>
                <FormError message={errors.newPassword.message} />
              </div>
            )}
          </div>
          {isError && (
            <span className="tw-text-red-500 tw-text-xs">
              Current password does not match
            </span>
          )}
          {isLoading && (
            <div className="tw-py-1">
              <Loader />
            </div>
          )}
          <div className="tw-float-right tw-p-3">
            <Button
              onClick={onClose}
              className="tw-px-1.5 tw-py-1 tw-mx-1 tw-text-sm"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button
              type="submit"
              className="tw-px-1.5 tw-py-1 tw-mx-1 tw-text-sm"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
