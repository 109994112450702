import React, { useState } from "react";
import useFetch from "../hooks/useFetch";
import useLocalStorage from "../hooks/useLocalStorage";
import Sidebar from "../components/layout/Sidebar";
import Navbar from "../components/layout/Navbar";
import { Loader } from "../components/ui/Loader";
import { Error } from "../components/ui/Error";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import document from "../images/DRLPS5745H.pdf";

const SampleDocument = () => {
  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const [openSidebar, setOpenSidebar] = useState(false);
  const plugin = defaultLayoutPlugin();

  const [pdf, setPdf] = useState(document);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-4">
          <h3 className="tw-font-semibold tw-text-xl tw-mb-2">
            Sample Pitch Deck
          </h3>
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div style={{ height: "calc(100vh - 120px)" }}>
              {pdf ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdf} plugins={[plugin]} />
                </Worker>
              ) : (
                <span>No Document uploaded</span>
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default SampleDocument;
