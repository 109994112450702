const LongCard = ({
  data,
  img = "",
  width = 30,
  gradient = "warning",
  classes = "tw-col-span-6 sm:tw-col-span-2",
}) => {
  return (
    <div className={`${classes} tw-shadow tw-rounded-md`}>
      <div className={`tw-card tw-py-6 stat-cards tw-relative ${gradient}`}>
        <span className="tw-font-medium">{data.title}</span>
        <div className="tw-flex tw-items-baseline tw-mt-2 tw-gap-1">
          <h4 className="tw-font-medium">{data.count}</h4>
        </div>
        <div className="bg-gradient tw-h-[60px] tw-w-[60px]">
          <img src={img} alt="card-icon" width={width} loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default LongCard;
