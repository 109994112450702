import ReactDom from "react-dom";
import { Button } from "../../Button";
import { CircleCheck } from "../../../../icons/icons";

export const ConfirmationModal = ({ open, onClose, message, callBackFn }) => {
  if (!open) return null;

  const submitModal = () => {
    callBackFn();
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-max-w-[400px] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <div className="tw-p-3 tw-pb-0 tw-mt-2 tw-text-center">
          <CircleCheck
            width="70px"
            height="70px"
            fill="#1bcf1b"
            className="tw-mx-auto"
          />
          <p className="tw-mt-4">{message}</p>
        </div>
        <div className="tw-flex tw-justify-center tw-p-3">
          <Button
            className="tw-px-2 tw-py-1 tw-mx-1"
            bgColor="tw-bg-primary"
            onClick={submitModal}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
