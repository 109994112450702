import { useNavigate } from "react-router-dom";
import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useUnSuspendApi = () => {
  const suspendAccountApi = `${process.env.REACT_APP_API_URL}unsuspend-account`;
  const { fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();

  const unSuspend = (id, callBackFn) => {
    if (userRole !== "admin") {
      navigate("/error-403");
      return;
    }
    fetchApi(
      {
        url: suspendAccountApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: id,
        },
      },
      () => {
        callBackFn();
      }
    );
  };
  return { unSuspend };
};
export default useUnSuspendApi;
