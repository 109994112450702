import React from "react";

const RecentTransaction = ({ data }) => {
  return (
    <table
      className="tw-table-auto tw-w-full tw-border tw-shadow-sm tw-border-slate-300"
      id="recent-transaction-table"
    >
      <thead className="tw-bg-primary tw-text-white tw-text-left tw-font-semibold tw-border-b tw-border-slate-300">
        <tr>
          <th className="tw-py-2 tw-ps-1 tw-font-medium tw-text-xs sm:tw-text-sm">
            Date
          </th>
          <th className="tw-py-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Amount
          </th>
          <th className="tw-py-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Plan Type
          </th>
          <th className="tw-py-2 tw-pe-1 tw-font-medium tw-text-xs sm:tw-text-sm">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          data.map((transaction) => (
            <tr
              className="tw-bg-white tw-border-b tw-border-slate-300"
              key={transaction.id}
            >
              <td className="tw-py-2 tw-ps-1 tw-text-xs sm:tw-text-sm">
                {transaction.date}
              </td>
              <td className="tw-py-2 tw-text-xs sm:tw-text-sm">
                ₹ {transaction.amount}
              </td>
              <td className="tw-py-2 tw-text-xs sm:tw-text-sm">
                <span
                  className={`tw-px-1 tw-text-xs sm:tw-text-sm tw-rounded-md tw-capitalize tw-border ${
                    transaction.plantype === "silver"
                      ? "tw-border-blue-500 tw-text-blue-500"
                      : transaction.plantype === "gold"
                      ? "tw-border-purple-500 tw-text-purple-500"
                      : "tw-border-yellow-500 tw-text-yellow-500"
                  }`}
                >
                  {transaction.plantype}
                </span>
              </td>
              <td className="tw-pe-1">
                <span
                  className={`tw-px-1 tw-text-xs sm:tw-text-sm tw-rounded-md tw-border ${
                    transaction.status === "paid"
                      ? "tw-border-green-500 tw-text-green-500"
                      : "tw-border-red-500 tw-text-red-500"
                  }`}
                >
                  {transaction.status === "paid" ? "Success" : "Failed"}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr className="tw-text-center">
            <td colSpan={4} className="tw-py-2 tw-text-xs sm:tw-text-sm">
              No Transaction found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RecentTransaction;
