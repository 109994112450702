import { Stars } from "../../../../components/ui/modals/rating-modal/Stars";
import {
  BriefCaseIcon,
  BuildingIcon,
  CalenderIcon,
  LocationIcon,
  RupeeIcon,
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";

const MentorDetails = ({ details, flag, handleSuspension }) => {
  return (
    <div className="tw-grid tw-grid-cols-7 tw-gap-2">
      <div className="tw-col-span-7 tw-bg-white tw-shadow-md tw-p-3 tw-rounded-md md:tw-col-span-3">
        <div className="tw-flex tw-items-start tw-justify-between">
          <img
            src={details?.logo?.length < 1 ? FavIcon : details?.logo}
            className="tw-rounded-full tw-h-[90px] tw-w-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-1"
            alt="profile-img"
          />
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-end md:tw-hidden">
            {details?.is_suspend === "0" ? (
              <button
                className="md:tw-hidden tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                id="suspend-btn"
                onClick={() => handleSuspension("suspend")}
              >
                Suspend
              </button>
            ) : (
              <button
                className="md:tw-hidden tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-green-500 tw-text-white"
                id="unsuspend-btn"
                onClick={() => handleSuspension("unsuspend")}
              >
                Un-Suspend
              </button>
            )}
            <div className="tw-my-3">
              <Stars rating={details?.details?.average_rating} size={12} />
            </div>
          </div>
        </div>
        <div className="tw-text-start">
          <p className="tw-flex tw-items-center tw-pt-2 tw-mb-0 tw-font-bold tw-text-lg tw-uppercase">
            {details?.name}
          </p>
          <div className="tw-flex tw-items-center tw-mb-2">
            <CalenderIcon />
            <p className="tw-text-sm tw-mb-0 tw-ms-3">
              <span className="tw-font-bold tw-me-1">Joined On :</span>
              {details?.details?.date}
            </p>
          </div>
          <div className="tw-flex tw-items-center tw-mb-2">
            <BriefCaseIcon fill="#9c9c9c" />
            <p className="tw-text-sm tw-mb-0 tw-ms-3">
              <span className="tw-font-bold tw-me-1">Experience :</span>
              {details?.details?.work_experience} <span>years +</span>
            </p>
          </div>
          <div className="tw-flex tw-items-center tw-mb-2">
            <BuildingIcon fill="#9c9c9c" />
            <p className="tw-text-sm tw-mb-0 tw-ms-3 tw-capitalize">
              <span className="tw-font-bold tw-me-1">
                Previous/Current Company :
              </span>
              {details?.details?.previous_company}{" "}
            </p>
          </div>
          <div className="tw-flex tw-items-center tw-mb-2">
            <RupeeIcon fill="#9c9c9c" />
            <p className="tw-text-sm tw-mb-0 tw-ms-3 tw-capitalize">
              <span className="tw-font-bold tw-me-1">Session Charge :</span>₹{" "}
              {details?.details?.fee}{" "}
            </p>
          </div>
          <div className="tw-flex tw-mb-4">
            <LocationIcon fill="#9c9c9c" />
            <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3">
              <span className="tw-font-bold tw-me-1">Address :</span>
              <div className="tw-flex tw-items-center">
                <span className="tw-me-1 tw-capitalize">{details?.city}</span>
                <img
                  src={
                    flag && `${process.env.REACT_APP_API_URL}` + flag.slice(6)
                  }
                  alt={`${details?.country}-flag`}
                  width={18}
                  title={details?.country}
                />
              </div>
            </div>
          </div>
          <div className="tw-border-t tw-py-2">
            <p className="tw-mb-0 tw-font-bold">Industries</p>
            {details?.details?.mentor_industry.map((industry, i) => (
              <span key={i} className="outline-tags">
                {industry.replace(new RegExp("_", "g"), " ")}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="tw-col-span-7 md:tw-col-span-4">
        <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between tw-mb-3">
          <div className="tw-flex tw-items-center">
            <Stars rating={details?.details?.average_rating} size={15} />
          </div>
          {details?.is_suspend === "0" ? (
            <button
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
              id="suspend-btn"
              onClick={() => handleSuspension("suspend")}
            >
              Suspend
            </button>
          ) : (
            <button
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-green-500 tw-text-white"
              id="unsuspend-btn"
              onClick={() => handleSuspension("unsuspend")}
            >
              Un-Suspend
            </button>
          )}
        </div>
        <div className="tw-px-0" style={{ height: "270px" }}>
          <div
            className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3"
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <h5 className="tw-font-medium tw-pb-3">Startup Idea</h5>
            <p className="tw-mb-0 tw-text-sm">
              {details?.details?.short_description.length > 0
                ? details?.details?.short_description
                : "Not updated."}
            </p>
          </div>
        </div>
        <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-mt-3">
          <h5 className="tw-text-slate-400 tw-pb-3">Video</h5>
          {details?.video?.length > 0 ? (
            <video
              controls
              className="tw-w-full"
              id="videoFile"
              src={details?.video}
              style={{ height: "200px" }}
            >
              Video uploaded
            </video>
          ) : (
            <p>No video uploaded.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorDetails;
