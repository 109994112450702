import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { useToastContext } from "../../context/ToastContext";
import { randomId } from "../../utils/randomId";
import { EditAdModal } from "../../components/ui/modals/market/editAdModal";
import { PostAdModal } from "../../components/ui/modals/market/postAdModal";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Button, Pagination } from "../../components/ui/Button";
import { DeleteIcon, EyeIcon, PenIcon } from "../../icons/icons";

const MyAds = () => {
  const postAdApi = `${process.env.REACT_APP_API_URL}post-ad`;
  const startupAdsApi = `${process.env.REACT_APP_API_URL}startup-ads`;
  const deleteAdApi = `${process.env.REACT_APP_API_URL}delete-ad`;
  const editAdApi = `${process.env.REACT_APP_API_URL}edit-ad`;

  const { token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();

  const [ads, setAds] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [editAdModal, setEditAdModal] = useState(false);
  const [singleAd, setSingleAd] = useState([]);
  const [page, setPage] = useState(1);

  const postAd = (data, expertise) => {
    fetchApi(
      {
        url: postAdApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          title: data.title,
          max_budget: data.maxBudget,
          min_budget: data.minBudget,
          deadline: data.deadline,
          tags: expertise,
          description: data.description,
        },
      },
      () => {
        startupAds();
      }
    );
  };

  const startupAds = useCallback(() => {
    fetchApi(
      {
        url: startupAdsApi,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setAds(data.ads);
      }
    );
  }, [token, startupAdsApi, fetchApi]);

  useEffect(() => {
    const timer = setTimeout(() => {
      startupAds();
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    }, 10);
    return () => clearTimeout(timer);
  }, [fetchApi, token, startupAds, displayIndustries]);

  const editAd = (data, expertise, id) => {
    fetchApi(
      {
        url: editAdApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
          title: data.title,
          max_budget: data.maxBudget,
          min_budget: data.minBudget,
          deadline: data.deadline,
          tags: expertise,
          description: data.description,
        },
      },
      (data) => {
        if (data.message === "awarded") {
          toast.open("warning", "Cannot edit awarded Project");
          return;
        }
        startupAds();
      }
    );
  };

  const deleteAd = (id) => {
    fetchApi(
      {
        url: deleteAdApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: { id: id },
      },
      (data) => {
        if (data.message === "awarded") {
          toast.open("warning", "Cannot delete awarded Project");
          return;
        }
        startupAds();
      }
    );
  };

  const handleEditModal = (data) => {
    setSingleAd(data);
    setEditAdModal(true);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="tw-p-4">
            <Error />
          </div>
        )}
        {!isLoading && !error && (
          <>
            <div className="tw-flex tw-items-center tw-justify-between tw-p-3">
              <h4 className="tw-text-xl tw-font-semibold">My Projects</h4>
              <Button
                className="tw-p-1.5 tw-text-sm"
                onClick={() => setPostModal(true)}
              >
                Post Project
              </Button>
            </div>
            <p className="tw-text-sm tw-p-3">
              <span className="tw-font-medium tw-pe-1">Note :</span> The awarded
              amount is freezed and released to the service provider only upon
              completion of the project.
            </p>
            <div className="tw-p-3 tw-max-w-fit tw-overflow-auto md:tw-max-w-none md:tw-overflow-none md:tw-w-full">
              <table className="tw-table-auto tw-min-w-max md:tw-w-full tw-border">
                <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
                  <tr>
                    <th className="tw-py-2 tw-text-sm tw-ps-1 tw-w-5/12 tw-font-medium">
                      Title
                    </th>
                    <th className="tw-py-2 tw-text-sm tw-ps-1 tw-font-medium">
                      Budget (₹)
                    </th>
                    <th className="tw-py-2 tw-text-sm tw-ps-1 tw-font-medium">
                      Project Fee (₹)
                    </th>
                    <th className="tw-py-2 tw-text-sm tw-ps-1 tw-font-medium">
                      Timeline
                    </th>
                    <th className="tw-py-2 tw-text-sm tw-ps-1 tw-font-medium">
                      Status
                    </th>
                    <th className="tw-py-2 tw-text-sm tw-px-1 tw-font-medium">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ads.length > 0 ? (
                    ads.slice(page * 7 - 7, page * 7).map((ad) => (
                      <tr
                        key={ad._id}
                        className="tw-bg-white tw-border-b tw-border-slate-300"
                      >
                        <td className="tw-py-2 md:tw-py-1.5 tw-text-sm tw-ps-1">
                          {ad.title}
                        </td>
                        <td className="tw-py-2 md:tw-py-1.5 tw-text-sm tw-ps-1">
                          {ad.min_budget} - {ad.max_budget}
                        </td>
                        <td className="tw-py-2 md:tw-py-1.5 tw-text-sm tw-ps-1">
                          {ad.amount === 0 ? (
                            <span className="tw-text-red-500">NA</span>
                          ) : (
                            ad.amount
                          )}
                        </td>
                        <td className="tw-py-2 md:tw-py-1.5 tw-text-sm tw-ps-1">
                          {ad.deadline} days
                        </td>
                        <td
                          className={`tw-py-2 md:tw-py-1.5 tw-text-sm tw-ps-1 ${
                            ad.status === "0"
                              ? "tw-text-blue-500"
                              : ad.status === "1"
                              ? "tw-text-orange-500"
                              : "tw-text-green-500"
                          }`}
                        >
                          {ad.status === "0"
                            ? "Active"
                            : ad.status === "1"
                            ? "Awarded"
                            : "Completed"}
                        </td>
                        <td className="tw-py-2 md:tw-py-1.5 tw-text-sm tw-px-1">
                          <p className="tw-flex tw-justify-between tw-items-center">
                            <Link to={`/bids/${ad._id}`} state={{ id: ad._id }}>
                              <EyeIcon
                                fill="#22C55E"
                                className="tw-mx-1 tw-cursor-pointer"
                              />
                            </Link>
                            {ad.status === "0" ? (
                              <PenIcon
                                width="16px"
                                fill="#3B82F6"
                                className="tw-mx-1 tw-cursor-pointer"
                                onClick={() =>
                                  ad.status === "0" && handleEditModal(ad)
                                }
                              />
                            ) : (
                              <PenIcon
                                width="16px"
                                fill="#3B82F6"
                                className="disabled tw-mx-1"
                                onClick={() =>
                                  ad.status === "0" && handleEditModal(ad)
                                }
                              />
                            )}
                            {ad.status === "0" ? (
                              <DeleteIcon
                                className="tw-mx-1 tw-cursor-pointer"
                                onClick={() =>
                                  ad.status === "0" && deleteAd(ad._id)
                                }
                              />
                            ) : (
                              <DeleteIcon className="tw-mx-1 disabled" />
                            )}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="tw-text-center">
                      <td
                        colSpan={5}
                        className="tw-py-2 md:tw-py-1.5 tw-text-sm"
                      >
                        No Ad posted
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {ads.length > 0 && (
              <Pagination page={page} setPage={setPage} data={ads} />
            )}
          </>
        )}
      </div>
      <PostAdModal
        open={postModal}
        onClose={() => setPostModal(false)}
        postAd={postAd}
        industries={industries}
      />
      <EditAdModal
        key={randomId()}
        open={editAdModal}
        onClose={() => setEditAdModal(false)}
        singleAd={singleAd}
        editAd={editAd}
        industries={industries}
      />
    </div>
  );
};

export default MyAds;
