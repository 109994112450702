import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import { Button } from "../../../components/ui/Button";
import { ConfirmationModal } from "../../../components/ui/modals/utility-modals/confirmationModal";
import { ImportImage } from "../../../utils/importImage";
import FavIcon from "../../../images/favicon.png";

const InvestmentDetails = () => {
  const investmentDetailsApi = `${process.env.REACT_APP_API_URL}view-investment`;
  const updateInvestmentStatusApi = `${process.env.REACT_APP_API_URL}update-investment-status`;

  const { token, userRole } = useLocalStorage();
  const { fetchApi, isLoading, error } = useFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({
    bank: [],
    startup: [],
    investor: [],
    transaction: [],
  });

  const investmentDetails = useCallback(() => {
    fetchApi(
      {
        url: investmentDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          startup_id: data.startup_id,
          investor_id: data.investor_id,
          transaction_id: data.transaction_id,
        },
      },
      (data) => {
        setDetails({
          bank: data.bank_details,
          startup: data.startup_details,
          investor: data.investor_details,
          transaction: data.transaction,
        });
      }
    );
  }, [investmentDetailsApi, token, fetchApi, data]);

  const updateStatus = useCallback(
    (type, status) => {
      let bodyData = {
        startup_id: data.startup_id,
        transaction_id: data.transaction_id,
      };
      if (type === "verify") {
        bodyData = { ...bodyData, status_by_admin: status };
      } else {
        bodyData = { ...bodyData, transfer_to_startup_status: status };
      }
      fetchApi(
        {
          url: updateInvestmentStatusApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: bodyData,
        },
        () => {
          investmentDetails();
        }
      );
    },
    [updateInvestmentStatusApi, token, fetchApi, data, investmentDetails]
  );

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      investmentDetails();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [investmentDetails, navigate, userRole]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-4">
          {isLoading && (
            <div className="tw-pt-[150px]">
              <Loader />
            </div>
          )}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-3">
              {details.investor.length > 0 && (
                <div className="tw-p-2 tw-shadow-md tw-rounded-md">
                  <img
                    src={
                      details.investor[0].logo.length > 0
                        ? details.investor[0].logo
                        : FavIcon
                    }
                    alt="investor-img"
                    width={100}
                    className="tw-h-[100px] tw-rounded-full tw-p-1 tw-mb-2 tw-border tw-border-slate-400"
                  />
                  <p className="tw-font-semibold">
                    Role :
                    <span className="tw-font-medium tw-ps-1">Investor</span>
                  </p>
                  <p className="tw-font-semibold">
                    Name :
                    <span className="tw-font-medium tw-ps-1">
                      {details.investor[0].name}
                    </span>
                  </p>
                  <p className="tw-font-semibold">
                    Email :
                    <span className="tw-font-medium tw-ps-1">
                      {details.investor[0].email}
                    </span>
                  </p>
                  <p className="tw-font-semibold">
                    Phone :
                    <span className="tw-font-medium tw-ps-1">
                      {details.investor[0].phone}
                    </span>
                  </p>
                </div>
              )}
              {details.startup.length > 0 && (
                <div className="tw-p-2 tw-shadow-md tw-rounded-md">
                  <img
                    src={
                      details.startup[0].logo.length > 0
                        ? details.startup[0].logo
                        : FavIcon
                    }
                    alt="startup-img"
                    width={100}
                    className="tw-h-[100px] tw-rounded-full tw-p-1 tw-mb-2 tw-border tw-border-slate-400"
                  />
                  <p className="tw-font-semibold">
                    Role :
                    <span className="tw-font-medium tw-ps-1">Startup</span>
                  </p>
                  <p className="tw-font-semibold">
                    Name :
                    <span className="tw-font-medium tw-ps-1">
                      {details.startup[0].name}
                    </span>
                  </p>
                  <p className="tw-font-semibold">
                    Email :
                    <span className="tw-font-medium tw-ps-1">
                      {details.startup[0].email}
                    </span>
                  </p>
                  <p className="tw-font-semibold">
                    Phone :
                    <span className="tw-font-medium tw-ps-1">
                      {details.startup[0].phone}
                    </span>
                  </p>
                </div>
              )}
              <div className="tw-col-span-1 md:tw-col-span-2 tw-p-3 tw-shadow-md tw-rounded-md">
                {details.transaction.length > 0 && (
                  <>
                    <div className="md:tw-col-span-2 tw-flex tw-items-center tw-justify-between tw-mb-4">
                      <h4 className="tw-font-semibold">Investment Details</h4>
                      {details.transaction[0].status_by_admin === "0" ? (
                        <div>
                          <Button
                            className="button tw-py-1 tw-px-2 tw-mx-2 tw-text-xs"
                            bgColor="tw-bg-green-500"
                            onClick={() => updateStatus("verify", "1")}
                          >
                            Payment Verified
                          </Button>
                          <Button
                            className="button tw-py-1 tw-px-2 tw-mx-2 tw-text-xs"
                            bgColor="tw-bg-secondary"
                            onClick={() => updateStatus("verify", "-1")}
                          >
                            Payment Not Recieved
                          </Button>
                        </div>
                      ) : details.transaction[0].status_by_admin === "1" ? (
                        <Button
                          className="tw-py-1 tw-px-2 tw-mx-2 tw-text-xs disabled"
                          bgColor="tw-bg-green-700"
                          disabled
                        >
                          Verified
                        </Button>
                      ) : (
                        <Button
                          className="tw-py-1 tw-px-2 tw-mx-2 tw-text-xs disabled"
                          bgColor="tw-bg-secondary"
                          disabled
                        >
                          Rejected
                        </Button>
                      )}
                    </div>
                    <div className="tw-grid tw-grid-cols-4 tw-gap-1">
                      <p className="tw-font-medium">
                        Date :
                        <span className="tw-ps-2">
                          {details.transaction[0].date}
                        </span>
                      </p>
                      <p className="tw-font-medium">
                        Amount :
                        <span className="tw-ps-2">
                          ₹{details.transaction[0].withdrable_amount}
                        </span>
                      </p>
                      <p className="tw-col-span-2 tw-font-medium">
                        Transaction Id :
                        <span className="tw-ps-2">
                          {details.transaction[0].transaction_id}
                        </span>
                      </p>
                      <p className="tw-font-medium">
                        Verified :
                        <span
                          className={`tw-ms-2 tw-p-0.5 tw-rounded-md tw-text-sm ${
                            details.transaction[0].status_by_admin === "0"
                              ? "tw-bg-orange-200 tw-text-orange-800"
                              : details.transaction[0].status_by_admin === "1"
                              ? "tw-bg-green-200 tw-text-green-800"
                              : "tw-bg-red-200 tw-text-red-800"
                          }`}
                        >
                          {details.transaction[0].status_by_admin === "0"
                            ? "Pending"
                            : details.transaction[0].status_by_admin === "1"
                            ? "Verified"
                            : "Rejected"}
                        </span>
                      </p>
                      <p className="tw-font-medium">
                        Processed :
                        <span
                          className={`tw-ms-2 tw-p-0.5 tw-rounded-md tw-text-sm ${
                            details.transaction[0]
                              .transfer_to_startup_status === "0"
                              ? "tw-bg-red-200 tw-text-red-800"
                              : "tw-bg-green-200 tw-text-green-800"
                          }`}
                        >
                          {details.transaction[0].transfer_to_startup_status ===
                          "0"
                            ? "No"
                            : "Yes"}
                        </span>
                      </p>
                      <p className="tw-col-span-2 tw-font-medium">
                        Transaction Mode :
                        <span className="tw-ps-2 tw-capitalize">
                          {details.transaction[0].transaction_mode}
                        </span>
                      </p>
                    </div>
                  </>
                )}
                <div className="tw-flex tw-justify-between tw-items-center">
                  <h4 className="tw-font-semibold tw-my-3">Bank Details</h4>
                </div>
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 tw-mb-3">
                  {details.bank.length > 0 ? (
                    details.bank.map((bank) => (
                      <div
                        key={bank._id}
                        className="tw-flex tw-justify-between tw-items-center tw-p-2 tw-bg-slate-300 tw-shadow-md tw-rounded-md"
                      >
                        <div>
                          <p className="tw-font-medium">
                            Name :
                            <span className="tw-ps-2 tw-uppercase">
                              {bank.name}
                            </span>
                          </p>
                          <p className="tw-font-medium">
                            Bank :
                            <span className="tw-ps-2 tw-uppercase">
                              {bank.bank_name} BANK
                            </span>
                          </p>
                          <p className="tw-font-medium">
                            Account Number :
                            <span className="tw-ps-2">
                              {bank.account_number}
                            </span>
                          </p>
                          <p className="tw-font-medium">
                            IFSC :<span className="tw-ps-2">{bank.ifsc}</span>
                          </p>
                        </div>
                        <ImportImage
                          imageName={bank.bank_name}
                          width={45}
                          alt={bank.bank_name}
                        />
                      </div>
                    ))
                  ) : (
                    <p className="tw-p-2 md:tw-col-span-2">
                      Bank details not found. Please ask startup to add bank
                      details from profile page.
                    </p>
                  )}
                </div>
                {details.transaction.length > 0 ? (
                  details.transaction[0].status_by_admin === "1" ? (
                    details.transaction[0].transfer_to_startup_status ===
                    "0" ? (
                      <Button
                        className="button tw-py-1 tw-px-2 tw-text-xs"
                        onClick={() => setOpen(true)}
                      >
                        Trasnfered to Startup
                      </Button>
                    ) : (
                      <Button
                        className="tw-py-1 tw-px-2 tw-text-xs disabled"
                        disabled
                      >
                        Payment Processed
                      </Button>
                    )
                  ) : details.transaction[0].status_by_admin === "0" ? (
                    <Button
                      className="tw-py-1 tw-px-2 tw-text-xs disabled"
                      disabled
                    >
                      Need Payment Verification
                    </Button>
                  ) : null
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModal
        open={open}
        onClose={() => setOpen(false)}
        message="Please make sure that you have transfered the correct money to startup successfully before confirming."
        callBackFn={() => updateStatus("processed", "1")}
      />
    </div>
  );
};

export default InvestmentDetails;
