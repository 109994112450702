import { useState, useRef, useEffect } from "react";
import { SendIcon } from "../../icons/icons";
import SingleMessage from "./SingleMessage";

const Chat = ({ sendMessage, messageList }) => {
  const [msg, setMsg] = useState("");
  const lastMessageRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMsg("");
    sendMessage(msg);
  };

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageList]);

  return (
    <div className="chat">
      <ul className="messageList tw-space-y-2">
        {messageList?.map((msg) => (
          <SingleMessage key={msg.time} data={msg} />
        ))}
        <li ref={lastMessageRef}></li>
      </ul>
      <form onSubmit={handleSubmit} className="chat-form tw-rounded-md">
        <div className="chat-input">
          <input
            type="text"
            className="tw-w-full tw-p-2"
            placeholder="Type a message"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          />
          <button
            type="submit"
            className="tw-flex tw-items-center tw-justify-between tw-px-2"
          >
            <SendIcon className="tw-rotate-45" fill="#000" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Chat;
