import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Pagination } from "../../components/ui/Button";
import FavIcon from "../../images/favicon.png";

export const BlockedUsers = ({ blockedConnections, unBlock }) => {
  const [page, setPage] = useState(1);

  return (
    <>
      <h4 className="tw-mb-1 md:tw-mb-4 tw-font-semibold md:tw-text-lg">
        Blocked Users
      </h4>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-font-medium tw-text-sm">User Name</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Blocked on</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Actions</th>
            </tr>
          </thead>
          <tbody>
            {blockedConnections.length > 0 ? (
              blockedConnections.slice(page * 7 - 7, page * 7).map((user) => (
                <tr
                  key={user.user_id}
                  className="tw-bg-white tw-border-b tw-border-slate-300"
                >
                  <td className="tw-p-2 tw-flex tw-items-center tw-capitalize">
                    <img
                      src={user.logo.length > 0 ? user.logo : FavIcon}
                      alt="Startup Logo"
                      className="tw-mx-3 tw-w-[40px] tw-h-[40px] md:tw-w-[50px] md:tw-h-[50px] tw-rounded-full"
                      loading="lazy"
                    />
                    {user.name}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {user.date}
                  </td>
                  <td
                    className="tw-p-2 tw-text-xs md:tw-text-sm"
                    id="connectedUserId"
                  >
                    <Link
                      to={`/view-startup/${user.name}/${user.user_id}`}
                      className="tw-py-1.5 tw-px-2 tw-text-xs md:tw-text-sm tw-bg-indigo-600 tw-text-white tw-mx-1 tw-rounded-md hover:tw-text-white"
                    >
                      Profile
                    </Link>
                    <Button
                      onClick={() => unBlock(user.user_id)}
                      className="tw-py-1 tw-px-2 tw-mx-1 tw-text-xs md:tw-text-sm"
                      bgColor="tw-bg-green-600"
                    >
                      Un-Block
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan="3" className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No blocked users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {blockedConnections.length > 0 && (
        <Pagination page={page} setPage={setPage} data={blockedConnections} />
      )}
    </>
  );
};
