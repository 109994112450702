import ReactDom from "react-dom";
import parse from "html-react-parser";

const ViewSummary = ({ open, onClose, data, userId }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[750px] md:tw-max-w-[750px] tw-bg-[#f9f9f9] tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <div className="tw-h-[550px] tw-overflow-auto tw-p-5 tw-flex tw-flex-col tw-items-start tw-gap-y-5">
          {data.length > 0 &&
            data.map((summary) => (
              <div key={summary._id}>
                <h5 className="tw-font-medium tw-text-lg tw-mb-1">
                  {summary.user_id === userId
                    ? "My Summary"
                    : "Other Users Summary"}
                </h5>
                <div>{parse(summary.summary)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default ViewSummary;
