import React, { useState } from "react";
import { Pagination } from "../../../components/ui/Button";

export function TransactionTable({ walletTransactions }) {
  const [page, setPage] = useState(1);

  return (
    <>
      <div className="tw-overflow-auto tw-w-full tw-mt-3 md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-ps-1 tw-font-medium tw-text-sm">Date</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Plan Name</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Amount</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Tokens</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                TransactionId
              </th>
              <th className="tw-p-2 tw-pe-1 tw-font-medium tw-text-sm">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {walletTransactions.length > 0 ? (
              walletTransactions
                .slice(page * 9 - 9, page * 9)
                .map((transaction) => (
                  <tr
                    key={transaction.transaction_id}
                    className="tw-bg-white tw-border-b tw-border-slate-300"
                  >
                    <td className="tw-p-2 tw-ps-1 tw-text-xs md:tw-text-sm">
                      {transaction.date}
                    </td>
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      <span
                        className={`tw-px-1 tw-rounded-md tw-capitalize tw-text-xs md:tw-text-sm tw-border ${
                          transaction.plan_type === "silver"
                            ? "tw-border-blue-400 tw-text-blue-400"
                            : transaction.plan_type === "gold"
                            ? "tw-border-orange-400 tw-text-orange-400"
                            : "tw-border-purple-500 tw-text-purple-500"
                        }`}
                      >
                        {transaction.plan_type}
                      </span>
                    </td>
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      {transaction.amount}
                    </td>
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      {transaction.credits}
                    </td>
                    <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                      {transaction.transaction_id}
                    </td>
                    <td className="tw-pe-1">
                      <span
                        className={`tw-px-1 tw-text-xs md:tw-text-sm tw-rounded-md tw-capitalize tw-border ${
                          transaction.status === "paid"
                            ? "tw-border-green-400 tw-text-green-400"
                            : "tw-border-red-400 tw-text-red-400"
                        }`}
                      >
                        {transaction.status}
                      </span>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan="6" className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No transaction found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {walletTransactions.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          data={walletTransactions}
          dataCount={9}
        />
      )}
    </>
  );
}
