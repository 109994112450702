import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useFetchCredits } from "../../hooks/useFetchCredits";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckConnection from "../../hooks/useCheckConnection";
import useUnfollowConnection from "../../hooks/useUnfollowConnection";
import useVerificationApi from "../../hooks/useVerificationApi";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import "./Profile.css";
import "./viewProfile.css";
import FavIcon from "../../images/favicon.png";
import {
  CalenderIcon,
  FolderIcon,
  LocationIcon,
  MinusIcon,
  RupeeIcon,
} from "../../icons/icons";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import { useToastContext } from "../../context/ToastContext";

const ViewInvestorProfile = () => {
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}display-investor-profile`;
  const sendConnectionRequestApi = `${process.env.REACT_APP_API_URL}send-connect-request`;

  const [investorDetails, setInvestorDetails] = useState([]);
  const [investorIndustries, setInvestorIndustries] = useState([]);
  const [prevInvestments, setPrevInvestments] = useState([]);
  const [investorBio, setInvestorBio] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("");
  const [startupVerified, setStartupVerified] = useState("0");
  const [openSidebar, setOpenSidebar] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(true);
  const { user_id } = useParams();
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { credits, getCredits } = useFetchCredits();
  const { sendNotification } = useNotificationApi();
  const { checkConnection } = useCheckConnection();
  const { unFollow } = useUnfollowConnection();
  const { verification } = useVerificationApi();
  const toast = useToastContext();

  const getInvestorDetails = useCallback(() => {
    if (!user_id) {
      toast.open("false", "Sorry, something went wrong. Please try again");
      console.log("user_id not found");
      return;
    }
    fetchApi(
      {
        url: investorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          alert("This accound has been suspended/Deactivated.");
          navigate(NavigateToDashboard(userRole));
          return;
        }
        setInvestorDetails(data.investor_profile[0]);
        setInvestorIndustries(data.investor_profile[0].investor_industries);
        setProfileImg(data.investor_profile[0].logo);
        setInvestorBio(data.investor_profile[0].description);
        setPrevInvestments(data.investor_profile[0].previous_investment);
        setStartupVerified(data.profile_verify);
      }
    );
  }, [investorDetailsApi, fetchApi, token, user_id, navigate, userRole, toast]);

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getInvestorDetails();
      checkConnection(user_id, setConnectionStatus);
      getCredits();
    }
    return () => {
      ref.current = false;
    };
  }, [
    userRole,
    navigate,
    getCredits,
    getInvestorDetails,
    verification,
    checkConnection,
    user_id,
  ]);

  const sendConnectionRequest = () => {
    fetchApi(
      {
        url: sendConnectionRequestApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
        },
      },
      (data) => {
        setConnectionStatus("pending");
        sendNotification(
          user_id,
          `Incoming connection request from ${data.name}`,
          "connections"
        );
      }
    );
  };

  const routeToChat = () => {
    dispatch(deleteChatRoom());
    navigate("/chat");
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-3 md:tw-p-4">
          <div>
            {isLoading && !error ? (
              <Loader />
            ) : (
              <>
                <div className="tw-grid tw-grid-cols-6 tw-gap-2">
                  <div className="tw-col-span-6 tw-ml-auto md:tw-hidden">
                    {connectionStatus === "connected" &&
                      userRole === "f@!3A" && (
                        <>
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="msg-btn"
                            onClick={() => routeToChat()}
                          >
                            Message
                          </button>
                          <button
                            className="tw-flex tw-items-center tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                            id="disconnect-btn"
                            onClick={() =>
                              unFollow(user_id, setConnectionStatus)
                            }
                          >
                            Disconnect
                            <MinusIcon className="tw-ps-2" />
                          </button>
                        </>
                      )}
                    {connectionStatus === "disconnect" &&
                    userRole === "f@!3A" ? (
                      credits >= 100 && startupVerified === "1" ? (
                        <button
                          className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                          id="connect-btn"
                          onClick={() => sendConnectionRequest()}
                        >
                          Connect
                        </button>
                      ) : credits >= 100 && startupVerified !== "1" ? (
                        <Link
                          title="Not verified"
                          className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                          id="connect-btn"
                        >
                          Connect
                        </Link>
                      ) : credits < 100 && startupVerified === "1" ? (
                        <Link
                          to="/subscription"
                          title="Insufficient tokens"
                          className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                          id="connect-btn"
                        >
                          Connect
                        </Link>
                      ) : (
                        <Link
                          title="Not verified"
                          className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                          id="connect-btn"
                        >
                          Connect
                        </Link>
                      )
                    ) : null}
                  </div>
                  <div className="tw-col-span-6 md:tw-col-span-2">
                    <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-text-center">
                      <img
                        src={profileImg.length > 0 ? profileImg : FavIcon}
                        className="tw-rounded-full tw-w-[90px] tw-h-[90px] md:tw-w-[140px] md:tw-h-[140px] tw-border tw-border-slate-400 tw-p-2"
                        alt="profile-img"
                      />
                      <div className="tw-text-start">
                        <p className="tw-flex tw-items-center tw-font-bold tw-text-lg tw-pt-2 tw-mb-0 tw-uppercase">
                          {investorDetails.name}
                        </p>
                        <div className="tw-flex tw-items-center tw-mb-2">
                          <CalenderIcon className="#999999" />
                          <p className="tw-text-sm tw-mb-0 tw-ms-3">
                            <span className="tw-font-bold tw-me-1">
                              Joined On :
                            </span>
                            {investorDetails.date}
                          </p>
                        </div>
                        <div className="tw-flex tw-items-center tw-mb-2">
                          <RupeeIcon fill="#999999" />
                          <p className="tw-text-sm tw-mb-0 tw-ms-3">
                            <span className="tw-font-bold tw-me-1">
                              Total Investment :
                            </span>
                            {investorDetails.invested_amount}
                          </p>
                        </div>
                        <div className="tw-flex tw-items-center tw-mb-2">
                          <RupeeIcon fill="#999999" />
                          <p className="tw-text-sm tw-mb-0 tw-ms-3">
                            <span className="tw-font-bold tw-me-1">
                              Investment Target :
                            </span>
                            {investorDetails.future_investment_amount}
                          </p>
                        </div>
                        <div className="tw-flex tw-items-center tw-mb-2">
                          <FolderIcon fill="#999999" />
                          <p className="tw-text-sm tw-mb-0 tw-ms-3">
                            <span className="tw-font-bold tw-me-1">
                              Companies in portfolio :
                            </span>{" "}
                            {investorDetails.companies_invested_count}
                          </p>
                        </div>
                        <div className="tw-flex tw-mb-4">
                          <LocationIcon fill="#999999" />
                          <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3">
                            <span className="tw-font-bold tw-me-1">
                              Address :
                            </span>
                            <div className="tw-flex tw-items-center">
                              <span className="tw-me-1">
                                {investorDetails.city}
                              </span>
                              <img
                                src={
                                  investorDetails.flag_path &&
                                  `${process.env.REACT_APP_API_URL}` +
                                    investorDetails.flag_path.slice(6)
                                }
                                alt={`${investorDetails.country}-flag`}
                                width={18}
                                title={investorDetails.country}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tw-border-t tw-py-2">
                          <p className="tw-mb-0 tw-font-bold">Industries</p>
                          {investorIndustries.map((industry, i) => (
                            <span key={i} className="outline-tags">
                              {industry.replace(new RegExp("_", "g"), " ")}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tw-col-span-6 md:tw-col-span-4">
                    {startupVerified !== "1" &&
                      connectionStatus !== "connected" &&
                      userRole === "f@!3A" && (
                        <span className="tw-text-secondary tw-text-xs">
                          Get mentor verification to connect with investor.
                          <a
                            href="https://www.youtube.com/watch?v=FRLDZTLYn8w"
                            target="_blank"
                            rel="noreferrer"
                            className="tw-ms-1 tw-underline tw-underline-offset-2 tw-text-blue-500"
                          >
                            Know why ?
                          </a>
                        </span>
                      )}
                    <div className="tw-hidden md:tw-flex tw-items-center tw-justify-end tw-mb-8">
                      {connectionStatus === "connected" &&
                        userRole === "f@!3A" && (
                          <>
                            <button
                              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white"
                              id="msg-btn"
                              onClick={() => routeToChat()}
                            >
                              Message
                            </button>
                            <button
                              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                              id="disconnect-btn"
                              onClick={() =>
                                unFollow(user_id, setConnectionStatus)
                              }
                            >
                              Disconnect
                            </button>
                          </>
                        )}
                      {connectionStatus === "disconnect" &&
                      userRole === "f@!3A" ? (
                        credits >= 100 && startupVerified === "1" ? (
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="connect-btn"
                            onClick={() => sendConnectionRequest()}
                          >
                            Connect
                          </button>
                        ) : credits >= 100 && startupVerified !== "1" ? (
                          <Link
                            title="Not verified"
                            className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="connect-btn"
                          >
                            Connect
                          </Link>
                        ) : credits < 100 && startupVerified === "1" ? (
                          <Link
                            to="/subscription"
                            title="Insufficient tokens"
                            className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="connect-btn"
                          >
                            Connect
                          </Link>
                        ) : (
                          <Link
                            title="Not verified"
                            className="disabled tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="connect-btn"
                          >
                            Connect
                          </Link>
                        )
                      ) : null}
                    </div>
                    {investorBio.length > 0 ? (
                      <div className="tw-mt-4 tw-p-3 tw-pt-1 tw-shadow-md tw-rounded-md">
                        <h4 className="tw-my-3 tw-font-medium tw-text-lg">
                          Bio
                        </h4>
                        <p>{investorBio}</p>
                      </div>
                    ) : (
                      <div className="tw-rounded-md tw-bg-white tw-shadow-md tw-mt-3 tw-p-3">
                        No Bio added
                      </div>
                    )}
                    {prevInvestments.length > 0 ? (
                      <>
                        <div className="tw-p-2 tw-rounded-md tw-bg-white tw-shadow tw-mt-3 tw-py-3">
                          <h5 className="tw-font-medium tw-mb-3">
                            Previously Invested Companies
                          </h5>
                          {prevInvestments.map((investment) => (
                            <div
                              key={investment._id}
                              className="tw-flex tw-items-center tw-justify-between tw-p-2 tw-my-1 tw-bg-gray-200 tw-rounded"
                            >
                              <div>
                                <span className="tw-font-medium tw-me-2">
                                  Company Name :
                                </span>
                                <span>{investment.company_name}</span>
                              </div>
                              <Link
                                to={"https://" + investment.company_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="tw-font-medium tw-me-2">
                                  Website Url :
                                </span>
                                <span>{investment.company_url}</span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="tw-rounded-md tw-bg-white tw-shadow-md tw-mt-3 tw-p-3">
                        No previous investments
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewInvestorProfile;
