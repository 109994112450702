import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import {
  bannedInputs,
  numberRegex,
  textWithSpaceRegex,
} from "../../../../constants";
import { BuildingIcon, PhoneIcon, UserIcon } from "../../../../icons/icons";
import Dropdown from "../../inputs/dropdown";

export const EditVendorProfile = ({
  open,
  onClose,
  editVendorDetails,
  editData,
  industries,
}) => {
  const [expertise, setExpertise] = useState([]);
  const ref = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vendorName: editData.name,
      vendorPhone: editData.phone,
      vendorCompany: editData.companyName,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData.industry) {
        setExpertise([...editData.industry]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    document.getElementById("industry-error").classList.add("tw-hidden");
    document.getElementById("limit-error").classList.add("tw-hidden");
    if (expertise.length < 1) {
      document.getElementById("industry-error").classList.remove("tw-hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("limit-error").classList.remove("tw-hidden");
      return;
    }
    editVendorDetails(data, expertise);
    setExpertise([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[50%] md:tw-max-w-[50%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 tw-p-3 tw-mt-5"
        >
          <div className="tw-mb-2">
            <label htmlFor="vendorName" className="tw-mb-0 tw-text-sm">
              Edit Name
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="vendorName"
                name="vendorName"
                placeholder="Enter Name"
                autoFocus
                {...register("vendorName", {
                  validate: {
                    validLength: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? fieldValue.trim().length > 2 ||
                            "minimum required length is 3"
                        : null;
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons icon-span tw-w-[39px]">
                <UserIcon />
              </span>
            </div>
            {errors.vendorName && (
              <div>
                <FormError message={errors.vendorName.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-2">
            <label htmlFor="vendorPhone" className="tw-mb-0 tw-text-sm">
              Change Contact Number
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                maxLength="12"
                minLength="7"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="vendorPhone"
                name="vendorPhone"
                placeholder="Enter Phone Number"
                {...register("vendorPhone", {
                  validate: {
                    minValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length > 6 ||
                            "Number should be atleast 7 digits"
                        : null;
                    },
                    maxValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length < 13 ||
                            "Number cannot be more than 12 digits"
                        : null;
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span tw-w-[39px]">
                <PhoneIcon />
              </span>
            </div>
            {errors.vendorPhone && (
              <div>
                <FormError message={errors.vendorPhone.message} />
              </div>
            )}
          </div>
          <div className="tw-flex-col">
            <p className="tw-text-sm tw-mb-0">
              Company expertise <span>({expertise.length + "/3"})</span>
              <span
                id="limit-error"
                className="tw-hidden tw-text-red-500 tw-text-xs"
              >
                Limit exceeded
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={expertise.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setExpertise(selectedOptions.map((option) => option.value))
              }
              isMulti
            />
            <small
              className="tw-text-red-500 tw-mb-2 tw-hidden"
              id="industry-error"
            >
              This field is mandatory.
            </small>
          </div>
          <div className="tw-mb-2">
            <label htmlFor="vendorCompany" className="tw-mb-0 tw-text-sm">
              Change Company Name
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="vendorCompany"
                name="vendorCompany"
                placeholder="Enter Company Name"
                {...register("vendorCompany", {
                  validate: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      : null;
                  },
                })}
              />
              <span className="input-icons icon-span tw-w-[39px]">
                <BuildingIcon />
              </span>
            </div>
            {errors.vendorCompany && (
              <div>
                <FormError message={errors.vendorCompany.message} />
              </div>
            )}
          </div>
          <div className="md:tw-col-span-2 tw-flex tw-items-center tw-justify-end tw-p-3">
            <Button
              onClick={onClose}
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button
              type="submit"
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
