import React, { useCallback, useEffect, useRef, useState } from "react";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { ViewTicketModal } from "../../components/ui/modals/ticket/viewTicketModal";
import { TicketModal } from "../../components/ui/modals/ticket/ticketModal";
import { Button, Pagination } from "../../components/ui/Button";

export const ComplaintTickets = () => {
  const getSupportTicketsApi = `${process.env.REACT_APP_API_URL}display-support-tickets`;
  const cancelTicketApi = `${process.env.REACT_APP_API_URL}cancel-ticket`;
  const ticketDetailsApi = `${process.env.REACT_APP_API_URL}ticket-details`;
  const supportTicketApi = `${process.env.REACT_APP_API_URL}support-ticket`;

  const [tickets, setTickets] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [ticketCount, setTicketCount] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);

  const displaySupportTickets = useCallback(() => {
    fetchApi(
      {
        url: getSupportTicketsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setTickets(data.support_ticket);
        setTicketCount(data.ticket_count);
      }
    );
  }, [getSupportTicketsApi, fetchApi, token]);

  const cancelTicket = useCallback(
    (ticketId) => {
      setIsError(false);
      fetchApi(
        {
          url: cancelTicketApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: ticketId,
          },
        },
        (data) => {
          if (data.message !== "cancelled") {
            setIsError(true);
            return;
          }
          displaySupportTickets();
        }
      );
    },
    [cancelTicketApi, fetchApi, token, displaySupportTickets]
  );

  const ticketDetail = useCallback(
    (ticketId) => {
      fetchApi(
        {
          url: ticketDetailsApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: ticketId,
          },
        },
        (data) => {
          setTicketDetails(data.support_ticket);
          setOpen(true);
        }
      );
    },
    [ticketDetailsApi, fetchApi, token]
  );

  const supportTicket = async (data) => {
    fetchApi(
      {
        url: supportTicketApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          support_type: data.complaintCategory.replace("-", " "),
          ticket_heading: data.complaintHeading,
          query: data.complaintQuery,
        },
      },
      () => {
        displaySupportTickets();
      }
    );
  };

  useEffect(() => {
    if (ref.current) {
      displaySupportTickets();
    }
    return () => {
      ref.current = false;
    };
  }, [displaySupportTickets]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-3 md:tw-p-5 tw-h-full">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <>
              <div className="tw-flex tw-items-center tw-justify-between">
                <p className="tw-mb-0">
                  <span className="tw-font-bold tw-pe-1">
                    COMPLAINTS RAISED :{" "}
                  </span>
                  {ticketCount}
                </p>
                <Button
                  bgColor="tw-bg-[#F97316]"
                  className="tw-py-1.5 tw-px-2"
                  onClick={() => setOpenTicketModal(true)}
                >
                  Raise Support Ticket
                </Button>
              </div>
              {isError && (
                <p className="tw-text-red-500">
                  Sorry, Ticket cancellelation failed. Please try again later or
                  raise a support ticket for help.
                </p>
              )}
              <div className="tw-overflow-auto md:tw-overflow-none md:tw-w-full">
                <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border">
                  <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300">
                    <tr className="tw-text-center">
                      <th className="tw-py-2 tw-font-medium tw-text-xs md:tw-text-sm">
                        Created On
                      </th>
                      <th className="tw-py-2 tw-font-medium tw-text-xs md:tw-text-sm">
                        Complaint Category
                      </th>
                      <th className="tw-py-2 tw-font-medium tw-text-xs md:tw-text-sm">
                        Status
                      </th>
                      <th className="tw-py-2 tw-font-medium tw-text-xs md:tw-text-sm">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.length > 0 ? (
                      tickets.slice(page * 7 - 7, page * 7).map((ticket) => (
                        <tr
                          key={ticket._id}
                          className="tw-bg-white tw-border-b tw-border-slate-300 tw-text-center"
                        >
                          <td className="tw-py-1.5 tw-text-xs md:tw-text-sm">
                            {ticket.date}
                          </td>
                          <td className="tw-py-1.5 tw-text-xs md:tw-text-sm tw-capitalize">
                            {ticket.support_type}
                          </td>
                          <td
                            className={`tw-text-xs md:tw-text-sm ${
                              ticket.status === "0"
                                ? "!tw-text-red-600"
                                : ticket.status === "1"
                                ? "!tw-text-orange-600"
                                : "!tw-text-green-600"
                            }`}
                          >
                            {ticket.status === "0"
                              ? "Pending"
                              : ticket.status === "1"
                              ? "Under Review"
                              : "Solved"}
                          </td>
                          <td className="tw-py-1.5 tw-flex tw-justify-center tw-items-center">
                            <Button
                              className="button tw-px-1 tw-py-0.5 md:tw-px-2 md:tw-py-1 tw-mx-1 tw-text-xs"
                              onClick={() => ticketDetail(ticket._id)}
                            >
                              View Details
                            </Button>
                            <Button
                              className={`button tw-px-1 tw-py-0.5 md:tw-px-2 md:tw-py-1 tw-mx-1 tw-text-xs ${
                                ticket.status === "2" && "disabled"
                              }`}
                              onClick={() => cancelTicket(ticket._id)}
                              bgColor="tw-bg-[#F97316]"
                              disabled={ticket.status === "2" ? true : false}
                            >
                              Cancel
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="tw-text-center">
                        <td
                          colSpan={4}
                          className="tw-py-1.5 tw-text-xs md:tw-text-sm"
                        >
                          No complaints raised
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {tickets.length > 0 && (
                <Pagination page={page} setPage={setPage} data={tickets} />
              )}
            </>
          )}
        </div>
        <ViewTicketModal
          open={open}
          onClose={() => setOpen(false)}
          cancelTicket={cancelTicket}
          ticketDetails={ticketDetails}
        />
        <TicketModal
          open={openTicketModal}
          onClose={() => setOpenTicketModal(false)}
          supportTicket={supportTicket}
        />
      </div>
    </div>
  );
};
