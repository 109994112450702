import React from "react";

const WalletTable = ({ data }) => {
  return (
    <table
      className="tw-table-auto tw-w-full tw-border tw-shadow-sm tw-border-slate-300"
      id="recent-transaction-table"
    >
      <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
        <tr>
          <th className="tw-py-2 tw-ps-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Startup
          </th>
          <th className="tw-py-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Date
          </th>
          <th className="tw-py-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Amount
          </th>
          <th className="tw-py-2 tw-pe-1 tw-font-medium tw-text-xs sm:tw-text-sm">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          data.map((transaction) => (
            <tr
              key={transaction.id}
              className="tw-bg-white tw-border-b tw-border-slate-300"
            >
              <td className="tw-py-2 tw-ps-2 tw-text-xs sm:tw-text-sm tw-capitalize">
                {transaction.name}
              </td>
              <td className="tw-py-2 tw-text-xs sm:tw-text-sm">
                {transaction.date}
              </td>
              <td className="tw-py-2 tw-text-xs sm:tw-text-sm">
                {transaction.freeze_points}
              </td>
              <td className="tw-pe-1">
                <span
                  className={`tw-px-1 tw-text-xs sm:tw-text-sm tw-rounded-md tw-border ${
                    transaction.status === "0"
                      ? "tw-border-blue-500 tw-text-blue-500"
                      : transaction.status === "1"
                      ? "tw-border-orange-500 tw-text-orange-500"
                      : "tw-border-green-500 tw-text-green-500"
                  }`}
                >
                  {transaction.status === "0"
                    ? "Freezed"
                    : transaction.status === "1"
                    ? "Withdrawable"
                    : "Withdrawn"}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr className="tw-text-center">
            <td colSpan={4} className="tw-py-2 tw-text-xs sm:tw-text-sm">
              No transaction found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default WalletTable;
