import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Button } from "../../components/ui/Button";
import { Loader } from "../../components/ui/Loader";
import { EnvelopeIcon, EyeIcon, SlashedEyeIcon } from "../../icons/icons";

export const LoginForm = ({
  postLogin,
  forgotPasswordBtn,
  suspended,
  setSuspended,
}) => {
  const loginApi = `${process.env.REACT_APP_API_URL}login`;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isLoading, error, fetchApi } = useFetch();

  const [isError, setIsError] = useState(false);
  const [show, setShow] = useState(false);

  const LoginApiCall = (data, e) => {
    e.preventDefault();
    setSuspended(false);
    setIsError(false);
    fetchApi(
      {
        url: loginApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: { email: data.loginEmail, password: data.password },
      },
      postLogin
    );
  };

  return (
    <form
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-col-start-2 md:tw-col-span-5 md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-col-span-2 lg:tw-my-0 lg:tw-py-0"
      id="loginForm"
      onSubmit={handleSubmit(LoginApiCall)}
    >
      <div className="tw-mx-auto tw-p-5 md:tw-p-6 tw-rounded-md tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0">
        <h4 className="tw-mb-2 tw-text-lg tw-font-medium md:tw-text-xl">
          Welcome to {process.env.REACT_APP_COMPANY_NAME}! 👋
        </h4>
        <p className="tw-mb-4 tw-text-sm md:tw-text-base">
          Sign in to unlock a world of opportunities in our vibrant Startup
          ecosystem.
        </p>
        <div>
          <div className="tw-mb-1">
            <label htmlFor="loginEmail" className="tw-text-sm tw-mb-0 required">
              Email
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="email"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="loginEmail"
                name="loginEmail"
                placeholder="Registered email"
                {...register("loginEmail", {
                  required: true,
                  validate: {
                    matchPattern: (value) =>
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                        value
                      ) || "Please enter a valid email address",
                  },
                })}
                autoFocus
              />
              <span className="input-icons icon-span">
                <EnvelopeIcon />
              </span>
            </div>
            {errors.loginEmail && (
              <span className="tw-text-secondary tw-text-sm">
                Email is required
              </span>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="password" className="tw-text-sm tw-mb-0 required">
              Password
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={show ? "text" : "password"}
                id="password"
                className="tw-border-0 tw-w-full tw-ps-3"
                name="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: true,
                  minLength: 7,
                })}
              />
              <span
                className="input-icons tw-cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.password && (
              <span className="tw-text-secondary tw-text-sm">
                Password is required
              </span>
            )}
          </div>
          <small className="tw-hidden tw-text-red-500" id="incorrectLoginText">
            All fields are mandatory.
          </small>
          {error && !isError && (
            <small className="tw-text-red-500 passwordError">
              Incorrect email or password.
            </small>
          )}
          {suspended && (
            <small className="tw-text-red-500 passwordError">
              Account has been suspended check mail.
            </small>
          )}
          <span
            className="tw-my-3 tw-float-right tw-border-0 tw-text-blue-500 tw-cursor-pointer tw-text-xs md:tw-text-sm"
            onClick={forgotPasswordBtn}
          >
            Forgot Password?
          </span>
          <Button type="submit" className="tw-py-1.5 tw-w-full">
            Sign in
          </Button>
          <div className="tw-pt-2">{isLoading && <Loader pt="10px" />}</div>
        </div>
        <p className="tw-text-center tw-py-3 md:tw-py-2">
          <span>New on our platform?</span>
          <Link to="/register">
            <span className="tw-ps-1 tw-text-blue-500">Create Account</span>
          </Link>
        </p>
      </div>
    </form>
  );
};
