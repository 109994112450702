import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Pagination } from "../../../components/ui/Button";
import { EyeIcon, PenIcon, UserIcon } from "../../../icons/icons";

export const AllAgreements = ({
  agreementDetails,
  handleEditModal,
  viewAgreement,
  openHistoryModal,
}) => {
  const [page, setPage] = useState(1);

  return (
    <>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                Investor Name
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Equity</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Total Amount</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Startup Sign</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                Investor Sign
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Agreed On</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Action</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Details</th>
            </tr>
          </thead>
          <tbody>
            {agreementDetails && agreementDetails.length > 0 ? (
              agreementDetails
                .slice(page * 7 - 7, page * 7)
                .map((agreement) => (
                  <tr
                    className="tw-bg-white tw-border-b tw-border-slate-300"
                    key={agreement.id}
                  >
                    <td className="tw-p-2 tw-text-sm">
                      {agreement.investor_name}
                    </td>
                    <td className="tw-p-2 tw-text-sm">{agreement.equity}%</td>
                    <td className="tw-p-2 tw-text-sm">{agreement.amount}</td>
                    <td className="tw-p-2 tw-text-sm">
                      <span
                        className={`tw-px-1 tw-text-sm tw-rounded-md tw-border ${
                          agreement.startup_status === "0"
                            ? "tw-border-red-500 tw-text-red-500"
                            : "tw-border-green-500 tw-text-green-500"
                        }`}
                      >
                        {agreement.startup_status === "0"
                          ? "Not Signed"
                          : "Signed"}
                      </span>
                    </td>
                    <td className="tw-p-2 tw-text-sm">
                      <span
                        className={`tw-px-1 tw-text-sm tw-rounded-md tw-border ${
                          agreement.investor_status === "0"
                            ? "tw-border-red-500 tw-text-red-500"
                            : "tw-border-green-500 tw-text-green-500"
                        }`}
                      >
                        {agreement.investor_status === "0"
                          ? "Not Signed"
                          : "Signed"}
                      </span>
                    </td>
                    <td className="tw-p-2 tw-text-sm">
                      {agreement.agreed_date || "-"}
                    </td>
                    <td className="tw-flex tw-items-center tw-p-2 tw-text-sm">
                      {agreement.investor_deactivate === "1" ||
                      agreement.startup_deactivate === "1" ? (
                        <EyeIcon fill="#22C55E" className="disabled tw-mx-1" />
                      ) : (
                        <EyeIcon
                          fill="#22C55E"
                          className="tw-cursor-pointer tw-mx-1"
                          onClick={() => viewAgreement(agreement)}
                        />
                      )}
                      {agreement.investor_deactivate === "1" ||
                      agreement.startup_deactivate === "1" ||
                      (agreement.startup_status === "1" &&
                        agreement.investor_status === "1") ? (
                        <PenIcon fill="#9DC0FA" className="disabled tw-mx-1" />
                      ) : (
                        <PenIcon
                          fill="#9DC0FA"
                          className="tw-mx-1 tw-cursor-pointer"
                          onClick={() =>
                            handleEditModal(
                              agreement.amount,
                              agreement.equity,
                              agreement.id,
                              agreement.investor_id
                            )
                          }
                        />
                      )}
                      <Link
                        to={
                          agreement.investor_deactivate === "1" ||
                          agreement.startup_deactivate === "1"
                            ? "#"
                            : `/view-investor/${agreement.investor_name}/${agreement.investor_id}`
                        }
                      >
                        {agreement.investor_deactivate === "1" ||
                        agreement.startup_deactivate === "1" ? (
                          <UserIcon
                            fill="#F97316"
                            className="disabled tw-mx-1"
                          />
                        ) : (
                          <UserIcon fill="#F97316" className="tw-mx-1" />
                        )}
                      </Link>
                    </td>
                    <td>
                      <Button
                        className="tw-p-1 tw-w-max"
                        bgColor="tw-bg-orange-500"
                        onClick={() => openHistoryModal(agreement)}
                      >
                        Details
                      </Button>
                    </td>
                  </tr>
                ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan={9} className="tw-p-2 tw-text-sm">
                  No agreement found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {agreementDetails.length > 0 && (
        <Pagination page={page} setPage={setPage} data={agreementDetails} />
      )}
    </>
  );
};
