import { Link } from "react-router-dom";
import "../../Pages/admin-portal/admin-dashboard/adminDashboard.css";
import {
  DashboardIcon,
  UserIcon,
  CameraIcon,
  RupeeIcon,
  ClipBoardIcon,
  IndustryIcon,
} from "../../icons/icons";

const AdminSidebarMain = ({ currPath, activeStyle, regularStyle }) => {
  return (
    <ul className="tw-p-3 tw-pt-4">
      <Link
        to="/admin-dashboard"
        className={
          currPath === "#/admin-dashboard" ? activeStyle : regularStyle
        }
      >
        <DashboardIcon
          fill={currPath === "#/admin-dashboard" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Dashboard
        </span>
      </Link>
      <Link
        to="/admin/users"
        className={currPath === "#/admin/users" ? activeStyle : regularStyle}
      >
        <UserIcon fill={currPath === "#/admin/users" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">Users</span>
      </Link>
      <Link
        to="/admin/user-images"
        className={
          currPath === "#/admin/user-images" ? activeStyle : regularStyle
        }
      >
        <CameraIcon
          fill={currPath === "#/admin/user-images" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          User Images
        </span>
      </Link>
      <Link
        to="/admin/withdraw-requests"
        className={
          currPath === "#/admin/withdraw-requests" ? activeStyle : regularStyle
        }
      >
        <RupeeIcon
          fill={currPath === "#/admin/withdraw-requests" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Withdraw Requests
        </span>
      </Link>
      <Link
        to="/admin/complaint-tickets"
        className={
          currPath === "#/admin/complaint-tickets" ? activeStyle : regularStyle
        }
      >
        <ClipBoardIcon
          fill={currPath === "#/admin/complaint-tickets" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Tickets
        </span>
      </Link>
      <Link
        to="/admin/investments"
        className={
          currPath === "#/admin/investments" ? activeStyle : regularStyle
        }
      >
        <RupeeIcon
          fill={currPath === "#/admin/investments" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Investments
        </span>
      </Link>
      <Link
        to="/admin/plans"
        className={currPath === "#/admin/plans" ? activeStyle : regularStyle}
      >
        <RupeeIcon fill={currPath === "#/admin/plans" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Subscription
        </span>
      </Link>
      <Link
        to="/admin/industries"
        className={
          currPath === "#/admin/industries" ? activeStyle : regularStyle
        }
      >
        <IndustryIcon
          fill={currPath === "#/admin/industries" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Industries
        </span>
      </Link>
    </ul>
  );
};

export default AdminSidebarMain;
