import ReactDom from "react-dom";
import { Button } from "../Button";
import useUploadImage from "../../../hooks/useUploadImage";
import useAddDescription from "../../../hooks/useAddDescription";
import { useState } from "react";
import { FormError } from "../FormError";

export const ClaimModal = ({ open, onClose, claimFields, callBackFn, url }) => {
  const { uploadImage } = useUploadImage();
  const { addDescription } = useAddDescription();

  const [data, setData] = useState({
    logo: null,
    description: "",
  });

  if (!open) return null;

  const submitModal = (e) => {
    e.preventDefault();
    document.getElementById("claim-error").classList.add("tw-hidden");
    document.getElementById("description-error").classList.add("tw-hidden");
    if (!data.logo && !data.description) {
      document.getElementById("claim-error").classList.remove("tw-hidden");
      return;
    }
    if (data.description && data.description.length < 30) {
      document
        .getElementById("description-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (!claimFields.logo && !claimFields.description) {
      uploadImage(data.logo, callBackFn);
      addDescription(url, { description: data.description }, callBackFn);
    } else if (!claimFields.logo && claimFields.description) {
      uploadImage(data.logo, callBackFn);
    } else if (claimFields.logo && !claimFields.description) {
      addDescription(url, { description: data.description }, callBackFn);
    }
    setData({
      logo: null,
      description: "",
    });
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Claim Status
        </h4>
        <form onSubmit={submitModal} className="tw-p-3 tw-text-left">
          {claimFields.logo ? (
            <p className="tw-flex tw-justify-between tw-items-center tw-mb-2">
              <span className="tw-font-medium">Logo :</span>
              <span className="tw-px-1.5 tw-py-1 tw-rounded-md tw-bg-gray-200 tw-text-[#31a831]">
                Added
              </span>
            </p>
          ) : (
            <div className="tw-flex tw-flex-col tw-mb-2">
              <label
                className="tw-cursor-pointer tw-mb-0 tw-text-sm tw-font-medium"
                htmlFor="logo"
              >
                Logo
              </label>
              <input
                type="file"
                accept="image/*"
                id="logo"
                onChange={(e) =>
                  setData((prev) => ({ ...prev, logo: e.target.files[0] }))
                }
              />
            </div>
          )}
          {claimFields.description ? (
            <p className="tw-flex tw-justify-between tw-items-center">
              <span className="tw-font-medium">Description :</span>
              <span className="tw-px-1.5 tw-py-1 tw-rounded-md tw-bg-gray-200 tw-text-[#31a831]">
                Added
              </span>
            </p>
          ) : (
            <div>
              <label
                className="tw-cursor-pointer tw-mb-0 tw-text-sm tw-font-medium"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="7"
                className="tw-border tw-rounded-md tw-p-1"
                onChange={(e) =>
                  setData((prev) => ({ ...prev, description: e.target.value }))
                }
              ></textarea>
            </div>
          )}
          <div id="claim-error" className="tw-hidden">
            <FormError message="Make sure to add logo and description" />
          </div>
          <div id="description-error" className="tw-hidden">
            <FormError message="Description must be atleast 30 chrachters long" />
          </div>

          <div className="modalFooter tw-py-3">
            <Button
              className="tw-px-2 tw-py-1 tw-mx-1"
              bgColor="tw-bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
              Claim
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
