import React from "react";
import FavIcon from "../../images/favicon.png";
import { Stars } from "../../components/ui/modals/rating-modal/Stars";

export const SingleReview = ({ data }) => {
  return (
    <div className="tw-grid tw-grid-cols-6 tw-my-1 tw-shadow-md tw-py-3 tw-rounded-md">
      <div className="tw-flex tw-items-center tw-justify-center tw-px-0">
        <img
          src={data.logo.length > 0 ? data.logo : FavIcon}
          alt="reviewerImage"
          className="tw-rounded-full tw-w-[60px] tw-h-[60px]"
        />
      </div>
      <div className="tw-col-span-5 tw-px-0">
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <div className="tw-flex tw-items-center">
            <span className="tw-me-2 tw-text-sm tw-font-semibold">
              {data.name}
            </span>
            <Stars rating={data.rating} size={"20px"} />
          </div>
          <div className="tw-flex tw-flex-col tw-items-end">
            <span className="tw-me-3 tw-text-xs">{data.date}</span>
            <span
              className={`tw-me-3 tw-px-1 tw-mt-1 tw-text-xs tw-rounded-md tw-border ${
                data.role === "f@!3A"
                  ? "tw-border-blue-400 tw-text-blue-400"
                  : data.role === "R$7s2"
                  ? "tw-border-orange-400 tw-text-orange-400"
                  : data.role === "Sw%aa"
                  ? "tw-border-green-500 tw-text-green-500"
                  : "tw-border-indigo-500 tw-text-indigo-500"
              }`}
            >
              {data.role === "f@!3A"
                ? "Startup"
                : data.role === "R$7s2"
                ? "Mentor"
                : data.role === "Sw%aa"
                ? "Investor"
                : "Service Provider"}
            </span>
          </div>
        </div>
        <small>{data.review}</small>
      </div>
    </div>
  );
};
