import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Country, State, City } from "country-state-city";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import {
  numberRegex,
  textWithSpaceRegex,
  bannedInputs,
  roleOptions,
} from "../../constants";
import {
  UserIcon,
  PhoneIcon,
  EnvelopeIcon,
  EyeIcon,
  SlashedEyeIcon,
} from "../../icons/icons";
import { PrivacyPolicy } from "../../components/ui/modals/privacyPolicy";
import Dropdown from "../../components/ui/inputs/dropdown";

export const RegistrationForm = ({ postRegistration }) => {
  const registerApi = `${process.env.REACT_APP_API_URL}register`;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, error, fetchApi } = useFetch();

  let countryData = Country.getAllCountries();
  let stateData = State.getStatesOfCountry("IN");
  let cityData = City.getCitiesOfState("IN", "TG");

  const [viewPass, setViewPass] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [geoData, setGeoData] = useState({
    countries: countryData,
    states: stateData,
    cities: cityData,
  });
  const [address, setAddress] = useState({
    country: "India",
    countryIso: "IN",
    state: "Telangana",
    stateIso: "TN",
    city: "Hyderabad",
  });

  const today = Date.now();

  const registerApiCall = (data) => {
    document.getElementById("email-exists").classList.add("tw-hidden");
    if (address.country === "") {
      document.getElementById("country-error").classList.remove("tw-hidden");
      return;
    }
    if (address.state === "") {
      document.getElementById("state-error").classList.remove("tw-hidden");
      return;
    }
    if (address.city === "") {
      document.getElementById("city-error").classList.remove("tw-hidden");
      return;
    }
    fetchApi(
      {
        url: registerApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          name: data.name,
          email: data.email.toLowerCase(),
          phone: data.phone,
          password: data.password,
          country: address.country,
          state: address.state,
          city: address.city,
          role: data.role.value,
          date: new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(today),
        },
      },
      postRegistration
    );
  };

  const onCountryChange = (value) => {
    let countryIsoCode = geoData.countries.filter(
      (country) => country.name === value
    )[0]?.isoCode;
    setAddress({
      ...address,
      country: value,
      countryIso: countryIsoCode,
    });
    setGeoData({
      ...geoData,
      states: State.getStatesOfCountry(countryIsoCode),
    });
  };

  const onStateChange = (value) => {
    let stateIsoCode = geoData.states.filter((state) => state.name === value)[0]
      ?.isoCode;
    setAddress({ ...address, state: value, stateIso: stateIsoCode });
    setGeoData({
      ...geoData,
      cities: City.getCitiesOfState(address.countryIso, stateIsoCode),
    });
  };

  const onCityChange = (value) => {
    setAddress({ ...address, city: value });
  };

  return (
    <div
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-my-0 lg:tw-py-3 md:tw-col-start-2 md:tw-col-span-5 lg:tw-col-span-2"
      id="registrationForm"
    >
      <div className="tw-mx-auto tw-p-5 tw-rounded-md tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0">
        <h4 className="tw-text-lg tw-font-medium">Adventure starts here 🚀</h4>
        <p className="tw-mb-2 tw-text-sm">
          Access the future. Sign up, step into our startup ecosystem
        </p>
        <form onSubmit={handleSubmit(registerApiCall)}>
          <div className="tw-mb-1">
            <label htmlFor="name" className="tw-text-sm tw-mb-0 required">
              Full Name
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="name"
                name="name"
                placeholder="Enter Full Name"
                autoFocus
                {...register("name", {
                  required: "Name is required",
                  validate: {
                    MinLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 2 ||
                        "minimum required length is 3"
                      );
                    },
                    MaxLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length < 51 ||
                        "cannot exceed 50 characters"
                      );
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.name && (
              <div>
                <FormError message={errors.name.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="phone" className="tw-text-sm tw-mb-0 required">
              Phone
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="phone"
                name="phone"
                {...register("phone", {
                  required: "Phone is required",
                  validate: {
                    minValue: (fieldValue) => {
                      return (
                        fieldValue.length > 6 ||
                        "Number should be atleast 7 digits"
                      );
                    },
                    maxValue: (fieldValue) => {
                      return (
                        fieldValue.length < 13 ||
                        "Number cannot be more than 12 digits"
                      );
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
                placeholder="Enter Phone Number"
              />
              <span className="input-icons icon-span">
                <PhoneIcon />
              </span>
            </div>
            {errors.phone && (
              <div>
                <FormError message={errors.phone.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="email" className="tw-text-sm tw-mb-0 required">
              Email
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type="email"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="email"
                name="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required",
                  validate: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different email address"
                    );
                  },
                })}
              />
              <span className="input-icons icon-span">
                <EnvelopeIcon />
              </span>
            </div>
            {errors.email && (
              <div>
                <FormError message={errors.email.message} />
              </div>
            )}
            <p
              id="email-exists"
              className="tw-hidden tw-text-secondary tw-text-xs"
            >
              This email already exisits
            </p>
          </div>
          <div className="tw-mb-1">
            <label htmlFor="password" className="tw-text-sm tw-mb-0 required">
              Set Password
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={viewPass ? "text" : "password"}
                id="password"
                className="tw-border-0 tw-w-full tw-ps-3"
                name="password"
                placeholder="New password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 7,
                    message: "Minimum length must be 7 characters",
                  },
                  maxLength: {
                    value: 30,
                    message: "Maximum length must be 30 characters",
                  },
                })}
              />
              <span
                className="input-icons tw-cursor-pointer icon-span"
                onClick={() => setViewPass(!viewPass)}
              >
                {viewPass ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
            {errors.password && (
              <div>
                <FormError message={errors.password.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <p className="required">Country</p>
            <Dropdown
              options={geoData.countries.map((country) => ({
                value: country.name,
                label: country.name,
              }))}
              onChange={(selectedOption) =>
                onCountryChange(selectedOption.value)
              }
            />
            <div id="country-error" className="tw-hidden">
              <FormError message={"Country is required"} />
            </div>
          </div>
          <div className="tw-mb-1">
            <p className="required">State</p>
            <Dropdown
              options={geoData.states.map((state) => ({
                value: state.name,
                label: state.name,
              }))}
              onChange={(selectedOption) => onStateChange(selectedOption.value)}
            />
            <div id="state-error" className="tw-hidden">
              <FormError message={"State is required"} />
            </div>
          </div>
          {geoData.cities.length > 0 && (
            <div className="tw-mb-1">
              <p className="required">City/District</p>
              <Dropdown
                options={geoData.cities.map((city) => ({
                  value: city.name,
                  label: city.name,
                }))}
                onChange={(selectedOption) =>
                  onCityChange(selectedOption.value)
                }
                styles={{
                  menuList: (baseStyles) => ({
                    ...baseStyles,
                    maxHeight: "150px",
                    overflowY: "auto",
                  }),
                }}
              />
              <div id="city-error" className="tw-hidden">
                <FormError message={"City is required"} />
              </div>
            </div>
          )}

          <div className="tw-mb-1 tw-flex-col">
            <label className="required">Role</label>
            <Controller
              name="role"
              control={control}
              rules={{ required: "Select a role" }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={roleOptions}
                  styles={{
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      maxHeight: "100px",
                      overflowY: "auto",
                    }),
                  }}
                />
              )}
            />
            {errors.role && (
              <div>
                <FormError message={errors.role.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-3 tw-mt-2 tw-flex tw-items-center">
            <input
              className="tw-me-2"
              type="checkbox"
              id="terms-conditions"
              name="terms"
              required
              onChange={(e) => {
                if (e.target.checked) {
                  setOpenPolicyModal(true);
                }
              }}
            />
            <label
              className="tw-mb-0 tw-text-xs sm:tw-text-sm"
              htmlFor="terms-conditions"
            >
              I agree to
              <span className="tw-ms-1 tw-text-blue-500">
                privacy policy & terms
              </span>
            </label>
          </div>
          <Button type="submit" className="tw-py-1.5 tw-w-full">
            Register
          </Button>
        </form>
        {isLoading && !error ? <Loader pt="10px" classes="tw-my-2" /> : null}
        <p className="tw-text-center tw-mt-2">
          <span>Already have an account?</span>
          <Link to="/">
            <span className="tw-ps-1 tw-text-blue-500">Sign in</span>
          </Link>
        </p>
      </div>
      <PrivacyPolicy
        open={openPolicyModal}
        onClose={() => setOpenPolicyModal(false)}
      />
    </div>
  );
};
