import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import { useToastContext } from "../../../context/ToastContext";
import { ConnectedUsers } from "../connectedUsers";
import { IncomingRequests } from "../incomingRequests";
import { OutgoingRequests } from "../outgoingRequests";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import Sidebar from "../../../components/layout/Sidebar";
import Navbar from "../../../components/layout/Navbar";
import { Button } from "../../../components/ui/Button";

export const StartupConnections = () => {
  const displayConnectionsApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const incomingRequestApi = `${process.env.REACT_APP_API_URL}incoming-requests`;
  const outGoingConnectionReqApi = `${process.env.REACT_APP_API_URL}outgoing-requests`;
  const acceptOrCancelReqApi = `${process.env.REACT_APP_API_URL}accept-cancel-connect-request`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [connections, setConnections] = useState([]);
  const [incomingRequest, setIncomingRequest] = useState([]);
  const [outGoingRequests, setOutGoingRequests] = useState([]);
  const [tableType, setTableType] = useState("connected");
  const [openSidebar, setOpenSidebar] = useState(false);
  const ref = useRef(true);

  const displayFriends = useCallback(() => {
    setTableType("connected");
    fetchApi(
      {
        url: displayConnectionsApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setConnections(data.response);
      }
    );
  }, [displayConnectionsApi, token, fetchApi]);

  const getIncomingRequests = useCallback(() => {
    setTableType("incoming");
    fetchApi(
      { url: incomingRequestApi, headers: { "x-token": token } },
      (data) => setIncomingRequest(data.response)
    );
  }, [incomingRequestApi, fetchApi, token]);

  const getOutGoingRequests = useCallback(() => {
    setTableType("outgoing");
    fetchApi(
      {
        url: outGoingConnectionReqApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setOutGoingRequests(data.response);
      }
    );
  }, [outGoingConnectionReqApi, token, fetchApi]);

  const acceptOrCancelRequest = useCallback(
    (connection_id, type, tableType) => {
      fetchApi(
        {
          url: acceptOrCancelReqApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: connection_id,
            type: type,
          },
        },
        (data) => {
          if (tableType === "incoming") {
            if (data.message === "already cancelled") {
              getIncomingRequests();
              toast.open("false", "Request was already cancelled");
            }
            if (data.message === "cancelled") {
              sendNotification(
                connection_id,
                `Connection request declined by ${data.name}`,
                "connections"
              );
              getIncomingRequests();
            }
            if (data.message === "accepted") {
              sendNotification(
                connection_id,
                `Connection request accepted by ${data.name}`,
                "connections"
              );
              getIncomingRequests();
            }
          } else {
            if (data.message === "already cancelled") {
              getOutGoingRequests();
              toast.open("false", "Request was already cancelled");
            }
            if (data.message === "cancelled") {
              sendNotification(
                connection_id,
                `Connection request was cancelled by ${data.name}`,
                "connections"
              );
              getOutGoingRequests();
            }
            if (data.message === "connected") {
              toast.open("false", "Request was already accepted");
              getOutGoingRequests();
            }
          }
        }
      );
    },
    [
      acceptOrCancelReqApi,
      fetchApi,
      token,
      getOutGoingRequests,
      getIncomingRequests,
      sendNotification,
      toast,
    ]
  );

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/");
      return;
    }
    if (ref.current) {
      displayFriends();
    }
    return () => {
      ref.current = false;
    };
  }, [displayFriends, navigate, userRole]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-4">
          <div className="tw-text-end">
            <Button
              onClick={displayFriends}
              className="tw-px-2 tw-py-1 tw-m-1 tw-text-xs md:tw-text-sm"
            >
              Connected users
            </Button>
            <Button
              onClick={getIncomingRequests}
              className="tw-px-2 tw-py-1 tw-m-1 tw-text-xs md:tw-text-sm"
              bgColor="tw-bg-green-600"
            >
              Incoming requests
            </Button>
            <Button
              onClick={getOutGoingRequests}
              className="tw-px-2 tw-py-1 tw-m-1 tw-text-xs md:tw-text-sm"
              bgColor="tw-bg-[#F97316]"
            >
              Outgoing requests
            </Button>
          </div>
          {isLoading && !error ? (
            <Loader />
          ) : tableType === "connected" ? (
            <ConnectedUsers
              connections={connections}
              displayFriends={displayFriends}
              acceptOrCancelRequest={acceptOrCancelRequest}
            />
          ) : tableType === "incoming" ? (
            <IncomingRequests
              incomingRequest={incomingRequest}
              getIncomingRequests={getIncomingRequests}
              acceptOrCancelRequest={acceptOrCancelRequest}
            />
          ) : (
            <OutgoingRequests
              outGoingRequests={outGoingRequests}
              getOutGoingRequests={getOutGoingRequests}
              acceptOrCancelRequest={acceptOrCancelRequest}
            />
          )}
          {error && <Error />}
        </div>
      </div>
    </div>
  );
};
