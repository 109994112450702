import { Link } from "react-router-dom";
import {
  DashboardIcon,
  UserIcon,
  RocketIcon,
  UserTieIcon,
  CommentIcon,
  WalletIcon,
  AddUserIcon,
  BellIcon,
  UserPenIcon,
  ClipBoardIcon,
  GearIcon,
  CircleExclamationIcon,
  VideoOnIcon,
} from "../../icons/icons";

export const MentorSidebar = ({ currPath, activeStyle, regularStyle }) => {
  return (
    <ul className="tw-p-3 tw-pt-4">
      <Link
        to="/mentor-dashboard"
        className={
          currPath === "#/mentor-dashboard" ? activeStyle : regularStyle
        }
      >
        <DashboardIcon
          fill={currPath === "#/mentor-dashboard" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Dashboard
        </span>
      </Link>
      <Link
        to="/mentor-profile"
        className={currPath === "#/mentor-profile" ? activeStyle : regularStyle}
      >
        <UserIcon fill={currPath === "#/mentor-profile" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Profile
        </span>
      </Link>
      <Link
        to="/startups"
        className={
          currPath === "#/startups" || currPath === "#/view-startup"
            ? activeStyle
            : regularStyle
        }
      >
        <RocketIcon
          fill={
            currPath === "#/startups" || currPath === "#/view-startup"
              ? "#fff"
              : "#999999"
          }
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Startups
        </span>
      </Link>
      <Link
        to="/investors"
        className={
          currPath === "#/investors" || currPath === "#/view-investor"
            ? activeStyle
            : regularStyle
        }
      >
        <UserTieIcon
          fill={
            currPath === "#/investors" || currPath === "#/view-investor"
              ? "#fff"
              : "#999999"
          }
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Investors
        </span>
      </Link>
      <Link
        to="/chat"
        className={currPath === "#/chat" ? activeStyle : regularStyle}
      >
        <CommentIcon fill={currPath === "#/chat" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">Chat</span>
      </Link>
      {/* <Link
        to="/meetings"
        className={currPath === "#/meetings" ? activeStyle : regularStyle}
      >
        <VideoOnIcon
          fill={currPath === "#/meetings" ? "#fff" : "#999999"}
          width="18px"
          height="18px"
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Meetings
        </span>
      </Link> */}
      <Link
        to="/mentor-wallet"
        className={currPath === "#/mentor-wallet" ? activeStyle : regularStyle}
      >
        <WalletIcon
          fill={currPath === "#/mentor-wallet" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Wallet
        </span>
      </Link>
      <Link
        to="/mentor-connections"
        className={
          currPath === "#/mentor-connections" ? activeStyle : regularStyle
        }
      >
        <AddUserIcon
          fill={currPath === "#/mentor-connections" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Connections
        </span>
      </Link>
      <Link
        to="/notifications"
        className={currPath === "#/notifications" ? activeStyle : regularStyle}
      >
        <BellIcon fill={currPath === "#/notifications" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Notifications
        </span>
      </Link>
      <Link
        to="/reviews"
        className={currPath === "#/reviews" ? activeStyle : regularStyle}
      >
        <UserPenIcon fill={currPath === "#/reviews" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Reviews
        </span>
      </Link>
      <Link
        to="/tickets"
        className={currPath === "#/tickets" ? activeStyle : regularStyle}
      >
        <ClipBoardIcon fill={currPath === "#/tickets" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Tickets
        </span>
      </Link>
      <Link
        to="/settings"
        className={currPath === "#/settings" ? activeStyle : regularStyle}
      >
        <GearIcon fill={currPath === "#/settings" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Settings
        </span>
      </Link>
      <Link
        to="/faq"
        className={currPath === "#/faq" ? activeStyle : regularStyle}
      >
        <CircleExclamationIcon
          fill={currPath === "#/faq" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">FAQ</span>
      </Link>
    </ul>
  );
};
