import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { numberRegex, bannedInputs } from "../../constants";
import { BriefCaseIcon, BuildingIcon, UploadIcon } from "../../icons/icons";
import { useToastContext } from "../../context/ToastContext";
import Dropdown from "../../components/ui/inputs/dropdown";

export const MentorDetailsForm = ({ token, industries }) => {
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}insert-mentor-details`;

  const navigate = useNavigate();
  const toast = useToastContext();
  const { isLoading, error } = useFetch();

  const [selectedIndustry, setSelectedIndustry] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const MentorDetailsApiCall = (data) => {
    document.getElementById("mentor-category-error").classList.add("tw-hidden");
    document.getElementById("mentor-category-limit").classList.add("tw-hidden");
    if (selectedIndustry.length < 1) {
      document
        .getElementById("mentor-category-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document
        .getElementById("mentor-category-limit")
        .classList.remove("tw-hidden");
      return;
    }
    let logo = document.getElementById("mentorLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("work_experience", data.work_experience);
    formData.append("mentor_industry", selectedIndustry);
    formData.append("previous_company", data.previous_company);
    formData.append("short_description", data.short_description);
    axios
      .post(mentorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/mentor-dashboard");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      id="mentorDetailsForm"
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-my-0 lg:tw-py-3 md:tw-col-start-2 md:tw-col-span-5 lg:tw-col-span-2"
    >
      <form
        onSubmit={handleSubmit(MentorDetailsApiCall)}
        className="tw-mx-auto tw-p-5 tw-rounded-md tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0"
      >
        <h5 className="tw-font-medium tw-text-lg tw-mb-2">
          Some details about your career
        </h5>
        <div className="tw-mb-1">
          <label
            htmlFor="workExperience"
            className="tw-mb-0 tw-text-sm required"
          >
            Work experience(in years)
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="work_experience"
              name="work_experience"
              placeholder="ex: 5"
              {...register("work_experience", {
                required: "Work experience is required",
                validate: (fieldvalue) => {
                  return fieldvalue > 0 || "Work experience cannot be zero";
                },
                pattern: {
                  value: numberRegex,
                  message: "Number must only contain digits",
                },
                maxLength: {
                  value: 2,
                  message: "Cannot exceed 2 characters",
                },
              })}
              autoFocus
            />
            <span className="input-icons icon-span">
              <BriefCaseIcon />
            </span>
          </div>
          {errors.work_experience && (
            <div>
              <FormError message={errors.work_experience.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1 tw-flex-col">
          <p className="tw-text-sm">
            Industry{" "}
            <span className="required">({selectedIndustry.length + "/3"})</span>
            <span
              id="mentor-category-limit"
              className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
            >
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setSelectedIndustry(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small
            className="tw-text-red-500 tw-mb-2 tw-hidden"
            id="mentor-category-error"
          >
            This field is mandatory.
          </small>
        </div>
        <div className="tw-mb-1">
          <label
            htmlFor="mentorCompany"
            className="tw-mb-0 tw-text-sm required"
          >
            Current or Previous company Name
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="previous_company"
              name="previous_company"
              placeholder="Enter company name"
              {...register("previous_company", {
                required: "Company name is required",
                validate: {
                  minLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length > 2 ||
                      "Company Name must be atleast 3 characters"
                    );
                  },
                  maxLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length < 101 ||
                      "Cannot exceed 100 characters"
                    );
                  },
                  bannedValue: (fieldvalue) => {
                    return fieldvalue.trim().length > 2
                      ? !bannedInputs.some((value) =>
                          fieldvalue.includes(value)
                        ) || "Enter a different name"
                      : null;
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <BuildingIcon />
            </span>
          </div>
          {errors.previous_company && (
            <div>
              <FormError message={errors.previous_company.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1">
          <label
            htmlFor="short_description"
            className="tw-mb-0 tw-text-sm required"
          >
            About me
          </label>
          <div className="tw-border">
            <textarea
              name="short_description"
              id="short_description"
              cols="30"
              rows="4"
              className="tw-border-0 tw-w-full tw-ps-3 tw-pt-1 tw-rounded-md"
              placeholder="Write here..."
              {...register("short_description", {
                required: "short description is required",
                validate: {
                  minLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length > 20 ||
                      "Short description must be atleast 20 characters"
                    );
                  },
                  maxLength: (fieldvalue) => {
                    return (
                      fieldvalue.trim().length < 1001 ||
                      "Cannot exceed 1000 characters"
                    );
                  },
                },
              })}
            ></textarea>
          </div>
          {errors.short_description && (
            <div>
              <FormError message={errors.short_description.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-3">
          <label htmlFor="mentorLogo" className="tw-text-sm tw-mb-0">
            Profile Image
          </label>
          <div
            className="tw-flex tw-items-center tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/jpeg, image/png"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="mentorLogo"
              name="mentorLogo"
              placeholder="Upload Profile Image"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        {isLoading && !error ? <Loader pt="10px" classes="tw-my-2" /> : null}
        <Button type="submit" className="tw-py-1.5 tw-w-full">
          Next
        </Button>
      </form>
    </div>
  );
};
