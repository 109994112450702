import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useCheckRole from "../../hooks/useCheckRole";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useFetchCredits } from "../../hooks/useFetchCredits";
import useSearchApi from "../../hooks/useSearchApi";
import { useFreezeAmount } from "../../hooks/useFreezeAmount";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { useFilterContext } from "../../context/filterContext";
import NotiType from "../../utils/notificationRoute";
import SingleNotification from "../ui/singleNotification";
import {
  BarsIcon,
  BellIcon,
  CreditCardIcon,
  GearIcon,
  LogoutIcon,
  SearchIcon,
  UserIcon,
  WalletIcon,
} from "../../icons/icons";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import FavIcon from "../../images/favicon.png";

const Navbar = (props) => {
  const getNotificationsApi = `${process.env.REACT_APP_API_URL}view-notifications`;
  const searchMentorsApi = `${process.env.REACT_APP_API_URL}search-mentors-profile`;
  const searchInvestorsApi = `${process.env.REACT_APP_API_URL}search-investors-profile`;
  const searchStartupsApi = `${process.env.REACT_APP_API_URL}search-startups-profile`;
  const searchVendorApi = `${process.env.REACT_APP_API_URL}search-vendors-profile`;
  const searchAdApi = `${process.env.REACT_APP_API_URL}search-ad`;
  const markAsReadApi = `${process.env.REACT_APP_API_URL}mark-as-read`;

  const {
    setFilterStartupArr,
    setFilterMentorArr,
    setFilterInvestorArr,
    setFilterVendorArr,
    setFilterAds,
  } = useFilterContext();
  const { fetchApi } = useFetch();
  const { role, CheckRole } = useCheckRole();
  const navigate = useNavigate();
  const { token, userRole, name } = useLocalStorage();
  const { credits, getCredits } = useFetchCredits();
  const { freezePoints, getFreezePoints } = useFreezeAmount();
  const { width } = useWindowDimensions();
  const { search } = useSearchApi();

  const [searchInput, setSearchInput] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [unReadNotiCount, setUnReadNotiCount] = useState(0);
  const [profileImg, setProfileImg] = useState("");

  const memoizedNotiType = useMemo(() => NotiType, []);

  const checkedRole = () => {
    if (role === 1) {
      navigate("/startup-profile");
    } else if (role === 2) {
      navigate("/mentor-profile");
    } else if (role === 3) {
      navigate("/investor-profile");
    } else {
      navigate("/vendor-profile");
    }
  };

  const goToWallet = () => {
    if (role === 1) {
      navigate("/startup-wallet");
    } else if (role === 2) {
      console.log("mentor");
      navigate("/mentor-wallet");
    } else {
      navigate("/vendor-wallet");
    }
  };

  const getAllNotifications = useCallback(() => {
    fetchApi(
      {
        url: getNotificationsApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        if (data.message === "Invalid token") {
          navigate("/error-401");
        }
        setNotifications(data.notifications);
        setUnReadNotiCount(data.unread_notifications);
        setProfileImg(data.logo);
      }
    );
  }, [getNotificationsApi, token, fetchApi, navigate]);

  const markAsRead = () => {
    fetchApi(
      {
        url: markAsReadApi,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      () => {
        navigate("/notifications");
      }
    );
  };

  useEffect(() => {
    CheckRole(userRole);
    if (role === 1 || role === 4) {
      getCredits();
    }
    if (role === 2) {
      getFreezePoints();
    }
    const searchTimeout = setTimeout(() => {
      getAllNotifications();
      if (props.lastUrl === "startups") {
        search(searchStartupsApi, token, searchInput, (data) => {
          setFilterStartupArr({
            data: data.data,
            investmentList: data.investment_list,
            verifiedList: data.verify_list,
          });
        });
      } else if (props.lastUrl === "mentors") {
        search(searchMentorsApi, token, searchInput, (data) => {
          setFilterMentorArr({
            data: data.data,
            verifiedList: data.verify_list,
          });
        });
      } else if (props.lastUrl === "investors") {
        search(searchInvestorsApi, token, searchInput, (data) => {
          setFilterInvestorArr({
            data: data.data,
            friendsList: data.connections_id,
          });
        });
      } else if (props.lastUrl === "vendors") {
        search(searchVendorApi, token, searchInput, (data) => {
          setFilterVendorArr({
            data: data.vendors,
            status: data.ad_status,
          });
        });
      } else if (props.lastUrl === "market") {
        search(searchAdApi, token, searchInput, (data) => {
          setFilterAds({
            data: data.ads,
          });
        });
      }
    }, 150);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [
    CheckRole,
    getAllNotifications,
    role,
    getCredits,
    getFreezePoints,
    searchInput,
    userRole,
    props.lastUrl,
    search,
    token,
    fetchApi,
    searchStartupsApi,
    searchMentorsApi,
    searchInvestorsApi,
    searchVendorApi,
    searchAdApi,
    setFilterStartupArr,
    setFilterMentorArr,
    setFilterInvestorArr,
    setFilterVendorArr,
    setFilterAds,
  ]);

  return (
    <div
      className="tw-sticky tw-top-0 tw-m-0 tw-z-[1] tw-px-4 tw-py-2 tw-bg-white md:tw-relative tw-shadow-[5px_1px_20px_-6px_rgba(0,0,0,0.4)] tw-grid tw-grid-cols-3 md:tw-gap-4"
      style={props.style}
    >
      {width >= 1024 ? (
        <div className={props.lastUrl ? "tw-col-span-2 tw-p-0" : "tw-hidden"}>
          <div className="tw-flex tw-items-center tw-px-2 tw-py-1">
            <SearchIcon fill="#9D9DA3" />
            <input
              type="text"
              placeholder="Search by industry..."
              className="tw-8/12 tw-border-0 tw-w-full tw-ps-3 tw-py-3"
              id="search-input"
              onChange={(e) => setSearchInput(e.target.value.trim())}
            />
          </div>
        </div>
      ) : (
        <>
          <img src={pitchHereLogo} alt="pitchere-logo" width={100} />
        </>
      )}
      <div className="tw-hidden tw-col-start-3 lg:tw-flex lg:tw-justify-end">
        <div className="tw-relative tw-flex tw-items-center tw-group tw-me-2 tw-cursor-pointer">
          <div className="tw-relative">
            <BellIcon width="24px" height="24px" fill="#adadad" />
            <div className="tw-absolute tw-inline-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-text-xs tw-font-bold tw-text-white tw-bg-red-500 tw-border-2 tw-border-transparent tw-rounded-full -tw-top-2 -tw-right-2">
              {unReadNotiCount}
            </div>
          </div>
          <div className="tw-hidden group-hover:tw-block tw-absolute tw-right-[10%] tw-top-[90%] tw-card tw-w-[250px] tw-bg-primary tw-text-center tw-shadow-md">
            <ul>
              {notifications.length > 0 ? (
                notifications
                  .slice(0, 5)
                  .map((noti) => (
                    <SingleNotification
                      key={noti.id}
                      noti={noti}
                      userRole={userRole}
                      memoizedNotiType={memoizedNotiType}
                    />
                  ))
              ) : (
                <li className="tw-text-white">No new notifications</li>
              )}
              <li className="tw-mt-3">
                <p
                  onClick={() => markAsRead()}
                  className="tw-bg-primary tw-mt-2 tw-px-2 tw-py-1 tw-rounded tw-text-white hover:tw-text-white"
                >
                  View all
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="tw-relative tw-group tw-flex tw-justify-center lg:tw-justify-normal tw-cursor-pointer tw-ms-4">
          <img
            src={profileImg.length > 0 ? profileImg : FavIcon}
            className="tw-rounded-full tw-h-[50px]"
            alt="profile-img"
            width={50}
          />
          <div className="tw-hidden group-hover:tw-block tw-absolute tw-right-[5%] tw-mt-[50px] tw-card tw-w-max tw-shadow-md tw-bg-primary">
            <ul className="fa-ul">
              <li
                onClick={checkedRole}
                className="tw-flex tw-items-center tw-mt-1 tw-mb-2 tw-ps-1 tw-text-sm tw-text-white tw-capitalize hover:tw-text-customOrange"
              >
                <span className="fa-li tw-pr-2">
                  <UserIcon fill="#fff" />
                </span>
                {name}
              </li>
              {role !== 3 && (
                <li
                  onClick={() => {
                    if (role !== 4) {
                      goToWallet();
                    }
                  }}
                  className="tw-flex tw-items-center tw-mt-1 tw-mb-2 tw-ps-1 tw-text-sm tw-text-white hover:tw-text-customOrange"
                >
                  <span className="fa-li tw-pr-2">
                    <CreditCardIcon fill="#fff" />
                  </span>
                  {role === 2 ? "₹ " + freezePoints : credits + " Tokens"}
                </li>
              )}
              {role === 4 && (
                <li
                  onClick={goToWallet}
                  className="tw-flex tw-items-center tw-mt-1 tw-mb-2 tw-ps-1 tw-text-sm tw-text-white hover:tw-text-customOrange"
                >
                  <span className="fa-li tw-pr-2">
                    <WalletIcon fill="#fff" />
                  </span>
                  Wallet
                </li>
              )}
              <li className="tw-mt-1 tw-mb-2 tw-ps-1 tw-text-sm tw-text-white hover:tw-text-customOrange">
                <Link
                  to="/settings"
                  className="tw-flex tw-items-center hover:tw-text-customOrange"
                >
                  <span className="fa-li tw-pr-2">
                    <GearIcon fill="#fff" />
                  </span>
                  Settings
                </Link>
              </li>
              <li className="tw-font-medium tw-mt-1 tw-ps-1 tw-text-sm tw-text-white hover:tw-text-customOrange">
                <Link
                  to="/"
                  className="tw-flex tw-items-center hover:tw-text-customOrange"
                >
                  <span className="fa-li tw-pr-2">
                    <LogoutIcon fill="#fff" />
                  </span>
                  LogOut
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="tw-col-start-3 tw-flex tw-items-center tw-justify-around lg:tw-hidden"
        id="sidebar-icon"
      >
        <div className="tw-relative tw-flex tw-items-center tw-group tw-me-2 tw-cursor-pointer">
          <div className="tw-relative">
            <BellIcon width="24px" height="24px" fill="#adadad" />
            <div className="tw-absolute tw-inline-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-text-xs tw-font-bold tw-text-white tw-bg-red-500 tw-border-2 tw-border-transparent tw-rounded-full -tw-top-2 -tw-right-2">
              {unReadNotiCount}
            </div>
          </div>
          <div className="tw-hidden group-hover:tw-block tw-absolute tw-right-[10%] tw-top-[90%] tw-card tw-w-[250px] tw-bg-primary tw-text-center tw-shadow-md">
            <ul>
              {notifications.length > 0 ? (
                notifications
                  .slice(0, 5)
                  .map((noti) => (
                    <SingleNotification
                      key={noti.id}
                      noti={noti}
                      userRole={userRole}
                      memoizedNotiType={memoizedNotiType}
                    />
                  ))
              ) : (
                <li className="tw-text-white">No new notifications</li>
              )}
              <li className="tw-mt-3">
                <p
                  onClick={() => markAsRead()}
                  className="tw-bg-primary tw-mt-2 tw-px-2 tw-py-1 tw-rounded tw-text-white hover:tw-text-white"
                >
                  View all
                </p>
              </li>
            </ul>
          </div>
        </div>
        <BarsIcon onClick={props.handleSidebar} />
      </div>
    </div>
  );
};

export default Navbar;
