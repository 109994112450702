import React, { useState, useContext } from "react";

const filterContext = React.createContext();

export const FilterDetailsProvider = ({ children }) => {
  const [filterStartupArr, setFilterStartupArr] = useState({
    data: [],
    friendsList: [],
  });
  const [filterMentorArr, setFilterMentorArr] = useState({
    data: [],
    friendsList: [],
  });
  const [filterInvestorArr, setFilterInvestorArr] = useState({
    data: [],
    friendsList: [],
  });
  const [filterVendorArr, setFilterVendorArr] = useState({
    data: [],
  });
  const [filterAds, setFilterAds] = useState({
    data: [],
  });

  return (
    <filterContext.Provider
      value={{
        filterStartupArr,
        setFilterStartupArr,
        filterMentorArr,
        setFilterMentorArr,
        filterInvestorArr,
        setFilterInvestorArr,
        filterVendorArr,
        setFilterVendorArr,
        filterAds,
        setFilterAds,
      }}
    >
      {children}
    </filterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(filterContext);
};
