import React from "react";
import { Pagination } from "../../../components/ui/Button";
import { Link } from "react-router-dom";
import { EyeIcon } from "../../../icons/icons";

const InvestmentTable = ({ page, setPage, data, type }) => {
  let filterData;

  if (type === "All") {
    filterData = data;
  } else if (type === "0") {
    filterData = data.filter(
      (data) =>
        data.status_by_admin === "0" || data.transfer_to_startup_status === "0"
    );
  } else {
    filterData = data.filter(
      (data) =>
        data.status_by_admin === "1" && data.transfer_to_startup_status === "1"
    );
  }

  return (
    <>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
            <tr>
              <th className="tw-p-2 tw-font-medium tw-text-sm">S.No</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Date</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                Actual Amount
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Withdrawable</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                Transaction Id
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">
                Transaction Mode
              </th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Verified</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Processed</th>
              <th className="tw-p-2 tw-font-medium tw-text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 7 - 7, page * 7).map((data, index) => (
                <tr
                  key={data.id}
                  className="tw-bg-white tw-border-b tw-border-slate-300"
                >
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {(page - 1) * 7 + index + 1}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.date}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.amount}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.withdrable_amount}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.transaction_id}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {data.transaction_mode}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    <span
                      className={`tw-text-xs tw-font-medium tw-p-1.5 tw-rounded-lg ${
                        data.status_by_admin === "0"
                          ? "tw-text-orange-800 tw-bg-orange-200"
                          : data.status_by_admin === "1"
                          ? "tw-text-green-800 tw-bg-green-200"
                          : "tw-text-red-800 tw-bg-red-200"
                      }`}
                    >
                      {data.status_by_admin === "0"
                        ? "Pending"
                        : data.status_by_admin === "1"
                        ? "Verified"
                        : "Rejected"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    <span
                      className={`tw-text-xs tw-font-medium tw-p-1.5 tw-rounded-lg ${
                        data.transfer_to_startup_status === "0"
                          ? "tw-text-orange-800 tw-bg-orange-200"
                          : "tw-text-green-800 tw-bg-green-200"
                      }`}
                    >
                      {data.transfer_to_startup_status === "0" ? "No" : "Yes"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    <Link
                      to={`/admin/investment-details/${data.id}`}
                      state={{ data }}
                    >
                      <EyeIcon fill="#008000" className="tw-cursor-pointer" />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan={9} className="tw-p-2">
                  No Investment Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination page={page} setPage={setPage} data={filterData} />
      )}
    </>
  );
};

export default InvestmentTable;
