import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom, setChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import FavIcon from "../../images/favicon.png";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import { Button } from "../../components/ui/Button";
import { BuildingIcon, GlobeIcon, RedirectArrowIcon } from "../../icons/icons";
import NavigateToDashboard from "../../utils/navigateToDashboard";

const ViewVendorProfile = () => {
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}vendor-profile`;
  const connectApi = `${process.env.REACT_APP_API_URL}connect`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const { token, userRole } = useLocalStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, error, fetchApi } = useFetch();
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const vendorDetails = useCallback(() => {
    fetchApi(
      {
        url: vendorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          user_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          alert("This accound has been suspended/Deactivated.");
          navigate(NavigateToDashboard(userRole));
        }
        setVendorData({
          documents: data.documents,
          details: { ...data.vendor_details[0] },
          website: data.website_url,
        });
      }
    );
  }, [vendorDetailsApi, fetchApi, token, user_id]);

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/error-403");
      return;
    }
    const timer = setTimeout(() => {
      vendorDetails();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [vendorDetails, userRole, navigate]);

  const connect = () => {
    fetchApi(
      {
        url: connectApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
        },
      },
      (data) => {
        if (data.message === "connection already established") {
          getRoomId();
          navigate("/chat");
        } else {
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: user_id,
              status: "1",
            })
          );
          navigate("/chat");
        }
      }
    );
  };

  const getRoomId = useCallback(() => {
    fetchApi(
      {
        url: getRoomIdApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
          role: "V&iR8",
        },
      },
      (data) => {
        dispatch(deleteChatRoom());
        dispatch(
          setChatRoom({
            roomId: data.room_id,
            recieverId: data.connection_id,
            status: "1",
          })
        );
      }
    );
  }, [getRoomIdApi, fetchApi, token, dispatch, user_id]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="tw-p-3">
            <Error />
          </div>
        )}
        {!isLoading && !error && Object.keys(vendorData).length && (
          <section className="tw-grid tw-grid-cols-7 tw-gap-3 tw-m-5">
            <div className="tw-col-span-7 md:tw-col-span-2 tw-p-3 tw-shadow-md tw-rounded-md">
              {location.state?.active && (
                <Button
                  onClick={() => connect()}
                  className="md:tw-hidden tw-float-right tw-p-1 tw-text-sm"
                >
                  Invite
                </Button>
              )}
              <div className="tw-flex tw-flex-col">
                <img
                  src={
                    vendorData.details.logo.length > 0
                      ? vendorData.details.logo
                      : FavIcon
                  }
                  alt="vendor-img"
                  width={140}
                  className="tw-rounded-full tw-h-[90px] tw-w-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-1"
                />
                <p className="tw-font-medium tw-text-lg tw-mt-2 tw-uppercase">
                  {vendorData.details.name}
                </p>
              </div>
              <div className="tw-flex tw-items-center tw-mb-2">
                <BuildingIcon fill="#999999" />
                <p className="tw-text-sm tw-mb-0 tw-ms-3 tw-capitalize">
                  <span className="tw-me-1 tw-font-bold">Company :</span>
                  {vendorData.details.company_name}
                </p>
              </div>
              <div className="tw-flex tw-items-center tw-mb-2">
                <GlobeIcon fill="#999999" />
                <p className="tw-text-sm tw-mb-0 tw-ms-3">
                  <span className="tw-me-1 tw-font-bold">Website :</span>
                  {vendorData.website.length > 0 ? (
                    <a
                      href={"https://" + vendorData.website}
                      target="_blank"
                      rel="noreferrer"
                      className="tw-text-sm tw-mb-0 tw-ms-1 tw-text-blue-500"
                    >
                      URL
                    </a>
                  ) : (
                    <span className="tw-text-sm tw-mb-0 tw-ms-3 tw-text-secondary">
                      N/A
                    </span>
                  )}
                </p>
              </div>
              <div className="tw-border-t tw-py-2">
                <p className="tw-mb-0 tw-font-bold">Industries</p>
                {vendorData.details.expert_in.length > 0 ? (
                  vendorData.details.expert_in.map((tag, i) => (
                    <span key={i} className="outline-tags">
                      {tag.replace(new RegExp("_", "g"), " ")}
                    </span>
                  ))
                ) : (
                  <p>Not available</p>
                )}
              </div>
            </div>
            <div className="tw-col-span-7 md:tw-col-span-5 tw-p-3 tw-shadow-md tw-rounded-md">
              {location.state?.active && (
                <Button
                  onClick={() => connect()}
                  className="tw-hidden md:tw-inline-block button tw-float-right tw-p-1 tw-text-sm hover:tw-bg-green-600"
                >
                  Invite
                </Button>
              )}
              <div>
                <h4 className="tw-font-medium tw-mb-2">Bio</h4>
                <p>{vendorData.details.description}</p>
              </div>
              <div>
                <h4 className="tw-font-medium tw-mt-5 tw-mb-2">Portfolio</h4>
                <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                  {vendorData.documents.length > 0 ? (
                    vendorData.documents.map((data) => (
                      <a
                        key={data._id}
                        href={data.path}
                        target="_blank"
                        rel="noreferrer"
                        className="tw-col-span-2 md:tw-col-span-1 tw-flex tw-items-center tw-justify-between tw-p-2 tw-bg-gray-200 tw-rounded"
                      >
                        <span>{data.file_name}</span>
                        <RedirectArrowIcon />
                      </a>
                    ))
                  ) : (
                    <p>Portfolio not updated</p>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <RatingModal
        open={openRoleModal}
        onClose={() => setOpenRoleModal(false)}
        role="vendor"
        id={user_id}
      />
    </div>
  );
};

export default ViewVendorProfile;
