import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";

export const StartupStageModal = ({ open, onClose, editStartupStage }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    const prevInvestment = document.getElementById("prevInvestment").value;
    editStartupStage(data, prevInvestment);
    onClose();
  };

  const handlePrevInfo = () => {
    const prevInvestment = document.getElementById("prevInvestment").value;
    if (prevInvestment === "yes") {
      document
        .getElementById("prevInvestmentDetails")
        .classList.remove("tw-hidden");
    } else {
      document
        .getElementById("prevInvestmentDetails")
        .classList.add("tw-hidden");
    }
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="tw-py-2 tw-border-b tw-text-center tw-font-semibold tw-text-lg">
          More Information
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="tw-p-2 tw-pb-0">
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm" htmlFor="startupStage">
              Startup stage
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <select
                className="form-select tw-border-0 tw-p-2 tw-w-full"
                name="startupStage"
                id="startupStage"
                {...register("startupStage")}
              >
                <option value="">Select Stage</option>
                <option value="seed-stage">Seed stage</option>
                <option value="early-funded">Early stage</option>
                <option value="growth-stage">Growth stage</option>
                <option value="late-stage">Late stage</option>
              </select>
            </div>
          </div>
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm" htmlFor="prevInvestment">
              Any Previous Round Investment
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <select
                className="form-select tw-border-0 tw-p-2 tw-w-full"
                name="prevInvestment"
                id="prevInvestment"
                onChange={handlePrevInfo}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
          <div className="tw-hidden" id="prevInvestmentDetails">
            <div className="tw-mb-1">
              <label
                className="tw-mb-0 tw-text-sm"
                htmlFor="prevInvestedAmount"
              >
                Previous Investment Amount
              </label>
              <div
                className="tw-flex tw-rounded-md tw-overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <select
                  className="form-select tw-border-0 tw-p-2 tw-w-full"
                  name="prevInvestedAmount"
                  id="prevInvestedAmount"
                  {...register("prevInvestedAmount")}
                >
                  <option value="">Select Amount</option>
                  <option value="Less than 5Lakh">Upto 5Lakh</option>
                  <option value="5Lakh-10Lakh">5Lakh-10Lakh</option>
                  <option value="10Lakh-25Lakh">10Lakh-25Lakh</option>
                  <option value="25Lakh-50Lakh">25Lakh-50Lakh</option>
                  <option value="More than 50Lakh">50Lakh+</option>
                </select>
              </div>
            </div>
          </div>
          <div className="tw-col-start-2 tw-text-right tw-p-2">
            <Button type="submit" className="tw-px-1 tw-py-0.5 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
