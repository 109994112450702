import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import { Button } from "../../Button";
import "../../../../custom.css";
import { numberRegex } from "../../../../constants";
import { PercentIcon, RupeeIcon } from "../../../../icons/icons";
import { useToastContext } from "../../../../context/ToastContext";

export const InvestmentModal = ({
  open,
  onClose,
  startupId,
  connectionStatus,
}) => {
  const navigate = useNavigate();
  const toast = useToastContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (!open) return null;

  const toEsign = (data, e) => {
    e.preventDefault();
    if (connectionStatus !== "connected") {
      toast.open(
        "warning",
        "Need to establish connection with startup before investing"
      );
      onClose();
      return;
    }
    navigate("/agreement", {
      state: {
        amount: data.amount,
        equity: data.equity,
        startupId: startupId,
      },
    });
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[50%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Investment Details
        </h4>
        <form onSubmit={handleSubmit(toEsign)} className="tw-p-3">
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm required" htmlFor="amount">
              Investment Amount(in Rupees)
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="amount"
                name="amount"
                autoFocus
                {...register("amount", {
                  required: "amount is required",
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                  validate: (fieldvalue) => {
                    return fieldvalue == 0 ? "Amount cannot be 0" : null;
                  },
                })}
              />
              <span className="input-icons icon-span">
                <RupeeIcon />
              </span>
            </div>
            {errors.amount && (
              <div>
                <FormError message={errors.amount.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm required" htmlFor="equity">
              Acquiring Equity(%)
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="equity"
                name="equity"
                {...register("equity", {
                  required: "equity is required",
                  pattern: {
                    value:
                      /[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?/,
                    message: "equity must only contain [0-9] [.]",
                  },
                  validate: (fieldvalue) => {
                    return fieldvalue == 0 ? "Equity cannot be 0" : null;
                  },
                })}
              />
              <span className="input-icons icon-span">
                <PercentIcon />
              </span>
            </div>
            {errors.equity && (
              <div>
                <FormError message={errors.equity.message} />
              </div>
            )}
          </div>
          <Button
            type="submit"
            className="tw-px-2 tw-py-1 tw-m-2 tw-float-right"
          >
            Next
          </Button>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
