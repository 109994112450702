import { useCallback } from "react";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";
import { useToastContext } from "../context/ToastContext";
import CompanyLogo from "../images/favicon.png";
import { useNavigate } from "react-router-dom";

const useCheckout = () => {
  const checkoutApi = `${process.env.REACT_APP_API_URL}checkout`;
  const insertTransactionApi = `${process.env.REACT_APP_API_URL}insert-transaction-details`;

  const { token, name } = useLocalStorage();
  const toast = useToastContext();
  const navigate = useNavigate();

  const createCheckoutSession = useCallback(
    async (bodyData, callbackFn) => {
      const response = await axios
        .post(checkoutApi, bodyData, {
          headers: { "x-token": token },
        })
        .catch(() => {
          toast.open("false", "Error creating checkout session");
          return null;
        });

      if (!response || response.data.message === "deactivated") {
        callbackFn();
        toast.open("false", "Sorry, This plan has been deactivated.");
        return;
      }

      const order = response.data;

      const options = {
        key: order.key, // Enter the Key ID generated from the Dashboard
        amount: order.final_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "PitchHere", //your business name
        description: "Test Transaction",
        image: CompanyLogo,
        order_id: order.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          let bodyData = {
            ...response,
            ...order,
          };

          await axios
            .post(insertTransactionApi, bodyData, {
              headers: { "x-token": token },
            })
            .then((res) => {
              if (res.data.message === "deactivated") {
                callbackFn();
                toast.open("false", "Sorry, This plan has been deactivated.");
                return;
              } else {
                navigate(`/payment/${res.data.message}`);
                return;
              }
            })
            .catch(() => {
              toast.open("false", "Error creating checkout session");
              return null;
            });
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          name: name, //your customer's name
          email: "",
          contact: "", //Provide the customer's phone number for better conversion rates
        },
        theme: {
          color: "#17335E",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    },
    [checkoutApi, insertTransactionApi, token, name, toast, navigate]
  );

  return { createCheckoutSession };
};

export default useCheckout;
