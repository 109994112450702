import { Stars } from "../../../../components/ui/modals/rating-modal/Stars";
import {
  BuildingIcon,
  GlobeIcon,
  LocationIcon,
  RedirectArrowIcon,
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";

const VendorDetails = ({ details, flag, handleSuspension }) => {
  return (
    <section className="tw-grid tw-grid-cols-7 tw-gap-3">
      <div className="tw-col-span-7 md:tw-col-span-2 tw-p-3 tw-shadow-md tw-rounded-md">
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-items-start tw-justify-between">
            <img
              src={details?.logo?.length < 1 ? FavIcon : details?.logo}
              className="tw-rounded-full tw-h-[90px] tw-w-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-1"
              alt="profile-img"
            />
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-end md:tw-hidden">
              {details?.is_suspend === "0" ? (
                <button
                  className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
                  id="suspend-btn"
                  onClick={() => handleSuspension("suspend")}
                >
                  Suspend
                </button>
              ) : (
                <button
                  className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-green-500 tw-text-white"
                  id="unsuspend-btn"
                  onClick={() => handleSuspension("unsuspend")}
                >
                  Un-Suspend
                </button>
              )}
              <div className="tw-my-3">
                <Stars rating={details?.details?.average_rating} size={12} />
              </div>
            </div>
          </div>
          <p className="tw-font-medium tw-text-lg tw-mt-2 tw-uppercase">
            {details?.name}
          </p>
        </div>
        <div className="tw-flex tw-items-center tw-mb-2">
          <BuildingIcon fill="#9c9c9c" />
          <p className="tw-text-sm tw-mb-0 tw-ms-3 tw-capitalize">
            <span className="tw-me-1 tw-font-bold">Company :</span>
            {details?.details?.company_name}
          </p>
        </div>
        <div className="tw-flex tw-items-center tw-mb-2">
          <GlobeIcon fill="#9c9c9c" />
          <p className="tw-text-sm tw-mb-0 tw-ms-3">
            <span className="tw-me-1 tw-font-bold">Website :</span>
            {details?.portfolio?.website?.length > 0 ? (
              <a
                href={"https://" + details?.portfolio?.website}
                target="_blank"
                rel="noreferrer"
                className="tw-text-sm tw-mb-0 tw-ms-1 tw-text-blue-500"
              >
                URL
              </a>
            ) : (
              <span className="tw-text-sm tw-mb-0 tw-ms-3 tw-text-secondary">
                N/A
              </span>
            )}
          </p>
        </div>
        <div className="tw-flex tw-mb-4">
          <LocationIcon fill="#9c9c9c" />
          <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3">
            <span className="tw-font-bold tw-me-1">Address :</span>
            <div className="tw-flex tw-items-center">
              <span className="tw-me-1 tw-capitalize">{details?.city}</span>
              <img
                src={flag && `${process.env.REACT_APP_API_URL}` + flag.slice(6)}
                alt={`${details?.country}-flag`}
                width={18}
                title={details?.country}
              />
            </div>
          </div>
        </div>
        <div className="tw-border-t tw-py-2">
          <p className="tw-mb-0 tw-font-bold">Industries</p>
          {details?.details?.expert_in?.length > 0 ? (
            details?.details?.expert_in?.map((tag, i) => (
              <span key={i} className="outline-tags">
                {tag.replace(new RegExp("_", "g"), " ")}
              </span>
            ))
          ) : (
            <p>Not available</p>
          )}
        </div>
      </div>
      <div className="tw-col-span-7 md:tw-col-span-5 tw-p-3 tw-shadow-md tw-rounded-md">
        {details?.is_suspend === "0" ? (
          <button
            className="tw-hidden md:tw-block tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-secondary tw-text-white"
            id="suspend-btn"
            onClick={() => handleSuspension("suspend")}
          >
            Suspend
          </button>
        ) : (
          <button
            className="tw-hidden md:tw-block tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-green-500 tw-text-white"
            id="unsuspend-btn"
            onClick={() => handleSuspension("unsuspend")}
          >
            Un-Suspend
          </button>
        )}
        <div className="tw-mt-3">
          <h4 className="tw-font-medium tw-mb-2">Bio</h4>
          <p>{details?.details?.description}</p>
        </div>
        <div>
          <h4 className="tw-font-medium tw-mt-5 tw-mb-2">Portfolio</h4>
          <div className="tw-grid tw-grid-cols-2 tw-gap-2">
            {details?.portfolio?.documents?.length > 0 ? (
              details?.portfolio?.documents?.map((data) => (
                <a
                  key={data._id}
                  href={data.path}
                  target="_blank"
                  rel="noreferrer"
                  className="tw-col-span-2 md:tw-col-span-1 tw-flex tw-items-center tw-justify-between tw-p-2 tw-bg-gray-200 tw-rounded"
                >
                  <span>{data.file_name}</span>
                  <RedirectArrowIcon />
                </a>
              ))
            ) : (
              <p>Portfolio not updated</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VendorDetails;
