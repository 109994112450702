import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const useFetch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchApi = useCallback(
    async (requestConfig, applyData) => {
      setIsLoading(true);
      setError(null);
      try {
        var res = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers: requestConfig.headers ? requestConfig.headers : {},
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });
        var data = await res.json();
        console.log(data);
        console.log(requestConfig.url);
      } catch (error) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
      if (data.status === 200) {
        setIsLoading(false);
        await applyData(data);
      } else if (data.status === 401) {
        navigate("/error-401");
      } else {
        setError("Something went wrong");
        setIsLoading(false);
      }
    },
    [navigate]
  );
  return { isLoading, error, fetchApi, setError };
};

export default useFetch;
