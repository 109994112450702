import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import useCheckConnection from "../../hooks/useCheckConnection";
import useUnfollowConnection from "../../hooks/useUnfollowConnection";
import useVerificationApi from "../../hooks/useVerificationApi";
import { useToastContext } from "../../context/ToastContext";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { InvestmentModal } from "../../components/ui/modals/investment/investmentModal";
import { Stars } from "../../components/ui/modals/rating-modal/Stars";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";
import "./viewProfile.css";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import certifiedLogo from "../../images/certified.png";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import {
  BuildingIcon,
  LinkedInIcon,
  LocationIcon,
  RedirectArrowIcon,
  RedirectIcon,
  RupeeIcon,
  UserTieIcon,
} from "../../icons/icons";
import NavigateToDashboard from "../../utils/navigateToDashboard";
import { randomId } from "../../utils/randomId";

const ViewStartupProfile = () => {
  const startupDetailsApi = `${process.env.REACT_APP_API_URL}display-startup-profile`;
  const reviewsCountApi = `${process.env.REACT_APP_API_URL}reviews-count`;
  const verifyStartupApi = `${process.env.REACT_APP_API_URL}verify-startup`;
  const sendConnectionRequestApi = `${process.env.REACT_APP_API_URL}send-connect-request`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const { checkConnection } = useCheckConnection();
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const { unFollow } = useUnfollowConnection();
  const { verification } = useVerificationApi();
  const { displayFiles } = useDisplayFiles();
  const toast = useToastContext();

  const ref = useRef(true);
  const [startupDetails, setStartupDetails] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("");
  const [startupId, setStartupId] = useState("");
  const [rating, setRating] = useState(0);
  const [profileImg, setProfileImg] = useState("");
  const [open, setOpen] = useState(false);
  const [verified, setVerified] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(false);
  const [files, setFiles] = useState({
    documents: [],
    msme: [],
    gst: [],
    incorporation: [],
    others: [],
    video: "",
    reviewCount: 0,
  });

  const getStartupDetails = useCallback(async () => {
    if (!user_id) {
      toast.open("false", "Sorry, something went wrong. Please try again");
      console.log("user_id not found");
      return;
    }
    fetchApi(
      {
        url: startupDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: user_id,
        },
      },
      (data) => {
        if (data.message === "suspended") {
          toast.open("warning", "This accound has been Suspended/Deactivated");
          navigate(NavigateToDashboard(userRole));
        }
        setStartupDetails(data.startup_profile);
        setStartupId(data.startup_profile[0].user_id);
        setRating(data.startup_profile[0].average_rating);
        setProfileImg(data.startup_profile[0].logo);
        if (data.startup_profile[0].verify_profile === "1") {
          setVerified(true);
        } else setVerified(false);
      }
    );
  }, [user_id, startupDetailsApi, token, fetchApi, navigate, userRole, toast]);

  const reviewsCount = useCallback(() => {
    fetchApi(
      {
        url: reviewsCountApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          user_id: user_id,
        },
      },
      (data) => {
        setFiles((prev) => ({ ...prev, reviewCount: data.review_count }));
      }
    );
  }, [reviewsCountApi, fetchApi, token, user_id]);

  const sendConnectionRequest = useCallback(() => {
    fetchApi(
      {
        url: sendConnectionRequestApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
        },
      },
      (data) => {
        setConnectionStatus("pending");
        sendNotification(
          user_id,
          `Incoming connection request from investor ${data.name}`,
          "connections"
        );
      }
    );
  }, [sendConnectionRequestApi, fetchApi, token, user_id, sendNotification]);

  const verifyStartup = () => {
    fetchApi(
      {
        url: verifyStartupApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          startup_id: user_id,
        },
      },
      (data) => {
        setVerified(true);
        sendNotification(
          user_id,
          `Your profile has been verified by mentor ${data.name}`,
          "profile"
        );
      }
    );
  };

  const postFilesApi = (data) => {
    setFiles((prev) => ({
      ...prev,
      documents: data.documents,
      msme: data.msme,
      gst: data.gst,
      incorporation: data.incorporation,
      others: data.others,
      video: data.video,
    }));
  };

  useEffect(() => {
    if (userRole === "f@!3A") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getStartupDetails();
      verification(
        user_id,
        userRole === "R$7s2" ? "verify" : "invested",
        setReviewStatus
      );
      displayFiles(postFilesApi, user_id);
      checkConnection(user_id, setConnectionStatus);
      reviewsCount();
    }

    return () => {
      ref.current = false;
    };
  }, [
    navigate,
    userRole,
    getStartupDetails,
    verification,
    displayFiles,
    checkConnection,
    reviewsCount,
    user_id,
  ]);

  const verifyConnection = () => {
    checkConnection(user_id, setConnectionStatus);
    if (!isLoading) {
      setOpen(true);
    }
  };

  const routeToChat = () => {
    dispatch(deleteChatRoom());
    navigate("/chat");
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-3 md:tw-p-4">
          {error && <Error />}
          {isLoading && !error ? (
            <Loader />
          ) : (
            <>
              <div className="tw-grid tw-grid-cols-8 tw-gap-3">
                <div className="tw-col-span-8 tw-ml-auto md:tw-hidden">
                  {connectionStatus === "connected" && (
                    <>
                      {reviewStatus && (
                        <button
                          className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-[#F97316] tw-text-white"
                          id="review-btn"
                          onClick={() => setOpenReviewModal(true)}
                        >
                          Review
                        </button>
                      )}
                      {!reviewStatus && userRole === "R$7s2" && (
                        <button
                          className="tw-px-2 tw-py-1 tw-text-xs tw-rounded-sm tw-bg-green-600 tw-text-white tw-mx-1"
                          id="certification-btn"
                          onClick={() => verifyStartup()}
                        >
                          Certify
                        </button>
                      )}
                      {userRole === "Sw%aa" && (
                        <button
                          className="tw-px-2 tw-py-1 tw-text-xs tw-rounded-sm tw-bg-green-600 tw-text-white tw-mx-1"
                          id="invest-btn"
                          onClick={() => verifyConnection()}
                        >
                          Invest
                        </button>
                      )}
                      <button
                        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white"
                        id="msg-btn"
                        onClick={() => routeToChat()}
                      >
                        Message
                      </button>
                      <button
                        className="tw-px-2 tw-py-1 tw-text-xs tw-rounded-sm tw-bg-red-600 tw-text-white tw-mx-1"
                        id="disconnect-btn"
                        onClick={() => unFollow(user_id, setConnectionStatus)}
                      >
                        Disconnect
                      </button>
                    </>
                  )}
                  {/* {connectionStatus === "pending" && userRole === "Sw%aa" && (
                    <>
                      <button
                        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-orange-700 tw-text-white"
                        id="cancel-btn"
                        onClick={() => acceptorDenyRequest("cancel")}
                      >
                        Cancel
                      </button>
                    </>
                  )} */}
                  {userRole === "Sw%aa" &&
                    connectionStatus === "disconnect" && (
                      <button
                        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                        id="connect-btn"
                        onClick={() => sendConnectionRequest()}
                      >
                        Connect
                      </button>
                    )}
                </div>
                <div className="tw-col-span-8 md:tw-col-span-3 tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-text-center">
                  <div className="tw-flex tw-items-start tw-justify-between">
                    <img
                      src={
                        profileImg === undefined ||
                        profileImg === "" ||
                        profileImg.length < 1
                          ? FavIcon
                          : profileImg
                      }
                      className="tw-rounded-full tw-h-[90px] tw-w-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-2"
                      alt="profile-img"
                    />
                    <div className="tw-flex tw-items-center md:tw-hidden">
                      <div className="tw-my-3">
                        <Stars rating={rating} size={12} />
                      </div>
                      <span className="tw-px-1 md:tw-px-2">
                        ({files.reviewCount})
                      </span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "f@!3A",
                        }}
                        className="tw-ps-0 tw-text-blue-400 tw-text-sm"
                      >
                        Reviews
                      </Link>
                    </div>
                  </div>
                  {startupDetails.map((startup) => (
                    <div className="tw-text-start" key={startup.user_id}>
                      <div className="tw-font-bold tw-text-lg tw-pt-2 tw-mb-0 tw-flex tw-items-center tw-uppercase">
                        {startup.name}{" "}
                        <span>
                          {verified && (
                            <img
                              src={certifiedLogo}
                              alt="Certified by Mentor"
                              title="Certified by Mentor"
                              width={40}
                              className="tw-pl-1"
                            />
                          )}
                        </span>
                      </div>
                      {userRole === "Sw%aa" && (
                        <div className="tw-flex tw-items-center tw-mb-2">
                          <LinkedInIcon fill="#999999" />
                          <p className="tw-text-sm tw-mb-0 tw-ms-3">
                            <span className="tw-me-1 tw-font-medium">
                              Linkedin :
                            </span>
                            {startup.linked_in ? (
                              <a
                                href={startup.linked_in}
                                target="_blank"
                                rel="noreferrer"
                                className="tw-text-blue-500"
                              >
                                Profile
                              </a>
                            ) : (
                              <span className="tw-text-secondary">N/A</span>
                            )}
                          </p>
                        </div>
                      )}
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <RupeeIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-me-1 tw-font-medium">
                            Desired Investment :
                          </span>
                          {startup.require_investment}
                        </p>
                      </div>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <BuildingIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-me-1 tw-font-medium">
                            Company :
                          </span>
                          {startup.company_name}
                        </p>
                      </div>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <RedirectIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-me-1 tw-font-medium">
                            Website :
                          </span>
                          {startup.website.length > 0 ? (
                            <a
                              href={"https://" + startup.website}
                              target="_blank"
                              rel="noreferrer"
                              className="tw-text-xs tw-mb-0 tw-text-blue-400"
                            >
                              URL
                            </a>
                          ) : (
                            <span className="tw-text-secondary tw-text-sm">
                              N/A
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <UserTieIcon fill="#999999" />
                        <p className="tw-text-sm tw-mb-0 tw-ms-3">
                          <span className="tw-me-1 tw-font-medium">
                            Company Size :{" "}
                            <span className="tw-font-normal">
                              {startup.no_of_employees || (
                                <span className="tw-text-secondary tw-text-sm">
                                  N/A
                                </span>
                              )}
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="tw-flex tw-items-center tw-mb-2">
                        <LocationIcon fill="#999999" />
                        <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3 tw-font-medium">
                          Address :{" "}
                          <div className="tw-flex tw-items-center tw-mb-0 tw-ms-1">
                            <span className="tw-me-1 tw-font-normal">
                              {startup.city}
                            </span>
                            <img
                              src={
                                `${process.env.REACT_APP_API_URL}` +
                                startup.flag_path.slice(6)
                              }
                              alt={`${startup.country}-flag`}
                              width={18}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tw-border-t tw-py-2">
                        <p className="tw-mb-0 tw-font-medium">Industries</p>
                        {startup.startup_industry.map((industry, i) => (
                          <span key={i} className="outline-tags">
                            {industry.replace(new RegExp("_", "g"), " ")}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tw-hidden md:tw-block tw-col-span-5">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-5">
                    <div className="tw-flex tw-items-center">
                      <div className="tw-my-3">
                        <Stars rating={rating} />
                      </div>
                      <span className="tw-px-2">({files.reviewCount})</span>
                      <Link
                        to="/reviews"
                        state={{
                          user_id: user_id,
                          role: "f@!3A",
                        }}
                        className="tw-ps-0 tw-text-blue-400"
                      >
                        Reviews
                      </Link>
                    </div>
                    <div>
                      {connectionStatus === "connected" && (
                        <>
                          {reviewStatus && (
                            <button
                              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-[#F97316] tw-text-white"
                              id="review-btn"
                              onClick={() => setOpenReviewModal(true)}
                            >
                              Review
                            </button>
                          )}
                          {!reviewStatus && userRole === "R$7s2" && (
                            <button
                              className="tw-px-2 tw-py-1 tw-text-xs tw-rounded-sm tw-bg-green-600 tw-text-white tw-mx-1"
                              id="certification-btn"
                              onClick={() => verifyStartup()}
                            >
                              Certify
                            </button>
                          )}
                          {userRole === "Sw%aa" && (
                            <button
                              className="tw-px-2 tw-py-1 tw-text-xs tw-rounded-sm tw-bg-green-600 tw-text-white tw-mx-1"
                              id="invest-btn"
                              onClick={() => verifyConnection()}
                            >
                              Invest
                            </button>
                          )}
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white hover:tw-text-white"
                            id="msg-btn"
                            onClick={() => routeToChat()}
                          >
                            Message
                          </button>
                          <button
                            className="tw-px-2 tw-py-1 tw-text-xs tw-rounded-sm tw-bg-red-600 tw-text-white tw-mx-1"
                            id="disconnect-btn"
                            onClick={() =>
                              unFollow(user_id, setConnectionStatus)
                            }
                          >
                            Disconnect
                          </button>
                        </>
                      )}
                      {/* {connectionStatus === "pending" &&
                        userRole === "Sw%aa" && (
                          <>
                            <button
                              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-orange-700 tw-text-white"
                              id="cancel-btn"
                              onClick={() => acceptorDenyRequest("cancel")}
                            >
                              Cancel
                            </button>
                          </>
                        )} */}
                      {userRole === "Sw%aa" &&
                        connectionStatus === "disconnect" && (
                          <button
                            className="tw-px-2 tw-py-1 tw-mx-1 tw-text-xs tw-rounded-sm tw-bg-primary tw-text-white"
                            id="connect-btn"
                            onClick={() => sendConnectionRequest()}
                          >
                            Connect
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="tw-grid tw-grid-cols-1 tw-gap-3">
                    <div className="tw-px-0" style={{ height: "270px" }}>
                      <div
                        className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3"
                        style={{
                          height: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <h5 className="tw-font-medium tw-pb-3">Startup Idea</h5>
                        <p className="tw-mb-0 tw-text-sm">
                          {startupDetails.length > 0
                            ? startupDetails[0].description.length
                              ? startupDetails[0].description
                              : "Not updated."
                            : "Not updated."}
                        </p>
                      </div>
                    </div>
                    <div className="tw-pe-0">
                      <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3">
                        {files.video.length > 0 ? (
                          <>
                            <h5 className="tw-font-medium tw-pb-7">
                              Video About Startup
                            </h5>
                            <video
                              controls
                              className="tw-w-full"
                              id="videoFile"
                              src={files.video}
                              style={{ height: "200px" }}
                            >
                              Video uploaded
                            </video>
                          </>
                        ) : (
                          <p>No Video uploaded.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-mt-3 tw-grid tw-grid-cols-2 tw-gap-3">
                {startupDetails.map((startup) => (
                  <div
                    className="tw-col-span-2 md:tw-col-span-1 tw-px-0"
                    key={startup.user_id}
                  >
                    <div
                      className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3"
                      style={{
                        height: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <h5 className="tw-pb-3 tw-font-medium">
                        Business Overview
                      </h5>
                      <div className="tw-grid tw-grid-cols-2">
                        <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                          <p className="tw-mb-0 tw-font-bold tw-text-sm">
                            Startup Stage
                          </p>
                          <p className="tw-text-sm tw-capitalize">
                            {startup.startup_stage.replace("-", " ") || "N/A"}
                          </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                          <p className="tw-mb-0 tw-font-bold tw-text-sm">
                            Previous Investment
                          </p>
                          <p className="tw-text-sm tw-capitalize">
                            {startup.previous_investment || "N/A"}
                          </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                          <p className="tw-mb-0 tw-font-bold tw-text-sm">
                            Invested Amount
                          </p>
                          <p className="tw-text-sm">
                            {startup.investment_amount || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="md:tw-hidden tw-col-span-2">
                  <div className="tw-px-0 tw-mb-3" style={{ height: "270px" }}>
                    <div
                      className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3"
                      style={{
                        height: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <h5 className="tw-font-medium tw-pb-3">Startup Idea</h5>
                      <p className="tw-mb-0 tw-text-sm">
                        {startupDetails.length > 0
                          ? startupDetails[0].description.length
                            ? startupDetails[0].description
                            : "Not updated."
                          : "Not updated."}
                      </p>
                    </div>
                  </div>
                  <div className="tw-pe-0">
                    <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3">
                      {files.video.length > 0 ? (
                        <>
                          <h5 className="tw-font-medium tw-pb-7">
                            Video About Startup
                          </h5>
                          <video
                            controls
                            className="tw-w-full"
                            id="videoFile"
                            src={files.video}
                            style={{ height: "200px" }}
                          >
                            Video uploaded
                          </video>
                        </>
                      ) : (
                        <p>No Video uploaded.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="tw-col-span-2 md:tw-col-span-1 tw-px-0">
                  <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-mb-2 tw-h-100 tw-overflow-y-auto">
                    <h5 className="tw-font-medium tw-pb-3">Pitch Deck</h5>
                    {files.documents.length > 0 &&
                      files.documents.map((document) => (
                        <a
                          href={document.path}
                          key={document._id}
                          target="_blank"
                          rel="noreferrer"
                          className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                        >
                          <p>{document.file_name}</p>
                          <RedirectArrowIcon className="tw-cursor-pointer" />
                        </a>
                      ))}
                    {!files.documents.length > 0 && (
                      <span>No Files uploaded</span>
                    )}
                  </div>
                  <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-h-100 tw-overflow-y-auto">
                    <h5 className="tw-font-medium tw-pb-3">
                      Documents & Certifications
                    </h5>
                    {files.msme.length > 0 && (
                      <a
                        href={files.msme[0].path}
                        target="_blank"
                        rel="noreferrer"
                        className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                      >
                        <p className="tw-uppercase">
                          {files.msme[0].document_name}
                        </p>
                        <RedirectArrowIcon className="tw-cursor-pointer" />
                      </a>
                    )}
                    {files.gst.length > 0 && (
                      <a
                        href={files.gst[0].path}
                        target="_blank"
                        rel="noreferrer"
                        className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                      >
                        <p className="tw-uppercase">
                          {files.gst[0].document_name}
                        </p>
                        <RedirectArrowIcon className="tw-cursor-pointer" />
                      </a>
                    )}
                    {files.incorporation.length > 0 && (
                      <a
                        href={files.incorporation[0].path}
                        target="_blank"
                        rel="noreferrer"
                        className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                      >
                        <p className="tw-uppercase">
                          {files.incorporation[0].document_name}
                        </p>
                        <RedirectArrowIcon className="tw-cursor-pointer" />
                      </a>
                    )}
                    {files.others.length > 0 &&
                      files.others.map((file) => (
                        <a
                          href={file.path}
                          key={file._id}
                          target="_blank"
                          rel="noreferrer"
                          className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                        >
                          <p className="tw-capitalize">{file.document_name}</p>
                          <RedirectArrowIcon className="tw-cursor-pointer" />
                        </a>
                      ))}
                    {!files.msme.length > 0 &&
                      !files.gst.length > 0 &&
                      !files.incorporation.length > 0 &&
                      !files.others.length > 0 && (
                        <span>No Files uploaded</span>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
        <InvestmentModal
          open={open}
          onClose={() => setOpen(false)}
          startupId={startupId}
          connectionStatus={connectionStatus}
        />
        <RatingModal
          open={openReviewModal}
          onClose={() => setOpenReviewModal(false)}
          role="startup"
          id={user_id}
        />
      </div>
    </div>
  );
};

export default ViewStartupProfile;
