import "./chat.css";
import FavIcon from "../../images/favicon.png";

const ChatList = (props) => {
  const { data, getRoomId, unreadCount } = props;

  return (
    <li
      onClick={() =>
        getRoomId(data.connection_id, data.role, data.status, data.deactivate)
      }
      className="tw-mb-2 tw-flex tw-items-center tw-justify-between"
    >
      <div className="tw-flex tw-items-center">
        <div className="tw-relative">
          <img
            className="tw-rounded-full tw-h-[45px]"
            src={data.logo.length > 0 ? data.logo : FavIcon}
            alt="connection-img"
            width={45}
            loading="lazy"
          />
          {/* <div className="online-status-circle"></div> */}
        </div>
        <div className="tw-ps-2">
          <p className="tw-font-medium tw-capitalize">{data.name}</p>
          {data.role === "f@!3A" ? (
            <span className="tw-text-xs tw-px-1 tw-bg-green-500 tw-text-white tw-rounded-md">
              Startup
            </span>
          ) : data.role === "R$7s2" ? (
            <span className="tw-text-xs tw-px-1 tw-bg-primary tw-text-white tw-rounded-md">
              Mentor
            </span>
          ) : data.role === "Sw%aa" ? (
            <span className="tw-text-xs tw-px-1 tw-bg-secondary tw-text-white tw-rounded-md">
              Investor
            </span>
          ) : (
            <span className="tw-text-xs tw-px-1 tw-bg-orange-500 tw-text-white tw-rounded-md">
              Vendor
            </span>
          )}
        </div>
      </div>
      {unreadCount > 0 && (
        <p className="tw-bg-secondary tw-text-white tw-rounded-full tw-p-0.5 tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center">
          {unreadCount}
        </p>
      )}
    </li>
  );
};

export default ChatList;
