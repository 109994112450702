import React, { useEffect } from "react";
import axios from "axios";
import useCheckRole from "../../hooks/useCheckRole";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ResponsiveSidebar } from "./responsiveSidebar";
import { RegularSidebar } from "./regularSidebar";
import useWindowDimensions from "../../hooks/useWindowDimension";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";

const Sidebar = ({ openSidebar, closeSidebar, style }) => {
  const supportTicketApi = `${process.env.REACT_APP_API_URL}support-ticket`;
  const { fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { role, CheckRole } = useCheckRole();
  const { width } = useWindowDimensions();

  // const supportTicket = async (data) => {
  //   fetchApi(
  //     {
  //       url: supportTicketApi,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         "x-token": token,
  //       },
  //       body: {
  //         support_type: data.complaintCategory.replace("-", " "),
  //         ticket_heading: data.complaintHeading,
  //         query: data.complaintQuery,
  //       },
  //     },
  //     () => {}
  //   );
  // };
  const supportTicket = (file, data) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("support_type", data.complaintCategory.replace("-", " "));
    formData.append("ticket_heading", data.complaintHeading);
    formData.append("query", data.complaintQuery);
    axios
      .post(supportTicketApi, formData, {
        headers: { "x-token": token },
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    CheckRole(userRole);
  }, [CheckRole, userRole]);

  return (
    <>
      {openSidebar && width < 1024 && (
        <ResponsiveSidebar
          supportTicket={supportTicket}
          role={role}
          closeSidebar={closeSidebar}
          style={style}
        />
      )}
      {width >= 1024 && (
        <RegularSidebar
          supportTicket={supportTicket}
          role={role}
          style={style}
        />
      )}
    </>
  );
};

export default Sidebar;
