import {
  MarketIcon,
  RocketIcon,
  UserGraduateIcon,
  UserIcon,
  UserTieIcon,
} from "../../../icons/icons";

const SmallCardWithoutButton = ({
  data,
  icon,
  gradient,
  font = "normal",
  titleColor = "",
}) => {
  const iconType = () => {
    switch (icon) {
      case "startup":
        return <RocketIcon height="20px" fill="#fff" />;
      case "mentor":
        return <UserGraduateIcon height="20px" fill="#fff" />;
      case "investor":
        return <UserTieIcon height="20px" fill="#fff" />;
      case "vendor":
        return <MarketIcon height="20px" fill="#fff" />;
      case "total":
        return <UserIcon height="20px" fill="#fff" />;
      default:
        return <UserIcon height="20px" fill="#fff" />;
    }
  };
  return (
    <div className="tw-card course-box tw-h-full">
      <div className="tw-flex tw-items-center tw-justify-between tw-h-full">
        <div className={`course-icon ${gradient}`}>{iconType()}</div>
        <div className={`tw-flex tw-flex-col tw-font-${font} tw-text-end`}>
          <h4 className="tw-mb-0 tw-text-xl">{data.count}+</h4>
          <span className={`${titleColor} tw-font-medium`}>{data.title}</span>
        </div>
      </div>
    </div>
  );
};

export default SmallCardWithoutButton;
