import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import { Loader } from "../../Loader";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import "../../../../custom.css";
import { numberRegex } from "../../../../constants";
import { PercentIcon, RupeeIcon } from "../../../../icons/icons";

export const EditAgreementModal = ({
  open,
  onClose,
  propDetails,
  editAgreement,
}) => {
  const { isLoading, error } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (!open) return null;

  const inputValidation = (data) => {
    document.getElementById("entered-zero").classList.add("tw-hidden");
    if (data.editEquity == 0) {
      document.getElementById("entered-zero").classList.remove("tw-hidden");
      return;
    }
    editAgreement(propDetails.agreementId, data, propDetails.userId);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[50%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Edit Agreement Details
        </h4>
        <form
          onSubmit={handleSubmit(inputValidation)}
          className="tw-grid tw-grid-cols-2 tw-gap-3 tw-p-3"
        >
          <div>
            <h5 className="tw-mb-2 tw-font-medium">Current Values</h5>
            <div className="tw-mb-1">
              <label className="tw-mb-0 tw-text-sm" htmlFor="editAmount">
                Amount(in Rupees) (Current)
              </label>
              <div
                className="tw-flex tw-rounded-md tw-overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  className="tw-border-0 tw-w-full tw-ps-3"
                  id="editAmount"
                  name="editAmount"
                  value={propDetails.amount}
                  disabled
                />
                <span className="input-icons icon-span">
                  <RupeeIcon />
                </span>
              </div>
            </div>
            <div className="tw-mb-1">
              <label className="tw-mb-0 tw-text-sm" htmlFor="editEquity">
                Equity(%) (Current)
              </label>
              <div
                className="tw-flex tw-rounded-md tw-overflow-hidden"
                style={{
                  border: "1px solid #ced4da",
                }}
              >
                <input
                  type="text"
                  className="tw-border-0 tw-w-full tw-ps-3"
                  id="editEquity"
                  name="editEquity"
                  value={propDetails.equity}
                  disabled
                />
                <span className="input-icons icon-span">
                  <PercentIcon />
                </span>
              </div>
            </div>
          </div>
          <div>
            <h5 className="tw-mb-2 tw-font-medium">New Values</h5>
            <div className="tw-mb-1">
              <label
                className="tw-mb-0 tw-text-sm required"
                htmlFor="editAmount"
              >
                Amount(in Rupees)
              </label>
              <div className="tw-flex tw-border tw-border-gray-400 tw-rounded-md tw-overflow-hidden">
                <input
                  type="number"
                  className="tw-w-full tw-px-2 tw-py-1"
                  id="editAmount"
                  name="editAmount"
                  autoFocus
                  {...register("editAmount", {
                    required: "amount is required",
                    pattern: {
                      value: numberRegex,
                      message: "Number must only contain digits",
                    },
                  })}
                />
                <span className="input-icons icon-span">
                  <RupeeIcon />
                </span>
              </div>
              {errors.editAmount && (
                <div>
                  <FormError message={errors.editAmount.message} />
                </div>
              )}
            </div>
            <div className="tw-mb-1">
              <label
                className="tw-mb-0 tw-text-sm required"
                htmlFor="editEquity"
              >
                Equity(%)
              </label>
              <div className="tw-flex tw-border tw-border-gray-400 tw-rounded-md tw-overflow-hidden">
                <input
                  type="number"
                  className="tw-w-full tw-px-2 tw-py-1"
                  id="editEquity"
                  name="editEquity"
                  {...register("editEquity", {
                    required: "equity is required",
                  })}
                />
                <span className="input-icons icon-span">
                  <PercentIcon />
                </span>
              </div>
              {errors.editEquity && (
                <div>
                  <FormError message={errors.editEquity.message} />
                </div>
              )}
              <span
                id="entered-zero"
                className="tw-hidden tw-text-secondary tw-text-xs"
              >
                Equity cannot be 0
              </span>
            </div>
          </div>
          <div className="modalFooter tw-col-span-2 tw-border-t tw-p-3">
            <Button
              onClick={onClose}
              className="tw-px-2 tw-py-1 tw-mx-1"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
              Update
            </Button>
          </div>
        </form>
        {isLoading && <Loader />}
        {error && (
          <p className="tw-text-red-500 tw-text-xs tw-my-1">
            Sorry something went wrong. Please try again later
          </p>
        )}
      </div>
    </div>,
    document.getElementById("portal")
  );
};
