import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useCheckRole from "../../hooks/useCheckRole";
import useFetch from "../../hooks/useFetch";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { FormError } from "../../components/ui/FormError";
import { Button } from "../../components/ui/Button";
import { numberRegex, urlRegex } from "../../constants";
import "./Profile.css";
import "../../custom.css";
import {
  BriefCaseIcon,
  BuildingIcon,
  LinkedInIcon,
  MoneyUpTrendIcon,
  RedirectIcon,
  RupeeIcon,
} from "../../icons/icons";
import Dropdown from "../../components/ui/inputs/dropdown";

const ProfileCompletion = () => {
  const businessDetailsApi = `${process.env.REACT_APP_API_URL}insert-business-details`;
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}insert-mentor-details`;
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}insert-investor-details`;
  const vendorDetailsApi = `${process.env.REACT_APP_API_URL}insert-vendor-details`;
  const navigate = useNavigate();
  const { error, fetchApi } = useFetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: mentorRegister,
    handleSubmit: mentorSubmit,
    formState: { errors: mentorError },
  } = useForm();
  const {
    register: investorRegister,
    handleSubmit: investorSubmit,
    formState: { errors: investorError },
  } = useForm();
  const {
    register: vendorRegister,
    handleSubmit: vendorSubmit,
    formState: { errors: vendorError },
  } = useForm();

  const token = useSelector((state) => {
    return state.users[0].id;
  });
  const getRole = useSelector((state) => {
    return state.users[0].role;
  });
  const { displayIndustries } = useDisplayIndustries();

  const { role, CheckRole } = useCheckRole();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [startupIndustry, setStartupIndustry] = useState([]);
  const [mentorIndustry, setMentorIndustry] = useState([]);
  const [investorIndustry, setInvestorIndustry] = useState([]);
  const [investment, setInvestment] = useState({
    investedAmount: "",
    futureInvestmentAmount: "",
  });

  const businessDetailsApiCall = (data) => {
    document.getElementById("duplicate-error").classList.add("tw-hidden");
    document.getElementById("category-error").classList.add("tw-hidden");
    document.getElementById("category-limit").classList.add("tw-hidden");
    if (startupIndustry.length < 1) {
      document.getElementById("category-error").classList.remove("tw-hidden");
      return;
    }
    if (startupIndustry.length > 3) {
      document.getElementById("category-limit").classList.remove("tw-hidden");
      return;
    }
    fetchApi(
      {
        url: businessDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          company_name: data.companyName,
          startup_industry: startupIndustry.join(","),
          website: data.website,
          require_investment: data.requireInvestment,
          linked_in: data.startupLinkedin,
        },
      },
      (res) => {
        if (res.message === "validation error") {
          alert("Sorry, Failed to submit. Validation failed");
          return;
        }
        if (res.message === "duplicate") {
          document
            .getElementById("duplicate-error")
            .classList.remove("tw-hidden");
          return;
        }
        navigate("/startup-profile");
      }
    );
  };

  const mentorDetailsApiCall = (data) => {
    document.getElementById("mentor-category-error").classList.add("tw-hidden");
    document.getElementById("mentor-category-limit").classList.add("tw-hidden");
    if (mentorIndustry.length < 1) {
      document
        .getElementById("mentor-category-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (mentorIndustry.length > 3) {
      document
        .getElementById("mentor-category-limit")
        .classList.remove("tw-hidden");
      return;
    }
    fetchApi(
      {
        url: mentorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          work_experience: data.work_experience,
          mentor_industry: mentorIndustry.join(","),
          previous_company: data.previous_company,
          short_description: data.short_description,
        },
      },
      (res) => {
        if (res.message === "validation error") {
          alert("Sorry, Failed to submit. Validation failed");
          return;
        }
        navigate("/mentor-profile");
      }
    );
  };

  const investorDetailsApiCall = (data) => {
    document.getElementById("invested-amount-error").classList.add("tw-hidden");
    document.getElementById("future-amount-error").classList.add("tw-hidden");
    document
      .getElementById("investor-category-error")
      .classList.add("tw-hidden");
    document.getElementById("industry-limit").classList.add("tw-hidden");

    if (investment.investedAmount === "") {
      document
        .getElementById("invested-amount-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (investment.futureInvestmentAmount === "") {
      document
        .getElementById("future-amount-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (investorIndustry.length < 1) {
      document
        .getElementById("investor-category-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (investorIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("tw-hidden");
      return;
    }
    fetchApi(
      {
        url: investorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          companies_invested_count: data.numberOfInvestments,
          invested_amount: investment.investedAmount,
          investor_industries: investorIndustry.join(","),
          future_investment_amount: investment.futureInvestmentAmount,
          linked_in: data.investorLinkedin,
        },
      },
      (res) => {
        if (res.message === "validation error") {
          alert("Sorry, Failed to submit. Validation failed");
          return;
        }
        navigate("/investor-profile");
      }
    );
  };

  const vendorDetailsApiCall = (data) => {
    document.getElementById("missing-expertise").classList.add("tw-hidden");
    document.getElementById("expertise-limit").classList.add("tw-hidden");
    if (expertise.length < 1) {
      document
        .getElementById("missing-expertise")
        .classList.remove("tw-hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("expertise-limit").classList.remove("tw-hidden");
      return;
    }
    fetchApi(
      {
        url: vendorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          company_name: data.vendorCompany,
          description: data.vendorBio,
          expert_in: expertise.join(","),
        },
      },
      (res) => {
        if (res.message === "validation error") {
          alert("Sorry, Failed to submit. Validation failed");
          return;
        }
        navigate("/vendor-profile");
      }
    );
  };

  useEffect(() => {
    CheckRole(getRole);
    if (getRole === "V&iR8") {
      displayIndustries(
        (data) => setIndustries(data.industries),
        "service provider"
      );
    } else {
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
  }, [CheckRole, getRole, displayIndustries]);

  const style = {
    filter: "blur(1px)",
    pointerEvents: "none",
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
        style={style}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} style={style} />
        <section className="tw-p-5 tw-flex tw-items-center tw-justify-center">
          {role === 1 ? (
            <div
              className="tw-w-full tw-bg-white tw-shadow tw-card tw-pb-3"
              id="startup-details-form"
            >
              <h4 className="tw-text-center tw-my-4">
                Please complete your profile to continue
              </h4>
              <small
                className="tw-text-red-500 tw-mb-2 tw-hidden"
                id="fillDetailsText"
              >
                Please fill in all the mandatory(*) details.
              </small>
              {error && (
                <p className="tw-text-red-500 tw-text-sm tw-my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                onSubmit={handleSubmit(businessDetailsApiCall)}
                className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3"
              >
                <div className="tw-mb-1">
                  <label htmlFor="companyName" className="small mb-0">
                    Company Name*
                  </label>
                  <div
                    className="tw-flex"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="text"
                      className="tw-border-0 tw-w-full tw-ps-3"
                      id="companyName"
                      name="companyName"
                      placeholder="Enter your Company Name"
                      {...register("companyName", {
                        required: "Company Name is required",
                      })}
                    />
                    <span className="input-icons icon-span">
                      <BuildingIcon />
                    </span>
                  </div>
                  {errors.companyName && (
                    <div>
                      <FormError message={errors.companyName.message} />
                    </div>
                  )}
                </div>
                <div className="tw-mb-1 tw-flex-col">
                  <p className="tw-text-sm">
                    Industry* <span>({startupIndustry.length + "/3"})</span>
                    <span
                      id="category-limit"
                      className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
                    >
                      Limit exceeded
                    </span>
                  </p>
                  <Dropdown
                    options={industries?.map((industry) => ({
                      label: industry.industry_name
                        .replace(new RegExp("_", "g"), " ")
                        .toUpperCase(),
                      value: industry.industry_name,
                    }))}
                    onChange={(selectedOptions) =>
                      setStartupIndustry(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    isMulti
                  />
                  <small
                    className="tw-text-red-500 tw-mb-2 tw-hidden"
                    id="category-error"
                  >
                    This field is mandatory.
                  </small>
                </div>
                <div className="tw-mb-1">
                  <label htmlFor="ceoDetails" className="tw-mb-0 tw-text-sm">
                    Website Link
                  </label>
                  <div
                    className="tw-flex"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="text"
                      className="tw-border-0 tw-w-full tw-ps-3"
                      id="website"
                      name="website"
                      placeholder="Websites URL"
                      {...register("website", {
                        validate: (fieldValue) => {
                          return fieldValue.length > 0
                            ? urlRegex.test(fieldValue) ||
                                "Please enter a valid url"
                            : null;
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <RedirectIcon />
                    </span>
                  </div>
                  {errors.website && (
                    <div>
                      <FormError message={errors.website.message} />
                    </div>
                  )}
                  <span
                    id="duplicate-error"
                    className="tw-hidden tw-text-red-500 tw-text-sm"
                  >
                    website already exists.
                  </span>
                </div>
                <div className="tw-mb-1">
                  <label
                    htmlFor="requireInvestment"
                    className="tw-mb-0 tw-text-sm"
                  >
                    How much investment are you looking for(₹)*
                  </label>
                  <div
                    className="tw-flex"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="text"
                      className="tw-border-0 tw-w-full tw-ps-3"
                      id="requireInvestment"
                      name="requireInvestment"
                      placeholder="ex: 500000"
                      {...register("requireInvestment", {
                        required: "Required investment is required",
                        pattern: {
                          value: numberRegex,
                          message: "Amount must only contain digits",
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <RupeeIcon />
                    </span>
                  </div>
                  {errors.requireInvestment && (
                    <div>
                      <FormError message={errors.requireInvestment.message} />
                    </div>
                  )}
                </div>
                <div className="tw-mb-1">
                  <label
                    htmlFor="startupLinkedin"
                    className="tw-mb-0 tw-text-sm"
                  >
                    Linkedin profile{" "}
                    <small>
                      ( LinkedIn profiles boost authenticity and
                      credibility—highly recommended. )
                    </small>
                  </label>
                  <div
                    className="tw-flex"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="text"
                      className="tw-border-0 tw-w-full tw-ps-3"
                      id="startupLinkedin"
                      name="startupLinkedin"
                      placeholder="LinkedIn URL"
                      {...register("startupLinkedin", {
                        validate: (fieldValue) => {
                          return fieldValue.length > 0
                            ? urlRegex.test(fieldValue) ||
                                "Please enter a valid url"
                            : null;
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <LinkedInIcon />
                    </span>
                  </div>
                  {errors.startupLinkedin && (
                    <div>
                      <FormError message={errors.startupLinkedin.message} />
                    </div>
                  )}
                </div>
                <div className="tw-flex tw-items-center">
                  <Button type="submit" className="tw-py-2.5 tw-w-full">
                    Proceed
                  </Button>
                </div>
              </form>
            </div>
          ) : role === 2 ? (
            <div
              className="tw-w-full tw-bg-white tw-shadow tw-card"
              id="mentor-details-form"
            >
              <h4 className="tw-text-center tw-my-4">
                Please complete your profile to continue
              </h4>
              <small
                className="tw-text-red-500 tw-mb-2 tw-hidden"
                id="fillDetailsText"
              >
                Please fill in all the mandatory(*) details.
              </small>
              {error && (
                <p className="tw-text-red-500 tw-text-sm tw-my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                onSubmit={mentorSubmit(mentorDetailsApiCall)}
                className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3"
                id="mentor-details"
              >
                <div>
                  <div className="tw-mb-1">
                    <label
                      htmlFor="workExperience"
                      className="tw-mb-0 tw-text-sm"
                    >
                      Work experience(in years*)
                    </label>
                    <div
                      className="tw-flex"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="text"
                        className="tw-border-0 tw-w-full tw-ps-3"
                        id="work_experience"
                        name="work_experience"
                        placeholder="ex: 5"
                        autoFocus
                        {...mentorRegister("work_experience", {
                          required: "Work experience is required",
                          pattern: {
                            value: numberRegex,
                            message: "Number must only contain digits",
                          },
                        })}
                      />
                      <span className="input-icons icon-span">
                        <BriefCaseIcon />
                      </span>
                    </div>
                    {mentorError.work_experience && (
                      <div>
                        <FormError
                          message={mentorError.work_experience.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="tw-mb-1">
                    <label
                      htmlFor="mentorCompany"
                      className="tw-mb-0 tw-text-sm"
                    >
                      Current or Previous company Name*
                    </label>
                    <div
                      className="tw-flex"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="text"
                        className="tw-border-0 tw-w-full tw-ps-3"
                        id="previous_company"
                        name="previous_company"
                        placeholder="Enter company name"
                        {...mentorRegister("previous_company", {
                          required: "Company name is required",
                        })}
                      />
                      <span className="input-icons icon-span">
                        <BuildingIcon />
                      </span>
                    </div>
                    {mentorError.previous_company && (
                      <div>
                        <FormError
                          message={mentorError.previous_company.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="tw-mb-1 tw-flex-col">
                    <p className="tw-text-sm">
                      Industry* <span>({mentorIndustry.length + "/3"})</span>
                      <span
                        id="mentor-category-limit"
                        className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
                      >
                        Limit exceeded
                      </span>
                    </p>
                    <Dropdown
                      options={industries?.map((industry) => ({
                        label: industry.industry_name
                          .replace(new RegExp("_", "g"), " ")
                          .toUpperCase(),
                        value: industry.industry_name,
                      }))}
                      onChange={(selectedOptions) =>
                        setMentorIndustry(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      isMulti
                    />
                    <small
                      className="tw-text-red-500 tw-mb-2 tw-hidden"
                      id="mentor-category-error"
                    >
                      This field is mandatory.
                    </small>
                  </div>
                </div>
                <div>
                  <div className="tw-mb-4">
                    <label htmlFor="mentorBio" className="tw-mb-0 tw-text-sm">
                      Short bio about yourself*
                    </label>
                    <div className="totalInvestmentInput tw-w-full tw-border tw-rounded-sm tw-m-auto">
                      <textarea
                        name="short_description"
                        id="short_description"
                        cols="30"
                        rows="5"
                        className="tw-border-0 tw-w-full tw-ps-3 tw-pt-2"
                        placeholder="Write here..."
                        {...mentorRegister("short_description", {
                          required: "Short description is required",
                          minLength: {
                            value: 20,
                            message:
                              "Short description must be atleast 20 characters",
                          },
                        })}
                      ></textarea>
                    </div>
                    {mentorError.short_description && (
                      <div>
                        <FormError
                          message={mentorError.short_description.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="md:tw-col-start-2">
                    <Button type="submit" className="tw-py-1.5 tw-w-full">
                      Proceed
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          ) : role === 3 ? (
            <div
              className="tw-w-full tw-bg-white tw-shadow tw-card"
              id="investor-details-form"
            >
              <h4 className="tw-text-center tw-my-4">
                Please complete your profile to continue
              </h4>
              {error && (
                <p className="tw-text-red-500 tw-text-sm tw-my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                onSubmit={investorSubmit(investorDetailsApiCall)}
                className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3"
                id="investor-details"
              >
                <div>
                  <label
                    htmlFor="numberOfInvestments"
                    className="tw-mb-0 tw-text-sm"
                  >
                    Number of companies in your portfolio*
                  </label>
                  <div
                    className="tw-flex"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="number"
                      className="tw-border-0 tw-w-full tw-ps-3"
                      id="numberOfInvestments"
                      name="numberOfInvestments"
                      placeholder="ex: 3"
                      {...investorRegister("numberOfInvestments", {
                        required: "Portfolio count is required",
                        pattern: {
                          value: numberRegex,
                          message: "Count must only contain digits",
                        },
                      })}
                    />
                    <span className="input-icons icon-span tw-w-[39px]">
                      <MoneyUpTrendIcon />
                    </span>
                  </div>
                  {investorError.numberOfInvestments && (
                    <div>
                      <FormError
                        message={investorError.numberOfInvestments.message}
                      />
                    </div>
                  )}
                </div>
                <div className="tw-flex-col">
                  <p className="tw-text-sm">
                    Industry* <span>({investorIndustry.length + "/3"})</span>
                    <span
                      id="industry-limit"
                      className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
                    >
                      Limit exceeded
                    </span>
                  </p>
                  <Dropdown
                    options={industries?.map((industry) => ({
                      label: industry.industry_name
                        .replace(new RegExp("_", "g"), " ")
                        .toUpperCase(),
                      value: industry.industry_name,
                    }))}
                    onChange={(selectedOptions) =>
                      setInvestorIndustry(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    isMulti
                  />
                  <small
                    className="tw-text-red-500 tw-mb-2 tw-hidden"
                    id="investor-category-error"
                  >
                    This field is mandatory.
                  </small>
                </div>
                <div>
                  <label
                    htmlFor="investedAmount"
                    className="tw-mb-0 tw-text-sm"
                  >
                    Total Investment done till now*
                  </label>
                  <Dropdown
                    name="investedAmount"
                    id="investedAmount"
                    options={[
                      { label: "Not Applicable", value: "NA" },
                      { label: "Upto 5Lakh", value: "Upto 5Lakh" },
                      { label: "5Lakh-10Lakh", value: "5Lakh-10Lakh" },
                      { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
                      { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
                      { label: "50Lakh+", value: "More than 50Lakh" },
                    ]}
                    onChange={(selectedOption) =>
                      setInvestment((prev) => ({
                        ...prev,
                        investedAmount: selectedOption.value,
                      }))
                    }
                  />
                  <div className="tw-hidden" id="invested-amount-error">
                    <FormError message="Amount is required" />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="futureInvestment"
                    className="tw-mb-0 tw-text-sm"
                  >
                    How much are you looking to invest*
                  </label>
                  <Dropdown
                    name="futureInvestmentAmount"
                    id="futureInvestmentAmount"
                    options={[
                      { label: "Upto 5Lakh", value: "Upto 5Lakh" },
                      { label: "5Lakh-10Lakh", value: "5Lakh-10Lakh" },
                      { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
                      { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
                      { label: "50Lakh+", value: "More than 50Lakh" },
                    ]}
                    onChange={(selectedOption) =>
                      setInvestment((prev) => ({
                        ...prev,
                        futureInvestmentAmount: selectedOption.value,
                      }))
                    }
                  />
                  <div className="tw-hidden" id="future-amount-error">
                    <FormError message="Amount is required" />
                  </div>
                </div>
                <div className="tw-mb-1">
                  <label
                    htmlFor="investorLinkedin"
                    className="tw-mb-0 tw-text-sm"
                  >
                    Linkedin profile
                  </label>
                  <div
                    className="tw-flex"
                    style={{ border: "1px solid #ced4da" }}
                  >
                    <input
                      type="text"
                      className="tw-border-0 tw-w-full tw-ps-3"
                      id="investorLinkedin"
                      name="investorLinkedin"
                      placeholder="LinkedIn URL"
                      {...investorRegister("investorLinkedin", {
                        validate: (fieldValue) => {
                          return fieldValue.length > 0
                            ? urlRegex.test(fieldValue) ||
                                "Please enter a valid url"
                            : null;
                        },
                      })}
                    />
                    <span className="input-icons icon-span">
                      <LinkedInIcon />
                    </span>
                  </div>
                  {investorError.investorLinkedin && (
                    <div>
                      <FormError
                        message={investorError.investorLinkedin.message}
                      />
                    </div>
                  )}
                </div>
                <div className="tw-w-full md:tw-col-start-2 tw-self-center">
                  <Button type="submit" className="tw-py-2 tw-me-2 tw-w-full">
                    Proceed
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div
              className="tw-w-full tw-bg-white tw-shadow tw-card"
              id="vendor-details-form"
            >
              <h4 className="tw-text-center tw-my-4">
                Please complete your profile to continue
              </h4>
              {error && (
                <p className="tw-text-red-500 tw-text-sm tw-my-1">
                  Sorry, something went wrong. Please try again
                </p>
              )}
              <form
                className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3"
                id="vendor-details"
                onSubmit={vendorSubmit(vendorDetailsApiCall)}
              >
                <div>
                  <div>
                    <label
                      htmlFor="vendorCompany"
                      className="tw-mb-0 tw-text-sm"
                    >
                      Company Name
                    </label>
                    <div
                      className="tw-flex tw-mb-3"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <input
                        type="text"
                        className="tw-border-0 tw-w-full tw-ps-3"
                        id="vendorCompany"
                        name="vendorCompany"
                        placeholder="Enter company name"
                        {...vendorRegister("vendorCompany", {
                          required: "company name is required",
                        })}
                      />
                      <span className="input-icons icon-span">
                        <BuildingIcon />
                      </span>
                    </div>
                    {vendorError.vendorCompany && (
                      <div>
                        <FormError
                          message={vendorError.vendorCompany.message}
                        />
                      </div>
                    )}
                  </div>
                  <div className="tw-flex-col">
                    <p className="tw-text-sm">
                      Company expertise
                      <span>({expertise.length + "/3"})</span>
                      <span
                        id="expertise-limit"
                        className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
                      >
                        Limit exceeded
                      </span>
                    </p>
                    <Dropdown
                      options={industries?.map((industry) => ({
                        label: industry.industry_name
                          .replace(new RegExp("_", "g"), " ")
                          .toUpperCase(),
                        value: industry.industry_name,
                      }))}
                      onChange={(selectedOptions) =>
                        setExpertise(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      isMulti
                    />
                  </div>
                  <p
                    id="missing-expertise"
                    className="tw-hidden tw-mt-1 tw-text-red-500 tw-text-sm"
                  >
                    This field is mandatory
                  </p>
                </div>
                <div>
                  <div>
                    <label htmlFor="vendorBio" className="tw-mb-0 tw-text-sm">
                      Short description about your company
                    </label>
                    <div
                      className="tw-flex tw-mb-3"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <textarea
                        name="vendorBio"
                        id="vendorBio"
                        cols="30"
                        rows="5"
                        placeholder="Write here..."
                        className="tw-border-0 tw-w-full tw-ps-3 tw-pt-1"
                        {...vendorRegister("vendorBio", {
                          required: "Bio is required",
                          minLength: {
                            value: 15,
                            message: "Bio must be at least 15 characters",
                          },
                        })}
                      ></textarea>
                    </div>
                    {vendorError.vendorBio && (
                      <div>
                        <FormError message={vendorError.vendorBio.message} />
                      </div>
                    )}
                  </div>
                  <div className="tw-w-full md:tw-col-start-2">
                    <Button
                      type="submit"
                      className="tw-py-1.5 tw-me-2 tw-w-full"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default ProfileCompletion;
