import { Button, Pagination } from "../../../components/ui/Button";

const TicketTable = ({ page, setPage, data, type, handleModal }) => {
  let filterData;
  if (type === "All") {
    filterData = data;
  } else {
    filterData = data.filter((data) => data.support_type === type);
  }

  return (
    <>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-fixed tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-border tw-border-gray-200 tw-bg-slate-50">
            <tr className="tw-divide-x tw-divide-gray-200 tw-text-left">
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Name
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Date
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Category
              </th>
              <th className="tw-w-[40%] tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Title
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Status
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="tw-divide-y tw-divide-gray-200">
            {filterData.length > 0 ? (
              filterData.slice(page * 10 - 10, page * 10).map((ticket) => (
                <tr key={ticket.id} className="tw-divide-x tw-divide-gray-200">
                  <td className="tw-p-2 tw-text-xs tw-uppercase">
                    {ticket.name}{" "}
                    <span
                      className={`tw-w-[15px] tw-h-[15px] tw-text-white tw-rounded-full tw-text-center ${
                        ticket.role === "f@!3A"
                          ? "tw-bg-primary"
                          : ticket.role === "R$7s2"
                          ? "tw-bg-secondary"
                          : ticket.role === "Sw%aa"
                          ? "tw-bg-orange-600"
                          : "tw-bg-green-600"
                      }`}
                    >
                      {ticket.role === "f@!3A"
                        ? "S"
                        : ticket.role === "R$7s2"
                        ? "M"
                        : ticket.role === "Sw%aa"
                        ? "I"
                        : "V"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs">{ticket.date}</td>
                  <td className="tw-p-2 tw-text-xs tw-capitalize">
                    {ticket.support_type}
                  </td>
                  <td className="tw-p-2 tw-text-xs">{ticket.ticket_heading}</td>
                  <td className="tw-p-2 tw-text-xs">
                    <span
                      className={`tw-text-xs tw-font-medium tw-p-1.5 tw-rounded-lg ${
                        ticket.status === "0"
                          ? "tw-text-blue-800 tw-bg-blue-200"
                          : ticket.status === "1"
                          ? "tw-text-orange-800 tw-bg-orange-200"
                          : "tw-text-green-800 tw-bg-green-200"
                      }`}
                    >
                      {ticket.status === "0"
                        ? "Pending"
                        : ticket.status === "1"
                        ? "Processing"
                        : "Solved"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs">
                    <Button
                      onClick={() => handleModal(ticket)}
                      className="tw-p-1"
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan={7} className="tw-p-2 tw-text-xs">
                  No New Ticket
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          data={filterData}
          dataCount={10}
        />
      )}
    </>
  );
};

export default TicketTable;
