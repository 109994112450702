import React, { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import { TicketModal } from "../../components/ui/modals/ticket/ticketModal";
import { useNavigate } from "react-router-dom";
import ErrorImg from "../../images/500.svg";
import { Button } from "../../components/ui/Button";

export const Error500 = () => {
  const supportTicketApi = `${process.env.REACT_APP_API_URL}support-ticket`;
  const { fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const supportTicket = async (
    complaint_category,
    complaint_heading,
    complaint
  ) => {
    fetchApi(
      {
        url: supportTicketApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          support_type: complaint_category.replace("-", " "),
          ticket_heading: complaint_heading,
          query: complaint,
        },
      },
      () => {}
    );
  };

  const checkRole = () => {
    if (userRole === "f@!3A") {
      navigate("/startup-dashboard");
    } else if (userRole === "R$7s2") {
      navigate("/mentor-dashboard");
    } else if (userRole === "Sw%aa") {
      navigate("/investor-dashboard");
    } else {
      navigate("/vendor-profile");
    }
  };

  return (
    <div className="tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <img src={ErrorImg} alt="Error-500" width={450} />
      <p className="tw-mb-2 tw-font-medium">
        Sorry something went wrong. Please try again or raise a support ticket
        if the issue persists.
      </p>
      <div>
        <Button onClick={() => checkRole()} className="tw-px-2 tw-py-1 tw-mx-2">
          Dashboard
        </Button>
        <Button
          onClick={() => checkRole()}
          className="tw-px-2 tw-py-1 tw-mx-2"
          bgColor="tw-bg-[#F97316]"
        >
          Raise support ticket
        </Button>
      </div>
      <TicketModal
        open={open}
        onClose={() => setOpen(false)}
        supportTicket={supportTicket}
      />
    </div>
  );
};
