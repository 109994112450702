import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StartupSidebar } from "./startupSidebar";
import { MentorSidebar } from "./mentorSidebar";
import { InvestorSidebar } from "./investorSidebar";
import { VendorSidebar } from "./vendorSidebar";
import { TicketModal } from "../ui/modals/ticket/ticketModal";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import { CloseIcon } from "../../icons/icons";

export const ResponsiveSidebar = ({
  closeSidebar,
  supportTicket,
  role,
  style,
}) => {
  const [open, setOpen] = useState(false);

  const currPath = window.location.hash;
  const activeStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-bg-primary tw-text-white hover:tw-text-white tw-rounded-md tw-cursor-pointer";
  const regularStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-rounded-md tw-cursor-pointer";

  return (
    <div
      className="responsive tw-h-full tw-w-full tw-bg-[#f0f8ff] tw-text-left tw-shadow-lg"
      id="sidebar"
      style={style}
    >
      <CloseIcon
        onClick={closeSidebar}
        className="tw-absolute tw-left-[2%] md:-tw-left-[5%] tw-top-[5%] tw-bg-[#f0f8ff] tw-z-2 tw-text-[2.5rem]"
      />
      <div className="tw-flex tw-items-center tw-justify-center tw-py-2">
        <img src={pitchHereLogo} alt="logo" width={200} />
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          {role === 1 ? (
            <StartupSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : role === 2 ? (
            <MentorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : role === 3 ? (
            <InvestorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          ) : (
            <VendorSidebar
              currPath={currPath}
              activeStyle={activeStyle}
              regularStyle={regularStyle}
            />
          )}
        </div>
      </nav>
      <div className="tw-absolute tw-w-full tw-bottom-12 tw-mb-2 tw-text-center">
        <span
          onClick={() => setOpen(!open)}
          className="tw-w-11/12 tw-py-2.5 tw-px-4 tw-text-sm tw-bg-customOrange tw-text-white tw-rounded-md tw-cursor-pointer"
        >
          Raise Support Ticket
        </span>
      </div>
      <Link
        to="/"
        className="tw-absolute tw-w-full tw-bottom-0 tw-mb-2 tw-text-center"
      >
        <span className="tw-w-11/12 tw-py-2.5 tw-px-4 tw-text-sm tw-bg-primary tw-text-white tw-rounded-md tw-cursor-pointer">
          Log Out
        </span>
      </Link>
      <TicketModal
        open={open}
        onClose={() => setOpen(false)}
        supportTicket={supportTicket}
      />
    </div>
  );
};
