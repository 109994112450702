const WelcomeCard = ({ name }) => {
  return (
    <div className="tw-card tw-relative tw-px-5 tw-py-7 profile-box">
      <div className="media media-wrapper tw-justify-between">
        <div className="media-body">
          <div className="greeting-user">
            <h4 className="tw-font-semibold tw-text-white tw-capitalize">
              Hello, {name}
            </h4>
            <p className="tw-w-full">
              New to our platform ? Watch this tutorial explaining all about it.
            </p>
            <div className="tw-flex tw-items-center">
              <div className="tw-mt-6">
                <a
                  href="https://www.youtube.com/@PitchHere123"
                  target="_blank"
                  rel="noreferrer"
                  className="tw-px-3 tw-py-2 tw-text-sm tw-mx-1 tw-rounded-md button tw-bg-white tw-text-indigo-500"
                >
                  Tutorial
                </a>
              </div>
              <div className="tw-mt-6">
                <a
                  href="https://pitchhere.in/"
                  target="_blank"
                  rel="noreferrer"
                  className="tw-px-3 tw-py-2 tw-text-sm tw-mx-1 tw-rounded-md button tw-bg-white tw-text-indigo-500"
                >
                  Know more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cartoon">
        <img
          className="img-fluid tw-ms-auto lg:tw-ml-auto 2xl:tw-w-[70%]"
          src="../assets/images/dashboard/cartoon.svg"
          alt="vector women with lptop"
        />
      </div>
    </div>
  );
};

export default WelcomeCard;
