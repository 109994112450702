import React, {
  useState,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import "./dashboard.css";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { BarGraph } from "../../components/ui/graphs-charts/barGraph";
import LongCard from "../../components/ui/cards/longCard";
import SmallCard from "../../components/ui/cards/smallCard";
import SingleConnection from "./singleConnection";
import WelcomeCard from "../../components/ui/cards/welcomeCard";
import AmountCard from "../../components/ui/cards/amountCard";
import RecentInvestments from "./recentInvestment";
import rupeeIcon from "../../images/rupee-icon.svg";
import usersIcon from "../../images/users-icon.svg";
import addUserIcon from "../../images/add-user-icon.svg";

const InvestorDashBoard = () => {
  const investorDashboardApi = `${process.env.REACT_APP_API_URL}investor-dashboard`;
  const { isLoading, error, fetchApi } = useFetch();
  const navigate = useNavigate();
  const { token, userRole, name } = useLocalStorage();
  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);

  const initialState = {
    name: "",
    profile_completion: 0,
    connection_count: 0,
    total_investment: 0,
    incoming_requests: 0,
    investors: 0,
    mentors: 0,
    startups: 0,
    recentInvestments: [],
    recentStartups: [],
    barGraphData: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "recent-startups":
        return {
          ...state,
          recentStartups: [...state.recentStartups, ...action.payload],
        };
      case "recent-investments":
        return {
          ...state,
          recentInvestments: [...state.recentInvestments, ...action.payload],
        };
      case "update-platform-stats":
        return {
          ...state,
          startups: action.payload.startups,
          mentors: action.payload.mentors,
          investors: action.payload.investors,
        };
      case "update-user":
        return {
          ...state,
          name: action.payload.name,
          profile_completion: action.payload.profile_completion,
          total_investment: action.payload.total_investment || 0,
          connection_count: action.payload.connection_count,
          incoming_requests: action.payload.incoming_requests || 0,
        };
      case "update-bar-graph":
        return {
          ...state,
          barGraphData: action.payload.barGraphData,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getInvestorData = (data) => {
    dispatch({ type: "recent-startups", payload: data.recent_startup });
    dispatch({ type: "recent-investments", payload: data.agreement_details });
    dispatch({
      type: "update-platform-stats",
      payload: {
        startups: data.startup_count,
        mentors: data.mentor_count,
        investors: data.investor_count,
      },
    });
    dispatch({
      type: "update-user",
      payload: {
        name: data.name,
        total_investment: data.total_investment,
        withdrawable_amount: data.wallet_points,
        profile_completion: data.profile_completion,
        connection_count: data.connection_count,
      },
    });
    dispatch({
      type: "update-bar-graph",
      payload: {
        barGraphData: data.industries_count,
      },
    });
  };

  const investorDashboard = useCallback(() => {
    fetchApi(
      {
        url: investorDashboardApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        getInvestorData(data);
      }
    );
  }, [investorDashboardApi, fetchApi, token]);

  useEffect(() => {
    if (userRole !== "Sw%aa") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      investorDashboard();
    }
    return () => {
      ref.current = false;
    };
  }, [investorDashboard, userRole, navigate]);

  const ViewProfile = (connectionId, name) => {
    navigate(`/view-startup/${name}/${connectionId}`, {});
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-6">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="tw-grid tw-grid-cols-6 tw-gap-3 widget-grid">
              <div className="tw-col-span-6 lg:tw-col-span-3">
                <WelcomeCard name={name} />
                <AmountCard
                  userRole={userRole}
                  profile_completion={state.profile_completion}
                />
              </div>
              <div className="tw-col-span-6 lg:tw-col-span-3 tw-card tw-shadow tw-rounded-md">
                <h5 className="tw-text-lg tw-font-medium">
                  Popular startup industries
                </h5>
                <div className="tw-px-0 cstm-svg-pos tw-uppercase">
                  <BarGraph data={state.barGraphData} />
                </div>
              </div>
              <LongCard
                data={{
                  title: "Total Investment",
                  count: state.total_investment,
                }}
                img={rupeeIcon}
                alt="rupee-icon"
                gradient="primary"
              />
              <LongCard
                data={{
                  title: "Incoming Requests",
                  count: state.incoming_requests,
                }}
                img={addUserIcon}
                alt="add-user-icon"
                gradient="success"
              />
              <LongCard
                data={{ title: "Connections", count: state.connection_count }}
                img={usersIcon}
                alt="people-icon"
              />
              <div className="tw-col-span-6 sm:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Registered Investors",
                    count: state.investors,
                  }}
                  icon="investor"
                  gradient="pink-icon"
                  buttonData={{
                    link: "investors",
                    title: "View Investors",
                  }}
                />
              </div>
              <div className="tw-col-span-6 sm:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Registered Startups",
                    count: state.startups,
                  }}
                  icon="startup"
                  gradient="green-icon"
                  buttonData={{
                    link: "startups",
                    title: "View Startups",
                  }}
                />
              </div>
              <div className="tw-col-span-6 sm:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Registered Mentors",
                    count: state.mentors,
                  }}
                  icon="mentor"
                  gradient="orange-icon"
                  buttonData={{
                    link: "mentors",
                    title: "View Mentors",
                  }}
                />
              </div>
              <div className="tw-col-span-6 lg:tw-col-span-4">
                <div className="tw-border-b-0 tw-ps-2">
                  <h5 className="tw-text-lg tw-font-medium">
                    Recent Investments
                  </h5>
                </div>
                <div className="tw-py-3 md:tw-px-3">
                  <RecentInvestments data={state.recentInvestments} />
                </div>
              </div>
              <div className="tw-col-span-6 lg:tw-col-span-2">
                <div className="tw-card tw-shadow tw-rounded-md">
                  <div className="tw-border-b tw-border-slate-300 tw-pb-2">
                    <h5 className="tw-text-lg tw-font-medium">New Startups</h5>
                  </div>
                  <div className="tw-py-3">
                    <div className="tw-grid tw-gap-2">
                      {state.recentStartups &&
                      state.recentStartups.length > 0 ? (
                        state.recentStartups.map((startup) => (
                          <SingleConnection
                            key={startup.user_id}
                            data={startup}
                            ViewProfile={ViewProfile}
                            userRole={userRole}
                            userId={startup.user_id}
                          />
                        ))
                      ) : (
                        <>
                          <p>Make your first connection now.</p>
                          <Link
                            to="/startups"
                            className="tw-w-fit tw-p-1 tw-bg-primary tw-text-white tw-rounded-md hover:tw-text-white"
                          >
                            Startups
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestorDashBoard;
