import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { useToastContext } from "../../context/ToastContext";

const Agreement = () => {
  const sendAgreementApi = `${process.env.REACT_APP_API_URL}send-agreement`;
  const agreementApi = `${process.env.REACT_APP_API_URL}agreement`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const { sendNotification } = useNotificationApi();
  const toast = useToastContext();
  const location = useLocation();
  const navigate = useNavigate();

  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [agreementData, setAgreementData] = useState({
    date: "",
    investor_name: "",
    investor_phone: "",
    investor_email: "",
    investor_address: "",
    startup_name: "",
    startup_email: "",
    startup_address: "",
    startup_phone: "",
    startup_description: "",
  });

  const agreement = useCallback(() => {
    fetchApi(
      {
        url: agreementApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          startup_id: location.state.startupId,
        },
      },
      (data) => {
        setAgreementData({
          date: data.date,
          investor_name: data.investor_name,
          investor_phone: data.investor_phone,
          investor_email: data.investor_email,
          investor_address: data.investor_address,
          startup_name: data.startup_name,
          startup_email: data.startup_email,
          startup_address: data.startup_address,
          startup_phone: data.startup_phone,
          startup_description: data.startup_description,
        });
      }
    );
  }, [token, fetchApi, agreementApi, location.state.startupId]);

  useEffect(() => {
    if (ref.current) {
      agreement();
    }
    return () => {
      ref.current = false;
    };
  }, [agreement]);

  const sendAgreement = async (e) => {
    e.preventDefault();
    fetchApi(
      {
        url: sendAgreementApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          startup_id: location.state.startupId,
          equity: location.state.equity,
          amount: location.state.amount,
        },
      },
      (data) => {
        if (data.message === "agreement already exists") {
          toast.open("warning", "Agreement already exists");
          if (userRole === "f@!3A") {
            navigate("/startup-investments");
          } else {
            navigate("/investor-investments");
          }
          return;
        }
        sendNotification(
          location.state.startupId,
          `You have recieved an investment agreement from ${name}`,
          "investments"
        );
        if (userRole === "f@!3A") {
          navigate("/startup-investments");
        } else {
          navigate("/investor-investments");
        }
      }
    );
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-4">
          <h4 className="tw-pb-7 tw-text-lg tw-font-semibold tw-text-center">
            Investment Agreement
          </h4>
          <p className="tw-my-2">
            THIS INVESTMENT AGREEMENT (the "Agreement") is entered into as of{" "}
            {agreementData.date}, (the "Effective Date") by and between:
          </p>
          <div className="tw-my-2">
            <p className="tw-font-semibold">INVESTOR :</p>
            <p className="tw-font-medium">
              Name :{" "}
              <span className="tw-font-normal">
                {agreementData.investor_name}
              </span>
            </p>
            <p className="tw-font-medium">
              Email:{" "}
              <span className="tw-font-normal">
                {agreementData.investor_email}
              </span>
            </p>
            <p className="tw-font-medium">
              Phone:{" "}
              <span className="tw-font-normal">
                {agreementData.investor_phone}
              </span>
            </p>
            <p className="tw-font-medium">
              Address:{" "}
              <span className="tw-font-normal">
                {agreementData.investor_address}
              </span>
            </p>
          </div>
          <div className="tw-my-2">
            <p className="tw-font-semibold">STARTUP :</p>
            <p className="tw-font-medium">
              Name :{" "}
              <span className="tw-font-normal">
                {agreementData.startup_name}
              </span>
            </p>
            <p className="tw-font-medium">
              Email:{" "}
              <span className="tw-font-normal">
                {agreementData.startup_email}
              </span>
            </p>
            <p className="tw-font-medium">
              Phone:{" "}
              <span className="tw-font-normal">
                {agreementData.startup_phone}
              </span>
            </p>
            <p className="tw-font-medium">
              Address:{" "}
              <span className="tw-font-normal">
                {agreementData.startup_address}
              </span>
            </p>
          </div>
          <div>
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              BACKGROUND :
            </h6>
            <p className="tw-mb-2">
              WHEREAS, the Startup is in the business of{" "}
              {agreementData.startup_description};
            </p>
            <p className="tw-mb-2">
              WHEREAS, the Investor desires to invest in the Startup, and the
              Startup desires to secure funding for its business operations and
              expansion;
            </p>
            <p className="tw-mb-2">
              NOW, THEREFORE, in consideration of the mutual covenants contained
              herein, the Investor and the Startup agree as follows:
            </p>
          </div>
          <div>
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              1. INVESTMENT :
            </h6>
            <p className="tw-mb-2">
              1.1 Amount: The Investor agrees to invest {location.state.amount}{" "}
              in Rupees (the "Investment") in the Startup.
            </p>
            <p className="tw-mb-2">
              1.2 Equity: In consideration for the Investment, the Startup shall
              issue to the Investor {location.state.equity}% equity ownership in
              the Startup.
            </p>
          </div>
          <div>
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              2. USE OF FUNDS :
            </h6>
            <p className="tw-mb-2">
              The Startup shall use the Investment for [Specify Purpose, e.g.,
              product development, marketing, working capital, etc.].
            </p>
          </div>
          <div className="tw-my-2">
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              3. CLOSING :
            </h6>
            <p className="tw-mb-2">
              Payment: "The Investor shall transfer the investment amount to the
              designated bank account of the platform (PitchHere) on the Closing
              Date. The startup's account on the platform will be updated with
              the same amount as the available balance for withdrawal. The
              startup needs to make a withdrawal request to the platform, and
              the amount will be transferred to the startup’s designated bank
              account within 3-4 working days after the deduction of platform
              fees, as follows:
            </p>
            <table className="tw-w-3/6 tw-mx-auto tw-mt-5 tw-auto tw-border-collapse tw-border tw-border-slate-300 tw-text-center">
              <thead>
                <tr>
                  <th className="tw-border tw-border-slate-300 tw-p-1.5">
                    Investment Amount (INR)
                  </th>
                  <th className="tw-border tw-border-slate-300 tw-p-1.5">
                    Platform Fee
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">
                    Amount &lt;= 15,00,000
                  </td>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">5%</td>
                </tr>
                <tr>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">
                    15,00,000 &lt; Amount &lt;= 25,00,000{" "}
                  </td>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">3%</td>
                </tr>
                <tr>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">
                    25,00,000 &lt; Amount &lt;= 55,00,000{" "}
                  </td>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">
                    2.5%
                  </td>
                </tr>
                <tr>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">
                    Amount &gt; 55,00,000{" "}
                  </td>
                  <td className="tw-border tw-border-slate-300 tw-p-1.5">2%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tw-my-2">
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              4. REPRESENTATIONS AND WARRANTIES :
            </h6>
            <p className="tw-mb-1">
              4.1 By the Startup: The Startup represents and warrants that:
            </p>
            <p className="tw-mb-1">
              It is duly organized, validly existing, and in good standing under
              the laws of its jurisdiction.
            </p>
            <p className="tw-mb-1">
              The execution, delivery, and performance of this Agreement have
              been duly authorized.
            </p>
            <p className="tw-mb-2">
              It has the right to issue the equity and there are no existing
              claims or encumbrances on the equity.
            </p>
            <p className="tw-mb-1">
              4.2 By the Investor: The Investor represents and warrants that:
            </p>
            <p className="tw-mb-1">
              It has the legal capacity and authority to enter into this
              Agreement.
            </p>
            <p className="tw-mb-1">
              The funds used for the Investment are not derived from illegal
              activities.
            </p>
          </div>
          <div className="tw-my-2">
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              5. GOVERNING LAW :
            </h6>
            <p className="tw-mb-2">
              This Agreement shall be governed by and construed in accordance
              with the laws of India.
            </p>
          </div>
          <div className="tw-my-2">
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              6. CONFIDENTIALITY :
            </h6>
            <p className="tw-mb-2">
              Both parties agree to keep confidential all information obtained
              during the course of this investment, except as required by law.
            </p>
          </div>
          <div className="tw-my-2">
            <h6 className="tw-text-sm tw-mb-2 tw-pt-5 tw-font-semibold">
              7. MISCELLANEOUS :
            </h6>
            <p className="tw-mb-2">
              7.1 Notices: All notices and other communications required or
              permitted by this Agreement shall be in writing and delivered to
              the addresses listed at the beginning of this Agreement.
            </p>
            <p className="tw-mb-2">
              7.2 Amendments: No amendment, modification, or waiver of any
              provision of this Agreement shall be effective unless in writing
              and signed by both parties.
            </p>
          </div>
          <p className="tw-my-2">
            IN WITNESS WHEREOF, the Investor and the Startup have executed this
            Investment Agreement as of the Effective Date.
          </p>
          <div className="tw-my-2">
            <p className="tw-font-semibold">INVESTOR :</p>
            <p className="tw-font-medium">
              Name :{" "}
              <span className="tw-font-normal">
                {agreementData.investor_name}
              </span>
            </p>
            <p className="tw-font-medium">Signature : </p>
          </div>
          <div className="tw-my-2">
            <p className="tw-font-semibold">STARTUP :</p>
            <p className="tw-font-medium">
              Name :{" "}
              <span className="tw-font-normal">
                {agreementData.startup_name}
              </span>
            </p>
            <p className="tw-font-medium">Signature : </p>
          </div>
          <div className="tw-mb-2 tw-mt-5 tw-space-y-2">
            <p className="tw-font-semibold">Investment Disclaimer: </p>
            <p>
              PitchHere is a facilitator for connecting startups with potential
              investors on its funding platform. We do not assume responsibility
              for any investment agreements entered into between startups and
              investors using our platform. The terms, conditions, and specifics
              of investment deals, including but not limited to term sheets, are
              to be discussed, negotiated, and agreed upon solely between the
              startup and the investor.{" "}
            </p>
            <p>
              Once both parties mutually agree on the terms outlined in the term
              sheet, it is the responsibility of the investor to release the
              funds as per the agreed-upon terms. PitchHere does not act as a
              party to any investment agreement and shall not be held liable for
              the outcomes or consequences arising from such agreements.{" "}
            </p>
            <p>
              Additionally, PitchHere disclaims any responsibility for the
              subsequent growth or performance of the startup and any associated
              investment profits or losses. The decision to invest rests solely
              with the investor, and the startup's success or failure is
              independent of PitchHere's involvement.{" "}
            </p>
            <p>
              Users of the PitchHere platform are advised to conduct thorough
              due diligence, seek legal advice, and exercise prudence when
              entering into investment agreements. PitchHere encourages
              transparency, communication, and fair dealings between startups
              and investors but does not warrant or guarantee any specific
              investment outcomes.{" "}
            </p>
            <p className="tw-font-semibold">
              PitchHere emphasizes that this investment agreement is NOT a legal
              document and serves as a mutual agreement to initiate discussions.
              The final legal document, including but not limited to investment
              agreements and term sheets, should be discussed, negotiated, and
              agreed upon exclusively between the startup and the investor.{" "}
            </p>
            <p>
              By utilizing the PitchHere platform, users acknowledge and agree
              to the terms outlined in this investment disclaimer. It is
              recommended to carefully review and understand these terms before
              engaging in any investment activities on the platform.{" "}
            </p>
          </div>
          <form className="tw-pt-5" onSubmit={sendAgreement}>
            <div className="tw-my-2 tw-flex tw-items-start">
              <input type="checkbox" className="tw-mt-1" required />
              <label className="tw-mb-0 tw-ps-1 tw-font-medium">
                I have carefully read and understood the terms and conditions,
                including the disclaimer, of the PitchHere Investment Agreement
                document. I hereby acknowledge my agreement to these terms and
                conditions, and I am fully aware that this mutual agreement
                serves as a preliminary understanding. I recognize that the
                final legal document should be discussed, negotiated, and agreed
                upon solely between the startup and the investor. By signing
                this document, I commit to engaging in good faith discussions
                and negotiations in pursuit of a comprehensive and legally
                binding investment agreement.
              </label>
            </div>
            {isLoading && (
              <div className="tw-my-1">
                <Loader />
              </div>
            )}
            <Button
              type="submit"
              className="tw-px-4 tw-py-1 tw-my-2 tw-text-sm"
            >
              Send agreement to startup
            </Button>
          </form>
          {error && (
            <p className="tw-my-2 tw-text-red-500 tw-text-sm">
              Sorry we were unable to send the agreement. Please try again
              later.
            </p>
          )}
        </section>
      </div>
    </div>
  );
};

export default Agreement;
