import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useUploadImage from "../../hooks/useUploadImage";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { ImportImage } from "../../utils/importImage";
import { randomId } from "../../utils/randomId";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { BankAccountModal } from "../../components/ui/modals/bank/bankAccountModal";
import { DeleteModal } from "../../components/ui/modals/utility-modals/deleteModal";
import { CertificateModal } from "../../components/ui/modals/profile/certificateModal";
import { EditStartupProfile } from "../../components/ui/modals/profile/editStartupProfile";
import { EditBankAccountModal } from "../../components/ui/modals/bank/editBankAccountModal";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import { StartupStageModal } from "../../components/ui/modals/profile/startupStageModal";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import certifiedLogo from "../../images/certified.png";
import verifiedLogo from "../../images/verified.png";
import { CameraIcon, DeleteIcon, FileIcon, PenIcon } from "../../icons/icons";

const StartupProfile = () => {
  const startupDetailsApi = `${process.env.REACT_APP_API_URL}display-startup-profile`;
  const editStartupProfileApi = `${process.env.REACT_APP_API_URL}update-startup-profile`;
  const deleteBankDetailsApi = `${process.env.REACT_APP_API_URL}delete-bank-details`;
  const uploadDocsApi = `${process.env.REACT_APP_API_URL}upload-documents`;
  const uploadVideoApi = `${process.env.REACT_APP_API_URL}upload-video`;
  const uploadCertificates = `${process.env.REACT_APP_API_URL}upload-certificates`;
  const deleteFileApi = `${process.env.REACT_APP_API_URL}delete-file`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { updateProfile } = useUpdateProfile();
  const { uploadImage } = useUploadImage();
  const { displayFiles } = useDisplayFiles();
  const navigate = useNavigate();
  const ref = useRef(true);
  const { displayIndustries } = useDisplayIndustries();

  const [startupDetails, setStartupDetails] = useState([]);
  const [editData, setEditData] = useState({});
  const [bankDetails, setBankDetails] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [idea, setIdea] = useState("");
  const [open, setOpen] = useState(false);
  const [editBankModal, setEditBankModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCertificateModal, setOpenCertificateModal] = useState(false);
  const [type, setType] = useState("add");
  const [fileType, setFileType] = useState("");
  const [documentType, setDocumentType] = useState("mandatory");
  const [id, setId] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [stageModal, setStageModal] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [singleBank, setSingleBank] = useState([]);
  const [files, setFiles] = useState({
    documents: [],
    msme: [],
    gst: [],
    incorporation: [],
    others: [],
    video: "",
  });

  const getStartupData = useCallback(async () => {
    fetchApi(
      {
        url: startupDetailsApi,
        method: "POST",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setStartupDetails(data.startup_profile);
        setEditData(...data.startup_profile);
        setProfileImg(data.startup_profile[0].logo);
        setIdea(data.startup_profile[0].description);
        setBankDetails(data.bank_details);
      }
    );
  }, [startupDetailsApi, token, fetchApi]);

  const addDocument = (newFile) => {
    const formData = new FormData();
    formData.append("pdf[]", newFile);
    axios
      .post(uploadDocsApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        displayFiles(postFilesApi);
      })
      .catch((err) => console.log(err));
  };

  const addVideo = (newFile) => {
    const formData = new FormData();
    formData.append("video", newFile);
    axios
      .post(uploadVideoApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        displayFiles(postFilesApi);
      })
      .catch((err) => console.log(err));
  };

  const addCertificates = (file, document_name) => {
    const formData = new FormData();
    formData.append("files[]", file);
    formData.append("document_name", document_name);
    axios
      .post(uploadCertificates, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        displayFiles(postFilesApi);
      })
      .catch((err) => console.log(err));
  };

  const deleteFile = (type, id) => {
    fetchApi(
      {
        url: deleteFileApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          type: type,
          id: id,
        },
      },
      () => displayFiles(postFilesApi)
    );
  };

  const deleteBankDetails = (id) => {
    fetchApi(
      {
        url: deleteBankDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      () => getStartupData()
    );
  };

  const editStartupDetails = (data, industry) => {
    let dataObj = {
      name: data.startupName,
      phone: data.startupPhone,
      company_name: data.companyName,
      require_investment: data.requireInvestment,
      website: data.companyWebsite,
      business_start_date: data.startingDate,
      no_of_employees: data.noofemployees,
      linked_in: data.startupLinkedin,
    };
    if (industry.length > 0) {
      dataObj = { ...dataObj, startup_industry: industry };
    }
    updateProfile(editStartupProfileApi, dataObj, getStartupData, "startup");
  };

  const editStartupStage = (data, previous_investment) => {
    fetchApi(
      {
        url: editStartupProfileApi,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          startup_stage: data.startupStage,
          previous_investment: previous_investment,
          investment_amount: data.prevInvestedAmount,
        },
      },
      (data) => {
        if (data.message === "validation error") {
          alert("Sorry, Failed to submit. Validation failed");
          return;
        }
        navigate("/startup-profile");
        getStartupData();
      }
    );
  };

  const postFilesApi = useCallback(
    (data) => {
      setFiles({
        documents: data.documents,
        msme: data.msme,
        gst: data.gst,
        incorporation: data.incorporation,
        others: data.others,
        video: data.video,
      });
      getStartupData();
    },
    [getStartupData]
  );

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getStartupData();
      displayFiles(postFilesApi);
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
    return () => {
      ref.current = false;
    };
  }, [
    getStartupData,
    displayFiles,
    navigate,
    userRole,
    postFilesApi,
    displayIndustries,
  ]);

  const handleModal = (type, id, data) => {
    setType(type);
    if (type === "add") {
      setOpen(true);
    } else if (type === "edit") {
      setId(id);
      setSingleBank(data);
      setEditBankModal(true);
    } else {
      setId("");
    }
  };

  const handleDeleteModal = (id, type, fileType) => {
    setType(type);
    setFileType(fileType);
    setId(id);
    setOpenDeleteModal(true);
  };

  const handleCeritificateModal = (type) => {
    setDocumentType(type);
    setOpenCertificateModal(true);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="md:tw-p-5">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <>
              <div
                className="tw-relative tw-hidden md:tw-block tw-bg-cover tw-h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div className="tw-bg-white tw-shadow-md md:tw-rounded-md">
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-5 tw-px-3 tw-gap-x-1">
                  <div className="tw-ml-auto md:tw-hidden">
                    <button
                      type="button"
                      className="profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-mx-1 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-text-xs md:tw-text-sm"
                      onClick={() => setProfileModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">
                    <div>
                      <img
                        src={profileImg.length > 0 ? profileImg : FavIcon}
                        className="profile-img tw-shadow"
                        alt="profile-img"
                      />
                      <div className="img-icon tw-bg-primary tw-p-1">
                        <label
                          htmlFor="upload-logo"
                          className="tw-mb-0 tw-cursor-pointer"
                        >
                          <CameraIcon fill="#fff" />
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="upload-logo"
                          hidden
                          onChange={(e) =>
                            uploadImage(e.target.files[0], getStartupData)
                          }
                        />
                      </div>
                    </div>
                    {profileImg.length < 1 && (
                      <p className="tw-text-red-500 tw-font-medium">
                        Upload image
                      </p>
                    )}
                  </div>
                  <div className="tw-relative md:tw-col-span-4 tw-pb-4 tw-mb-5 md:tw-pl-[3rem] 2xl:tw-pl-0">
                    <button
                      type="button"
                      className="tw-hidden md:tw-block profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-absolute tw-right-0 md:tw-top-unset md:tw-bottom-2.5 tw-text-xs md:tw-text-sm"
                      onClick={() => setProfileModal(true)}
                    >
                      Edit
                    </button>
                    {startupDetails &&
                      startupDetails.map((startup) => (
                        <div
                          key={startup.user_id}
                          className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-1 tw-mb-5 tw-pt-3 tw-mt-10 md:tw-mt-0"
                        >
                          <div className="md:tw-col-span-3 tw-grid tw-grid-cols-2">
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-font-semibold tw-flex tw-items-center">
                              Name :{" "}
                              <span className="tw-ms-1 tw-flex tw-items-center tw-font-medium tw-uppercase">
                                {startup.name}
                                {startupDetails.length > 0 && (
                                  <span className="tw-flex tw-items-center">
                                    {startupDetails[0].platform_verify ===
                                      "1" && (
                                      <img
                                        src={verifiedLogo}
                                        alt="PitchHere verification logo"
                                        width={30}
                                        title="Verified by PitchHere"
                                      />
                                    )}
                                    {startupDetails[0].verify_profile ===
                                      "1" && (
                                      <img
                                        src={certifiedLogo}
                                        alt="Mentor certification logo"
                                        width={30}
                                        title="Mentorship Completed"
                                      />
                                    )}
                                  </span>
                                )}
                              </span>
                            </p>
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-font-semibold">
                              Email :{" "}
                              <span className="tw-font-medium">
                                {startup.email}
                              </span>
                            </p>
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-font-semibold">
                              Company :{" "}
                              <span className="tw-font-medium tw-capitalize">
                                {startup.company_name}
                              </span>
                            </p>
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-font-semibold">
                              Desired Investment :{" "}
                              <span className="tw-font-medium">
                                ₹ {startup.require_investment}
                              </span>
                            </p>
                          </div>
                          <p className="tw-font-semibold">
                            Website :{" "}
                            <span className="tw-font-medium">
                              {startup.website.length > 0 ? (
                                <a
                                  href={"https://" + startup.website}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="tw-text-blue-500"
                                >
                                  URL
                                </a>
                              ) : (
                                <span className="tw-text-red-500 tw-text-sm">
                                  Missing
                                </span>
                              )}
                            </span>
                          </p>
                          <p className="tw-font-semibold">
                            LinkedIn :{" "}
                            <span className="tw-font-medium">
                              {startup.linked_in.length > 0 ? (
                                <a
                                  href={startup.linked_in}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="tw-text-blue-500"
                                >
                                  Profile
                                </a>
                              ) : (
                                <span className="tw-text-red-500 tw-text-sm">
                                  Missing
                                </span>
                              )}
                            </span>
                          </p>
                          <p className="tw-flex tw-items-center tw-font-semibold">
                            Address :{" "}
                            <span className="tw-flex tw-items-center tw-ms-1 tw-font-medium tw-capitalize">
                              {startup.country.length > 0 ? (
                                <>
                                  <span className="tw-pe-1">
                                    {startup.city}
                                  </span>
                                  <img
                                    src={
                                      startup.flag_path.length > 0 &&
                                      `${process.env.REACT_APP_API_URL}` +
                                        startup.flag_path.slice(6)
                                    }
                                    alt="country-flag"
                                    width={30}
                                    title={startup.country}
                                  />
                                </>
                              ) : (
                                <span className="tw-text-red-500 tw-text-sm">
                                  Missing
                                </span>
                              )}
                            </span>
                          </p>
                          <p className="md:tw-col-span-3 tw-font-semibold">
                            Industries :{" "}
                            <span className="tw-font-medium tw-ms-1">
                              {startup.startup_industry.map((industry, i) => (
                                <span className="outline-tags" key={i}>
                                  {industry.replace(new RegExp("_", "g"), " ")}
                                </span>
                              ))}
                            </span>
                          </p>
                          <p className="tw-mt-3 md:tw-col-span-3 tw-font-semibold tw-under tw-underline tw-underline-offset-2">
                            Note : How to update your profile.{" "}
                            <a
                              href="https://www.youtube.com/watch?v=2IFhheMdwCs"
                              target="_blank"
                              rel="noreferrer"
                              className="tw-text-blue-500"
                            >
                              Watch here.
                            </a>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mt-2 tw-gap-x-3">
                <div className="ideaContainer tw-p-3 tw-shadow-md tw-rounded-md tw-relative tw-h-full">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Description</h5>
                    <p
                      className=" tw-text-customOrange tw-cursor-pointer"
                      type="button"
                      onClick={() => setDescriptionModal(true)}
                    >
                      Write here
                    </p>
                  </div>
                  {idea ? (
                    <p className="tw-text-sm">{idea}</p>
                  ) : (
                    <p>Add short description about your company and work</p>
                  )}
                </div>
                <div className="more-info-container tw-shadow-md tw-rounded-md tw-p-3">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">More Information</h5>
                    <span
                      className="tw-text-customOrange tw-cursor-pointer"
                      onClick={() => setStageModal(true)}
                    >
                      Edit
                    </span>
                  </div>
                  {startupDetails &&
                    startupDetails.map((startup) => (
                      <div
                        className="tw-grid tw-grid-cols-2"
                        key={startup.user_id}
                      >
                        <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                          <p className="tw-mb-0 tw-font-bold tw-text-xs">
                            Startup Stage
                          </p>
                          <p className="tw-text-xs tw-capitalize">
                            {startup.startup_stage.replace("-", " ") || "N/A"}
                          </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                          <p className="tw-mb-0 tw-font-bold tw-text-xs">
                            Previous Investment
                          </p>
                          <p className="tw-text-xs tw-capitalize">
                            {startup.previous_investment || "N/A"}
                          </p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                          <p className="tw-mb-0 tw-font-bold tw-text-xs">
                            Invested Amount
                          </p>
                          <p className="tw-text-xs">
                            {startup.investment_amount || "N/A"}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mt-2 tw-gap-3">
                <div className="documentsContainer tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Pitch Deck (pdf*)</h5>
                    <label
                      className="tw-text-customOrange tw-cursor-pointer tw-mb-0 tw-text-sm"
                      htmlFor="startup-documents"
                    >
                      Upload documents
                    </label>
                    <input
                      type="file"
                      id="startup-documents"
                      accept="application/pdf,.pdf"
                      onChange={(e) => addDocument(e.target.files[0])}
                      hidden
                    />
                  </div>
                  {files.documents.length > 0 ? (
                    <div className="tw-grid tw-grid-cols-1">
                      {files.documents.map((document) => (
                        <div
                          key={document._id}
                          className="profile-detail-card tw-relative"
                        >
                          <Link
                            to={document.path}
                            target="_blank"
                            rel="noreferrer"
                            id="documents"
                            className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-rounded-md tw-bg-gray-200 hover:tw-opacity-50"
                          >
                            <span className="tw-font-medium">
                              {document.file_name}
                            </span>
                            <FileIcon />
                          </Link>
                          <DeleteIcon
                            className="delete-account-btn tw-absolute tw-right-[5%] tw-top-0 tw-z-5 tw-cursor-pointer"
                            onClick={() =>
                              handleDeleteModal(
                                document._id,
                                "file",
                                "document"
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No documents found, upload PDF.</p>
                  )}
                </div>
                <div className="certificatesContainer tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Certificates</h5>
                    <span
                      onClick={() => handleCeritificateModal("mandatory")}
                      className="tw-text-customOrange tw-cursor-pointer tw-mb-0 tw-text-sm"
                    >
                      Upload certificates
                    </span>
                  </div>
                  <div className="profile-detail-card tw-relative">
                    <Link
                      to={
                        files.incorporation.length > 0
                          ? files.incorporation[0].path
                          : "#"
                      }
                      target={
                        files.incorporation.length > 0 ? "_blank" : "_self"
                      }
                      rel="noopener noreferrer"
                      className={`tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-text-xs md:tw-text-sm tw-rounded-md tw-bg-gray-200 ${
                        files.incorporation.length > 0 && "hover:tw-opacity-50"
                      }`}
                    >
                      <span className="tw-font-medium">
                        Certificate of Incorporation
                      </span>
                      <span
                        className={
                          files.incorporation.length > 0
                            ? "tw-text-green-500"
                            : "tw-text-red-500"
                        }
                      >
                        {files.incorporation.length > 0 ? "Added" : "Missing"}
                      </span>
                    </Link>
                    {files.incorporation.length > 0 && (
                      <DeleteIcon
                        className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                        onClick={() =>
                          handleDeleteModal(
                            files.incorporation[0]._id,
                            "file",
                            "incorporation"
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="profile-detail-card tw-relative">
                    <Link
                      to={files.msme.length > 0 ? files.msme[0].path : "#"}
                      target={files.msme.length > 0 ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className={`tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-text-xs md:tw-text-sm tw-rounded-md tw-bg-gray-200 ${
                        files.msme.length > 0 && "hover:tw-opacity-50"
                      }`}
                    >
                      <span className="tw-font-medium">MSME Registration</span>
                      <span
                        className={
                          files.msme.length > 0
                            ? "tw-text-green-500"
                            : "tw-text-red-500"
                        }
                      >
                        {files.msme.length > 0 ? "Added" : "Missing"}
                      </span>
                    </Link>
                    {files.msme.length > 0 && (
                      <DeleteIcon
                        className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                        onClick={() =>
                          handleDeleteModal(files.msme[0]._id, "file", "msme")
                        }
                      />
                    )}
                  </div>
                  <div className="profile-detail-card tw-relative">
                    <Link
                      to={files.gst.length > 0 ? files.gst[0].path : "#"}
                      target={files.gst.length > 0 ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className={`tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-text-xs md:tw-text-sm tw-rounded-md tw-bg-gray-200 ${
                        files.gst.length > 0 && "hover:tw-opacity-50"
                      }`}
                    >
                      <span className="tw-font-medium">GST Registration</span>
                      <span
                        className={
                          files.gst.length > 0
                            ? "tw-text-green-500"
                            : "tw-text-red-500"
                        }
                      >
                        {files.gst.length > 0 ? "Added" : "Missing"}
                      </span>
                    </Link>
                    {files.gst.length > 0 && (
                      <DeleteIcon
                        className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                        onClick={() =>
                          handleDeleteModal(files.gst[0]._id, "file", "gst")
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="otherDocumentsContainer tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Other Documents</h5>
                    <span
                      onClick={() => handleCeritificateModal("other")}
                      className="tw-text-customOrange tw-cursor-pointer tw-mb-0 tw-text-sm"
                    >
                      Upload documents
                    </span>
                  </div>
                  {files.others.length > 0 ? (
                    <div className="tw-grid tw-grid-cols-1">
                      {files.others.map((file) => (
                        <div
                          key={file._id}
                          className="profile-detail-card tw-relative"
                        >
                          <Link
                            to={file.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            id="file"
                            className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-text-xs md:tw-text-sm tw-rounded-md tw-bg-gray-200 hover:tw-opacity-50"
                          >
                            <span className="tw-font-medium">
                              {file.document_name}
                            </span>
                            <FileIcon />
                          </Link>
                          <DeleteIcon
                            className="delete-account-btn tw-absolute tw-right-[5%] tw-top-0 tw-z-5 tw-cursor-pointer"
                            onClick={() =>
                              handleDeleteModal(file._id, "file", "other")
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No certificates found.</p>
                  )}
                </div>
                <div className="bankDetailsContainer tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Bank Details</h5>
                    {bankDetails && bankDetails.length < 1 && (
                      <p
                        className="tw-text-customOrange tw-cursor-pointer"
                        onClick={() => handleModal("add")}
                        type="button"
                      >
                        Add new
                      </p>
                    )}
                  </div>
                  <div
                    className="tw-grid tw-grid-cols-1 tw-mt-3"
                    id="bank-details"
                  >
                    {bankDetails && bankDetails.length > 0 ? (
                      bankDetails.map((bank) => (
                        <div
                          className="profile-detail-card tw-relative"
                          key={bank.id}
                        >
                          <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-rounded-md tw-bg-gray-200 hover:tw-opacity-50">
                            <div>
                              <p className="tw-font-semibold tw-text-xs md:tw-text-sm  tw-leading-relaxed">
                                Account Holder:
                                <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                                  {bank.name}
                                </span>
                              </p>
                              <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                                Account Number:
                                <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                                  {bank.account_number}
                                </span>
                              </p>
                            </div>
                            <ImportImage
                              imageName={bank.bank_name}
                              width={45}
                              alt={bank.bank_name}
                            />
                          </div>
                          <DeleteIcon
                            className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                            onClick={() => handleDeleteModal(bank.id, "bank")}
                          />
                          <PenIcon
                            fill="#22C55E"
                            className="edit-account-details tw-absolute tw-right-[10%] tw-top-0 tw-cursor-pointer"
                            onClick={() => handleModal("edit", bank.id, bank)}
                          />
                        </div>
                      ))
                    ) : (
                      <p id="noBankDetails">No Bank Account found.</p>
                    )}
                  </div>
                </div>
                <div className="videoContainer tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Video</h5>
                    <label
                      className="tw-text-customOrange tw-cursor-pointer tw-mb-0 tw-text-sm"
                      htmlFor="startup-video"
                    >
                      Upload video
                    </label>
                    <input
                      type="file"
                      id="startup-video"
                      accept="video/mp4,video/x-m4v,video/*"
                      hidden
                      onChange={(e) => addVideo(e.target.files[0])}
                    />
                  </div>
                  {files.video.length > 0 ? (
                    <div className="tw-grid tw-grid-cols-1 tw-mt-3">
                      <div className="tw-mt-4">
                        <video
                          controls
                          className="tw-w-full tw-h-[300px]"
                          id="videoFile"
                          src={files.video}
                        ></video>
                      </div>
                    </div>
                  ) : (
                    <p>No Video found.</p>
                  )}
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <BankAccountModal
        open={open}
        onClose={() => setOpen(false)}
        callBackFn={getStartupData}
      />
      <EditBankAccountModal
        key={id}
        open={editBankModal}
        onClose={() => setEditBankModal(false)}
        singleBank={singleBank}
        bankId={id}
        callBackFn={getStartupData}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deleteFile={deleteFile}
        deleteBankDetails={deleteBankDetails}
        type={type}
        fileType={fileType}
        id={id}
      />
      <CertificateModal
        open={openCertificateModal}
        onClose={() => setOpenCertificateModal(false)}
        addCertificates={addCertificates}
        documentType={documentType}
      />
      <EditStartupProfile
        key={randomId()}
        open={profileModal}
        onClose={() => setProfileModal(false)}
        editStartupDetails={editStartupDetails}
        editData={editData}
        industries={industries}
      />
      <DescriptionModal
        key={new Date()}
        open={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        callBackFn={() => getStartupData()}
        url={editStartupProfileApi}
        data={idea}
      />
      <StartupStageModal
        open={stageModal}
        onClose={() => setStageModal(false)}
        editStartupStage={editStartupStage}
      />
    </div>
  );
};

export default StartupProfile;
