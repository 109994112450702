import { Link } from "react-router-dom";
import AdminSidebarMain from "./adminSidebarMain";
import "../../Pages/dashboard/dashboard.css";
import "./sidebar.css";
import pitchHereLogo from "../../images/pitch-here-logo.png";
import { CloseIcon } from "../../icons/icons";

const ResponsiveAdminSidebar = ({ closeSidebar }) => {
  const currPath = window.location.hash;
  const activeStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-bg-primary tw-text-white hover:tw-text-white tw-rounded-md tw-cursor-pointer";
  const regularStyle =
    "tw-py-2.5 tw-my-1 tw-flex tw-items-center tw-px-2 tw-rounded-md tw-cursor-pointer";

  return (
    <div
      className="responsive tw-h-full tw-bg-[#f0f8ff] tw-text-left tw-shadow-lg"
      id="sidebar"
    >
      <CloseIcon
        onClick={closeSidebar}
        className="tw-absolute tw-left-[2%] md:-tw-left-[5%] tw-top-[5%] tw-bg-[#f0f8ff] tw-z-2 tw-text-[2.5rem]"
      />
      <div className="tw-flex tw-items-center tw-justify-center tw-py-2">
        <img src={pitchHereLogo} alt="logo" width={200} />
      </div>
      <nav className="sidebar-main">
        <div className="sidebar-inner">
          <AdminSidebarMain
            currPath={currPath}
            activeStyle={activeStyle}
            regularStyle={regularStyle}
          />
        </div>
      </nav>
      <Link
        to="/"
        className="tw-absolute tw-w-full tw-bottom-0 tw-mb-2 tw-text-center"
      >
        <span className="tw-w-11/12 tw-py-2.5 tw-px-4 tw-text-sm tw-bg-customOrange tw-text-white tw-rounded-md tw-cursor-pointer">
          Log Out
        </span>
      </Link>
    </div>
  );
};

export default ResponsiveAdminSidebar;
