import ReactDom from "react-dom";
import parse from "html-react-parser";
import { Button } from "../Button";
import PolicyAndTerms from "../../../json-data/policyAndTerms.json";

export const PrivacyPolicy = ({ open, onClose }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-max-w-[50%] tw-h-[400px] tw-overflow-auto tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <h3 className="tw-font-medium tw-text-2xl">Privacy Policy</h3>
        <div className="tw-my-2">{parse(PolicyAndTerms.privacyPolicy)}</div>
        <h3 className="tw-font-medium tw-text-2xl tw-mt-5">Terms of Use</h3>
        <div className="tw-my-2">{parse(PolicyAndTerms.terms)}</div>
        <Button
          onClick={onClose}
          className="tw-block tw-px-2 tw-py-1 tw-my-4 tw-ml-auto"
        >
          Mark as Read
        </Button>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
