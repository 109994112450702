import React from "react";
import blurChat from "../../images/blur-chat.png";

const BlankPreview = () => {
  return (
    <div className="tw-h-full tw-shadow-md tw-rounded-md tw-overflow-hidden">
      <div className="chat !tw-bg-white">
        <div
          className="tw-h-full tw-bg-contain tw-flex tw-items-center tw-justify-center chat-history chat-msg-box custom-scrollbar"
          style={{
            backgroundImage: `url(${blurChat})`,
            backgroundSize: "cover",
            minHeight: "calc(100vh - 220px)",
          }}
        >
          <p className="tw-p-5 tw-font-semibold tw-rounded-md tw-bg-white tw-shadow-lg">
            Select a connection to start communicating
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlankPreview;
