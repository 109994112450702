import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import FavIcon from "../../../images/favicon.png";
import { Button } from "../Button";

export const AdCard = ({ ad, role, handleBidModal, credits }) => {
  const {
    ad_id,
    deadline,
    description,
    logo,
    max_budget,
    min_budget,
    tags,
    title,
    name,
    startup_id,
  } = ad;

  const navigate = useNavigate();

  const checkCredits = useCallback(() => {
    if (credits < 25) {
      alert("Don't have enough credits");
      navigate("/vendor-plan");
      return;
    }
    handleBidModal(ad_id, title, min_budget, startup_id);
  }, [navigate, credits, ad_id, title, min_budget, startup_id, handleBidModal]);

  return (
    <div className="tw-shadow tw-p-3 tw-rounded-md">
      <div className="tw-grid tw-grid-cols-6">
        <div className="tw-hidden md:tw-flex tw-flex-col tw-items-center">
          <img
            src={logo.length > 0 ? logo : FavIcon}
            alt="startup-logo"
            width={100}
            className="tw-h-[100px] tw-rounded-full tw-border tw-border-slate-400 tw-p-1"
          />
          <span className="tw-font-medium">{name}</span>
        </div>
        <div className="tw-col-span-6  md:tw-col-span-5 tw-px-2">
          <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mb-2">
            <span className="tw-order-2 tw-mb-2 tw-font-semibold tw-text-lg md:tw-order-1 md:tw-w-8/12 md:tw-mb-0">
              {title}
            </span>
            <div className="tw-order-1 tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mb-2 md:tw-order-2 md:tw-block">
              <p className="tw-text-xs md:tw-hidden">
                <span className="tw-font-semibold tw-mr-1">Author :</span>
                {name}
              </p>
              <p className="tw-text-xs">
                <span className="tw-font-semibold tw-mr-1">Budget :</span>₹
                {min_budget} - ₹{max_budget}
              </p>
              <p className="tw-text-xs">
                <span className="tw-font-semibold tw-mr-1">Duration :</span>
                {deadline} days
              </p>
            </div>
          </div>
          <p
            className={`${
              description.length > 150 ? "description-text" : ""
            } tw-text-sm`}
          >
            {description}
          </p>
          {description.length > 150 && (
            <input type="checkbox" className="expand-btn" />
          )}
          <p className="tw-mt-3">
            {tags.map((tag) => (
              <span key={tag} className="outline-tags">
                {tag.replace(new RegExp("_", "g"), " ")}
              </span>
            ))}
          </p>
          <div className="tw-text-right">
            <Link
              to={`/bids/${ad_id}`}
              className="tw-bg-blue-500 tw-text-white tw-p-1 tw-rounded tw-mr-2 tw-text-xs hover:tw-text-white"
            >
              All Bids
            </Link>
            {role === "V&iR8" && (
              <Button
                className="tw-px-1 tw-py-0.5 tw-text-sm"
                bgColor="tw-bg-green-600"
                onClick={() => checkCredits()}
              >
                Bid
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
