import ReactDom from "react-dom";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Link } from "react-router-dom";

export const ViewTicketModal = ({
  open,
  onClose,
  ticketDetails,
  updateTicketStatus,
}) => {
  const { userRole } = useLocalStorage();

  if (!open) return null;

  const handleStatus = (id, status) => {
    updateTicketStatus(id, status);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[60%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <div>
          <p onClick={onClose} className="closeBtn">
            X
          </p>
          <h4 className="modal-heading tw-text-black tw-font-semibold tw-text-center tw-border-b tw-p-3">
            Ticket Details
          </h4>
          {ticketDetails ? (
            <div className="tw-p-2">
              {userRole === "admin" && (
                <div className="tw-flex tw-items-center tw-order-1 md:tw-order-2 tw-mb-4">
                  <label
                    htmlFor="table-filter"
                    className="tw-mb-0 tw-pe-1 tw-font-medium tw-text-sm"
                  >
                    Change Status :
                  </label>
                  <select
                    htmlFor="table-filter"
                    id="table-filter"
                    className="tw-border tw-shadow tw-p-1 tw-rounded tw-text-sm"
                    value={ticketDetails.status}
                    onChange={(e) =>
                      handleStatus(ticketDetails.id, e.target.value)
                    }
                  >
                    <option value="0">Pending</option>
                    <option value="1">Processing</option>
                    <option value="2">Completed</option>
                  </select>
                </div>
              )}
              <table className="tw-table-fixed tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
                <tbody>
                  {userRole === "admin" && (
                    <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                      <td className="tw-w-4/12 tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                        Name
                      </td>
                      <td className="tw-py-2 tw-px-2 tw-text-sm">
                        {ticketDetails.name}
                      </td>
                    </tr>
                  )}
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-w-4/12 tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Email
                    </td>
                    <td className="tw-py-2 tw-px-2 tw-text-sm">
                      {ticketDetails.email}
                    </td>
                  </tr>
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Date
                    </td>
                    <td className="tw-py-2 tw-px-2 tw-text-sm">
                      {ticketDetails.date}
                    </td>
                  </tr>
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Ticket Status
                    </td>
                    <td
                      className={`tw-text-sm tw-px-2 ${
                        ticketDetails.status === "0"
                          ? "!tw-text-red-600"
                          : ticketDetails.status === "1"
                          ? "!tw-text-orange-600"
                          : "!tw-text-green-600"
                      }`}
                    >
                      {ticketDetails.status === "0"
                        ? "Pending"
                        : ticketDetails.status === "1"
                        ? "Under Review"
                        : "Completed"}
                    </td>
                  </tr>
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Support Type
                    </td>
                    <td className="tw-py-2 tw-px-2 tw-text-sm">
                      {ticketDetails.support_type}
                    </td>
                  </tr>
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Ticket Heading
                    </td>
                    <td className="tw-py-2 tw-px-2 tw-text-sm">
                      {ticketDetails.ticket_heading}
                    </td>
                  </tr>
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Ticket Query
                    </td>
                    <td className="tw-py-2 tw-px-2 tw-text-sm">
                      {ticketDetails.query}
                    </td>
                  </tr>
                  <tr className="tw-bg-white tw-border-b tw-border-slate-300">
                    <td className="tw-py-2 tw-px-2 tw-font-medium tw-text-sm tw-border-r tw-border-slate-300">
                      Supporting Attachment
                    </td>
                    <td className="tw-py-2 tw-px-2 tw-text-sm">
                      <Link
                        to={
                          ticketDetails.path.length > 0
                            ? ticketDetails.path
                            : "#"
                        }
                        target="_blank"
                        className={`${
                          ticketDetails.path.length > 0
                            ? "tw-bg-slate-200 tw-shadow-md tw-p-1 tw-rounded-md"
                            : "tw-text-red-500"
                        }`}
                      >
                        {ticketDetails.file_name.length > 0
                          ? ticketDetails.file_name
                          : "N/A"}
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <p>
              Sorry, something went wrong while fetching ticket details. Please
              try again or contact us.
            </p>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
