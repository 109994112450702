import useFetch from "./useFetch";
import useLocalStorage from "./useLocalStorage";

const useAddNewBank = () => {
  const addBankDetailsApi = `${process.env.REACT_APP_API_URL}add-bank-details`;

  const { fetchApi } = useFetch();
  const { token } = useLocalStorage();

  const newBank = (data, callBackFn) => {
    fetchApi(
      {
        url: addBankDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          name: data.accountName,
          bank_name: data.bankName,
          account_number: data.accountNumber,
          ifsc: data.ifscCode,
        },
      },
      (data) => {
        if (data.message === "bank details exists") {
          alert(
            "Can add only one account. Please edit the existing account details if needed."
          );
          return;
        }
        if (data.message === "exists") {
          alert("Incorrect bank account number. Please try again");
          return;
        }
        callBackFn();
      }
    );
  };
  return { newBank };
};

export default useAddNewBank;
