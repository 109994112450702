import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import useToken from "../../hooks/useLocalStorage";
import useFetchRoom from "../../hooks/useFetchRoom";
import useFetch from "../../hooks/useFetch";
import { updateStatus } from "../../store/slices/ChatSlice";
import { useToastContext } from "../../context/ToastContext";
import socket from "../../utils/socketConnection";
import { MyMessage, OtherMessage } from "./Message";
import "./chat.css";
import FavIcon from "../../images/favicon.png";
import {
  AttachmentIcon,
  CircleXmarkIcon,
  // VideoOnIcon,
} from "../../icons/icons";

const Chat = ({ userId, refresh, handleMeetingModal }) => {
  const sendMessageApi = `${process.env.REACT_APP_API_URL}send-message`;
  const getMessagesApi = `${process.env.REACT_APP_API_URL}get-messages`;

  const { token, userRole } = useToken();
  const { roomData } = useFetchRoom();
  const { fetchApi } = useFetch();
  const toast = useToastContext();
  const dispatch = useDispatch();
  const location = useLocation();

  const date = new Date();
  const showTime =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const currDate = date.getDate() + "/" + (date.getMonth() + 1);

  const [newMessage, setNewMessage] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [connectionData, setConnectionData] = useState({
    name: "",
    image: "",
    fees: 0,
  });
  const [attachment, setAttachment] = useState();
  const lastMessageRef = useRef(null);
  const runOnce = useRef(false);

  const send = async (type) => {
    if (type === "text") {
      socket.emit("send message", {
        senderId: userId,
        recieverId: roomData.recieverId,
        message: newMessage,
        room: roomData.roomId,
        date: currDate,
        time: showTime,
        type: "text",
        status: "0",
      });
      setNewMessage("");
      try {
        var res = await fetch(sendMessageApi, {
          method: "POST",
          headers: { "Content-Type": "application/json", "x-token": token },
          body: JSON.stringify({
            reciever_id: roomData.recieverId,
            message: newMessage,
            room: roomData.roomId,
            date: currDate,
            time: showTime,
            type: "text",
            status: "0",
          }),
        });
        var data = await res.json();
      } catch (error) {
        console.log(error);
      }
      if (data.status === 200) {
        if (data.message !== "message delivered") {
          dispatch(updateStatus(data.connection_status));
          toast.open("warning", "You are not connected to this user anymore");
          refresh();
          return;
        }
      }
    } else {
      const formData = new FormData();
      formData.append("file", attachment);
      formData.append("sender_id", userId);
      formData.append("reciever_id", roomData.recieverId);
      formData.append("room", roomData.roomId);
      formData.append("date", currDate);
      formData.append("time", showTime);
      formData.append("type", "file");
      formData.append("status", "0");
      axios
        .post(sendMessageApi, formData, {
          headers: { "x-token": token },
        })
        .then((data) => {
          setAttachment(null);
          if (data.data.status === 200) {
            if (data.data.message !== "message delivered") {
              dispatch(updateStatus(data.connection_status));
              refresh();
              return;
            }
            socket.emit("send message", {
              senderId: userId,
              recieverId: roomData.recieverId,
              message: data.data.path,
              room: roomData.roomId,
              date: currDate,
              time: showTime,
              type: "file",
              status: "0",
            });
          }
        })
        .catch((err) => {
          if (err.response.status) {
            toast.open(
              "warning",
              "File must be of type ( .png, .jpg, .jpeg, pdf )"
            );
          }
        });
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (roomData.status !== "1" || roomData.deactivated === "1") {
      toast.open("warning", "You cannot send message to this user anymore");
      return;
    }
    if (newMessage.length > 0 || attachment) {
      if (newMessage.length > 0) {
        send("text");
      } else {
        send("file");
      }
    } else {
      toast.open("false", "Cannot send empty message");
    }
  };

  const fetchMessages = useCallback(async () => {
    fetchApi(
      {
        url: getMessagesApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: roomData.recieverId,
        },
      },
      (data) => {
        setMessagesList(data.messages);
        setConnectionData({
          name: data.name,
          image: data.logo,
          fees: data.mentor_fee,
        });
      }
    );
  }, [fetchApi, getMessagesApi, token, roomData.recieverId]);

  useEffect(() => {
    if (!runOnce.current) {
      socket.emit("user disconnect", { userId });
      if (roomData !== undefined) {
        socket.emit("join room", { roomId: roomData.roomId, userId });
      }
    }
    const timer = setTimeout(() => {
      fetchMessages();
    }, 40);
    return () => {
      runOnce.current = true;
      clearTimeout(timer);
    };
  }, [getMessagesApi, token, roomData, userId, location, fetchMessages]);

  useEffect(() => {
    socket.on("my message", (data) => {
      setMessagesList((prev) => [
        ...prev,
        {
          sender_id: data.senderId,
          reciever_id: data.recieverId,
          message: data.message,
          room: data.room,
          date: data.date,
          time: data.time,
          message_type: data.type,
          status: data.status,
        },
      ]);
    });
    socket.on("user message", (data) => {
      setMessagesList((prev) => [
        ...prev,
        {
          sender_id: data.senderId,
          reciever_id: data.recieverId,
          message: data.message,
          room: data.room,
          date: data.date,
          time: data.time,
          message_type: data.type,
          status: data.status,
        },
      ]);
    });
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    return () => {
      socket.removeAllListeners();
    };
  }, [messagesList]);

  return (
    <div className="tw-m-0 md:tw-shadow-md chat-container">
      <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between tw-border-b tw-p-2">
        <div className="tw-flex tw-items-center tw-justify-between">
          <img
            src={
              connectionData.image.length > 0 ? connectionData.image : FavIcon
            }
            alt="reciever-img"
            width={45}
            className="tw-h-[45px] tw-rounded-full tw-me-2"
          />
          <span>{connectionData.name}</span>
        </div>
        {/* {userRole !== "V&iR8" &&
          userRole !== "R$7s2" &&
          roomData.recieverRole !== "V&iR8" && (
            <VideoOnIcon
              fill="#cbc7c7"
              onClick={() =>
                handleMeetingModal(
                  roomData.recieverId,
                  connectionData.name,
                  connectionData.fees
                )
              }
            />
          )} */}
      </div>
      <div className="tw-pt-3 tw-px-2 tw-bg-[#f6f6f6] cstm-chat-height tw-overflow-auto">
        <ul className="tw-grid tw-grid-cols-4">
          {messagesList.map((message) =>
            message.sender_id !== roomData.recieverId ? (
              <MyMessage key={message.time} content={message} />
            ) : (
              <OtherMessage key={message.time} content={message} />
            )
          )}
          <li ref={lastMessageRef}></li>
        </ul>
      </div>
      {roomData.status === "1" && (
        <form
          onSubmit={sendMessage}
          className="tw-grid tw-grid-cols-6 tw-w-full tw-absolute tw-bottom-0 md:tw-relative"
        >
          <div className="tw-col-span-5 tw-flex tw-items-center tw-border">
            {newMessage.length > 0 && !attachment && roomData.status === "1" ? (
              <>
                <input
                  className="tw-p-2.5 tw-w-11/12"
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Type a message......"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <AttachmentIcon className="disabled" />
              </>
            ) : newMessage.length < 1 &&
              attachment &&
              roomData.status === "1" ? (
              <>
                <div className="tw-w-11/12 tw-px-2 tw-text-sm">
                  <span className="tw-relative tw-bg-gray-300 tw-p-1 tw-rounded">
                    {attachment.name}
                    <CircleXmarkIcon
                      className="tw-absolute -tw-right-1 -tw-top-1 tw-cursor-pointer"
                      onClick={() => setAttachment()}
                    />
                  </span>
                </div>
                <input
                  type="file"
                  id="attachment"
                  accept="application/pdf,image/png,image/jpeg"
                  onChange={(e) => setAttachment(e.target.files[0])}
                  hidden
                />
                <label
                  htmlFor="attachment"
                  className="tw-mb-0 tw-cursor-pointer"
                >
                  <AttachmentIcon />
                </label>
              </>
            ) : newMessage.length < 1 &&
              !attachment &&
              roomData.status === "1" ? (
              <>
                <input
                  className="tw-p-2.5 tw-w-11/12"
                  id="message"
                  type="text"
                  name="message"
                  placeholder="Type a message......"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <input
                  type="file"
                  id="attachment"
                  accept="application/pdf,image/png,image/jpeg"
                  onChange={(e) => setAttachment(e.target.files[0])}
                  hidden
                />
                <label
                  htmlFor="attachment"
                  className="tw-mb-0 tw-cursor-pointer"
                >
                  <AttachmentIcon />
                </label>
              </>
            ) : null}
          </div>
          <button
            className={`tw-p-2.5 tw-bg-indigo-500 tw-text-white tw-rounded-r ${
              roomData.status !== "1" ? "disabled" : ""
            }`}
            type="submit"
          >
            SEND
          </button>
        </form>
      )}

      {(roomData.status !== "1" || roomData.deactivated === "1") && (
        <span className="tw-shadow tw-p-1 tw-rounded-md tw-text-sm tw-bg-[#f5f5f5]">
          You cannot send message to this user anymore. For more details check
          your connection status with this user
          <Link
            to={
              userRole === "f@!3A"
                ? "/startup-connections"
                : userRole === "R$7s2"
                ? "/mentor-connections"
                : userRole === "Sw%aa"
                ? "/investor-connections"
                : null
            }
            className="tw-text-blue-500 tw-ps-1"
          >
            ( Connections ).
          </Link>
        </span>
      )}
    </div>
  );
};

export default Chat;
