import { Link } from "react-router-dom";
import {
  LongRightArrow,
  MarketIcon,
  RocketIcon,
  UserGraduateIcon,
  UserIcon,
  UserTieIcon,
} from "../../../icons/icons";

const SmallCard = ({ data, icon, gradient, buttonData }) => {
  const iconType = () => {
    switch (icon) {
      case "startup":
        return <RocketIcon height="20px" fill="#fff" />;
      case "mentor":
        return <UserGraduateIcon height="20px" fill="#fff" />;
      case "investor":
        return <UserTieIcon height="20px" fill="#fff" />;
      case "vendor":
        return <MarketIcon height="20px" fill="#fff" />;
      default:
        return <UserIcon height="20px" fill="#fff" />;
    }
  };

  return (
    <div className="tw-card course-box tw-h-full">
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className={`course-icon ${gradient}`}>{iconType()}</div>
        <div className="tw-flex tw-flex-col tw-text-end tw-font-medium">
          <h4 className="tw-mb-0">{data.count}+</h4>
          <span>{data.title}</span>
        </div>
      </div>
      <Link
        className="tw-flex tw-items-center tw-p-2 tw-mt-2 tw-rounded-md tw-bg-gray-100"
        to={`/${buttonData?.link}`}
      >
        {buttonData?.title}
        <span className="tw-ms-2">
          <LongRightArrow fill="#6D7580" />
        </span>
      </Link>
    </div>
  );
};

export default SmallCard;
