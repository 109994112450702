const RatingCircle = ({
  rating,
  title = "Overall Rating",
  tag = "Overall",
}) => {
  return (
    <p
      title={title}
      className="tw-flex tw-flex-col tw-justify-center tw-mx-2 tw-bg-[#f0f8ff] tw-items-center tw-font-medium tw-h-[100px] tw-w-[100px] tw-border tw-border-slate-300 tw-rounded-full"
    >
      <span>{rating}/5</span>
      <span>{tag}</span>
    </p>
  );
};

export default RatingCircle;
