import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "../register-page/Register.css";
import "./Signin.css";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { Button } from "../../components/ui/Button";
import { EyeIcon, SlashedEyeIcon } from "../../icons/icons";
import Favicon from "../../images/favicon.png";

const ResetPassword = () => {
  const resetPasswordApi = `${process.env.REACT_APP_API_URL}reset-password`;
  const { token } = useParams();
  const { isLoading, error, fetchApi } = useFetch();

  const [resetPassword, setResetPassword] = useState("");
  const [show, setShow] = useState(false);

  const resetPasswordApiCall = async (e) => {
    e.preventDefault();
    fetchApi(
      {
        url: resetPasswordApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: {
          verifytoken: token,
          password: resetPassword,
        },
      },
      (data) => {
        if (data.message === "success") {
          document
            .getElementById("resetPasswordForm")
            .classList.add("tw-hidden");
          document
            .getElementById("resetConfirmation")
            .classList.remove("tw-hidden");
        } else {
          document
            .getElementById("resetErrorText")
            .classList.remove("tw-hidden");
        }
      }
    );
  };

  return (
    <div className="resetPasswordContainer tw-flex tw-flex-col tw-items-center md:tw-justify-center tw-p-3 md:tw-p-0">
      <img src={Favicon} alt="pitchere-logo" width={80} className="tw-my-2" />
      <div
        className="tw-card tw-shadow-md tw-p-4 tw-w-full md:tw-w-[40%]"
        id="resetPasswordForm"
      >
        <h4 className="tw-text-center tw-font-medium">
          You can now reset your password
        </h4>
        <form onSubmit={resetPasswordApiCall}>
          <input
            type="text"
            id="token"
            name="token"
            value={token}
            hidden
            readOnly
          />
          <div className="tw-mb-1">
            <label
              htmlFor="resetPassword"
              className="tw-mb-0 tw-text-sm required"
            >
              New Password
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{ border: "1px solid #ced4da" }}
            >
              <input
                type={show ? "text" : "password"}
                id="resetPassword"
                className="tw-border-0 tw-w-full tw-ps-3"
                name="resetPassword"
                placeholder="Enter your password"
                value={resetPassword}
                onChange={(e) => setResetPassword(e.target.value)}
                required
              />
              <span
                className="input-icons tw-cursor-pointer icon-span"
                onClick={() => setShow(!show)}
              >
                {show ? <EyeIcon /> : <SlashedEyeIcon />}
              </span>
            </div>
          </div>
          {isLoading && <Loader pt="10px" />}
          {error && <Error />}
          <p className="tw-text-red-500 tw-mt-1 tw-hidden" id="resetErrorText">
            Invalid token. Please try again.
          </p>
          <Button type="submit" className="tw-px-3 tw-py-1 tw-mt-3 tw-w-fit">
            Reset Password
          </Button>
        </form>
      </div>
      <div
        className="tw-card tw-shadow-md tw-p-4 cstm-width tw-hidden tw-text-center"
        id="resetConfirmation"
      >
        <p className="tw-font-medium">Congrats! Password reset successfully.</p>
        <Link
          to="/"
          className="tw-bg-primary tw-w-4/12 tw-m-auto tw-text-white tw-px-2 tw-py-1 tw-rounded-md tw-my-2"
        >
          Login
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
