import { useState } from "react";
import ReactDom from "react-dom";
import { Stars } from "./Stars";
import { Button } from "../../Button";
import "./modal.css";
import useSubmitReview from "../../../../hooks/useSubmitReview";

const RatingModal = ({ open, onClose, role, id, submitRating, type }) => {
  const { submitReview } = useSubmitReview();

  const [rating, setRating] = useState(0);
  const [reviewMsg, setReviewMsg] = useState("");

  if (!open) return null;
  const handleModalSubmit = () => {
    if (type === "market") {
      submitRating(rating, reviewMsg);
    } else {
      submitReview(id, rating, reviewMsg, role, onClose);
    }
    setRating(0);
    setReviewMsg("");
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w[90%] md:tw-min-w-[40%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="tw-border-b tw-p-3 tw-text-center tw-font-medium tw-text-lg tw-capitalize">
          Review
        </h4>
        <div className="content tw-p-3">
          <div className="tw-mb-4 tw-flex tw-justify-center">
            <div className="tw-flex tw-flex-col tw-items-center tw-mx-1">
              <p
                onClick={() => setRating(1)}
                className="tw-px-3 tw-py-1 tw-w-[40px] tw-rounded-full tw-text-xl tw-font-semibold tw-border hover:tw-bg-primary hover:tw-text-white"
              >
                1
              </p>
              <p>Worst</p>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-mx-1">
              <p
                onClick={() => setRating(2)}
                className="tw-px-3 tw-py-1 tw-w-[40px] tw-rounded-full tw-text-xl tw-font-semibold tw-border hover:tw-bg-primary hover:tw-text-white"
              >
                2
              </p>
              <p>Bad</p>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-mx-1">
              <p
                onClick={() => setRating(3)}
                className="tw-px-3 tw-py-1 tw-w-[40px] tw-rounded-full tw-text-xl tw-font-semibold tw-border hover:tw-bg-primary hover:tw-text-white"
              >
                3
              </p>
              <p>Average</p>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-mx-1">
              <p
                onClick={() => setRating(4)}
                className="tw-px-3 tw-py-1 tw-w-[40px] tw-rounded-full tw-text-xl tw-font-semibold tw-border hover:tw-bg-primary hover:tw-text-white"
              >
                4
              </p>
              <p>Good</p>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-mx-1">
              <p
                onClick={() => setRating(5)}
                className="tw-px-3 tw-py-1 tw-w-[40px] tw-rounded-full tw-text-xl tw-font-semibold tw-border hover:tw-bg-primary hover:tw-text-white"
              >
                5
              </p>
              <p>Excellent</p>
            </div>
          </div>
          {rating > 0 && (
            <div className="tw-my-3">
              <Stars rating={rating} />
            </div>
          )}
          <textarea
            name="rating-reason"
            id="rating-reason"
            cols="30"
            rows="4"
            className="tw-p-2 tw-border tw-border-gray-300 tw-rounded-md"
            placeholder="Please state your reason for this rating**(mandatory)"
            value={reviewMsg}
            onChange={(e) => setReviewMsg(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="modalFooter tw-border-t tw-p-3">
          <Button
            onClick={onClose}
            className="tw-px-2 tw-py-1 tw-mx-1 hover:tw-bg-red-700 hover:tw-text-white"
            bgColor="tw-bg-secondary"
          >
            Close
          </Button>
          {rating && reviewMsg ? (
            <Button
              onClick={handleModalSubmit}
              className="tw-px-2 tw-py-1 tw-mx-1 hover:tw-bg-indigo-700 hover:tw-text-white"
            >
              Submit
            </Button>
          ) : (
            <Button className="disabled tw-px-2 tw-py-1 tw-mx-1" disabled>
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default RatingModal;
