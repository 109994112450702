import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import { bannedInputs, numberRegex, urlRegex } from "../../constants";
import { MoneyUpTrendIcon, LinkedInIcon, UploadIcon } from "../../icons/icons";
import { useToastContext } from "../../context/ToastContext";
import Dropdown from "../../components/ui/inputs/dropdown";

export const InvestorDetailsForm = ({ token, industries }) => {
  const investorDetailsApi = `${process.env.REACT_APP_API_URL}insert-investor-details`;

  const navigate = useNavigate();
  const { isLoading, error } = useFetch();
  const toast = useToastContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [investment, setInvestment] = useState({
    investedAmount: "",
    futureInvestmentAmount: "",
  });

  const InvestorDetailsApiCall = (data) => {
    document.getElementById("invested-amount-error").classList.add("tw-hidden");
    document.getElementById("future-amount-error").classList.add("tw-hidden");
    document.getElementById("industry-limit").classList.add("tw-hidden");
    document.getElementById("industry-limit").classList.add("tw-hidden");
    if (investment.investedAmount === "") {
      document
        .getElementById("invested-amount-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (investment.futureInvestmentAmount === "") {
      document
        .getElementById("future-amount-error")
        .classList.remove("tw-hidden");
      return;
    }
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("tw-hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("tw-hidden");
      return;
    }
    let logo = document.getElementById("profileImg").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("companies_invested_count", data.numberOfInvestments);
    formData.append("invested_amount", investment.investedAmount);
    formData.append("investor_industries", selectedIndustry);
    formData.append(
      "future_investment_amount",
      investment.futureInvestmentAmount
    );
    formData.append("linked_in", data.investorLinkedin);
    axios
      .post(investorDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/investor-dashboard");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      id="investorDetailsForm"
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-my-0 lg:tw-py-3 md:tw-col-start-2 md:tw-col-span-5 lg:tw-col-span-2"
    >
      <form
        onSubmit={handleSubmit(InvestorDetailsApiCall)}
        className="tw-mx-auto tw-p-5 tw-rounded-md tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0"
      >
        <h4 className="tw-text-lg tw-font-medium">
          Some details about your investment career
        </h4>
        <div className="tw-mb-1">
          <label
            htmlFor="numberOfInvestments"
            className="tw-text-sm tw-mb-0 required"
          >
            Number of companies in your portfolio
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="number"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="numberOfInvestments"
              name="numberOfInvestments"
              placeholder="ex: 3"
              {...register("numberOfInvestments", {
                required: "portfolio count is required",
                pattern: {
                  value: numberRegex,
                  message: "Count must only contain digits",
                },
                maxLength: {
                  value: 3,
                  message: "Cannot exceed 3 digits",
                },
              })}
            />
            <span className="input-icons icon-span">
              <MoneyUpTrendIcon />
            </span>
          </div>
          {errors.numberOfInvestments && (
            <div>
              <FormError message={errors.numberOfInvestments.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1 tw-flex-col">
          <label
            htmlFor="investedAmount"
            className="tw-text-sm tw-mb-0 required"
          >
            Total Investment done till now
          </label>
          <Dropdown
            name="investedAmount"
            id="investedAmount"
            options={[
              { label: "Not Applicable", value: "NA" },
              { label: "Upto 5Lakh", value: "Upto 5Lakh" },
              { label: "5Lakh-10Lakh", value: "5Lakh-10Lakh" },
              { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
              { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
              { label: "50Lakh+", value: "More than 50Lakh" },
            ]}
            onChange={(selectedOption) =>
              setInvestment((prev) => ({
                ...prev,
                investedAmount: selectedOption.value,
              }))
            }
          />
          <div className="tw-hidden" id="invested-amount-error">
            <FormError message="Amount is required" />
          </div>
        </div>
        <div className="tw-mb-1 tw-flex-col">
          <label
            htmlFor="futureInvestmentAmount"
            className="tw-text-sm tw-mb-0 required"
          >
            How much are you looking to invest
          </label>
          <Dropdown
            name="futureInvestmentAmount"
            id="futureInvestmentAmount"
            options={[
              { label: "Upto 5Lakh", value: "Upto 5Lakh" },
              { label: "5Lakh-10Lakh", value: "5Lakh-10Lakh" },
              { label: "10Lakh-25Lakh", value: "10Lakh-25Lakh" },
              { label: "25Lakh-50Lakh", value: "25Lakh-50Lakh" },
              { label: "50Lakh+", value: "More than 50Lakh" },
            ]}
            onChange={(selectedOption) =>
              setInvestment((prev) => ({
                ...prev,
                futureInvestmentAmount: selectedOption.value,
              }))
            }
          />
          <div className="tw-hidden" id="future-amount-error">
            <FormError message="Amount is required" />
          </div>
        </div>
        <div className="tw-mb-1 tw-flex-col">
          <p className="tw-text-sm">
            Industry{" "}
            <span className="required">({selectedIndustry.length + "/3"})</span>
            <span
              id="industry-limit"
              className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
            >
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setSelectedIndustry(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small
            className="tw-text-red-500 tw-mb-2 tw-hidden"
            id="industry-error"
          >
            This field is mandatory.
          </small>
        </div>
        <div className="tw-mb-1">
          <label htmlFor="investorLinkedin" className="tw-text-sm tw-mb-0">
            Linkedin profile
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="investorLinkedin"
              name="investorLinkedin"
              placeholder="Linkedin URL"
              {...register("investorLinkedin", {
                validate: {
                  validUrl: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? urlRegex.test(fieldValue) || "Please enter a valid url"
                      : null;
                  },
                  bannedUrl: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different url"
                      : null;
                  },
                  maxLength: (fieldValue) => {
                    return (
                      fieldValue.trim().length < 200 ||
                      "Cannot exceed 200 characters"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <LinkedInIcon />
            </span>
          </div>
          {errors.investorLinkedin && (
            <div>
              <FormError message={errors.investorLinkedin.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-3">
          <label htmlFor="profileImg" className="tw-text-sm tw-mb-0">
            Profile Image
          </label>
          <div
            className="tw-flex tw-items-center tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/jpeg, image/png"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="profileImg"
              name="profileImg"
              placeholder="Upload Profile Image"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        {isLoading && !error ? <Loader pt="10px" classes="tw-my-2" /> : null}
        <Button type="submit" className="tw-mt-4 tw-py-1.5 tw-w-full">
          Next
        </Button>
      </form>
    </div>
  );
};
