import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { randomId } from "../../../utils/randomId";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import SubscriptionCard from "../../../components/ui/cards/subscriptionCard";
import { Button } from "../../../components/ui/Button";
import AddPlan from "../../../components/ui/modals/plan-modal/addPlan";
import EditPlan from "../../../components/ui/modals/plan-modal/editPlan";
import { DeleteModal } from "../../../components/ui/modals/utility-modals/deleteModal";

const Plans = () => {
  const displaySubscriptionsApi = `${process.env.REACT_APP_API_URL}display-plans`;
  const addSubscriptionApi = `${process.env.REACT_APP_API_URL}add-subscription`;
  const updateSubscriptionApi = `${process.env.REACT_APP_API_URL}update-subscription`;
  const planStatusApi = `${process.env.REACT_APP_API_URL}change-plan-status`;
  const deletePlanApi = `${process.env.REACT_APP_API_URL}delete-plan`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openAddPlanModal, setOpenAddPlanModal] = useState(false);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState({
    startup: [],
    vendor: [],
  });
  const [singlePlan, setSinglePlan] = useState([]);
  const [planId, setPlanId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("Startup");

  const displaySubscriptions = useCallback(() => {
    fetchApi(
      { url: displaySubscriptionsApi, headers: { "x-token": token } },
      (data) => {
        setSubscriptionPlans({
          startup: data.plans?.filter((plan) => plan.type === "startup"),
          vendor: data.plans?.filter((plan) => plan.type === "vendor"),
        });
      }
    );
  }, [displaySubscriptionsApi, token, fetchApi]);

  const addSubscription = useCallback(
    (data, features) => {
      fetchApi(
        {
          url: addSubscriptionApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            type: data.planFor,
            plan_type: data.planName,
            amount: data.planPrice,
            points: data.planTokens,
            plan_features: features,
            discount_status: data.discount,
            discount: data.discountAmount,
            discount_type: data.type,
          },
        },
        () => {
          displaySubscriptions();
        }
      );
    },
    [token, addSubscriptionApi, fetchApi, displaySubscriptions]
  );

  const updateSubscription = useCallback(
    (data, features, id) => {
      fetchApi(
        {
          url: updateSubscriptionApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            type: data.planFor,
            plan_type: data.planName,
            amount: data.planPrice,
            points: data.planTokens,
            plan_features: features,
            discount_status: data.discount,
            discount: data.discountAmount,
            discount_type: data.type,
          },
        },
        () => {
          displaySubscriptions();
        }
      );
    },
    [token, updateSubscriptionApi, fetchApi, displaySubscriptions]
  );

  const changePlanStatus = useCallback(
    (id, status) => {
      fetchApi(
        {
          url: planStatusApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            status: status,
          },
        },
        () => {
          displaySubscriptions();
        }
      );
    },
    [token, planStatusApi, fetchApi, displaySubscriptions]
  );

  const deletePlan = useCallback(
    (id) => {
      fetchApi(
        {
          url: deletePlanApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => {
          displaySubscriptions();
        }
      );
    },
    [token, deletePlanApi, fetchApi, displaySubscriptions]
  );

  const updateModal = (data) => {
    setSinglePlan(data);
    setEditPlanModal(true);
  };

  const handleDeleteModal = (id) => {
    setPlanId(id);
    setDeleteModal(true);
  };

  useEffect(() => {
    const fetchPlans = setTimeout(() => {
      displaySubscriptions();
    }, 20);
    return () => {
      clearTimeout(fetchPlans);
    };
  }, [displaySubscriptions, fetchApi, token]);

  const checkSelectedPlan = () => {
    if (selectedPlan === "Startup") {
      return subscriptionPlans.startup;
    } else return subscriptionPlans.vendor;
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!isLoading && !error && (
          <div className="tw-grid tw-grid-cols-3 tw-gap-3 tw-p-3">
            <Button
              className="tw-w-4/12 tw-p-1 tw-col-start-3 tw-ml-auto"
              onClick={() => setOpenAddPlanModal(true)}
            >
              Add Plan
            </Button>
            <p className="tw-col-span-3 tw-mb-3 tw-font-medium tw-text-sm">
              Note : Use toggle switch to activate/deactive subscription plan.
            </p>
            <div>
              <span
                className={`${
                  selectedPlan === "Startup"
                    ? "tw-bg-primary tw-text-white"
                    : "tw-bg-gray-200"
                } tw-px-2 tw-py-1 tw-rounded-l-md tw-border-r-2 tw-border-gray-400`}
                onClick={() => setSelectedPlan("Startup")}
              >
                Startup
              </span>
              <span
                className={`${
                  selectedPlan === "Service Provider"
                    ? "tw-bg-primary tw-text-white"
                    : "tw-bg-gray-200"
                } tw-px-2 tw-py-1 tw-rounded-r-md`}
                onClick={() => setSelectedPlan("Service Provider")}
              >
                Service Provider
              </span>
            </div>
            <p className="tw-col-span-3 tw-font-medium tw-text-lg">
              {selectedPlan} Plans
            </p>
            {checkSelectedPlan().length > 0 ? (
              checkSelectedPlan().map((plan) => (
                <SubscriptionCard
                  key={plan._id}
                  data={plan}
                  role="admin"
                  updateModal={updateModal}
                  changePlanStatus={changePlanStatus}
                  handleDeleteModal={handleDeleteModal}
                />
              ))
            ) : (
              <p>No Plans Found.</p>
            )}
          </div>
        )}
      </div>
      <AddPlan
        key={randomId()}
        open={openAddPlanModal}
        onClose={() => setOpenAddPlanModal(false)}
        addSubscription={addSubscription}
      />
      <EditPlan
        key={randomId()}
        open={editPlanModal}
        onClose={() => setEditPlanModal(false)}
        singlePlan={singlePlan}
        updateSubscription={updateSubscription}
      />
      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        deleteMethod={deletePlan}
        id={planId}
      />
    </div>
  );
};

export default Plans;
