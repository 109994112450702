import { useCallback, useEffect, useRef } from "react";
import "./video.css";
import FavIcon from "../../images/favicon.png";

const RemoteVideo = ({
  remoteStream,
  localStream,
  userCount,
  userImages,
  userId,
  camera,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (userCount === 1) {
      if (localStream instanceof MediaStream && videoRef.current) {
        videoRef.current.srcObject = localStream;
      }
    } else if (userCount === 2) {
      if (remoteStream instanceof MediaStream && videoRef.current) {
        videoRef.current.srcObject = remoteStream;
      }
    }
    return () => {
      if (videoRef.current && !camera) {
        videoRef.current.srcObject = null;
      }
    };
  }, [remoteStream, localStream, userCount, camera]);

  const getMyImage = useCallback(() => {
    return userImages && userImages[userId] ? userImages[userId] : FavIcon;
  }, [userId, userImages]);

  const getRemoteImage = useCallback(() => {
    const otherUser = Object.entries(userImages).find(
      ([key]) => key !== userId
    );
    return otherUser ? otherUser[1] : FavIcon;
  }, [userId, userImages]);

  return (
    <div className="remoteVideo tw-relative" id="user-2">
      {userCount === 2 ? (
        camera && remoteStream ? (
          <video
            ref={videoRef}
            className="video-player tw-w-full"
            id="user-2-video"
            alt="Remote user video"
            autoPlay
            playsInline
          ></video>
        ) : !camera && remoteStream ? (
          <>
            <video
              ref={videoRef}
              className="video-player tw-w-full"
              id="user-2-video"
              alt="Remote user video"
              autoPlay
              playsInline
            ></video>
            <img
              src={getRemoteImage()}
              alt="remote-user-img"
              width={150}
              className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2 tw-h-[150px] tw-rounded-full tw-p-1"
            />
          </>
        ) : (
          <img
            src={getRemoteImage()}
            alt="remote-user-img"
            width={150}
            className="tw-h-[120px] tw-rounded-full tw-p-1"
          />
        )
      ) : !camera && localStream ? (
        <>
          <video
            ref={videoRef}
            className="video-player tw-w-full"
            id="user-2-video-local"
            alt="My video"
            autoPlay
            playsInline
          ></video>
          <img
            src={getMyImage()}
            alt="my-img"
            width={150}
            className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-y-1/2 -tw-translate-x-1/2 tw-h-[150px] tw-rounded-full tw-p-1"
          />
        </>
      ) : (
        <img
          src={getMyImage()}
          alt="my-img"
          width={150}
          className="tw-h-[150px] tw-rounded-full tw-p-1"
        />
      )}
    </div>
  );
};

export default RemoteVideo;
