import { Link } from "react-router-dom";
import { Pagination } from "../../../components/ui/Button";

const RequestTable = ({ page, setPage, type, data }) => {
  let filterData;

  if (type === "All") {
    filterData = data;
  } else if (type === "Pending") {
    filterData = data.filter((data) => data.status === "0");
  } else if (type === "Completed") {
    filterData = data.filter((data) => data.status === "1");
  } else {
    filterData = data.filter((data) => data.status === "-1");
  }

  return (
    <>
      <div className="tw-overflow-auto tw-w-full md:tw-overflow-none">
        <table className="tw-table-auto tw-min-w-max tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
          <thead className="tw-border tw-border-gray-200 tw-bg-slate-50">
            <tr className="tw-divide-x tw-divide-gray-200 tw-text-left">
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Name
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Role
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Amount
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Requested On
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Status
              </th>
              <th className="tw-font-semibold tw-p-2 tw-text-xs md:tw-text-sm">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="tw-divide-y tw-divide-gray-200">
            {filterData.length > 0 ? (
              filterData.slice(page * 10 - 10, page * 10).map((request) => (
                <tr key={request.id} className="tw-divide-x tw-divide-gray-200">
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm tw-uppercase">
                    {request.name}
                  </td>
                  <td className="tw-p-2 tw-text-xs">
                    <span
                      className={`tw-font-medium tw-p-1 tw-rounded-lg ${
                        request.role === "f@!3A"
                          ? "tw-text-blue-800 tw-bg-blue-200"
                          : request.role === "R$7s2"
                          ? "tw-text-orange-800 tw-bg-orange-200"
                          : "tw-text-green-800 tw-bg-green-200"
                      }`}
                    >
                      {request.role === "f@!3A"
                        ? "Startup"
                        : request.role === "R$7s2"
                        ? "Mentor"
                        : "Vendor"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {request.role === "f@!3A"
                      ? request.withdraw_amount
                      : request.withdraw_amount * (1 - 0.1)}
                  </td>
                  <td className="tw-p-2 tw-text-xs md:tw-text-sm">
                    {request.date}
                  </td>
                  <td className="tw-p-2 tw-text-xs">
                    <span
                      className={`tw-font-medium tw-p-1 tw-rounded-lg ${
                        request.status === "-1"
                          ? "tw-text-red-800 tw-bg-red-200"
                          : request.status === "0"
                          ? "tw-text-orange-800 tw-bg-orange-200"
                          : "tw-text-green-800 tw-bg-green-200"
                      }`}
                    >
                      {request.status === "-1"
                        ? "Rejected"
                        : request.status === "0"
                        ? "Pending"
                        : "Completed"}
                    </span>
                  </td>
                  <td className="tw-p-2 tw-text-xs">
                    <Link
                      to={`/admin/request/${request.id}`}
                      state={{ data: request }}
                      className="tw-p-1 md:tw-p-1.5 tw-bg-primary tw-text-white tw-rounded-md"
                    >
                      View Details
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tw-text-center">
                <td colSpan={6} className="tw-p-2 tw-text-xs md:tw-text-sm">
                  No Withdraw Request
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          data={filterData}
          dataCount={10}
        />
      )}
    </>
  );
};

export default RequestTable;
