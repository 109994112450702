import FavIcon from "../../images/favicon.png";
import "./chat.css";

const Header = ({ connectedUsersList, getRoomId }) => {
  return (
    <div className="tw-p-3 tw-mb-3">
      <h4 className="tw-text-lg">Chat</h4>
      <div className="tw-flex tw-items-center tw-justify-start tw-pt-5 tw-pb-3 tw-overflow-auto">
        {connectedUsersList?.map((user) => (
          <img
            key={user.connection_id}
            src={user.logo.length > 0 ? user.logo : FavIcon}
            alt="connections-profile-img"
            width={50}
            loading="lazy"
            className="tw-h-[50px] tw-rounded-full tw-mx-1 tw-border tw-border-slate-400 tw-p-1"
            onClick={() =>
              getRoomId(user.connection_id, user.role, user.status)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Header;
