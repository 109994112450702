import "./revenueCard.css";

const RevenueCard = ({
  title,
  amount,
  bgColor = "tw-bg-primary",
  borderColor = "tw-border-primary",
  classes = "",
}) => {
  return (
    <div className={`plan-card tw-border-b-4 ${borderColor} ${classes}`}>
      <h4>{title}</h4>
      <div className={`etiquet-price ${bgColor} tw-text-white`}>
        <p>{amount}</p>
        <div className={`tw-border-t-[13px] ${borderColor}`}></div>
      </div>
    </div>
  );
};

export default RevenueCard;
