import { Link } from "react-router-dom";
import {
  UserIcon,
  RocketIcon,
  MarketIcon,
  RupeeIcon,
  WalletIcon,
  CommentIcon,
  CoinsIcon,
  BellIcon,
  ClipBoardIcon,
  UserPenIcon,
  GearIcon,
  CircleExclamationIcon,
} from "../../icons/icons";

export const VendorSidebar = ({ currPath, activeStyle, regularStyle }) => {
  return (
    <ul className="tw-p-3 tw-pt-4">
      <Link
        to="/vendor-profile"
        className={currPath === "#/vendor-profile" ? activeStyle : regularStyle}
      >
        <UserIcon fill={currPath === "#/vendor-profile" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Profile
        </span>
      </Link>
      <Link
        to="/startups"
        className={
          currPath === "#/startups" || currPath === "#/view-startup"
            ? activeStyle
            : regularStyle
        }
      >
        <RocketIcon fill={currPath === "#/startups" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Startups
        </span>
      </Link>
      <Link
        to="/market"
        className={currPath === "#/market" ? activeStyle : regularStyle}
      >
        <MarketIcon fill={currPath === "#/market" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Market Place
        </span>
      </Link>
      <Link
        to="/my-bids"
        className={currPath === "#/my-bids" ? activeStyle : regularStyle}
      >
        <RupeeIcon fill={currPath === "#/my-bids" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          My Bids
        </span>
      </Link>
      <Link
        to="/vendor-wallet"
        className={currPath === "#/vendor-wallet" ? activeStyle : regularStyle}
      >
        <WalletIcon
          fill={currPath === "#/vendor-wallet" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Wallet
        </span>
      </Link>
      <Link
        to="/chat"
        className={currPath === "#/chat" ? activeStyle : regularStyle}
      >
        <CommentIcon fill={currPath === "#/chat" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">Chat</span>
      </Link>
      <Link
        to="/vendor-plan"
        className={currPath === "#/vendor-plan" ? activeStyle : regularStyle}
      >
        <CoinsIcon fill={currPath === "#/vendor-plan" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Subscription
        </span>
      </Link>
      <Link
        to="/notifications"
        className={currPath === "#/notifications" ? activeStyle : regularStyle}
      >
        <BellIcon fill={currPath === "#/notifications" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Notifications
        </span>
      </Link>
      <Link
        to="/reviews"
        className={currPath === "#/reviews" ? activeStyle : regularStyle}
      >
        <UserPenIcon fill={currPath === "#/reviews" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Reviews
        </span>
      </Link>
      <Link
        to="/tickets"
        className={currPath === "#/tickets" ? activeStyle : regularStyle}
      >
        <ClipBoardIcon fill={currPath === "#/tickets" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Tickets
        </span>
      </Link>
      <Link
        to="/settings"
        className={currPath === "#/settings" ? activeStyle : regularStyle}
      >
        <GearIcon fill={currPath === "#/settings" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Settings
        </span>
      </Link>
      <Link
        to="/faq"
        className={currPath === "#/faq" ? activeStyle : regularStyle}
      >
        <CircleExclamationIcon
          fill={currPath === "#/faq" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">FAQ</span>
      </Link>
    </ul>
  );
};
