import React from "react";
import { Link } from "react-router-dom";

const NotificationItem = ({ data, userRole, fn, updateNotification }) => {
  return (
    <Link
      to={fn(data.notification_type, userRole)}
      onClick={() => updateNotification(data.id)}
      className={`tw-p-2 tw-rounded-md tw-shadow-md ${
        data.status === "0" ? "tw-bg-[#e4e4e4]" : "tw-bg-white"
      }`}
    >
      <div className="tw-flex tw-flex-col tw-items-start md:tw-flex-row md:tw-items-center md:tw-justify-between">
        <p className="md:tw-mb-0 md:tw-me-4 tw-font-medium">{data.heading}</p>
        <span className="tw-ml-auto tw-text-xs md:tw-text-sm">{data.date}</span>
      </div>
    </Link>
  );
};

export default NotificationItem;
