import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import { randomId } from "../../utils/randomId";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { Button } from "../../components/ui/Button";
import MeetingsTable from "./meetingsTable";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import { MeetingSchedule } from "../../components/ui/modals/meetings/meetingSchedule";
import { EditMeeting } from "../../components/ui/modals/meetings/editMeeting";
import ViewSummary from "../../components/ui/modals/meetings/viewSummary";
import Dropdown from "../../components/ui/inputs/dropdown";

const Meetings = () => {
  const connectionsListApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;
  const scheduleMeetingApi = `${process.env.REACT_APP_API_URL}schedule-meeting`;
  const displayMeetingApi = `${process.env.REACT_APP_API_URL}display-meetings`;
  const cancelMeetingApi = `${process.env.REACT_APP_API_URL}cancel-meeting`;
  const editMeetingApi = `${process.env.REACT_APP_API_URL}edit-meeting`;
  const acceptMeetingApi = `${process.env.REACT_APP_API_URL}accept-reject`;
  const displaySummaryApi = `${process.env.REACT_APP_API_URL}display-summary`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [connections, setConnections] = useState([]);
  const [userId, setUserId] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [singleMeeting, setSingleMeeting] = useState({});
  const [summary, setSummary] = useState([]);
  const [tableType, setTableType] = useState("All");
  const [page, setPage] = useState(1);

  const connectionsList = useCallback(() => {
    fetchApi(
      {
        url: connectionsListApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setConnections(data.response);
      }
    );
  }, [connectionsListApi, token, fetchApi]);

  const displayMeeting = useCallback(() => {
    fetchApi(
      {
        url: displayMeetingApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setMeetings(data.details);
        setUserId(data.user_id);
      }
    );
  }, [displayMeetingApi, token, fetchApi]);

  useEffect(() => {
    const timer = setTimeout(() => {
      displayMeeting();
      connectionsList();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [connectionsList, displayMeeting]);

  const scheduleMeeting = useCallback(
    (type, data, recieverId) => {
      let dataObj = {
        time: data.hour + ":" + data.minutes,
        title: data.title,
        date: data.date,
      };
      if (type === "other") {
        dataObj = { ...dataObj, reciever_id: data.user };
      } else {
        dataObj = { ...dataObj, reciever_id: recieverId };
      }
      fetchApi(
        {
          url: scheduleMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: dataObj,
        },
        (data) => {
          if (data.message === "don't have wallet point") {
            toast.open("warning", "Insufficient tokens");
            navigate("/subscription");
          }
          displayMeeting();
        }
      );
    },
    [displayMeeting, scheduleMeetingApi, fetchApi, token, navigate, toast]
  );

  const acceptMeeting = useCallback(
    (meetingId, status, senderRole, recieverRole) => {
      fetchApi(
        {
          url: acceptMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: meetingId,
            type: status,
            mentor_role:
              senderRole === "R$7s2"
                ? "sender"
                : recieverRole === "R$7s2"
                ? "reciever"
                : "investor",
          },
        },
        (data) => {
          if (data.message === "delete") {
            toast.open("warning", "Meeting cancelled by startup.");
          }
          displayMeeting();
        }
      );
    },
    [displayMeeting, acceptMeetingApi, fetchApi, token, toast]
  );

  const cancelMeeting = useCallback(
    (meetingId, senderRole, recieverRole) => {
      fetchApi(
        {
          url: cancelMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: meetingId,
            mentor_role:
              senderRole === "R$7s2"
                ? "sender"
                : recieverRole === "R$7s2"
                ? "reciever"
                : "investor",
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("warning", "Meeting response received. Check status.");
          }
          displayMeeting();
        }
      );
    },
    [displayMeeting, cancelMeetingApi, fetchApi, token, toast]
  );

  const editMeeting = useCallback(
    (data, id) => {
      fetchApi(
        {
          url: editMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            time: data.hour + ":" + data.minutes,
            title: data.title,
            date: data.date,
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("warning", "Meeting response received. Check status.");
          }
          displayMeeting();
        }
      );
    },
    [displayMeeting, editMeetingApi, fetchApi, token, toast]
  );

  const getRoomId = useCallback(
    (connection_id, role, meetingId) => {
      fetchApi(
        {
          url: getRoomIdApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: connection_id,
            role: role,
          },
        },
        (data) => {
          navigate(`/video-call/${data.room_id}/${meetingId}`, {
            state: { userId: userId },
          });
        }
      );
    },
    [getRoomIdApi, fetchApi, token, navigate, userId]
  );

  const displaySummary = useCallback(
    (meetingId) => {
      fetchApi(
        {
          url: displaySummaryApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: meetingId,
          },
        },
        (data) => {
          setSummary(data.summary);
        }
      );
    },
    [displaySummaryApi, fetchApi, token]
  );

  const handleModals = (type, data) => {
    if (type === "meeting") {
      setSingleMeeting(data);
      setOpenEdit(true);
    } else if (type === "summary") {
      displaySummary(data);
      setSummaryModal(true);
    }
  };

  const changeTableType = (value) => {
    setTableType(value);
    setPage(1);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-4">
          {error && <Error />}
          {isLoading && <Loader />}
          {!error && !isLoading && (
            <>
              <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
                <h5 className="tw-text-lg tw-font-medium">Meetings</h5>
                {userRole !== "R$7s2" && userRole !== "V&iR8" && (
                  <Button className="tw-p-2" onClick={() => setOpen(true)}>
                    Schedule New Meeting
                  </Button>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-gap-0 md:tw-my-2">
                <div className="tw-font-medium tw-flex tw-items-start tw-text-xs tw-mb-2">
                  Note:
                  <p className="tw-ml-2 tw-text-xs">
                    <span>
                      1. Meetings will expire automatically 1 hour post
                      Schedule(Meeting) Time.
                    </span>
                    {userRole === "R$7s2" && (
                      <span>
                        2. It is mandatory for mentors to submit a summary.
                        Amount will only be transfered to your account if
                        summary has been added.
                      </span>
                    )}
                  </p>
                </div>
                <div className="tw-flex tw-items-center tw-order-1 md:tw-order-2 tw-mb-2">
                  <label
                    htmlFor="meeting-filter"
                    className="tw-text-lg tw-font-medium tw-mr-2 tw-mb-0"
                  >
                    Filter :{" "}
                  </label>
                  <Dropdown
                    options={[
                      { label: "All", value: "All" },
                      { label: "Accepted", value: "Accepted" },
                    ]}
                    onChange={(selectedOption) =>
                      changeTableType(selectedOption.value)
                    }
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        width: "200px",
                      }),
                    }}
                    defaultValue={{ label: "All", value: "All" }}
                    id="meeting-filter"
                  />
                </div>
              </div>
              <MeetingsTable
                data={meetings}
                userId={userId}
                acceptMeeting={acceptMeeting}
                cancelMeeting={cancelMeeting}
                handleModals={handleModals}
                getRoomId={getRoomId}
                page={page}
                setPage={setPage}
                tableType={tableType}
              />
            </>
          )}
        </div>
      </div>
      <MeetingSchedule
        key={randomId()}
        open={open}
        onClose={() => setOpen(false)}
        data={connections}
        scheduleMeeting={scheduleMeeting}
      />
      <EditMeeting
        key={randomId()}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        meetingData={singleMeeting}
        userId={userId}
        editMeeting={editMeeting}
      />
      <ViewSummary
        key={randomId()}
        open={summaryModal}
        onClose={() => setSummaryModal(false)}
        data={summary}
        userId={userId}
      />
    </div>
  );
};

export default Meetings;
