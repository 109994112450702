import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import InvestmentTable from "./InvestmentTable";
import Dropdown from "../../../components/ui/inputs/dropdown";

const Investments = () => {
  const getInvestmentsApi = `${process.env.REACT_APP_API_URL}investment-details`;
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();

  const [investments, setInvestments] = useState([]);
  const [type, setType] = useState("All");
  const [page, setPage] = useState(1);
  const [openSidebar, setOpenSidebar] = useState(false);

  const getInvestments = useCallback(() => {
    fetchApi(
      {
        url: getInvestmentsApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setInvestments(data.details);
      }
    );
  }, [getInvestmentsApi, fetchApi, token]);

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      getInvestments();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getInvestments, navigate, userRole]);

  const changeType = (type) => {
    setType(type);
    setPage(1);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!isLoading && !error && (
          <div className="tw-p-4">
            <h5 className="tw-text-lg tw-font-semibold tw-mb-3">Investments</h5>
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between">
              <p className="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-my-3 tw-font-semibold tw-text-xs">
                Note :{" "}
                <span className="tw-font-medium md:tw-ps-2">
                  Transfer only withdrable amount to the startup.
                </span>
              </p>
              <div className="tw-flex tw-items-center tw-order-1 md:tw-order-2">
                <label
                  htmlFor="investment-filter"
                  className="tw-text-lg tw-font-medium tw-mr-2 tw-mb-0"
                >
                  Filter :{" "}
                </label>
                <Dropdown
                  options={[
                    { label: "All", value: "All" },
                    { label: "Pending", value: "0" },
                    { label: "Completed", value: "1" },
                  ]}
                  onChange={(selectedOption) =>
                    changeType(selectedOption.value)
                  }
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: "150px",
                    }),
                  }}
                  defaultValue={{ label: "All", value: "All" }}
                  id="investment-filter"
                />
              </div>
            </div>
            <InvestmentTable
              page={page}
              setPage={setPage}
              data={investments}
              type={type}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Investments;
