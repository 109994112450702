import { Link } from "react-router-dom";
import ErrorImg from "../../images/401.svg";

export const Error401 = () => {
  return (
    <div className="tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <img src={ErrorImg} alt="Error-403" width={450} />
      <p className="tw-mb-2 tw-font-medium">
        Sorry something went wrong. Please login to continue.
      </p>
      <div>
        <Link
          to="/"
          className="tw-bg-primary tw-text-white tw-rounded-md tw-px-3 tw-py-2 tw-mx-2 hover:tw-text-white"
        >
          Login / Signup
        </Link>
      </div>
    </div>
  );
};
