import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import { Button } from "../../Button";

export const MeetingSchedule = ({
  open,
  onClose,
  data = [],
  scheduleMeeting,
  userData = {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      user: userData?.name,
    },
  });
  const [selectedUser, setSelectedUser] = useState({ fee: 0 });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data) => {
    if (Object.keys(userData).length > 0) {
      scheduleMeeting("chat", data, userData.userId);
    } else {
      scheduleMeeting("other", data);
    }
    onClose();
  };

  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;
    const selectedUserData = data.find(
      (user) => user.connection_id === selectedUserId
    );
    if (selectedUserData) {
      setSelectedUser(selectedUserData);
    }
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[40%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <div className="tw-p-3 tw-pb-0 tw-mt-2 tw-text-center">
          {data.length || Object.keys(userData).length > 0 ? (
            <form
              onSubmit={handleSubmit(submitModal)}
              className="tw-grid tw-grid-cols-2 tw-gap-2"
            >
              {data.length > 0 ? (
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                  <label htmlFor="user" className="tw-font-medium required">
                    User
                  </label>
                  <select
                    id="user"
                    name="user"
                    className="tw-border tw-border-slate-400 tw-rounded-md tw-p-2 tw-w-full"
                    {...register("user", {
                      required: "Please select a user",
                    })}
                    onChange={handleUserChange}
                  >
                    <option value="">Select User</option>
                    {data
                      ?.filter(
                        (user) => user.status === "1" && user.role !== "V&iR8"
                      )
                      .map((user) => (
                        <option
                          value={user.connection_id}
                          key={user.connection_id}
                        >
                          {user.name} -
                          {user.role === "f@!3A"
                            ? "startup"
                            : user.role === "R$7s2"
                            ? "mentor"
                            : "investor"}
                        </option>
                      ))}
                  </select>
                  {errors.user && (
                    <div>
                      <FormError message={errors.user.message} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                  <label htmlFor="user" className="tw-font-medium">
                    user
                  </label>
                  <input
                    type="text"
                    id="user"
                    name="user"
                    className="tw-border tw-border-slate-400 tw-p-1.5 tw-rounded-md tw-w-full"
                    {...register("user", {
                      required: "Please enter a user",
                    })}
                    disabled
                  />
                  {errors.user && (
                    <div>
                      <FormError message={errors.user.message} />
                    </div>
                  )}
                </div>
              )}
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <label htmlFor="mentorFee" className="tw-font-medium">
                  Fees ( tokens )
                </label>
                <input
                  type="text"
                  id="mentorFee"
                  name="mentorFee"
                  className="tw-border tw-border-slate-400 tw-p-1.5 tw-rounded-md tw-w-full tw-disabled"
                  {...register("mentorFee")}
                  value={selectedUser?.fee}
                  disabled
                />
                {errors.mentorFee && (
                  <div>
                    <FormError message={errors.mentorFee.message} />
                  </div>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <label htmlFor="title" className="tw-font-medium required">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="tw-border tw-border-slate-400 tw-p-1.5 tw-rounded-md tw-w-full"
                  {...register("title", {
                    required: "Please enter a title",
                  })}
                />
                {errors.title && (
                  <div>
                    <FormError message={errors.title.message} />
                  </div>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <label htmlFor="date" className="tw-font-medium required">
                  date
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="tw-border tw-border-slate-400 tw-p-1.5 tw-rounded-md tw-w-full"
                  {...register("date", { required: "Please select date" })}
                />
                {errors.date && (
                  <div>
                    <FormError message={errors.date.message} />
                  </div>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <label htmlFor="hour" className="tw-font-medium required">
                  Hour
                </label>
                <select
                  id="hour"
                  name="hour"
                  className="tw-border tw-border-slate-400 tw-rounded-md tw-p-2 tw-w-full"
                  {...register("hour", { required: "Please select a time" })}
                >
                  <option value="00">00</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                </select>
                {errors.hour && (
                  <div>
                    <FormError message={errors.hour.message} />
                  </div>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-justify-center">
                <label htmlFor="minutes" className="tw-font-medium required">
                  Minutes
                </label>
                <select
                  id="minutes"
                  name="minutes"
                  className="tw-border tw-border-slate-400 tw-rounded-md tw-p-2 tw-w-full"
                  {...register("minutes", { required: "Please select a time" })}
                >
                  <option value="00">00</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                </select>
                {errors.minutes && (
                  <div>
                    <FormError message={errors.minutes.message} />
                  </div>
                )}
              </div>
              <div className="tw-col-start-2 tw-text-end tw-mt-auto">
                <Button
                  type="submit"
                  className="tw-px-2 tw-py-1"
                  bgColor="tw-bg-primary"
                >
                  Schedule
                </Button>
              </div>
            </form>
          ) : (
            <p>
              You don't have any connections yet. Connect with a user to
              schedule a call.
            </p>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
