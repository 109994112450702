import ReactDom from "react-dom";
import { Button } from "../../Button";

export const DeleteModal = ({
  open,
  onClose,
  id,
  deleteFile,
  deleteBankDetails,
  deletePortfolioFile,
  deleteMethod,
  type,
  fileType,
}) => {
  if (!open) return null;

  const submitModal = () => {
    if (type === "file") {
      deleteFile(fileType, id);
    } else if (type === "bank") {
      deleteBankDetails(id);
    } else if (type === "portfolio") {
      deletePortfolioFile(id);
    } else {
      deleteMethod(id);
    }
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <div className="tw-p-3 tw-pb-0 tw-mt-2 tw-text-center">
          <p>Are you sure you want to Delete ?</p>
        </div>
        <div className="tw-flex tw-justify-center tw-p-3">
          <Button
            className="tw-px-2 tw-py-1 tw-mx-1"
            bgColor="tw-bg-secondary"
            onClick={submitModal}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
