import React, { useState } from "react";
import { Button, Pagination } from "../../../components/ui/Button";

export const WithdrawReq = ({ withdrawReqs, cancelWithdraw }) => {
  const [page, setPage] = useState(1);
  let sno = 1;
  return (
    <>
      <table className="tw-table-auto tw-w-full tw-mt-2 tw-border tw-shadow-sm tw-border-slate-300">
        <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
          <tr>
            <th className="tw-p-2 tw-font-medium tw-text-sm">No.</th>
            <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
              Amount
            </th>
            <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
              Date
            </th>
            <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
              Status
            </th>
            <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {withdrawReqs.length > 0 ? (
            withdrawReqs.slice(page * 7 - 7, page * 7).map((request) => (
              <tr
                key={request._id}
                className="tw-bg-white tw-border-b tw-border-slate-300"
              >
                <td className="tw-p-2 tw-text-sm tw-font-medium">{sno++}</td>
                <td className="tw-p-2 tw-text-sm">₹ {request.amount}</td>
                <td className="tw-p-2 tw-text-sm">{request.date}</td>
                <td className="tw-p-2 tw-text-sm">
                  <span
                    className={`tw-px-1 tw-text-sm tw-rounded-md tw-border ${
                      request.status === "0"
                        ? "tw-border-blue-500 tw-text-blue-500"
                        : request.status === "1"
                        ? "tw-border-green-500 tw-text-green-500"
                        : "tw-border-red-500 tw-text-red-500"
                    }`}
                  >
                    {request.status === "0"
                      ? "Processing"
                      : request.status === "1"
                      ? "Completed"
                      : "Rejected"}
                  </span>
                </td>
                <td className="tw-p-2 tw-text-sm">
                  {request.status === "0" ? (
                    <Button
                      className="tw-px-1 tw-py-0.5"
                      bgColor="tw-bg-secondary"
                      onClick={() => cancelWithdraw(request._id)}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className="disabled tw-px-1 tw-py-0.5"
                      bgColor="tw-bg-secondary"
                      disabled
                    >
                      Cancel
                    </Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr className="tw-text-center">
              <td colSpan={5} className="tw-p-2 tw-text-sm">
                No agreement found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {withdrawReqs.length > 0 && (
        <Pagination page={page} setPage={setPage} data={withdrawReqs} />
      )}
    </>
  );
};
