import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import PlanForm from "./planForm";

const EditPlan = ({ open, onClose, singlePlan, updateSubscription }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      planFor: singlePlan.type,
      planName: singlePlan.plan_type,
      planPrice: singlePlan.amount,
      planTokens: singlePlan.points,
      discount: singlePlan.discount_status,
      type: singlePlan.discount_type,
      discountAmount: singlePlan.discount,
    },
  });

  const [features, setFeatures] = useState([]);
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      if (singlePlan.plan_features) {
        setFeatures([...singlePlan.plan_features]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [singlePlan]);

  if (!open) return null;

  const submitModal = (data) => {
    if (features.length < 1) {
      document.getElementById("feature-required").classList.remove("tw-hidden");
      return;
    } else {
      document.getElementById("feature-required").classList.add("tw-hidden");
    }
    updateSubscription(data, features, singlePlan._id);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[750px] md:tw-max-w-[750px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <PlanForm
          submitModal={submitModal}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          features={features}
          setFeatures={setFeatures}
        />
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default EditPlan;
