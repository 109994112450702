import ReactDom from "react-dom";
import "../ticket/ticketModal.css";

export const ViewInvestmentHistory = ({
  open,
  onClose,
  agreementDetails,
  userRole,
}) => {
  if (!open) return null;
  let sno = 1;

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[75%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <div>
          <p onClick={onClose} className="closeBtn">
            X
          </p>
          <h4 className="modal-heading tw-text-black tw-font-semibold tw-text-center tw-border-b tw-p-3">
            Investment History
          </h4>
          <div className="tw-p-2">
            <p>
              <span className="tw-font-medium tw-me-1">Equity :</span>{" "}
              {agreementDetails?.equity}%
            </p>
            <p>
              <span className="tw-font-medium tw-me-1">
                Total Agreed Amount :
              </span>{" "}
              ₹ {agreementDetails?.amount}
            </p>
            <p>
              <span className="tw-font-medium tw-me-1">Released Amount :</span>{" "}
              ₹ {agreementDetails?.released_amount}
            </p>
            <p>
              <span className="tw-font-medium tw-me-1">Pending Amount :</span> ₹{" "}
              {agreementDetails?.pending_amount}
            </p>
            {userRole === "f@!3A" && (
              <>
                <p>
                  <span className="tw-font-medium tw-me-1">Platform Fee :</span>
                  {agreementDetails?.platform_fee}%
                </p>
                <p className="tw-text-xs">
                  <span className="tw-font-semibold tw-me-1">Note* :</span>
                  Withdrawable amount is ( released amount - platform fee )
                </p>
              </>
            )}
            <table className="tw-table-auto tw-w-full tw-mt-4 tw-border tw-shadow-sm tw-border-slate-300">
              <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
                <tr>
                  <th className="tw-p-2 tw-font-medium tw-text-sm">S.No</th>
                  <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
                    Released Amount
                  </th>
                  {userRole === "f@!3A" && (
                    <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
                      Withdrawable Amount
                    </th>
                  )}
                  <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
                    Released Date
                  </th>
                  <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
                    Admin approval
                  </th>
                  <th className="tw-p-2 tw-font-medium tw-text-sm tw-whitespace-nowrap">
                    Transfered to startup
                  </th>
                </tr>
              </thead>
              <tbody>
                {agreementDetails?.transactions_history.length > 0 ? (
                  agreementDetails?.transactions_history
                    .slice()
                    .reverse()
                    .map((transaction) => (
                      <tr
                        key={transaction._id}
                        className="tw-bg-white tw-border-b tw-border-slate-300"
                      >
                        <td className="tw-p-2 tw-text-sm">{sno++}</td>
                        <td className="tw-p-2 tw-text-sm">
                          {transaction.amount}
                        </td>
                        {userRole === "f@!3A" && (
                          <td className="tw-p-2 tw-text-sm">
                            {transaction.withdrable_amount}
                          </td>
                        )}
                        <td className="tw-p-2 tw-text-sm">
                          {transaction.date}
                        </td>
                        <td
                          className={`tw-p-2 tw-text-sm ${
                            transaction.status_by_admin === "0"
                              ? "tw-text-orange-500"
                              : transaction.status_by_admin === "1"
                              ? "tw-text-green-500"
                              : "tw-text-red-500"
                          }`}
                        >
                          {transaction.status_by_admin === "0"
                            ? "Pending"
                            : transaction.status_by_admin === "1"
                            ? "Completed"
                            : "Rejected"}
                        </td>
                        <td
                          className={`tw-p-2 tw-text-sm ${
                            transaction.transfer_to_startup_status === "0"
                              ? "tw-text-orange-500"
                              : "tw-text-green-500"
                          }`}
                        >
                          {transaction.transfer_to_startup_status === "0"
                            ? "Pending"
                            : "Completed"}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr className="tw-text-center">
                    <td colSpan={5}>No Transaction Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
