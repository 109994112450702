import { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import {
  bannedInputs,
  numberRegex,
  textWithSpaceRegex,
} from "../../../../constants";
import {
  BriefCaseIcon,
  BuildingIcon,
  DownArrowIcon,
  PhoneIcon,
  RupeeIcon,
  UpArrowIcon,
  UserIcon,
} from "../../../../icons/icons";
import Dropdown from "../../inputs/dropdown";

export const EditMentorProfile = ({
  open,
  onClose,
  updateMentoDetials,
  editData,
  industries,
}) => {
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const ref = useRef(false);
  const menuRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mentorName: editData.name,
      mentorMobile: editData.phone,
      mentorExperience: editData.work_experience,
      mentorPastCompany: editData.previous_company,
      mentorFee: editData.fee,
    },
  });

  useEffect(() => {
    if (!ref.current) {
      if (editData?.mentor_industry) {
        setSelectedIndustry([...editData.mentor_industry]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [editData]);

  if (!open) return null;

  const submitModal = (data) => {
    if (selectedIndustry.length < 1) {
      document.getElementById("industry-error").classList.remove("tw-hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("industry-limit").classList.remove("tw-hidden");
      return;
    }
    document.getElementById("industry-error").classList.add("tw-hidden");
    document.getElementById("industry-limit").classList.add("tw-hidden");
    updateMentoDetials(data, selectedIndustry);
    setSelectedIndustry([]);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[80%] md:tw-min-w-[700px] md:tw-max-w-[700px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3 tw-p-3 tw-mt-7"
        >
          <div className="tw-mb-1">
            <label htmlFor="mentorName" className="tw-mb-0 tw-text-sm">
              Edit Name
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="mentorName"
                name="mentorName"
                placeholder="Enter Name"
                autoFocus
                {...register("mentorName", {
                  validate: {
                    validLength: (fieldValue) => {
                      return fieldValue.trim().length > 0
                        ? fieldValue.trim().length > 2 ||
                            "minimum required length is 3"
                        : null;
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                })}
              />
              <span className="input-icons tw-cursor-pointer icon-span tw-w-[39px]">
                <UserIcon />
              </span>
            </div>
            {errors.mentorName && (
              <div>
                <FormError message={errors.mentorName.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="mentorMobile" className="tw-mb-0 tw-text-sm">
              Change Contact Number
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                maxLength="12"
                minLength="7"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="mentorMobile"
                name="mentorMobile"
                placeholder="Enter Phone number"
                {...register("mentorMobile", {
                  validate: {
                    minValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length > 6 ||
                            "Number should be atleast 7 digits"
                        : null;
                    },
                    maxValue: (fieldValue) => {
                      return fieldValue.length > 0
                        ? fieldValue.length < 13 ||
                            "Number cannot be more than 12 digits"
                        : null;
                    },
                  },
                  pattern: {
                    value: numberRegex,
                    message: "Number must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span tw-w-[39px]">
                <PhoneIcon />
              </span>
            </div>
            {errors.mentorMobile && (
              <div>
                <FormError message={errors.mentorMobile.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="mentorExperience" className="tw-mb-0 tw-text-sm">
              Years of Experience
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="mentorExperience"
                name="mentorExperience"
                placeholder="Enter Work Experience"
                {...register("mentorExperience", {
                  pattern: {
                    value: numberRegex,
                    message: "Experience must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span tw-w-[39px]">
                <BriefCaseIcon />
              </span>
            </div>
            {errors.mentorExperience && (
              <div>
                <FormError message={errors.mentorExperience.message} />
              </div>
            )}
          </div>
          <div className="tw-flex-col" ref={menuRef}>
            <p className="tw-text-sm">
              Industry <span>({selectedIndustry.length + "/3"})</span>
              <span
                id="industry-limit"
                className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
              >
                Limit exceeded
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={selectedIndustry.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setSelectedIndustry(
                  selectedOptions.map((option) => option.value)
                )
              }
              isMulti
            />
            <small
              className="tw-text-red-500 tw-mb-2 tw-hidden"
              id="industry-error"
            >
              This field is mandatory.
            </small>
          </div>
          <div className="tw-mb-1">
            <label htmlFor="mentorPastCompany" className="tw-mb-0 tw-text-sm">
              Previous Company
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="mentorPastCompany"
                name="mentorPastCompany"
                placeholder="Enter Company Name"
                {...register("mentorPastCompany", {
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                  validate: (fieldValue) => {
                    return fieldValue.trim().length > 0
                      ? !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      : null;
                  },
                })}
              />
              <span className="input-icons tw-cursor-pointer icon-span tw-w-[39px]">
                <BuildingIcon />
              </span>
            </div>
            {errors.mentorPastCompany && (
              <div>
                <FormError message={errors.mentorPastCompany.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label htmlFor="mentorFee" className="tw-mb-0 tw-text-sm">
              Session Fees
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="mentorFee"
                name="mentorFee"
                placeholder="Enter Session Fees"
                {...register("mentorFee", {
                  pattern: {
                    value: numberRegex,
                    message: "Invalid amount, must contain only digits",
                  },
                  validate: (fieldValue) => {
                    return fieldValue > 600 || "Fee cannot be less tha 600";
                  },
                })}
              />
              <span className="input-icons tw-cursor-pointer icon-span tw-w-[39px]">
                <RupeeIcon />
              </span>
            </div>
            {errors.mentorFee && (
              <div>
                <FormError message={errors.mentorFee.message} />
              </div>
            )}
          </div>
          <div className="md:tw-col-span-2 tw-flex tw-items-center tw-justify-end tw-p-3">
            <Button
              onClick={onClose}
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button
              type="submit"
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
