import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import loginSlice from "./slices/LoginSlice";
import chatSlice from "./slices/ChatSlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "advisorToken",
  storage,
};

const reducer = combineReducers({
  users: loginSlice,
  chatRoom: chatSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
let persistor = persistStore(Store);

export default Store;
export { persistor };
