import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useDisplayIndustries from "../../../hooks/useDisplayIndustries";
import { useToastContext } from "../../../context/ToastContext";
import { randomId } from "../../../utils/randomId";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/Error";
import { Button } from "../../../components/ui/Button";
import { AddIndustryModal } from "../../../components/ui/modals/industry/addIndustryModal";
import IndustryTable from "./industryTable";

const Industries = () => {
  const addIndustryApi = `${process.env.REACT_APP_API_URL}add-industry`;
  const editIndustryApi = `${process.env.REACT_APP_API_URL}edit-industry`;
  const deleteIndustryApi = `${process.env.REACT_APP_API_URL}delete-industry`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const toast = useToastContext();
  const { displayIndustries } = useDisplayIndustries();

  const [industries, setIndustries] = useState([]);
  const [selectedRole, setSelectedRole] = useState("other");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openIndustryModal, setOpenIndustryModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedIndustry, setSelectedIndustry] = useState({
    industry: "",
    role: "",
    id: null,
  });

  const addIndustry = (industry, role) => {
    fetchApi(
      {
        url: addIndustryApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          industry_name: industry,
          role: role,
        },
      },
      (data) => {
        if (data.message === "industry exist") {
          toast.open("false", "Industry exist");
          return;
        }
        displayIndustries((data) => setIndustries(data.industries));
      }
    );
  };

  const updateIndustry = (id, data, field) => {
    let bodyData = { id: id };
    if (field === "status") {
      bodyData = { ...bodyData, status: data };
    } else {
      bodyData = {
        ...bodyData,
        industry_name: data.industry_name,
        role: data.role,
      };
    }
    fetchApi(
      {
        url: editIndustryApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: bodyData,
      },
      (data) => {
        if (data.message === "industry exist") {
          toast.open("false", "Industry exist");
          return;
        }
        setSelectedIndustry({
          industry: "",
          role: "",
          id: null,
        });
        displayIndustries((data) => setIndustries(data.industries));
      }
    );
  };

  const deleteIndustry = (id) => {
    fetchApi(
      {
        url: deleteIndustryApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          id: id,
        },
      },
      () => {
        displayIndustries((data) => setIndustries(data.industries));
      }
    );
  };

  useEffect(() => {
    const fetchIndustries = setTimeout(() => {
      displayIndustries((data) => setIndustries(data.industries), "admin");
    }, 20);
    return () => {
      clearTimeout(fetchIndustries);
    };
  }, [displayIndustries, fetchApi, token]);

  const handleIndustryModal = (type, industry, role, id) => {
    if (type === "edit") {
      setSelectedIndustry({
        industry: industry,
        role: role,
        id: id,
      });
      setOpenIndustryModal(true);
    } else {
      setOpenIndustryModal(true);
    }
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!isLoading && !error && (
          <div className="tw-p-5">
            <Button
              className="tw-block tw-w-[100px] tw-p-1 tw-ml-auto"
              onClick={() => setOpenIndustryModal(true)}
            >
              Add Plan
            </Button>
            <p className="tw-col-span-3 tw-mb-3 tw-font-medium tw-text-sm">
              Note : Use toggle switch to activate/deactive subscription plan.
            </p>
            <div>
              <span
                className={`${
                  selectedRole === "other"
                    ? "tw-bg-primary tw-text-white"
                    : "tw-bg-gray-200"
                } tw-px-2 tw-py-1 tw-rounded-l-md tw-border-r-2 tw-border-gray-400`}
                onClick={() => {
                  setSelectedRole("other");
                  setPage(1);
                }}
              >
                Other
              </span>
              <span
                className={`${
                  selectedRole === "service provider"
                    ? "tw-bg-primary tw-text-white"
                    : "tw-bg-gray-200"
                } tw-px-2 tw-py-1 tw-rounded-r-md`}
                onClick={() => {
                  setSelectedRole("service provider");
                  setPage(1);
                }}
              >
                Service Provider
              </span>
            </div>
            <IndustryTable
              page={page}
              setPage={setPage}
              industries={industries}
              type={selectedRole}
              handleIndustryModal={handleIndustryModal}
              deleteIndustry={deleteIndustry}
            />
          </div>
        )}
      </div>
      <AddIndustryModal
        key={randomId()}
        open={openIndustryModal}
        onClose={() => {
          setOpenIndustryModal(false);
          setSelectedIndustry({
            industry: "",
            role: "",
            id: null,
          });
        }}
        addIndustry={addIndustry}
        updateIndustry={updateIndustry}
        selectedIndustry={selectedIndustry}
        setSelectedIndustry={setSelectedIndustry}
      />
    </div>
  );
};

export default Industries;
