import { useCallback } from "react";
import useFetch from "./useFetch";

const useSearchApi = () => {
  const { fetchApi } = useFetch();
  const search = useCallback(
    (url, token, searchInput, callBackFn) => {
      fetchApi(
        {
          url: url,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            search_data: searchInput,
          },
        },
        (data) => callBackFn(data)
      );
    },
    [fetchApi]
  );
  return { search };
};

export default useSearchApi;
