import React from "react";

const RecentInvestments = ({ data }) => {
  return (
    <table
      className="tw-table-auto tw-w-full tw-border tw-shadow-sm tw-border-slate-300"
      id="recent-transaction-table"
    >
      <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
        <tr>
          <th className="tw-py-2 tw-ps-1 tw-font-medium tw-text-xs sm:tw-text-sm">
            Startup
          </th>
          <th className="tw-py-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Date
          </th>
          <th className="tw-py-2 tw-font-medium tw-text-xs sm:tw-text-sm">
            Amount
          </th>
          <th className="tw-py-2 tw-pe-1 tw-font-medium tw-text-xs sm:tw-text-sm">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((investment) => (
            <tr
              key={investment.id}
              className="tw-bg-white tw-border-b tw-border-slate-300"
            >
              <td className="tw-py-2 tw-ps-1 tw-text-xs sm:tw-text-sm tw-capitalize">
                {investment.startup_name}
              </td>
              <td className="tw-py-2 tw-text-xs sm:tw-text-sm">
                {investment.date}
              </td>
              <td className="tw-py-2 tw-text-xs sm:tw-text-sm">
                {investment.amount}
              </td>
              <td className="tw-pe-1">
                <span
                  className={`tw-p-1 tw-text-xs tw-font-medium tw-rounded-md ${
                    investment.status === "0"
                      ? "tw-bg-orange-400 tw-text-orange-800"
                      : "tw-bg-green-400 tw-text-green-800"
                  }`}
                >
                  {investment.status === "0" ? "Pending" : "Completed"}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr className="tw-text-center">
            <td colSpan={4} className="tw-py-2 tw-text-xs sm:tw-text-sm">
              No investments found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RecentInvestments;
