import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import { useToastContext } from "../../context/ToastContext";
import { randomId } from "../../utils/randomId";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { EditBidModal } from "../../components/ui/modals/market/editBidModal";
import { Pagination } from "../../components/ui/Button";
import { DeleteIcon, EyeIcon, PenIcon } from "../../icons/icons";

const MyBids = () => {
  const getBidsApi = `${process.env.REACT_APP_API_URL}display-my-bids`;
  const editBidApi = `${process.env.REACT_APP_API_URL}edit-bid`;
  const deleteBidApi = `${process.env.REACT_APP_API_URL}delete-bid`;

  const { token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();
  const toast = useToastContext();

  const [bids, setBids] = useState([]);
  const [bidData, setBidData] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);

  const getBids = useCallback(() => {
    fetchApi(
      {
        url: getBidsApi,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setBids(data.bids);
      }
    );
  }, [getBidsApi, fetchApi, token]);

  const editBid = (data, id) => {
    fetchApi(
      {
        url: editBidApi,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: id,
          bid_amount: data.bidAmount,
          bid_deadline: data.workDuration,
          bid_description: data.bidDescription,
        },
      },
      (data) => {
        if (data.message === "awarded") {
          toast.open("warning", "Cannot edit awarded Bid");
          return;
        }
        getBids();
      }
    );
  };

  const deleteBid = (bidId) => {
    fetchApi(
      {
        url: deleteBidApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: bidId,
        },
      },
      (data) => {
        if (data.message === "awarded") {
          toast.open("warning", "Cannot delete awarded Bid");
          return;
        }
        getBids();
      }
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getBids();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getBids]);

  const handleBidModal = (data) => {
    setBidData(data);
    setOpenModal(true);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="tw-p-3">
            <Error />
          </div>
        )}
        {!error && !isLoading && (
          <>
            <div className="tw-p-3 md:tw-p-6 tw-grid tw-gap-4">
              <h4 className="tw-text-xl tw-font-medium">My Bids</h4>
              <span className="tw-text-xs">
                <span className="tw-font-medium tw-pe-1">Note :</span> The
                awarded amount is freezed and is only transfered to your account
                once the project is marked as completed by startup.
              </span>
              <div className="tw-max-w-fit tw-overflow-auto md:tw-max-w-none md:tw-overflow-none md:tw-w-full">
                <table className="tw-table-auto tw-min-w-max md:tw-w-full tw-border">
                  <thead className="tw-bg-primary tw-text-white tw-border-b tw-border-slate-300 tw-text-left">
                    <tr>
                      <th className="tw-py-2 tw-px-1 tw-text-sm tw-w-6/12 tw-font-medium">
                        Project Title
                      </th>
                      <th className="tw-py-2 tw-px-1 tw-text-sm tw-font-medium">
                        Amount
                      </th>
                      <th className="tw-py-2 tw-px-1 tw-text-sm tw-font-medium">
                        Deadline
                      </th>
                      <th className="tw-py-2 tw-px-1 tw-text-sm tw-font-medium">
                        Status
                      </th>
                      <th className="tw-py-2 tw-px-1 tw-text-sm tw-font-medium">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bids.length > 0 ? (
                      bids.slice(page * 7 - 7, page * 7).map((bid) => (
                        <tr
                          key={bid.bid_id}
                          className="tw-bg-white tw-border-b tw-border-slate-300"
                        >
                          <td className="tw-py-1.5 tw-px-1 tw-text-sm">
                            {bid.title}
                          </td>
                          <td className="tw-py-1.5 tw-px-1 tw-text-sm">
                            {bid.bid_amount}
                          </td>
                          <td className="tw-py-1.5 tw-px-1 tw-text-sm">
                            {bid.bid_deadline}
                          </td>
                          <td
                            className={`tw-py-1.5 tw-px-1 tw-text-sm ${
                              bid.status === "0" && bid.bid_status === "0"
                                ? "tw-text-blue-500"
                                : bid.status === "1" && bid.bid_status === "0"
                                ? "tw-text-red-500"
                                : bid.status === "2" && bid.bid_status === "0"
                                ? "tw-text-red-500"
                                : bid.status === "1" && bid.bid_status === "1"
                                ? "tw-text-orange-500"
                                : "tw-text-green-500"
                            }`}
                          >
                            {bid.status === "0" && bid.bid_status === "0"
                              ? "Active"
                              : bid.status === "1" && bid.bid_status === "0"
                              ? "Not Awarded"
                              : bid.status === "2" && bid.bid_status === "0"
                              ? "Not Awarded"
                              : bid.status === "1" && bid.bid_status === "1"
                              ? "Awarded"
                              : bid.status === "2" && bid.bid_status === "2"
                              ? "Completed"
                              : null}
                          </td>
                          <td className="tw-py-1.5 tw-px-1 tw-text-sm">
                            <p className="tw-flex tw-justify-between tw-items-center">
                              <Link to={`/bids/${bid.ad_id}`}>
                                <EyeIcon
                                  fill="#22C55E"
                                  className="tw-mx-1 tw-cursor-pointer"
                                />
                              </Link>
                              {bid.status === "0" ? (
                                <PenIcon
                                  fill="#3B82F6"
                                  className="tw-mx-1 tw-cursor-pointer"
                                  onClick={() =>
                                    bid.status === "0" && handleBidModal(bid)
                                  }
                                />
                              ) : (
                                <PenIcon
                                  fill="#3B82F6"
                                  className="disabled tw-mx-1"
                                />
                              )}
                              {bid.status === "0" ? (
                                <DeleteIcon
                                  className="tw-mx-1 tw-cursor-pointer"
                                  onClick={() => {
                                    bid.status === "0" && deleteBid(bid.bid_id);
                                  }}
                                />
                              ) : (
                                <DeleteIcon className="tw-mx-1 disabled" />
                              )}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="tw-text-center">
                        <td colSpan={5} className="tw-py-1.5 tw-text-sm">
                          No Bid posted
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {bids.length > 0 && (
              <Pagination page={page} setPage={setPage} data={bids} />
            )}
          </>
        )}
      </div>
      <EditBidModal
        key={randomId()}
        open={openModal}
        onClose={() => setOpenModal(false)}
        bidData={bidData}
        editBid={editBid}
      />
    </div>
  );
};

export default MyBids;
