import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import useUploadImage from "../../hooks/useUploadImage";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { randomId } from "../../utils/randomId";
import { ImportImage } from "../../utils/importImage";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { BankAccountModal } from "../../components/ui/modals/bank/bankAccountModal";
import { DeleteModal } from "../../components/ui/modals/utility-modals/deleteModal";
import { EditMentorProfile } from "../../components/ui/modals/profile/editMentorProfile";
import { EditBankAccountModal } from "../../components/ui/modals/bank/editBankAccountModal";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import certifiedLogo from "../../images/certified.png";
import { CameraIcon, DeleteIcon, PenIcon } from "../../icons/icons";

const MentorProfile = () => {
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}display-mentor-profile`;
  const editMentorProfileApi = `${process.env.REACT_APP_API_URL}update-mentor-profile`;
  const deleteBankDetailsApi = `${process.env.REACT_APP_API_URL}delete-bank-details`;
  const uploadVideoApi = `${process.env.REACT_APP_API_URL}upload-video`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { uploadImage } = useUploadImage();
  const { displayFiles } = useDisplayFiles();
  const { updateProfile } = useUpdateProfile();
  const { displayIndustries } = useDisplayIndustries();
  const navigate = useNavigate();
  const ref = useRef(true);

  const [idea, setIdea] = useState("");
  const [video, setVideo] = useState([]);
  const [open, setOpen] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openMentorProfile, setOpenMentorProfile] = useState(false);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [editData, setEditData] = useState({});
  const [mentorBank, setMentorBank] = useState([]);
  const [verifiedStartups, setVerifiedStartups] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openEditBank, setOpenEditBank] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [singleBank, setSingleBank] = useState([]);

  const getMentorData = useCallback(() => {
    fetchApi(
      {
        url: mentorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setMentorDetails(data.mentor_profile);
        setEditData(...data.mentor_profile);
        setProfileImg(data.mentor_profile[0].logo);
        setIdea(data.mentor_profile[0].short_description);
        setMentorBank(data.bank_details);
        setVerifiedStartups(data.verified_startups);
      }
    );
  }, [fetchApi, token, mentorDetailsApi]);

  const updateMentoDetials = (data, industry) => {
    let dataObj = {
      name: data.mentorName,
      phone: data.mentorMobile,
      work_experience: data.mentorExperience,
      previous_company: data.mentorPastCompany,
      fee: data.mentorFee,
    };
    if (industry.length > 0) {
      dataObj = { ...dataObj, mentor_industry: industry };
    }
    updateProfile(editMentorProfileApi, dataObj, getMentorData, "mentor");
  };

  const addVideo = (newFile) => {
    const formData = new FormData();
    formData.append("video", newFile);
    axios
      .post(uploadVideoApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        displayFiles();
      })
      .catch((err) => console.log(err));
  };

  const deleteBankDetails = (bankId) => {
    fetchApi(
      {
        url: deleteBankDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: bankId,
        },
      },
      () => getMentorData()
    );
  };

  useEffect(() => {
    if (userRole !== "R$7s2") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getMentorData();
      displayFiles((data) => setVideo(data.video));
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
    return () => {
      ref.current = false;
    };
  }, [getMentorData, displayFiles, userRole, navigate, displayIndustries]);

  const handleModal = (type, data) => {
    if (type === "edit") {
      setSingleBank(data);
      setOpenEditBank(true);
    } else {
      setOpen(true);
    }
  };

  const handleDeleteModal = (id) => {
    setSingleBank(id);
    setOpenDeleteModal(true);
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-3 md:tw-p-4">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <div>
              <div
                className="tw-relative tw-hidden md:tw-block tw-bg-cover tw-h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div className="tw-px-2 md:tw-px-5 md:tw-shadow-md md:tw-rounded-md">
                <div className="tw-grid tw-grid-cols-6">
                  <div className="tw-col-span-6 tw-ml-auto md:tw-hidden">
                    <button
                      type="button"
                      className="profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-px-2 tw-py-1 tw-mx-1 tw-rounded-md  tw-text-center tw-text-xs"
                      onClick={() => setOpenMentorProfile(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="tw-col-span-6 md:tw-col-span-1 tw-relative tw-flex tw-items-end tw-justify-center">
                    <img
                      src={profileImg.length > 0 ? profileImg : FavIcon}
                      className="profile-img tw-shadow tw-w-[90px] tw-h-[90px] md:tw-w-[140px] md:tw-h-[140px]"
                      alt="profile-img"
                    />
                    <div className="img-icon tw-bg-primary tw-rounded-full tw-p-1">
                      <label
                        htmlFor="upload-img"
                        className="tw-mb-0 tw-cursor-pointer"
                      >
                        <CameraIcon fill="#fff" />
                      </label>
                      <input
                        type="file"
                        id="upload-img"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], getMentorData)
                        }
                        hidden
                      />
                    </div>
                    {profileImg.length < 1 && (
                      <p className="tw-text-red-500 tw-font-medium">
                        Upload image
                      </p>
                    )}
                  </div>
                  <div className="tw-col-span-6 md:tw-col-span-5 tw-relative tw-py-2 md:tw-pl-[3.5rem] 2xl:tw-pl-0 tw-mb-5">
                    <button
                      type="button"
                      className="tw-hidden md:tw-block profile-btns tw-bg-primary tw-text-white tw-mt-2 tw-text-center tw-px-2 tw-py-1 tw-rounded-md tw-absolute tw-right-0 md:tw-top-unset md:tw-bottom-2.5 tw-text-xs md:tw-text-sm"
                      onClick={() => setOpenMentorProfile(true)}
                    >
                      Edit
                    </button>
                    {mentorDetails.map((mentor) => (
                      <div key={mentor.user_id}>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-mt-10 tw-gap-y-1 md:tw-mt-0">
                          <div className="md:tw-col-span-3 tw-grid tw-grid-cols-2">
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-flex tw-items-center tw-font-bold">
                              Name :
                              <span className="tw-flex tw-items-center tw-ms-1 tw-uppercase tw-font-medium">
                                {mentor.name}
                                <img
                                  src={certifiedLogo}
                                  alt="verified logo"
                                  width={40}
                                  className="tw-pl-1"
                                />
                              </span>
                            </p>
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-flex tw-items-center tw-font-bold">
                              Email :
                              <span className="tw-ms-1 tw-font-medium">
                                {mentor.email}
                              </span>
                            </p>
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                              Years of Experience :
                              <span className="tw-ms-1 tw-font-medium">
                                {mentor.work_experience}
                              </span>
                            </p>
                            <p className="tw-col-span-2 md:tw-col-span-1 tw-font-bold">
                              Company :
                              <span className="tw-ms-1 tw-capitalize tw-font-medium">
                                {mentor.previous_company}
                              </span>
                            </p>
                          </div>
                          <p className="md:tw-col-span-3 tw-font-bold">
                            Industries worked In :
                            <span className="tw-font-medium tw-ms-1">
                              {mentor.mentor_industry.map((industry, i) => (
                                <span className="outline-tags" key={i}>
                                  {industry.replace(new RegExp("_", "g"), " ")}
                                </span>
                              ))}
                            </span>
                          </p>
                          <p className="tw-font-bold">
                            Session Charges:
                            <span className="tw-ms-1 tw-capitalize tw-font-medium">
                              ₹ {mentor.fee}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-mx-0 tw-px-0">
                <div className="ideaContainer tw-col-span-2 md:tw-col-span-1 tw-mt-3 tw-shadow-md tw-rounded-md tw-p-3 tw-mb-2 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <h5 className="tw-font-semibold">About Me</h5>
                    <p
                      className="outline-btn tw-text-xs md:tw-text-sm"
                      type="button"
                      onClick={() => setDescriptionModal(true)}
                    >
                      Write here
                    </p>
                  </div>
                  {idea.length > 0 ? (
                    <p className="tw-mt-5">{idea}</p>
                  ) : (
                    <p className="tw-mt-5">
                      Add short description about your company and work
                    </p>
                  )}
                </div>
                <div className="documentsContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-mb-2 tw-mt-3 tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <h5 className="tw-font-semibold">Video About Me</h5>
                    <label
                      className="outline-btn tw-text-xs md:tw-text-sm"
                      htmlFor="mentor-video"
                    >
                      Upload video
                    </label>
                    <input
                      type="file"
                      id="mentor-video"
                      accept="video/mp4,video/x-m4v,video/*"
                      hidden
                      onChange={(e) => addVideo(e.target.files[0])}
                    />
                  </div>
                  {video.length > 0 ? (
                    <div className="tw-grid tw-grid-cols-1 tw-mt-3">
                      <div className="tw-mt-4">
                        <video
                          controls
                          className="tw-w-full tw-h-[300px]"
                          id="videoFile"
                          src={video}
                        ></video>
                      </div>
                    </div>
                  ) : (
                    <p>No Video found.</p>
                  )}
                </div>
                <div className="bankDetailsContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
                    <h5 className="tw-font-semibold">Bank Details</h5>
                    {mentorBank.length < 1 && (
                      <p
                        className="outline-btn tw-text-xs md:tw-text-sm"
                        onClick={() => handleModal("add")}
                        type="button"
                      >
                        Add new
                      </p>
                    )}
                  </div>
                  <div
                    className="tw-grid tw-grid-cols-1 tw-mt-3"
                    id="bank-details"
                  >
                    {mentorBank.length > 0 ? (
                      mentorBank.map((bank) => (
                        <div
                          className="profile-detail-card tw-relative"
                          key={bank.id}
                        >
                          <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-rounded-md tw-bg-gray-200 hover:tw-opacity-50">
                            <div>
                              <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                                Account Holder:
                                <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                                  {bank.name}
                                </span>
                              </p>
                              <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                                Account Number:
                                <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                                  {bank.account_number}
                                </span>
                              </p>
                            </div>
                            <ImportImage
                              imageName={bank.bank_name}
                              width={45}
                              alt={bank.bank_name}
                            />
                          </div>
                          <DeleteIcon
                            className="delete-account-btn tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer"
                            onClick={() => handleDeleteModal(bank.id)}
                          />
                          <PenIcon
                            fill="#22C55E"
                            className="edit-account-details tw-absolute tw-right-[10%] tw-top-0 tw-cursor-pointer"
                            onClick={() => handleModal("edit", bank)}
                          />
                        </div>
                      ))
                    ) : (
                      <p id="noBankDetails">No Bank Account found.</p>
                    )}
                  </div>
                </div>
                <div className="verifiedStartupsContainer tw-col-span-2 md:tw-col-span-1 tw-shadow-md tw-rounded-md tw-p-3 tw-relative">
                  <h5 className="tw-font-semibold">Startups verified by you</h5>
                  {verifiedStartups.length > 0 ? (
                    verifiedStartups.map((review) => (
                      <div
                        key={review.startup_id}
                        className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 tw-mt-3"
                      >
                        <div className="tw-flex tw-justify-between tw-items-center tw-p-2 tw-bg-gray-200 tw-rounded-md">
                          <div className="tw-flex tw-items-center">
                            <img
                              src={
                                review.logo.length > 0 ? review.logo : FavIcon
                              }
                              alt="company-logo"
                              className="tw-w-[40px] tw-h-[40px] md:tw-w-[50px] md:tw-h-[50px] tw-rounded-full"
                            />
                            <p className="tw-ms-2 tw-font-medium">
                              {review.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="tw-mt-3">
                      You have not verified any startups yet.
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
        <BankAccountModal
          open={open}
          onClose={() => setOpen(false)}
          callBackFn={getMentorData}
        />
        <EditBankAccountModal
          key={singleBank.id}
          open={openEditBank}
          onClose={() => setOpenEditBank(false)}
          singleBank={singleBank}
          bankId={singleBank.id}
          callBackFn={getMentorData}
        />
        <DeleteModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          deleteBankDetails={deleteBankDetails}
          id={singleBank.id}
          type="bank"
        />
        <EditMentorProfile
          key={randomId()}
          open={openMentorProfile}
          onClose={() => setOpenMentorProfile(false)}
          updateMentoDetials={updateMentoDetials}
          editData={editData}
          industries={industries}
        />
        <DescriptionModal
          key={new Date()}
          open={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          callBackFn={() => getMentorData()}
          url={editMentorProfileApi}
          data={idea}
        />
      </div>
    </div>
  );
};

export default MentorProfile;
