import React from "react";
import { Link } from "react-router-dom";

const AmountCard = ({ credits, profile_completion, userRole }) => {
  return (
    <div className="tw-card tw-relative tw-mt-5 tw-px-5 tw-py-7 tw-overflow-hidden balance-widget">
      {userRole !== "Sw%aa" ? (
        <>
          <span className="tw-text-gray-500 tw-font-medium">
            Remaining Balance
          </span>
          <h4 className="tw-mt-1 tw-font-medium tw-text-lg">
            <span>{credits}</span>
            <span className="tw-text-gray-500 tw-text-sm tw-ms-1">Tokens</span>
          </h4>
          {userRole === "f@!3A" ? (
            <Link
              className="tw-block tw-px-3 tw-py-1.5 tw-mb-3 tw-text-xs tw-w-max button tw-bg-primary tw-text-white tw-font-medium tw-rounded-md hover:tw-text-white"
              to="/subscription"
            >
              Top Up Balance
            </Link>
          ) : (
            <Link
              className="tw-block tw-px-3 tw-py-1.5 tw-mb-3 tw-text-xs tw-w-max button tw-bg-primary tw-text-white tw-font-medium tw-rounded-md hover:tw-text-white"
              to="/mentor-wallet"
            >
              Withdraw
            </Link>
          )}
        </>
      ) : (
        <span className="tw-w-8/12 tw-mb-3 tw-text-gray-500 tw-font-medium tw-text-sm">
          Complete your profile to find a better match
        </span>
      )}
      <div className="tw-mt-2">
        <p className="tw-mb-1.5 tw-text-gray-500 tw-font-semibold tw-text-xs">
          Profile Completetion
        </p>
        <div className="tw-w-[60%] tw-bg-gray-400 tw-rounded-full">
          <div
            className="tw-bg-[#F97316] tw-text-xs tw-font-medium tw-text-blue-100 tw-text-center tw-p-0.5 tw-leading-none tw-rounded-full"
            style={{ width: `${profile_completion}%` }}
          >
            {profile_completion}%
          </div>
        </div>
      </div>
      <div className="mobile-right-img tw-flex">
        <img
          className="left-mobile-img"
          src="../assets/images/dashboard-2/widget-img.png"
          alt=""
        />
        <img
          className="mobile-img"
          src="../assets/images/dashboard-2/mobile.gif"
          alt="mobile with coin"
        />
      </div>
    </div>
  );
};

export default AmountCard;
