import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import "../../../../custom.css";
import { IndustryIcon } from "../../../../icons/icons";
import { textWithSpaceRegex, bannedInputs } from "../../../../constants";
import { FormError } from "../../FormError";

export const AddIndustryModal = ({
  open,
  onClose,
  addIndustry,
  updateIndustry,
  selectedIndustry,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      newIndustry: selectedIndustry.industry.replace(new RegExp("_", "g"), " "),
      industryRole: selectedIndustry.role || "other",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  if (!open) return null;

  const submitModal = (data) => {
    const newIndustry = data.newIndustry
      .trim()
      .toLowerCase()
      .replace(new RegExp(" ", "g"), "_");
    if (selectedIndustry.industry.length > 0) {
      updateIndustry(selectedIndustry.id, {
        industry_name: newIndustry,
        role: data.industryRole,
      });
    } else {
      addIndustry(newIndustry, data.industryRole);
    }
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <form onSubmit={handleSubmit(submitModal)} className="tw-p-3">
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="newIndustry"
            >
              New Industry
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="newIndustry"
                name="newIndustry"
                placeholder="Enter Industry"
                autoFocus
                {...register("newIndustry", {
                  required: "Industry is required",
                  validate: {
                    validLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 1 ||
                        "minimum required length is 2"
                      );
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Invalid Industry name"
                      );
                    },
                  },
                })}
              />
              <span className="input-icons icon-span">
                <IndustryIcon />
              </span>
            </div>
            {errors.newIndustry && (
              <div>
                <FormError message={errors.newIndustry.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1 tw-pt-2 tw-flex-col">
            <label className="required" htmlFor="industryRole">
              Select Role
            </label>
            <div className="select-input tw-mb-1 tw-w-full tw-border tw-border-slate-300 tw-m-auto tw-rounded-md">
              <select
                name="industryRole"
                id="industryRole"
                className="tw-border-0 tw-w-full tw-text-gray-500"
                {...register("industryRole", {
                  required: "Role is required",
                })}
              >
                <option value="other">Other</option>
                <option value="service provider">Service Provider</option>
              </select>
            </div>
            {errors.industryRole && (
              <div>
                <FormError message={errors.industryRole.message} />
              </div>
            )}
          </div>
          <Button
            type="submit"
            className="tw-block tw-ml-auto tw-px-1.5 tw-py-1 tw-mt-2"
          >
            Add
          </Button>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
