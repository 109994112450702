export const Button = ({
  children,
  type = "button",
  className = "",
  bgColor = "tw-bg-primary",
  textColor = "tw-text-white",
  ...props
}) => {
  return (
    <button
      type={type}
      className={`tw-rounded-md ${bgColor} ${textColor} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export const Pagination = ({ page, setPage, data, dataCount = 7 }) => {
  return (
    <div className="pagination">
      <Button
        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
        bgColor="tw-bg-black"
        disabled={page < 2}
        onClick={() => setPage(page - 1)}
      >
        Prev
      </Button>
      <span>
        {page}/{Math.ceil(data?.length / dataCount)}
      </span>
      <Button
        className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
        bgColor="tw-bg-black"
        disabled={page >= Math.ceil(data?.length / dataCount)}
        onClick={() => setPage(page + 1)}
      >
        Next
      </Button>
    </div>
  );
};
