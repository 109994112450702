import React, { useState } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import useNotificationApi from "../../../hooks/useNotificationApi";
import useCheckout from "../../../hooks/useCheckout";
import RatingModal from "../modals/rating-modal/RatingModal";
import { Button } from "../Button";
import FavIcon from "../../../images/favicon.png";

export const BidCard = ({
  data,
  vendorName,
  role,
  connect,
  ad_status,
  markAsCompleted,
  ad_id,
  title,
}) => {
  const {
    bid_id,
    bid_amount,
    bid_deadline,
    bid_description,
    logo,
    status,
    user_id,
  } = data;

  const submitReviewApi = `${process.env.REACT_APP_API_URL}review`;

  const [openRateModal, setOpenRateModal] = useState(false);
  const { token, name } = useLocalStorage();
  const { fetchApi } = useFetch();
  const { sendNotification } = useNotificationApi();
  const { createCheckoutSession } = useCheckout();

  const submitRating = (rating, review) => {
    fetchApi(
      {
        url: submitReviewApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: user_id,
          review: review,
          rating: rating,
          role: "vendor",
          ad_id: ad_id,
        },
      },
      (data) => {
        if (data.message === "already reviewed") {
          alert(
            "Failed submission!. You have already given a review to this user."
          );
          setOpenRateModal(false);
          return;
        }
        sendNotification(
          user_id,
          `${name} marked the project as complete and reviewed it. Please do the same by opening the project page`,
          "reviews"
        );
        markAsCompleted(bid_id, user_id);
      }
    );
  };

  return (
    <div className="tw-mt-1 tw-px-1 tw-py-3 tw-shadow tw-rounded-md">
      <div className="tw-grid tw-grid-cols-6 tw-px-3">
        <div className="tw-flex tw-flex-col tw-items-center">
          <img
            src={logo.length > 0 ? logo : FavIcon}
            alt="vendor-logo"
            width={75}
            className="tw-h-[75px] tw-rounded-full tw-border tw-border-slate-400 tw-p-1"
            loading="lazy"
          />
          <span className="tw-font-medium tw-text-lg">{vendorName}</span>
        </div>
        <div className="tw-col-span-5 tw-ps-2">
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <div className="tw-flex">
              <p className="tw-text-sm tw-me-4">
                <span className="tw-font-medium tw-pe-1">Bid : </span>₹{" "}
                {bid_amount}
              </p>
              <p className="tw-text-sm">
                <span className="tw-font-medium tw-pe-1">Duration : </span>
                {bid_deadline} days
              </p>
            </div>
            {role === "f@!3A" && ad_status === "0" && (
              <div>
                <Button
                  className="tw-py-0.5 tw-px-1 tw-mx-2 tw-text-sm"
                  bgColor="tw-bg-green-600"
                  onClick={() => connect(user_id)}
                >
                  Chat
                </Button>
                <Button
                  className="tw-py-0.5 tw-px-1 tw-mx-2 tw-text-sm"
                  onClick={() =>
                    createCheckoutSession({
                      type: "market",
                      bid_id: bid_id,
                      ad_id: ad_id,
                      title: title,
                    })
                  }
                >
                  Award
                </Button>
              </div>
            )}
            {status !== "0" && (
              <div className="tw-flex">
                <p
                  className={`tw-py-0.5 tw-px-1 tw-mx-1 tw-text-white tw-rounded tw-text-sm ${
                    status === "1"
                      ? "tw-bg-orange-500"
                      : status === "2"
                      ? "tw-bg-green-500"
                      : null
                  }`}
                >
                  {status === "1"
                    ? "Awarded"
                    : status === "2"
                    ? "Completed"
                    : null}
                </p>

                {role === "f@!3A" && (
                  <>
                    <Button
                      className="tw-py-0.5 tw-px-1 tw-mx-1 tw-text-sm"
                      bgColor="tw-bg-green-600"
                      onClick={() => connect(user_id)}
                    >
                      Chat
                    </Button>
                    {status === "1" && (
                      <Button
                        className="tw-py-0.5 tw-px-1 tw-mx-1 tw-text-sm"
                        onClick={() => setOpenRateModal(true)}
                      >
                        Mark as completed
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <p
            className={`${
              bid_description.length > 150 ? "description-text" : ""
            } tw-text-sm`}
          >
            {bid_description}
          </p>
          {bid_description.length > 150 && (
            <input type="checkbox" className="expand-btn" />
          )}
        </div>
      </div>
      <RatingModal
        open={openRateModal}
        onClose={() => setOpenRateModal(false)}
        submitRating={submitRating}
        type="market"
      />
    </div>
  );
};
