import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { useToastContext } from "../../context/ToastContext";
import { Loader } from "../../components/ui/Loader";
import { Button } from "../../components/ui/Button";
import { FormError } from "../../components/ui/FormError";
import Dropdown from "../../components/ui/inputs/dropdown";
import { bannedInputs, numberRegex, urlRegex } from "../../constants";
import {
  BuildingIcon,
  RupeeIcon,
  RedirectIcon,
  LinkedInIcon,
  UploadIcon,
} from "../../icons/icons";

export const StartupDetailsForm = ({ token, industries }) => {
  const businessDetailsApi = `${process.env.REACT_APP_API_URL}insert-business-details`;

  const { isLoading, error } = useFetch();
  const navigate = useNavigate();
  const toast = useToastContext();

  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const BusinessDetailsApiCall = (data) => {
    document.getElementById("duplicate-error").classList.add("tw-hidden");
    document.getElementById("category-error").classList.add("tw-hidden");
    document.getElementById("category-limit").classList.add("tw-hidden");
    if (selectedIndustry.length < 1) {
      document.getElementById("category-error").classList.remove("tw-hidden");
      return;
    }
    if (selectedIndustry.length > 3) {
      document.getElementById("category-limit").classList.remove("tw-hidden");
      return;
    }
    let logo = document.getElementById("startupLogo").files[0];

    const formData = new FormData();
    formData.append("file", logo);
    formData.append("company_name", data.companyName);
    formData.append("require_investment", data.requireInvestment);
    formData.append("startup_industry", selectedIndustry);
    formData.append("website", data.website);
    formData.append("linked_in", data.startupLinkedin);
    axios
      .post(businessDetailsApi, formData, {
        headers: { "x-token": token },
      })
      .then((response) => {
        console.log(response);
        if (response.data.message === "duplicate") {
          document
            .getElementById("duplicate-error")
            .classList.remove("tw-hidden");
          return;
        }
        if (response.data.message === "validation error") {
          toast.open("false", "Sorry, Failed to submit. Validation failed.");
          return;
        }
        navigate("/startup-dashboard");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      id="startupForm"
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-my-0 lg:tw-py-3 md:tw-col-start-2 md:tw-col-span-5 lg:tw-col-span-2"
    >
      <form
        onSubmit={handleSubmit(BusinessDetailsApiCall)}
        className="tw-mx-auto tw-p-5 tw-rounded-md tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0"
      >
        <h4 className="tw-mb-2">Tell us something about your business</h4>
        <div className="tw-mb-1">
          <label htmlFor="companyName" className="tw-text-sm tw-mb-0 required">
            Company Name
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="companyName"
              name="companyName"
              placeholder="Company Name"
              autoFocus
              {...register("companyName", {
                required: "Company Name is required",
                validate: (fieldValue) => {
                  return (
                    !bannedInputs.some((value) => fieldValue.includes(value)) ||
                    "Enter a different name"
                  );
                },
                minLength: {
                  value: 3,
                  message: "Must be at least 3 characters long",
                },
                maxLength: {
                  value: 100,
                  message: "Max length is 100 characters",
                },
              })}
            />
            <span className="input-icons icon-span">
              <BuildingIcon />
            </span>
          </div>
          {errors.companyName && (
            <div>
              <FormError message={errors.companyName.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1">
          <label htmlFor="startupLogo" className="tw-text-sm tw-mb-0">
            Company Logo
          </label>
          <div
            className="tw-flex tw-items-center tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="file"
              accept="image/jpeg, image/png"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="startupLogo"
              name="startupLogo"
              placeholder="Upload Company Logo"
            />
            <span className="input-icons icon-span">
              <UploadIcon />
            </span>
          </div>
        </div>
        <div className="tw-mb-1">
          <label
            htmlFor="requireInvestment"
            className="tw-mb-0 tw-text-sm required"
          >
            How much investment are you looking for (₹)
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="number"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="requireInvestment"
              name="requireInvestment"
              placeholder="ex: 1000000"
              {...register("requireInvestment", {
                required: "Required investment is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                minLength: {
                  value: 4,
                  message: "Must be atleast 10000",
                },
              })}
            />
            <span className="input-icons icon-span">
              <RupeeIcon />
            </span>
          </div>
          {errors.requireInvestment && (
            <div>
              <FormError message={errors.requireInvestment.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1 tw-flex-col">
          <p className="tw-text-sm">
            Industry{" "}
            <span className="required">({selectedIndustry.length + "/3"})</span>
            <span
              id="category-limit"
              className="tw-hidden tw-text-red-500 tw-text-sm tw-ms-2"
            >
              Limit exceeded
            </span>
          </p>
          <Dropdown
            options={industries?.map((industry) => ({
              label: industry.industry_name
                .replace(new RegExp("_", "g"), " ")
                .toUpperCase(),
              value: industry.industry_name,
            }))}
            onChange={(selectedOptions) =>
              setSelectedIndustry(selectedOptions.map((option) => option.value))
            }
            isMulti
          />
          <small
            className="tw-text-red-500 tw-mb-2 tw-hidden"
            id="category-error"
          >
            This field is mandatory.
          </small>
        </div>
        <div className="tw-mb-1">
          <label htmlFor="website" className="tw-mb-0 tw-text-sm">
            Website Link
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="website"
              name="website"
              placeholder="Websites URL"
              {...register("website", {
                validate: {
                  validUrl: (fieldValue) => {
                    return fieldValue.length > 0
                      ? urlRegex.test(fieldValue) || "Please enter a valid url"
                      : null;
                  },
                  bannedUrl: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different url"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <RedirectIcon />
            </span>
          </div>
          {errors.website && (
            <div>
              <FormError message={errors.website.message} />
            </div>
          )}
        </div>
        <div className="tw-mb-1">
          <label htmlFor="startupLinkedin" className="tw-mb-0 tw-text-sm">
            Linkedin profile{" "}
            <small>
              ( LinkedIn profiles boost authenticity and credibility—highly
              recommended. )
            </small>
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="text"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="startupLinkedin"
              name="startupLinkedin"
              placeholder="Linkedin URL"
              {...register("startupLinkedin", {
                validate: {
                  validUrl: (fieldValue) => {
                    return fieldValue.length > 0
                      ? urlRegex.test(fieldValue) || "Please enter a valid url"
                      : null;
                  },
                  bannedUrl: (fieldValue) => {
                    return (
                      !bannedInputs.some((value) =>
                        fieldValue.includes(value)
                      ) || "Enter a different url"
                    );
                  },
                },
              })}
            />
            <span className="input-icons icon-span">
              <LinkedInIcon />
            </span>
          </div>
          {errors.startupLinkedin && (
            <div>
              <FormError message={errors.startupLinkedin.message} />
            </div>
          )}
        </div>
        <span
          id="duplicate-error"
          className="tw-hidden tw-text-red-500 tw-text-sm"
        >
          website already exists.
        </span>
        {isLoading && !error ? <Loader pt="10px" classes="tw-my-2" /> : null}
        <Button type="submit" className="tw-py-1.5 tw-mt-3 tw-w-full">
          Next
        </Button>
      </form>
    </div>
  );
};
