import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Loader } from "../../components/ui/Loader";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { SingleReview } from "./singleReview";
import RatingCircle from "./ratingCircle";

const Reviews = () => {
  const getAllReviewsApi = `${process.env.REACT_APP_API_URL}display-all-reviews`;
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState({
    average_rating: 0,
    investor: 0,
    mentor: 0,
    vendor: 0,
    startup: 0,
  });
  const [role, setRole] = useState("");
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { state } = useLocation();
  const [openSidebar, setOpenSidebar] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchApi(
        {
          url: getAllReviewsApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            user_id: state?.user_id || null,
            role: state?.role || userRole,
          },
        },
        (data) => {
          setReviews(data.reviews);
          setRole(data.role);
          setAverageRating({
            average_rating: data.average_rating,
            investor: data.rateByInvestor,
            mentor: data.rateByMentor,
            vendor: data.rateByVendor,
            startup: data.rateByStartup,
          });
        }
      );
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [getAllReviewsApi, token, fetchApi, userRole, state]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="tw-p-4">
          {!isLoading && !error ? (
            reviews && reviews.length > 0 ? (
              <div>
                <div className="tw-flex tw-items-center tw-justify-center tw-mb-5">
                  <RatingCircle rating={averageRating.average_rating} />
                  {role === "f@!3A" && (
                    <RatingCircle
                      rating={averageRating.mentor}
                      title="Rating given by mentor"
                      tag="Mentor"
                    />
                  )}
                  {role === "f@!3A" && (
                    <RatingCircle
                      rating={averageRating.investor}
                      title="Rating given by investor"
                      tag="Investor"
                    />
                  )}
                  {role === "f@!3A" && (
                    <RatingCircle
                      rating={averageRating.vendor}
                      title="Rating given by service provider"
                      tag="Vendor"
                    />
                  )}
                  {(role === "R$7s2" || role === "V&iR8") && (
                    <RatingCircle
                      rating={averageRating.startup}
                      title="Rating given by startup"
                      tag="Startup"
                    />
                  )}
                </div>
                {reviews.map((review) => (
                  <SingleReview key={review.id} data={review} />
                ))}
              </div>
            ) : (
              <p className="tw-font-medium tw-text-base">No reviews found.</p>
            )
          ) : (
            <Loader />
          )}
        </section>
      </div>
    </div>
  );
};

export default Reviews;
