import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import { Button } from "../../components/ui/Button";
import { EnvelopeIcon } from "../../icons/icons";

export const FrogotPasswordForm = ({ backToLogin }) => {
  const forgetPasswordApi = `${process.env.REACT_APP_API_URL}forget-password`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { fetchApi } = useFetch();

  const forgetPasswordApiCall = (data, e) => {
    e.preventDefault();
    fetchApi(
      {
        url: forgetPasswordApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: { email: data.resetEmail },
      },
      (data) => {
        if (data.message === "invalid") {
          document
            .getElementById("wrongResetEmail")
            .classList.remove("tw-hidden");
        } else {
          reset();
          document.getElementById("wrongResetEmail").classList.add("tw-hidden");
          document
            .getElementById("forgotPasswordForm")
            .classList.add("tw-hidden");
          document
            .getElementById("confirmationText")
            .classList.remove("tw-hidden");
        }
      }
    );
  };

  return (
    <form
      className="tw-flex tw-col-span-7 authentication-bg tw-relative md:tw-col-start-2 md:tw-col-span-5 md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-col-span-2 lg:tw-my-0 lg:tw-py-0"
      id="forgotPasswordForm"
      onSubmit={handleSubmit(forgetPasswordApiCall)}
    >
      <div className="tw-mx-auto tw-p-6 tw-rounded-md md:tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0">
        <h4 className="tw-hidden lg:tw-block tw-mb-5 tw-text-lg tw-font-semibold tw-text-center">
          {process.env.REACT_APP_COMPANY_NAME}! 👋
        </h4>
        <h5 className="tw-mb-5 tw-text-center">
          Forgot your password ? No, Worries we got you!
        </h5>
        <div>
          <label htmlFor="resetEmail" className="tw-mb-0 tw-text-sm required">
            Enter registered email
          </label>
          <div
            className="tw-flex tw-rounded-md tw-overflow-hidden"
            style={{ border: "1px solid #ced4da" }}
          >
            <input
              type="email"
              className="tw-border-0 tw-w-full tw-ps-3"
              id="resetEmail"
              name="resetEmail"
              placeholder="Registered email"
              {...register("resetEmail", {
                required: true,
                validate: {
                  matchPattern: (value) =>
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                      value
                    ) || "Please enter a valid email address",
                },
              })}
              autoFocus
            />
            <span className="input-icons icon-span">
              <EnvelopeIcon />
            </span>
          </div>
          {errors.resetEmail && (
            <span className="tw-text-secondary tw-text-sm">
              Email is required
            </span>
          )}
          {!errors.resetEmail && (
            <small className="tw-text-red-500 tw-hidden" id="wrongResetEmail">
              Invalid! Please enter a registered email address
            </small>
          )}
          <Button type="submit" className="tw-py-1.5 tw-mt-3 tw-w-full">
            Next
          </Button>
          <div className="tw-flex tw-justify-center tw-mt-3">
            <span>Missed Click your way here ? </span>
            <p
              className="tw-text-blue-500 tw-cursor-pointer tw-m-0 tw-ps-1"
              onClick={backToLogin}
            >
              Go Back
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};
