import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useFetchCredits } from "../../hooks/useFetchCredits";
import { BarGraph } from "../../components/ui/graphs-charts/barGraph";
import LongCard from "../../components/ui/cards/longCard";
import SmallCard from "../../components/ui/cards/smallCard";
import SingleConnection from "./singleConnection";
import WelcomeCard from "../../components/ui/cards/welcomeCard";
import AmountCard from "../../components/ui/cards/amountCard";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import "./dashboard.css";
import usersIcon from "../../images/users-icon.svg";
import users2Icon from "../../images/users2-icon.svg";
import creditIcon from "../../images/credit-icon.svg";
import TransactionTable from "./recentTransaction";
import { Button } from "../../components/ui/Button";
import { useToastContext } from "../../context/ToastContext";
import { ClaimModal } from "../../components/ui/modals/claimModal";

const StartupDashBoard = () => {
  const startupDashboardApi = `${process.env.REACT_APP_API_URL}startup-dashboard`;
  const claimTokensApi = `${process.env.REACT_APP_API_URL}claim-tokens`;

  const { isLoading, error, fetchApi } = useFetch();
  const navigate = useNavigate();
  const { token, userRole } = useLocalStorage();
  const { credits, getCredits } = useFetchCredits();
  const toast = useToastContext();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [claimFields, setClaimFields] = useState({
    logo: null,
    description: null,
  });
  const ref = useRef(true);

  const initialState = {
    name: "",
    logo: "",
    description: "",
    claim_status: "0",
    profile_completion: 0,
    connected_mentors: 0,
    connected_investors: 0,
    used_credits: 0,
    investors: 0,
    mentors: 0,
    startups: 0,
    vendors: 0,
    userTransactions: [],
    userConnections: [],
    barGraphData: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "update-connections":
        return {
          ...state,
          userConnections: [...state.userConnections, ...action.payload],
        };
      case "update-transactions":
        return {
          ...state,
          userTransactions: [...state.userTransactions, ...action.payload],
        };
      case "update-platform-stats":
        return {
          ...state,
          startups: action.payload.startups,
          mentors: action.payload.mentors,
          investors: action.payload.investors,
          vendors: action.payload.vendors,
        };
      case "update-user":
        return {
          ...state,
          name: action.payload.name,
          logo: action.payload.logo,
          description: action.payload.description,
          claim_status: action.payload.claim_status,
          used_credits: action.payload.used_credits,
          profile_completion: action.payload.profile_completion,
          connected_mentors: action.payload.connected_mentors,
          connected_investors: action.payload.connected_investors,
        };
      case "update-bar-graph":
        return {
          ...state,
          barGraphData: action.payload.barGraphData,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getStartupData = (data) => {
    dispatch({ type: "update-connections", payload: data.connections });
    dispatch({ type: "update-transactions", payload: data.transactions });
    dispatch({
      type: "update-platform-stats",
      payload: {
        startups: data.startup_count,
        mentors: data.mentor_count,
        investors: data.investor_count,
        vendors: data.vendor_count,
      },
    });
    dispatch({
      type: "update-user",
      payload: {
        name: data.name,
        logo: data.logo,
        description: data.description,
        claim_status: data.claim_status,
        used_credits: data.used_credit,
        profile_completion: data.profile_completion,
        connected_mentors: data.connected_mentors,
        connected_investors: data.connected_investors,
      },
    });
    dispatch({
      type: "update-bar-graph",
      payload: {
        barGraphData: data.industries_count,
      },
    });
  };

  const startupDashboard = useCallback(() => {
    fetchApi(
      {
        url: startupDashboardApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => getStartupData(data)
    );
  }, [token, startupDashboardApi, fetchApi]);

  const claimTokens = useCallback(() => {
    fetchApi(
      {
        url: claimTokensApi,
        method: "POST",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        if (data.message !== "Claim Successfull") {
          toast.open("warning", data.message);
          setClaimFields({
            logo: data.logo,
            description: data.description,
          });
          setOpenClaimModal(true);
          return;
        }
        toast.open("true", data.message);
        startupDashboard();
      }
    );
  }, [token, claimTokensApi, fetchApi, startupDashboard, toast]);

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      startupDashboard();
      getCredits();
    }
    setTimeout(() => {}, 30000);
    return () => {
      ref.current = false;
    };
  }, [startupDashboard, getCredits, navigate, userRole]);

  const ViewProfile = (connectionId, name, role) => {
    if (role === "f@!3A") {
      navigate(`/view-startup/${name}/${connectionId}`);
    } else if (role === "R$7s2") {
      navigate(`/view-mentor/${name}/${connectionId}`);
    } else if (role === "Sw%aa") {
      navigate(`/view-investor/${name}/${connectionId}`);
    } else {
      navigate(`/view-vendor/${name}/${connectionId}`);
    }
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="tw-p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="tw-grid tw-grid-cols-8 tw-gap-3 widget-grid">
              <div className="tw-col-span-8">
                {state.claim_status === "0" && (
                  <div className="claim-card tw-shadow-md">
                    <p>
                      {!state.logo || !state.description
                        ? "Add logo and description from profile to claim free 500 tokens"
                        : "Claim your free 500 tokens."}
                    </p>
                    <Button
                      className="claim-btn"
                      textColor="tw-text-black"
                      onClick={() => claimTokens()}
                    >
                      Claim
                    </Button>
                  </div>
                )}
              </div>
              <div className="tw-col-span-8 lg:tw-col-span-4">
                <WelcomeCard name={state.name} />
                <AmountCard
                  credits={credits}
                  profile_completion={state.profile_completion}
                  userRole={userRole}
                />
              </div>
              <div className="tw-col-span-8 lg:tw-col-span-4 tw-card tw-shadow tw-rounded-md">
                <h5 className="tw-text-lg tw-font-medium">
                  Popular startup industries
                </h5>
                <div className="tw-px-0 cstm-svg-pos tw-uppercase">
                  <BarGraph data={state.barGraphData} />
                </div>
              </div>
              <div className="tw-col-span-8">
                <div className="tw-grid tw-grid-cols-6 tw-gap-3">
                  <LongCard
                    data={{ title: "Tokens used", count: state.used_credits }}
                    img={creditIcon}
                  />
                  <LongCard
                    data={{
                      title: "Mentor Connections",
                      count: state.connected_mentors,
                    }}
                    img={usersIcon}
                  />
                  <LongCard
                    data={{
                      title: "Investor Connections",
                      count: state.connected_investors,
                    }}
                    img={users2Icon}
                    gradient="primary"
                  />
                </div>
              </div>
              <div className="tw-col-span-8 sm:tw-col-span-4 lg:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Startups",
                    count: state.startups,
                  }}
                  icon="startup"
                  gradient="green-icon"
                  buttonData={{
                    link: "startups",
                    title: "View Startups",
                  }}
                />
              </div>
              <div className="tw-col-span-8 sm:tw-col-span-4 lg:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Investors",
                    count: state.investors,
                  }}
                  icon="investor"
                  gradient="pink-icon"
                  buttonData={{
                    link: "investors",
                    title: "View Investors",
                  }}
                />
              </div>
              <div className="tw-col-span-8 sm:tw-col-span-4 lg:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Mentors",
                    count: state.mentors,
                  }}
                  icon="mentor"
                  gradient="orange-icon"
                  buttonData={{
                    link: "mentors",
                    title: "View Mentors",
                  }}
                />
              </div>
              <div className="tw-col-span-8 sm:tw-col-span-4 lg:tw-col-span-2 tw-shadow tw-rounded-md">
                <SmallCard
                  data={{
                    title: "Service Providers",
                    count: state.vendors,
                  }}
                  icon="vendor"
                  gradient="blue-icon"
                  buttonData={{
                    link: "vendors",
                    title: "View Service Providers",
                  }}
                />
              </div>
              <div className="tw-col-span-8 lg:tw-col-span-5">
                <div className="tw-border-b-0 tw-ps-2">
                  <h5 className="tw-text-lg tw-font-medium">
                    Recent Transactions
                  </h5>
                </div>
                <div className="tw-py-3 md:tw-px-3">
                  <TransactionTable data={state.userTransactions} />
                </div>
              </div>
              <div className="tw-col-span-8 lg:tw-col-span-3">
                <div className="tw-card tw-shadow tw-rounded-md">
                  <div className="tw-border-b tw-border-slate-300 tw-pb-2">
                    <h5 className="tw-text-lg tw-font-medium">
                      Recent Connections
                    </h5>
                  </div>
                  <div className="tw-py-3">
                    <div className="tw-grid tw-gap-2">
                      {state.userConnections &&
                      state.userConnections.length > 0 ? (
                        state.userConnections.map((connection) => (
                          <SingleConnection
                            key={connection.id}
                            data={connection}
                            ViewProfile={ViewProfile}
                            userRole={userRole}
                            userId={connection.connection_id}
                          />
                        ))
                      ) : (
                        <>
                          <p>Make your first connection now.</p>
                          <Link
                            to="/mentors"
                            className="tw-w-fit tw-p-1 tw-bg-primary tw-text-white tw-rounded-md hover:tw-text-white"
                          >
                            Mentors
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ClaimModal
        open={openClaimModal}
        onClose={() => setOpenClaimModal(false)}
        claimFields={claimFields}
        callBackFn={claimTokens}
        url={`${process.env.REACT_APP_API_URL}update-startup-profile`}
      />
    </div>
  );
};

export default StartupDashBoard;
