import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import useAddNewBank from "../../../../hooks/useAddNewBank";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import banks from "../../../../json-data/banks.json";
import {
  alphanumericRegex,
  numberRegex,
  textWithSpaceRegex,
  bannedInputs,
} from "../../../../constants";
import "../../../../custom.css";
import {
  BuildingColumnIcon,
  HashtagIcon,
  UserIcon,
} from "../../../../icons/icons";

export const BankAccountModal = ({ open, onClose, callBackFn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  const { newBank } = useAddNewBank();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    newBank(data, callBackFn);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Bank Account Details
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-p-3 tw-text-left"
        >
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="accountName"
            >
              Account holder name
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="accountName"
                name="accountName"
                placeholder="Enter Name"
                {...register("accountName", {
                  required: "Name is required",
                  pattern: {
                    value: textWithSpaceRegex,
                    message:
                      "Invalid name, must not start or end with space and must contain only alphabets",
                  },
                  validate: {
                    validLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 2 ||
                        "minimum required length is 3"
                      );
                    },
                    validName: (fieldValue) => {
                      return (
                        !bannedInputs.some((value) =>
                          fieldValue.includes(value)
                        ) || "Enter a different name"
                      );
                    },
                  },
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.accountName && (
              <div>
                <FormError message={errors.accountName.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1 tw-pt-2 tw-flex-col">
            <label htmlFor="bankName" className="required">
              Bank Name
            </label>
            <div className="select-input tw-mb-1 tw-w-full tw-border tw-border-slate-300 tw-m-auto tw-rounded-md">
              <select
                name="bankName"
                id="bankName"
                className="tw-border-0 tw-w-full tw-text-gray-500"
                {...register("bankName", {
                  required: "Select a bank",
                  validate: (fieldValue) => {
                    return fieldValue.length > 0 || "Select a bank";
                  },
                })}
              >
                <option value="">Select Bank</option>
                {banks?.banks.map((bank) => (
                  <option
                    key={bank.value}
                    value={bank.value}
                  >{`${bank.name} Bank`}</option>
                ))}
              </select>
            </div>
            {errors.bankName && (
              <div>
                <FormError message={errors.bankName.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="accountNumber"
            >
              Bank account number
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="accountNumber"
                name="accountNumber"
                placeholder="Enter account number"
                {...register("accountNumber", {
                  required: "Account number is required",
                  pattern: {
                    value: numberRegex,
                    message: "Amount must only contain digits",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <BuildingColumnIcon />
              </span>
            </div>
            {errors.accountNumber && (
              <div>
                <FormError message={errors.accountNumber.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm required" htmlFor="ifscCode">
              IFSC Code
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="ifscCode"
                name="ifscCode"
                placeholder="Enter IFSC Code"
                {...register("ifscCode", {
                  required: "IFSC code is required",
                  validate: {
                    minValidLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length > 9 ||
                        "minimum required length is 9"
                      );
                    },
                    maxValidLength: (fieldValue) => {
                      return (
                        fieldValue.trim().length < 13 ||
                        "maximum length can be 12"
                      );
                    },
                  },
                  pattern: {
                    value: alphanumericRegex,
                    message: "IFSC code can contain only alphabets and numbers",
                  },
                })}
              />
              <span className="input-icons icon-span">
                <HashtagIcon />
              </span>
            </div>
            {errors.ifscCode && (
              <div>
                <FormError message={errors.ifscCode.message} />
              </div>
            )}
          </div>
          <div className="modalFooter tw-p-3">
            <Button
              className="tw-px-2 tw-py-1 tw-mx-1"
              bgColor="tw-bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
