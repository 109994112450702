import { useEffect, useState, useRef } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";
import Dropdown from "../../inputs/dropdown";

export const EditAdModal = ({
  open,
  onClose,
  singleAd,
  editAd,
  industries,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: singleAd.title,
      minBudget: singleAd.min_budget,
      maxBudget: singleAd.max_budget,
      deadline: singleAd.deadline,
      description: singleAd.description,
    },
  });

  const [expertise, setExpertise] = useState([]);
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      if (singleAd.tags) {
        setExpertise([...singleAd.tags]);
      }
    }
    return () => {
      ref.current = true;
    };
  }, [singleAd]);

  if (!open) return null;

  const submitModal = (data) => {
    document.getElementById("low-max-budget").classList.add("tw-hidden");
    document.getElementById("limit-error").classList.add("tw-hidden");
    document.getElementById("select-tags").classList.add("tw-hidden");
    if (data.maxBudget <= data.minBudget) {
      document.getElementById("low-max-budget").classList.remove("tw-hidden");
      return;
    }
    if (expertise.length > 3) {
      document.getElementById("limit-error").classList.remove("tw-hidden");
      return;
    }
    if (expertise.length < 1) {
      document.getElementById("select-tags").classList.remove("tw-hidden");
      return;
    }
    editAd(data, expertise, singleAd._id);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[50%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <p className="tw-p-3 tw-text-center tw-border-b tw-text-xl tw-font-semibold">
          Edit Project
        </p>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-grid tw-grid-cols-2 tw-gap-1 tw-p-3"
        >
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-justify-center">
            <label htmlFor="title" className="tw-mb-0 required">
              Project Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              className="tw-border tw-border-gray-400 tw-p-1 tw-rounded"
              placeholder="Add project title"
              {...register("title", {
                required: "Title is required",
                minLength: {
                  value: 5,
                  message: "Minimum length is 5 characters",
                },
                maxLength: {
                  value: 100,
                  message: "Maximum length can be 100 characters",
                },
              })}
            />
            {errors.title && (
              <div>
                <FormError message={errors.title.message} />
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center">
            <label htmlFor="minBudget" className="tw-mb-0 required">
              Minimum Budget (₹)
            </label>
            <input
              type="number"
              id="minBudget"
              name="minBudget"
              className="tw-border tw-border-gray-400 tw-p-1 tw-rounded"
              placeholder="Enter minimum budget"
              {...register("minBudget", {
                required: "minimum budget is required",
                pattern: {
                  value: numberRegex,
                  message: "Budget must only contain digits",
                },
              })}
            />
            {errors.minBudget && (
              <div>
                <FormError message={errors.minBudget.message} />
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center">
            <label htmlFor="maxBudget" className="tw-mb-0 required">
              Maximum Budget (₹)
            </label>
            <input
              type="number"
              id="maxBudget"
              name="maxBudget"
              className="tw-border tw-border-gray-400 tw-p-1 tw-rounded"
              placeholder="Enter maximum budget"
              {...register("maxBudget", {
                required: "max budget is required",
                pattern: {
                  value: numberRegex,
                  message: "Budget must only contain digits",
                },
              })}
            />
            {errors.maxBudget && (
              <div>
                <FormError message={errors.maxBudget.message} />
              </div>
            )}
            <span
              id="low-max-budget"
              className="tw-hidden tw-text-red-500 tw-text-xs"
            >
              Max Budget cannot be less than Min budget
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center">
            <label htmlFor="deadline" className="tw-mb-0 required">
              Expected Deadline (days)
            </label>
            <input
              type="number"
              id="deadline"
              name="deadline"
              className="tw-border tw-border-gray-400 tw-p-1 tw-rounded"
              placeholder="Enter deadline budget"
              {...register("deadline", {
                required: "deadline is required",
                pattern: {
                  value: numberRegex,
                  message: "Deadline must only contain digits",
                },
                validate: (fieldValue) => {
                  return fieldValue > 0 || "Deadline must atleast be 1";
                },
              })}
            />
            {errors.deadline && (
              <div>
                <FormError message={errors.deadline.message} />
              </div>
            )}
          </div>
          <div className="tw-flex-col">
            <p className="tw-text-sm">
              Company expertise{" "}
              <span className="required">({expertise.length + "/3"})</span>
              <span
                id="limit-error"
                className="tw-hidden tw-text-red-500 tw-text-xs"
              >
                Limit exceeded
              </span>
              <span
                id="select-tags"
                className="tw-hidden tw-text-red-500 tw-text-xs"
              >
                Please select category
              </span>
            </p>
            <Dropdown
              options={industries?.map((industry) => ({
                label: industry.industry_name
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry.industry_name,
              }))}
              value={expertise.map((industry) => ({
                label: industry
                  .replace(new RegExp("_", "g"), " ")
                  .toUpperCase(),
                value: industry,
              }))}
              onChange={(selectedOptions) =>
                setExpertise(selectedOptions.map((option) => option.value))
              }
              styles={{
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  maxHeight: "200px",
                  overflowY: "auto",
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: "37px",
                }),
              }}
              isMulti
            />
          </div>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-justify-center">
            <label htmlFor="description" className="tw-mb-0 required">
              Work Description
            </label>
            <textarea
              name="description"
              id="description"
              cols="30"
              rows="6"
              className="tw-border tw-border-gray-400 tw-p-1 tw-rounded-md"
              placeholder="Enter project description"
              {...register("description", {
                required: "Description is required",
                minLength: {
                  value: 30,
                  message: "Description must be at least 30 characters long",
                },
              })}
            ></textarea>
            {errors.description && (
              <div>
                <FormError message={errors.description.message} />
              </div>
            )}
          </div>
          <div className="tw-col-start-2 tw-text-end tw-p-3">
            <Button
              className="tw-px-2 tw-py-1 tw-mx-1"
              bgColor="tw-bg-secondary"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
