import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";

export const AddBidModal = ({ open, onClose, adDetails, postBid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    document.getElementById("id-error").classList.add("tw-hidden");

    if (adDetails.id.length < 0) {
      document.getElementById("id-error").classList.remove("tw-hidden");
      return;
    }
    postBid(adDetails.id, adDetails.startupId, data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-max-w-[50%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="tw-py-2 tw-border-b tw-text-center tw-font-semibold tw-text-lg">
          Add Bid
        </h4>
        <form
          onSubmit={handleSubmit(submitModal)}
          className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-2 tw-p-2 tw-pb-0"
        >
          <span className="tw-hidden tw-text-red-500 tw-text-sm" id="id-error">
            Couldn't post bid. Ad id was missing try again.
          </span>
          <div className="md:tw-col-span-3 tw-flex tw-flex-col">
            <p htmlFor="ad-title" className="tw-font-medium">
              Bidding for
            </p>
            <p className="tw-p-2 tw-max-h-28 tw-overflow-auto tw-border tw-rounded tw-bg-slate-100">
              {adDetails.title}
            </p>
          </div>
          <div className="tw-flex tw-flex-col">
            <label
              htmlFor="minAmount"
              className="tw-mb-1 tw-font-medium tw-text-sm"
            >
              Minimum budget
            </label>
            <input
              type="text"
              className="tw-p-1 tw-border tw-rounded-md tw-bg-slate-100"
              value={adDetails.minAmount}
              disabled
              readOnly
            />
          </div>
          <div className="tw-flex tw-flex-col">
            <label
              htmlFor="bidAmount"
              className="tw-mb-1 tw-font-medium tw-text-sm required"
            >
              Bid Amount
            </label>
            <input
              type="number"
              id="bidAmount"
              name="bidAmount"
              className="tw-p-1 tw-border tw-rounded-md"
              placeholder="Enter bid amount"
              {...register("bidAmount", {
                required: "bid amount is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                validate: {
                  notZero: (fieldValue) => {
                    return fieldValue > 0 || "Amount cannot be 0";
                  },
                  minAmount: (fieldValue) => {
                    return fieldValue > 0
                      ? fieldValue >= adDetails.minAmount ||
                          "Amount cannot be less than minimum project budget"
                      : null;
                  },
                },
              })}
            />
            {errors.bidAmount && (
              <div>
                <FormError message={errors.bidAmount.message} />
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-col">
            <label
              htmlFor="workDuration"
              className="tw-mb-1 tw-font-medium tw-text-sm required"
            >
              Work Duration(days)
            </label>
            <input
              type="number"
              id="workDuration"
              name="workDuration"
              className="tw-p-1 tw-border tw-rounded-md"
              placeholder="Add work duration"
              {...register("workDuration", {
                required: "Duration is required",
                pattern: {
                  value: numberRegex,
                  message: "Duration must only contain digits",
                },
                validate: (fieldValue) => {
                  return fieldValue > 0 || "Duration cannot be 0";
                },
              })}
            />
            {errors.workDuration && (
              <div>
                <FormError message={errors.workDuration.message} />
              </div>
            )}
          </div>
          <div className="md:tw-col-span-3 tw-flex tw-flex-col">
            <label
              htmlFor="bidDescription"
              className="tw-mb-1 tw-font-medium tw-text-sm required"
            >
              Bid Description
            </label>
            <textarea
              name="bidDescription"
              id="bidDescription"
              cols="30"
              rows="7"
              minLength="30"
              className="tw-p-1 tw-border tw-rounded-md"
              placeholder="Add bid description"
              {...register("bidDescription", {
                required: "description is required",
                minLength: {
                  value: 30,
                  message: "description must be at least 30 characters",
                },
              })}
            ></textarea>
            {errors.bidDescription && (
              <div>
                <FormError message={errors.bidDescription.message} />
              </div>
            )}
          </div>
          <div className="md:tw-col-start-3 tw-text-right tw-p-2">
            <Button
              onClick={onClose}
              className="tw-px-1 tw-py-0.5 tw-mx-1"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="tw-px-1 tw-py-0.5 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
