import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useNotificationApi from "../../../../hooks/useNotificationApi";
import { useToastContext } from "../../../../context/ToastContext";
import { Button } from "../../../../components/ui/Button";
import { Stars } from "../../../../components/ui/modals/rating-modal/Stars";
import {
  BuildingIcon,
  LinkedInIcon,
  LocationIcon,
  RedirectArrowIcon,
  RedirectIcon,
  RupeeIcon,
  UserTieIcon,
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";
import certifiedLogo from "../../../../images/certified.png";
import verifiedLogo from "../../../../images/verified.png";

const StartupDetails = ({
  details,
  flag,
  handleSuspension,
  platformVerifyUser,
}) => {
  const { id } = useParams();

  return (
    <>
      <div className="tw-container tw-grid tw-grid-cols-8 tw-gap-3">
        <div className="tw-col-span-8 md:tw-col-span-3 tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-text-center">
          <div className="tw-flex tw-items-start tw-justify-between">
            <img
              src={details?.logo?.length < 1 ? FavIcon : details?.logo}
              className="tw-rounded-full tw-h-[90px] tw-w-[90px] md:tw-h-[140px] md:tw-w-[140px] tw-border tw-border-slate-400 tw-p-1"
              alt="profile-img"
            />
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-end md:tw-hidden">
              <div className="tw-flex tw-items-center tw-gap-x-1">
                {details?.is_suspend === "0" ? (
                  <Button
                    className="tw-px-2 tw-py-1 tw-text-xs"
                    bgColor="tw-bg-secondary"
                    id="suspend-btn"
                    onClick={() => handleSuspension("suspend")}
                  >
                    Suspend
                  </Button>
                ) : (
                  <Button
                    className="tw-px-2 tw-py-1 tw-text-xs"
                    bgColor="tw-bg-green-500"
                    id="unsuspend-btn"
                    onClick={() => handleSuspension("unsuspend")}
                  >
                    Un-Suspend
                  </Button>
                )}
                {details?.platform_verify === "0" ? (
                  <Button
                    className="tw-px-2 tw-py-1 tw-text-xs"
                    id="verify-btn"
                    onClick={() => platformVerifyUser(id)}
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    className="tw-px-2 tw-py-1 tw-text-xs"
                    id="verified-btn"
                    disabled
                  >
                    Verified
                  </Button>
                )}
              </div>
              <div className="tw-my-3">
                <Stars rating={details?.details?.average_rating} size={12} />
              </div>
            </div>
          </div>
          <div className="tw-text-start">
            <div className="tw-font-bold tw-text-lg tw-pt-2 tw-mb-0 tw-flex tw-items-center tw-uppercase">
              {details?.name}{" "}
              <span className="tw-flex tw-items-center">
                {details?.platform_verify === "1" && (
                  <img
                    src={verifiedLogo}
                    alt="Platform Verification Logo"
                    title="Platform Verified"
                    width={30}
                  />
                )}
                {details?.details?.verify_profile === "1" && (
                  <img
                    src={certifiedLogo}
                    alt="Mentorship Certification Logo"
                    title="Mentorship Completed"
                    width={30}
                  />
                )}
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <LinkedInIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-me-1 tw-font-medium">Linkedin :</span>
                {details?.details?.linked_in ? (
                  <a
                    href={details?.details?.linked_in}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-text-blue-500"
                  >
                    Profile
                  </a>
                ) : (
                  <span className="tw-text-secondary">N/A</span>
                )}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <RupeeIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-me-1 tw-font-medium">
                  Desired Investment :
                </span>
                {details?.details?.require_investment}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <BuildingIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-me-1 tw-font-medium">Company :</span>
                {details?.details?.company_name}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <RedirectIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-me-1 tw-font-medium">Website :</span>
                {details?.details?.website.length > 0 ? (
                  <a
                    href={"https://" + details?.details?.website}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-text-xs tw-mb-0 tw-text-blue-400"
                  >
                    URL
                  </a>
                ) : (
                  <span className="tw-text-secondary tw-text-sm tw-ms-3">
                    N/A
                  </span>
                )}
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <UserTieIcon fill="#9c9c9c" />
              <p className="tw-text-sm tw-mb-0 tw-ms-3">
                <span className="tw-me-1 tw-font-medium">
                  Company Size :{" "}
                  <span className="tw-font-normal">
                    {details?.details?.no_of_employees || (
                      <span className="tw-text-secondary tw-text-sm">N/A</span>
                    )}
                  </span>
                </span>
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-mb-2">
              <LocationIcon fill="#9c9c9c" />
              <div className="tw-flex tw-items-center tw-text-sm tw-mb-0 tw-ms-3 tw-font-medium">
                Address :{" "}
                <div className="tw-flex tw-items-center tw-mb-0 tw-ms-1">
                  <span className="tw-me-1 tw-font-normal">
                    {details?.city}
                  </span>
                  <img
                    src={
                      flag && `${process.env.REACT_APP_API_URL}` + flag.slice(6)
                    }
                    alt={`${details?.country}-flag`}
                    width={18}
                  />
                </div>
              </div>
            </div>
            <div className="tw-border-t tw-py-2">
              <p className="tw-mb-0 tw-font-medium">Industries</p>
              {details?.details?.startup_industry.map((industry, i) => (
                <span key={i} className="outline-tags">
                  {industry.replace(new RegExp("_", "g"), " ")}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-col-span-8 md:tw-col-span-5">
          <div className="tw-hidden md:tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center">
              <div className="tw-my-3">
                <Stars rating={details?.details?.average_rating} size={15} />
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-gap-x-3">
              {details?.is_suspend === "0" ? (
                <Button
                  className="tw-px-2 tw-py-1 tw-text-xs"
                  bgColor="tw-bg-secondary"
                  id="suspend-btn"
                  onClick={() => handleSuspension("suspend")}
                >
                  Suspend
                </Button>
              ) : (
                <Button
                  className="tw-px-2 tw-py-1 tw-text-xs"
                  bgColor="tw-bg-green-500"
                  id="unsuspend-btn"
                  onClick={() => handleSuspension("unsuspend")}
                >
                  Un-Suspend
                </Button>
              )}
              {details?.platform_verify === "0" ? (
                <Button
                  className="tw-px-2 tw-py-1 tw-text-xs"
                  id="verify-btn"
                  onClick={() => platformVerifyUser(id)}
                >
                  Verify
                </Button>
              ) : (
                <Button
                  className="tw-px-2 tw-py-1 tw-text-xs disabled"
                  id="verified-btn"
                  disabled
                >
                  Verified
                </Button>
              )}
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-3">
            <div className="tw-px-0" style={{ height: "270px" }}>
              <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-h-full tw-overflow-auto">
                <h5 className="tw-font-medium tw-pb-3">Startup Idea</h5>
                <p className="tw-mb-0 tw-text-sm">
                  {details?.details?.description.length > 0
                    ? details?.details?.description
                    : "Not updated."}
                </p>
              </div>
            </div>
            <div className="tw-pe-0">
              <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3">
                <h5 className="tw-font-medium tw-pb-5">Video</h5>
                {details?.video?.length > 0 ? (
                  <>
                    <video
                      controls
                      className="tw-w-full"
                      id="videoFile"
                      src={details?.video}
                      style={{ height: "200px" }}
                    >
                      Video uploaded
                    </video>
                  </>
                ) : (
                  <p>No Video uploaded.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-container tw-mt-3 tw-grid tw-grid-cols-2 tw-gap-3">
        <div className="tw-col-span-2 md:tw-col-span-1 tw-px-0">
          <div
            className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3"
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <h5 className="tw-pb-3 tw-font-medium">Business Overview</h5>
            <div className="tw-grid tw-grid-cols-2">
              <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                <p className="tw-mb-0 tw-font-bold tw-text-sm">Startup Stage</p>
                <p className="tw-text-sm tw-capitalize">
                  {details?.details?.startup_stage?.replace("-", " ") || "Nill"}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                <p className="tw-mb-0 tw-font-bold tw-text-sm">
                  Previous Investment
                </p>
                <p className="tw-text-sm tw-capitalize">
                  {details?.details?.previous_investment || "Nill"}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-mb-1">
                <p className="tw-mb-0 tw-font-bold tw-text-sm">
                  Invested Amount
                </p>
                <p className="tw-text-sm">
                  {details?.details?.investment_amount || "Nill"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-col-span-2 md:tw-col-span-1 tw-px-0">
          <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-mb-2 tw-h-100 tw-overflow-y-auto">
            <h5 className="tw-font-medium tw-pb-3">Pitch Deck</h5>
            {details?.document?.length > 0 &&
              details?.document?.map((document) => (
                <a
                  href={document.path}
                  key={document._id}
                  target="_blank"
                  rel="noreferrer"
                  className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                >
                  <p>{document.file_name}</p>
                  <RedirectArrowIcon className="tw-cursor-pointer" />
                </a>
              ))}
            {!details?.document?.length > 0 && <span>No Files uploaded</span>}
          </div>
          <div className="tw-bg-white tw-shadow-md tw-rounded-md tw-p-3 tw-h-100 tw-overflow-y-auto">
            <h5 className="tw-font-medium tw-pb-3">
              Documents & Certifications
            </h5>
            {details?.msme?.length > 0 && (
              <a
                href={details.msme[0].path}
                target="_blank"
                rel="noreferrer"
                className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
              >
                <p>{details?.msme[0].document_name}</p>
                <RedirectArrowIcon className="tw-cursor-pointer" />
              </a>
            )}
            {details?.gst?.length > 0 && (
              <a
                href={details?.gst[0].path}
                target="_blank"
                rel="noreferrer"
                className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
              >
                <p>{details?.gst[0].document_name}</p>
                <RedirectArrowIcon className="tw-cursor-pointer" />
              </a>
            )}
            {details?.incorporation?.length > 0 && (
              <a
                href={details?.incorporation[0].path}
                target="_blank"
                rel="noreferrer"
                className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
              >
                <p>{details?.incorporation[0].document_name}</p>
                <RedirectArrowIcon className="tw-cursor-pointer" />
              </a>
            )}
            {details?.others?.length > 0 &&
              details?.others?.map((file) => (
                <a
                  href={file.path}
                  key={file._id}
                  target="_blank"
                  rel="noreferrer"
                  className="tw-flex tw-justify-between tw-items-center tw-my-1 tw-p-2 tw-rounded-md tw-bg-gray-200"
                >
                  <p>{file.document_name}</p>
                  <RedirectArrowIcon className="tw-cursor-pointer" />
                </a>
              ))}
            {!details?.msme?.length > 0 &&
              !details?.gst?.length > 0 &&
              !details?.incorporation?.length > 0 &&
              !details?.others?.length > 0 && <span>No Files uploaded</span>}
          </div>
        </div>
      </div>
    </>
  );
};

export default StartupDetails;
