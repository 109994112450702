import React, { useState, useEffect, useCallback, useMemo } from "react";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import NotiType from "../../utils/notificationRoute";
import NotificationItem from "./NotificationItem";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { Button } from "../../components/ui/Button";

const Notifications = () => {
  const viewNotificationsApi = `${process.env.REACT_APP_API_URL}view-notifications`;
  const updateNotificationApi = `${process.env.REACT_APP_API_URL}update-notification`;
  const markAsReadApi = `${process.env.REACT_APP_API_URL}mark-as-read`;
  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const [notifications, setNotifications] = useState([]);
  const [unReadCount, setUnReadCount] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);

  const memoizedNotiType = useMemo(() => NotiType, []);

  const getAllNotifications = useCallback(() => {
    fetchApi(
      {
        url: viewNotificationsApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setNotifications(data.notifications);
        setUnReadCount(data.unread_notifications);
      }
    );
  }, [viewNotificationsApi, token, fetchApi]);

  const updateNotification = (notificationId) => {
    fetchApi(
      {
        url: updateNotificationApi,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: { id: notificationId },
      },
      () => getAllNotifications()
    );
  };

  const markAsRead = () => {
    fetchApi(
      {
        url: markAsReadApi,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      () => getAllNotifications()
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllNotifications();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [getAllNotifications]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="page-body tw-p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <>
              <Button
                onClick={() => markAsRead()}
                className={`tw-px-2 tw-py-1 tw-text-sm tw-block tw-ms-auto tw-mb-4 ${
                  unReadCount === 0 && "disabled "
                }`}
                disabled={unReadCount === 0}
              >
                Mark as Read
              </Button>
              <div className="tw-grid tw-gap-y-2">
                {notifications && notifications.length > 0 ? (
                  notifications.map((noti) => (
                    <NotificationItem
                      key={noti.id}
                      data={noti}
                      fn={memoizedNotiType}
                      userRole={userRole}
                      updateNotification={updateNotification}
                    />
                  ))
                ) : (
                  <p className="tw-font-medium tw-text-base">
                    You have no new notifications.
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
