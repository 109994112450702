import Select from "react-select";

const Dropdown = ({
  options,
  styles = {
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "200px",
      overflowY: "auto",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      minHeight: "49px",
    }),
  },
  required = true,
  ...props
}) => {
  return (
    <Select options={options} styles={styles} required={required} {...props} />
  );
};

export default Dropdown;
