import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { randomId } from "../utils/randomId";
import Toast from "../components/ui/toast/Toast";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const toastsRef = useRef(toasts);

  useEffect(() => {
    toastsRef.current = toasts;
  }, [toasts]);

  const openToast = (success, msg) => {
    const newId = randomId();
    if (toastsRef.current.length === 5) {
      setToasts((toasts) => {
        const newToasts = [...toasts];
        newToasts.pop();
        return [{ id: newId, success, msg }, ...newToasts];
      });
    } else {
      setToasts((toasts) => [{ id: newId, success, msg }, ...toasts]);
    }
    setTimeout(() => closeToast(newId), 5000);
  };

  const closeToast = (id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  const contextValue = useMemo(
    () => ({
      open: openToast,
      close: closeToast,
    }),
    []
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <div className="tw-space-y-2 tw-z-10 tw-fixed tw-top-4 tw-right-4">
        {toasts?.map(({ id, success, msg }) => (
          <Toast
            key={id}
            success={success}
            msg={msg}
            close={() => closeToast(id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};
