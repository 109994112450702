import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex } from "../../../../constants";

export const WithdrawInvestment = ({
  open,
  onClose,
  accountDetails,
  withdrawInvestment,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const handleModalSubmit = (data, e) => {
    e.preventDefault();
    withdrawInvestment(data.withdrawnAmount);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[40%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading tw-font-semibold tw-text-center tw-border-b tw-p-3">
          Amount Withdrawal
        </h4>
        <form
          onSubmit={handleSubmit(handleModalSubmit)}
          className="tw-p-3 tw-text-left"
        >
          <p className="tw-font-medium">
            Total Investment : <span>{accountDetails.total}</span>
          </p>
          <p className="tw-font-medium">
            Balance Amount : <span>{accountDetails.balance}</span>
          </p>
          <div className="tw-flex tw-mt-2">
            <span className="tw-me-2 tw-font-semibold tw-text-xs">Note :</span>
            <span className="tw-mb-0 tw-font-medium tw-text-xs">
              Balance amount must be atleast 2000.
            </span>
          </div>
          <div className="tw-mt-3">
            <label
              htmlFor="withdrawnAmount"
              className="tw-font-medium tw-text-base required"
            >
              Enter the amount
            </label>
            <input
              type="number"
              id="withdrawnAmount"
              className="tw-w-full tw-px-2 tw-py-1 tw-border tw-border-gray-400 tw-rounded-md focus:tw-ring-1 focus:tw-ring-indigo-600"
              {...register("withdrawnAmount", {
                required: "Amount is required",
                pattern: {
                  value: numberRegex,
                  message: "Amount must only contain digits",
                },
                validate: {
                  notZero: (fieldValue) => {
                    return fieldValue > 0 || "Amount cannot be 0";
                  },
                  checkBalance: (fieldValue) => {
                    return (
                      accountDetails.balance >= fieldValue ||
                      "Amount entered must be lower than or equal to 'Balance Amount'"
                    );
                  },
                  MinAmount: (fieldValue) => {
                    return (
                      fieldValue >= 2000 ||
                      "Balance amount must be atleast 2000"
                    );
                  },
                },
              })}
            />
          </div>
          {errors.withdrawnAmount && (
            <div>
              <FormError message={errors.withdrawnAmount.message} />
            </div>
          )}
          <div className="modalFooter tw-p-3">
            <Button
              type="submit"
              className="tw-px-2 tw-py-1 tw-mx-1 tw-text-sm"
            >
              Withdraw
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
