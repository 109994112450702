import { useEffect } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { urlRegex } from "../../../../constants";
import { GlobeIcon, UserIcon } from "../../../../icons/icons";

export const PrevInvestmentModal = ({
  open,
  onClose,
  updatePrevInvestments,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (data, e) => {
    e.preventDefault();
    updatePrevInvestments(data);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-w-[90%] md:tw-min-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="tw-py-2 tw-border-b tw-text-center tw-font-semibold tw-text-lg">
          Previous Investment Detail
        </h4>
        <form onSubmit={handleSubmit(submitModal)} className="tw-p-2 tw-pb-0">
          <div className="tw-mb-1">
            <label
              className="tw-mb-0 tw-text-sm required"
              htmlFor="prevCompanyName"
            >
              Company Name
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="prevCompanyName"
                name="prevCompanyName"
                autoFocus
                {...register("prevCompanyName", {
                  required: "Company name is required",
                })}
              />
              <span className="input-icons icon-span">
                <UserIcon />
              </span>
            </div>
            {errors.prevCompanyName && (
              <div>
                <FormError message={errors.prevCompanyName.message} />
              </div>
            )}
          </div>
          <div className="tw-mb-1">
            <label className="tw-mb-0 tw-text-sm" htmlFor="prevCompanyUrl">
              Company Website Link
            </label>
            <div
              className="tw-flex tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="prevCompanyUrl"
                name="prevCompanyUrl"
                {...register("prevCompanyUrl", {
                  validate: (fieldvalue) => {
                    return fieldvalue.trim().length > 0
                      ? urlRegex.test(fieldvalue) || "Enter a valid url"
                      : null;
                  },
                })}
              />
              <span className="input-icons icon-span">
                <GlobeIcon />
              </span>
            </div>
            {errors.prevCompanyUrl && (
              <div>
                <FormError message={errors.prevCompanyUrl.message} />
              </div>
            )}
          </div>
          <div className="tw-col-start-2 tw-text-right tw-p-2">
            <Button
              onClick={onClose}
              className="tw-px-1 tw-py-0.5 tw-mx-1"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button type="submit" className="tw-px-1 tw-py-0.5 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
