import { useState } from "react";
import ReactDom from "react-dom";
import { Button } from "../../Button";
import { urlRegex } from "../../../../constants";
import { GlobeIcon } from "../../../../icons/icons";

export const AddPortfolioModal = ({ open, onClose, vendorPortfolio, url }) => {
  const [portfolioFile, setPortfolioFile] = useState();
  const [vendorUrl, setVendorUrl] = useState(url);

  if (!open) return null;

  const submitModal = () => {
    document.getElementById("empty-inputs").classList.add("tw-hidden");
    document.getElementById("invalid-url").classList.add("tw-hidden");
    if (portfolioFile === undefined && vendorUrl === "") {
      document.getElementById("empty-inputs").classList.remove("tw-hidden");
      return;
    }
    if (vendorUrl.length > 0 && !urlRegex.test(vendorUrl)) {
      document.getElementById("invalid-url").classList.remove("tw-hidden");
      return;
    }
    vendorPortfolio(vendorUrl, portfolioFile, setPortfolioFile);
    onClose();
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-min-[90%] md:tw-w-[400px] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="tw-py-2 tw-border-b tw-text-center tw-font-semibold tw-text-lg">
          Company Portfolio
        </h4>
        <form className="tw-p-2 tw-pb-0">
          <span
            id="empty-inputs"
            className="tw-hidden tw-text-secondary tw-text-xs"
          >
            Please fill atleast one field before submitting
          </span>
          <span
            id="invalid-url"
            className="tw-hidden tw-text-secondary tw-text-xs"
          >
            Invalid website url
          </span>
          <div className="tw-mb-1">
            <label htmlFor="vendorWebsite" className="tw-mb-0 tw-text-sm">
              Portfolio website
            </label>
            <div
              className="tw-flex tw-mb-3 tw-rounded-md tw-overflow-hidden"
              style={{
                border: "1px solid #ced4da",
              }}
            >
              <input
                type="text"
                className="tw-border-0 tw-w-full tw-ps-3"
                id="vendorWebsite"
                name="vendorWebsite"
                value={vendorUrl}
                onChange={(e) => setVendorUrl(e.target.value)}
                placeholder="Enter full website address"
                autoFocus
              />
              <span className="input-icons icon-span">
                <GlobeIcon />
              </span>
            </div>
          </div>
          <div>
            <p className="tw-mb-0">Upload Images or PDF's</p>
            <label
              htmlFor="uploadPortfolio"
              className="tw-p-1 tw-rounded-md tw-bg-primary tw-text-white tw-text-sm tw-cursor-pointer"
            >
              Upload
            </label>
            <input
              type="file"
              id="uploadPortfolio"
              name="uploadPortfolio"
              accept="image/png,image/jpeg,application/pdf"
              className="tw-hidden"
              onChange={(e) => setPortfolioFile(e.target.files[0])}
            />
            <span className="tw-ms-2">
              {portfolioFile && portfolioFile.name}
            </span>
          </div>
          <div className="tw-col-start-2 tw-text-right tw-p-2">
            <Button
              onClick={onClose}
              className="tw-px-1 tw-py-0.5 tw-mx-1"
              bgColor="tw-bg-secondary"
            >
              Close
            </Button>
            <Button onClick={submitModal} className="tw-px-1 tw-py-0.5 tw-mx-1">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
