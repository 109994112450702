import { CircleCheck } from "../../icons/icons";

export const EmailSuccess = ({ backToLogin }) => {
  return (
    <div
      className="tw-flex tw-flex-col tw-col-span-7 authentication-bg tw-relative tw-text-center tw-pt-14 md:tw-pt-0 md:tw-justify-center md:tw-col-start-2 md:tw-col-span-5 md:tw-items-center tw-pb-3 tw-px-4 md:tw-px-0 md:tw-py-0 md:tw-my-3 lg:tw-col-span-2 lg:tw-my-0 lg:tw-py-0"
      id="confirmationText"
    >
      <div className="tw-mx-auto tw-pt-5 md:tw-p-6 md:tw-rounded-md md:tw-shadow-md md:tw-w-full tw-bg-white lg:tw-p-3 lg:tw-shadow-none lg:tw-rounded-none lg:tw-bg-transparent lg:tw-pt-0">
        <CircleCheck
          width="70px"
          height="70px"
          fill="#1bcf1b"
          className="tw-mx-auto tw-mb-4"
        />
        <h3 className="tw-font-medium tw-mb-2">Check your email!</h3>
        <p>
          An mail has been sent to your registered email address containing link
          to reset your password.
        </p>
        <p className="tw-mt-2">
          Go Back to
          <span
            className="tw-ps-1 tw-text-blue-500 tw-cursor-pointer tw-underline tw-underline-offset-1"
            onClick={backToLogin}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
