import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Button";
import "./ticketModal.css";
import { FormError } from "../../FormError";
import { CircleCheck } from "../../../../icons/icons";

export const TicketModal = ({ open, onClose, supportTicket }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  if (!open) return null;

  const complaintConfirmation = (data, e) => {
    e.preventDefault();
    let file = document.getElementById("ticket-document").files[0];
    supportTicket(file, data);
    document.getElementById("modal-front").classList.add("tw-hidden");
    document.getElementById("modal-back").classList.remove("tw-hidden");
  };

  return ReactDom.createPortal(
    <div
      className={`tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-z-10 ${
        open ? "tw-visible tw-bg-black/20" : "tw-invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`tw-w-[90%] md:tw-w-[40%] tw-bg-white tw-rounded-xl tw-shadow tw-p-4 tw-pb-2 tw-transition-all ${
          open ? "tw-scale-100 tw-opacity-100" : "tw-scale-125 tw-opacity-0"
        }`}
      >
        <div id="modal-front">
          <p onClick={onClose} className="closeBtn">
            X
          </p>
          <h4 className="modal-heading tw-text-black tw-font-semibold tw-text-center tw-border-b tw-p-3">
            Raise a complaint
          </h4>
          <form
            className="form tw-p-2 tw-text-gray-500 tw-text-sm"
            onSubmit={handleSubmit(complaintConfirmation)}
          >
            <div className="tw-mt-2">
              <label htmlFor="complaintCategory" className="tw-mb-1 required">
                What is your complaint regarding*
              </label>
              <select
                className="tw-w-full tw-px-2 tw-py-1 tw-border tw-border-gray-400 tw-rounded-md focus:tw-ring-1 focus:tw-ring-indigo-600"
                id="complaintCategory"
                name="complaintCategory"
                required
                {...register("complaintCategory", {
                  required: "category is required",
                })}
              >
                <option value="website-bug">Website Bug</option>
                <option value="fund-transfer">Fund Transfer</option>
                <option value="startup">Startup</option>
                <option value="mentor">Mentor</option>
                <option value="investor">Investor</option>
                <option value="service-provider">Service Provider</option>
                <option value="other">Other</option>
              </select>
              {errors.complaintCategory && (
                <div>
                  <FormError message={errors.complaintCategory.message} />
                </div>
              )}
            </div>
            <div className="tw-mt-2">
              <label htmlFor="complaintHeading" className="required">
                Complaint Heading
              </label>
              <input
                type="text"
                id="complaintHeading"
                name="complaintHeading"
                className="tw-w-full tw-px-2 tw-py-1 tw-border tw-border-gray-400 tw-rounded-md focus:tw-ring-1 focus:tw-ring-indigo-600"
                {...register("complaintHeading", {
                  required: "heading is required",
                  minLength: {
                    value: 10,
                    message: "Must be at least 10 characters long",
                  },
                  maxLength: {
                    value: 100,
                    message: "Must not exceed 100 characters",
                  },
                })}
              />
              {errors.complaintHeading && (
                <div>
                  <FormError message={errors.complaintHeading.message} />
                </div>
              )}
            </div>
            <div className="tw-mt-3">
              <textarea
                name="complaintQuery"
                id="complaintQuery"
                cols="30"
                rows="3"
                placeholder="Wrtie your query here..."
                className="tw-w-full tw-px-2 tw-py-1 tw-border tw-border-gray-400 tw-rounded-md focus:tw-ring-1 focus:tw-ring-indigo-600"
                {...register("complaintQuery", {
                  required: "description is required",
                  minLength: {
                    value: 30,
                    message: "Must be at least 30 characters long",
                  },
                })}
              ></textarea>
              {errors.complaintQuery && (
                <div>
                  <FormError message={errors.complaintQuery.message} />
                </div>
              )}
            </div>
            <div className="tw-my-2">
              <label
                htmlFor="ticket-document"
                className="tw-text-customOrange tw-cursor-pointer tw-bg-slate-200 tw-shadow-md tw-p-1 tw-rounded-md"
              >
                Upload Supporting documents
              </label>
              <input
                type="file"
                id="ticket-document"
                accept="application/pdf,image/jpeg,image/png,.pdf,.jpg,.jpeg,.png"
                hidden
              />
            </div>
            <div className="tw-float-right tw-p-0 tw-my-2">
              <Button type="submit" className="tw-px-2 tw-py-1 tw-mx-1">
                Submit
              </Button>
            </div>
          </form>
        </div>
        <div
          className="modal-back tw-p-3 tw-text-gray-500 tw-text-center tw-hidden"
          id="modal-back"
        >
          <button type="button" className="closeBtn" onClick={onClose}>
            X
          </button>
          <h5 className="tw-font-medium tw-border-b tw-p-2 tw-my-2">
            Complaint Registered Successfully!
          </h5>
          <p className="tw-py-2">
            We will get back to you as soon as possible. Thank you.
          </p>
          <CircleCheck
            width="70px"
            height="70px"
            fill="#45ba5c"
            className="tw-mx-auto"
          />
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
