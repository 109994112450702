import { Button } from "../../Button";
import { FormError } from "../../FormError";
import { numberRegex, textWithSpaceRegex } from "../../../../constants";
import { DeleteIcon, PenIcon, PlusIcon } from "../../../../icons/icons";
import { useState } from "react";
import { randomId } from "../../../../utils/randomId";

const PlanForm = ({
  submitModal,
  register,
  handleSubmit,
  errors,
  features,
  setFeatures,
}) => {
  const [featureId, setFeatureId] = useState(null);

  const addFeature = () => {
    const newFeature = document.getElementById("planFeatures").value;
    if (featureId) {
      updateFeature(featureId, newFeature);
      document.getElementById("planFeatures").value = "";
      return;
    }
    if (newFeature.trim() !== "") {
      document.getElementById("feature-error").classList.add("tw-hidden");
      setFeatures((prev) => [
        ...prev,
        { key: randomId(), value: newFeature.trim() },
      ]);
      document.getElementById("planFeatures").value = "";
    } else {
      document.getElementById("feature-error").classList.remove("tw-hidden");
    }
  };

  const removeFeature = (id) => {
    const updatedList = features.filter((feature) => feature.key !== id);
    setFeatures(updatedList);
  };

  const editFeature = (data) => {
    setFeatureId(data.key);
    document.getElementById("planFeatures").value = data.value;
  };

  const updateFeature = (id, newValue) => {
    setFeatureId(null);
    setFeatures((prevItems) =>
      prevItems.map((item) => {
        if (item.key === id) {
          return { ...item, value: newValue };
        }
        return item;
      })
    );
  };

  return (
    <form
      onSubmit={handleSubmit((data) => submitModal(data, features))}
      className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-3 tw-p-3 tw-pb-0 tw-mt-2"
    >
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="planFor"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Subscription For
        </label>
        <div className="select-input tw-mb-1 tw-w-full tw-border tw-border-slate-300 tw-m-auto tw-rounded-md">
          <select
            name="planFor"
            id="planFor"
            className="tw-border-0 tw-w-full tw-text-gray-500"
            {...register("planFor", {
              required: "Select a user type",
            })}
          >
            <option value="startup">Startup</option>
            <option value="vendor">Vendor</option>
          </select>
        </div>
        {errors.planFor && (
          <div>
            <FormError message={errors.planFor.message} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="planName"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Plan Name
        </label>
        <input
          type="text"
          id="planName"
          name="planName"
          className="tw-p-[0.7rem] tw-border tw-rounded-md"
          placeholder="Enter plan name"
          {...register("planName", {
            required: "Name is required",
            pattern: {
              value: textWithSpaceRegex,
              message: "Name must only contain alphabets",
            },
          })}
        />
        {errors.planName && (
          <div>
            <FormError message={errors.planName.message} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="planPrice"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Plan Price
        </label>
        <input
          type="number"
          id="planPrice"
          name="planPrice"
          className="tw-p-[0.7rem] tw-border tw-rounded-md"
          placeholder="Enter plan price"
          {...register("planPrice", {
            required: "price is required",
            pattern: {
              value: numberRegex,
              message: "Price must only contain digits",
            },
          })}
        />
        {errors.planPrice && (
          <div>
            <FormError message={errors.planPrice.message} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="planTokens"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Tokens
        </label>
        <input
          type="number"
          id="planTokens"
          name="planTokens"
          className="tw-p-[0.7rem] tw-border tw-rounded-md"
          placeholder="Enter tokens"
          {...register("planTokens", {
            required: "tokens is required",
            pattern: {
              value: numberRegex,
              message: "Tokens must only contain digits",
            },
            validate: {
              notZero: (fieldValue) => {
                return fieldValue > 0 || "Tokens cannot be 0";
              },
            },
          })}
        />
        {errors.planTokens && (
          <div>
            <FormError message={errors.planTokens.message} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="discount"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Discount
        </label>
        <div className="select-input tw-mb-1 tw-w-full tw-border tw-border-slate-300 tw-m-auto tw-rounded-md">
          <select
            name="discount"
            id="discount"
            className="tw-border-0 tw-w-full tw-text-gray-500"
            {...register("discount", {
              required: "Select a discount",
            })}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        {errors.discount && (
          <div>
            <FormError message={errors.discount.message} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="type"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Discount Type
        </label>
        <div className="select-input tw-mb-1 tw-w-full tw-border tw-border-slate-300 tw-m-auto tw-rounded-md">
          <select
            name="type"
            id="type"
            className="tw-border-0 tw-w-full tw-text-gray-500"
            {...register("type", {
              required: "Select a discount type",
            })}
          >
            <option value="flat">Flat</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        {errors.type && (
          <div>
            <FormError message={errors.type.message} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <label
          htmlFor="discountAmount"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Discount Amount/Percentage
        </label>
        <input
          type="text"
          id="discountAmount"
          name="discountAmount"
          className="tw-p-[0.7rem] tw-border tw-rounded-md"
          placeholder="Enter discount"
          {...register("discountAmount", {
            required: "amount/percentage is required",
            pattern: {
              value: numberRegex,
              message: "amount/percentage must only contain digits",
            },
          })}
        />
        {errors.discountAmount && (
          <div>
            <FormError message={errors.discountAmount.message} />
          </div>
        )}
      </div>
      <div className="md:tw-col-span-2 tw-flex tw-flex-col">
        <label
          htmlFor="planFeatures"
          className="tw-mb-1 tw-font-medium tw-text-sm required"
        >
          Plan Features
        </label>
        <div className="tw-flex tw-items-center tw-justify-between">
          <input
            type="text"
            id="planFeatures"
            name="planFeatures"
            className="tw-w-11/12 tw-p-[0.7rem] tw-border tw-rounded-md"
            placeholder="Enter plan feature"
          />
          <PlusIcon
            width="22px"
            height="22px"
            className="tw-bg-primary tw-p-1 tw-rounded-full"
            onClick={() => addFeature()}
          />
        </div>
        <p id="feature-error" className="tw-hidden tw-text-xs tw-text-red-500">
          Feature cannot be empty
        </p>
        <p
          id="feature-required"
          className="tw-hidden tw-text-xs tw-text-red-500"
        >
          Feature are required
        </p>
        {features.length > 0 && (
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-mt-2">
            {features.map((feature) => (
              <div
                key={feature.key}
                className="tw-flex tw-justify-between tw-items-center tw-w-full"
              >
                <div className="tw-bg-slate-200 tw-p-1 tw-rounded-md tw-max-w-[90%] tw-break-normal">
                  {feature.value}
                </div>
                <div className="tw-flex tw-items-center tw-gap-x-2">
                  <PenIcon
                    fill="#12d512"
                    onClick={() => editFeature(feature)}
                  />
                  <DeleteIcon onClick={() => removeFeature(feature.key)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Button
        type="submit"
        className="tw-col-span-3 tw-w-fit tw-ml-auto tw-px-3 tw-py-1.5 tw-mx-1"
      >
        Update
      </Button>
    </form>
  );
};

export default PlanForm;
