import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteChatRoom, setChatRoom } from "../../store/slices/ChatSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useToastContext } from "../../context/ToastContext";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import { BidCard } from "../../components/ui/cards/bidCard";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Button } from "../../components/ui/Button";
import RatingModal from "../../components/ui/modals/rating-modal/RatingModal";

const Bids = () => {
  const viewAdApi = `${process.env.REACT_APP_API_URL}display-ad`;
  const viewBidsApi = `${process.env.REACT_APP_API_URL}view-bids`;
  const connectApi = `${process.env.REACT_APP_API_URL}connect`;
  const markAsCompletedApi = `${process.env.REACT_APP_API_URL}mark-as-complete`;
  const createCheckoutSessionApi = `${process.env.REACT_APP_API_URL}create-checkout-session`;
  const submitReviewApi = `${process.env.REACT_APP_API_URL}review`;
  const getRoomIdApi = `${process.env.REACT_APP_API_URL}get-roomid`;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToastContext();
  const { userRole, token } = useLocalStorage();
  const { isLoading, error, fetchApi } = useFetch();

  const [bids, setBids] = useState([]);
  const [singleAd, setSingleAd] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openRatingModal, setOpenRatingModal] = useState(false);

  const connect = (userId) => {
    fetchApi(
      {
        url: connectApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          connection_id: userId,
        },
      },
      (data) => {
        if (data.message === "connection already established") {
          getRoomId(userId);
          navigate("/chat");
        } else {
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: userId,
              recieverRole: "V&iR8",
              status: "1",
            })
          );
          navigate("/chat");
        }
      }
    );
  };

  const getRoomId = useCallback(
    (userId) => {
      fetchApi(
        {
          url: getRoomIdApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            connection_id: userId,
            role: "V&iR8",
          },
        },
        (data) => {
          dispatch(deleteChatRoom());
          dispatch(
            setChatRoom({
              roomId: data.room_id,
              recieverId: userId,
              recieverRole: "V&iR8",
              status: "1",
            })
          );
        }
      );
    },
    [getRoomIdApi, fetchApi, token, dispatch]
  );

  const markAsCompleted = (bid_id, vendor_id) => {
    fetchApi(
      {
        url: markAsCompletedApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          ad_id: id,
          bid_id: bid_id,
          vendor_id: vendor_id,
        },
      },
      () => {
        navigate("/my-projects");
      }
    );
  };

  // const createCheckoutSession = (data) => {
  //   fetchApi(
  //     {
  //       url: createCheckoutSessionApi,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         "x-token": token,
  //       },
  //       body: {
  //         type: "market",
  //         data: data,
  //         ad_id: id,
  //         title: singleAd.title,
  //       },
  //     },
  //     (data) => {
  //       window.location = data.url;
  //     }
  //   );
  // };

  useEffect(() => {
    const viewAd = () => {
      fetchApi(
        {
          url: viewAdApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        (data) => {
          setSingleAd(data.ad);
        }
      );
    };

    const viewBids = () => {
      fetchApi(
        {
          url: viewBidsApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            ad_id: id,
          },
        },
        (data) => {
          setBids(data.bid_details);
        }
      );
    };

    const timer = setTimeout(() => {
      viewAd();
      viewBids();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [token, viewAdApi, viewBidsApi, fetchApi, id]);

  const submitRating = (rating, review) => {
    fetchApi(
      {
        url: submitReviewApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          reciever_id: singleAd.user_id,
          review: review,
          rating: rating,
          role: "startup",
          ad_id: id,
        },
      },
      (data) => {
        if (data.message === "already reviewed") {
          toast.open("warning", "You've already reviewed this user.");
        }
        setOpenRatingModal(false);
      }
    );
  };

  return (
    <div className="tw-grid tw-grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && (
          <div className="tw-p-4">
            <Error />
          </div>
        )}
        {!isLoading && !error && (
          <div className="tw-p-3">
            <h4 className="tw-text-lg tw-font-medium">Project</h4>
            <div className="tw-mt-1 tw-px-1 tw-py-3 tw-shadow tw-rounded-md">
              <div className="tw-px-2">
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mb-2">
                  <span className="tw-order-2 tw-my-2 tw-font-semibold tw-text-lg md:tw-order-1 md:tw-my-0">
                    {singleAd.title}
                  </span>
                  <div className="tw-order-1 tw-flex tw-flex-wrap tw-items-center tw-justify-between md:tw-order-2 md:tw-block">
                    <p className="tw-text-xs">
                      <span className="tw-font-semibold tw-pe-1">Budget :</span>
                      ₹{singleAd.min_budget} - ₹{singleAd.max_budget}
                    </p>
                    <p className="tw-text-xs">
                      <span className="tw-font-semibold tw-pe-1">
                        Duration :
                      </span>
                      {singleAd.deadline}
                      days
                    </p>
                  </div>
                </div>
                <p
                  className={`${
                    singleAd.description?.length > 150 ? "description-text" : ""
                  } tw-text-sm`}
                >
                  {singleAd.description}
                </p>
                {singleAd.description?.length > 150 && (
                  <input type="checkbox" className="expand-btn" />
                )}
                <p className="tw-mt-3">
                  {singleAd.tags?.map((tag, i) => (
                    <span key={i} className="outline-tags">
                      {tag.replace(new RegExp("_", "g"), " ")}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-mt-2">
              <p
                className={
                  singleAd.status === "0"
                    ? "tw-text-green-500"
                    : singleAd.status === "1"
                    ? "tw-text-orange-500"
                    : "tw-text-primary"
                }
              >
                <span className="tw-font-medium tw-text-black tw-pe-1">
                  Status :
                </span>
                {singleAd.status === "0"
                  ? "Active"
                  : singleAd.status === "1"
                  ? "Awarded"
                  : "Completed"}
              </p>
              <p className="tw-ms-3">
                <span className="tw-font-medium">Average bid amount :</span> ₹
                {singleAd.average_bid}
              </p>
            </div>
            {userRole === "f@!3A" && (
              <p className="tw-text-sm">
                <span className="tw-font-medium tw-pe-1">Note :</span> The
                awarded amount is freezed and released to the service provider
                only upon completion of the project.
              </p>
            )}
            {bids
              ?.filter((bid) => bid.status === "1" || bid.status === "2")
              .map((bid) => (
                <div key={bid.bid_id}>
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <h4 className="tw-mt-4 tw-text-lg tw-font-medium">
                      {bid.status === "1" ? "Awarded" : "Completed"} Bid
                    </h4>
                    {bid.status === "2" && userRole === "V&iR8" && (
                      <Button
                        className="tw-px-3 tw-py-1"
                        onClick={() => setOpenRatingModal(true)}
                      >
                        Review
                      </Button>
                    )}
                  </div>
                  <BidCard
                    data={bid}
                    vendorName={bid.name}
                    role={userRole}
                    connect={connect}
                    ad_status={singleAd.status}
                    markAsCompleted={markAsCompleted}
                    ad_id={id}
                    title={singleAd.title}
                  />
                </div>
              ))}
            <h4 className="tw-mt-4 tw-text-lg tw-font-medium">All Bids</h4>
            {bids.length > 0 &&
            bids.filter((bid) => bid.status === "0").length > 0 ? (
              bids
                .filter((bid) => bid.status === "0")
                .map((bid) => (
                  <BidCard
                    key={bid.bid_id}
                    data={bid}
                    vendorName={bid.name}
                    role={userRole}
                    connect={connect}
                    ad_status={singleAd.status}
                    ad_id={id}
                    title={singleAd.title}
                  />
                ))
            ) : (
              <p>No bid found</p>
            )}
          </div>
        )}
      </div>
      <RatingModal
        open={openRatingModal}
        onClose={() => setOpenRatingModal(false)}
        submitRating={submitRating}
        type="market"
      />
    </div>
  );
};

export default Bids;
