import { Link } from "react-router-dom";

const SingleNotification = ({ noti, userRole, memoizedNotiType }) => {
  return (
    <li key={noti.id}>
      <Link
        to={memoizedNotiType(noti.notification_type, userRole)}
        className={`tw-block tw-rounded tw-p-1 tw-mb-1 ${
          noti.status === "1"
            ? "tw-bg-gray-200"
            : "tw-bg-white tw-text-black xl:hover:tw-bg-[#ff3f00] xl:hover:tw-text-white"
        }`}
      >
        <p className="tw-text-left tw-text-xs">{noti.heading}</p>
        <p className="tw-text-right tw-text-[10px]">{noti.date}</p>
      </Link>
    </li>
  );
};

export default SingleNotification;
