import { Button } from "../Button";
import { CheckIcon, DeleteIcon, PenIcon } from "../../../icons/icons";
import useCheckout from "../../../hooks/useCheckout";

const SubscriptionCard = ({
  data,
  role = "",
  updateModal,
  changePlanStatus,
  handleDeleteModal,
  callbackUrl,
}) => {
  const { createCheckoutSession } = useCheckout();

  return (
    <div
      className={`tw-relative tw-card tw-border tw-rounded-md tw-shadow-none ${
        role === "admin" ? "tw-group hover:tw-bg-slate-200" : ""
      }`}
    >
      {role === "admin" && (
        <label className="tw-inline-flex tw-items-center tw-cursor-pointer">
          <input
            type="checkbox"
            className="tw-sr-only tw-peer"
            checked={data.plan_status === "0" ? false : true}
            onChange={() =>
              changePlanStatus(data._id, data.plan_status === "0" ? "1" : "0")
            }
          />
          <div className="tw-relative tw-w-9 tw-h-5 tw-bg-gray-200 tw-rounded-full tw-peer dark:tw-bg-gray-700 peer-checked:after:tw-translate-x-full rtl:peer-checked:after:-tw-translate-x-full peer-checked:after:tw-border-white after:content-[''] after:tw-absolute after:tw-top-[2px] after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-4 after:tw-w-4 after:tw-transition-all dark:tw-border-gray-600 peer-checked:tw-bg-blue-600"></div>
        </label>
      )}

      {role === "admin" && (
        <>
          <span
            className="tw-hidden group-hover:tw-inline tw-absolute tw-right-10 tw-top-2 tw-cursor-pointer"
            onClick={() => updateModal(data)}
          >
            <PenIcon fill="#12d512" />
          </span>
          <span
            className="tw-hidden group-hover:tw-inline tw-absolute tw-right-2 tw-top-2 tw-cursor-pointer"
            onClick={() => handleDeleteModal(data._id)}
          >
            <DeleteIcon />
          </span>
        </>
      )}
      <h4 className="tw-font-medium tw-text-center tw-capitalize tw-mb-1 tw-pt-1">
        {data.plan_type}
      </h4>
      <div className="tw-pb-3 tw-text-center">
        {data.discount_status === "0" ? (
          <div className="tw-flex tw-justify-center">
            <sup className="tw-text-base tw-text-medium tw-mt-3 tw-mb-0 tw-me-1">
              ₹
            </sup>
            <h4 className="tw-font-medium tw-text-4xl tw-mb-0 tw-text-indigo-500">
              {data.final_amount}
            </h4>
          </div>
        ) : (
          <>
            <div className="tw-flex tw-justify-center">
              <sup className="tw-text-base tw-text-medium tw-mt-3 tw-mb-0 tw-me-1">
                ₹
              </sup>
              <h4 className="tw-font-medium tw-text-4xl tw-mb-0 tw-text-indigo-500">
                {data.final_amount}
              </h4>
              <sub className="tw-flex tw-justify-center tw-items-center tw-opacity-75 tw-ml-1 tw-mt-3 tw-text-sm">
                /
                <div className="tw-flex tw-justify-center tw-items-center tw-ml-1">
                  <span className="tw-text-medium tw-me-1">₹</span>
                  <h4 className="tw-font-medium tw-mb-0 tw-text-indigo-500 tw-line-through">
                    {data.amount}
                  </h4>
                </div>
              </sub>
            </div>{" "}
          </>
        )}
        <ul className="fa-ul tw-ms-2 tw-my-4 tw-pt-3 tw-text-left">
          {data.plan_features.map((feature) => (
            <li
              className="tw-flex tw-items-start tw-mb-2 tw-text-sm"
              key={feature.key}
            >
              <span className="fa-li tw-pr-2">
                <CheckIcon width="14px" />
              </span>
              {feature.value}
            </li>
          ))}
        </ul>
        {role.length < 1 &&
          (data.final_amount !== 0 ? (
            <Button
              onClick={() =>
                createCheckoutSession(
                  {
                    type: "subscription",
                    plan_type: data.plan_type,
                    id: data._id,
                  },
                  callbackUrl
                )
              }
              className="tw-absolute tw-left-0 tw-bottom-0 tw-px-2 tw-py-1 tw-w-full tw-mb-0 tw-rounded-t-none"
            >
              Select
            </Button>
          ) : (
            <Button
              className="tw-absolute tw-left-0 tw-bottom-0 tw-px-2 tw-py-1 tw-w-full tw-mb-0 tw-rounded-t-none disabled"
              disabled
            >
              Default Plan
            </Button>
          ))}
      </div>
    </div>
  );
};

export default SubscriptionCard;
