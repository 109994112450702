import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useFetch from "../../../hooks/useFetch";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import AdminNavbar from "../../../components/layout/adminNavbar";
import AdminSidebar from "../../../components/layout/adminSidebar";
import FavIcon from "../../../images/favicon.png";
import { ImportImage } from "../../../utils/importImage";

const RequestDetails = () => {
  const getDetailsApi = `${process.env.REACT_APP_API_URL}view-withdraw`;
  const updateStatusApi = `${process.env.REACT_APP_API_URL}update-withdraw-request`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = location.state;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [userData, setUserData] = useState([]);
  const [status, setStatus] = useState("0");

  const getDetails = useCallback(() => {
    fetchApi(
      {
        url: getDetailsApi,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
        body: {
          user_id: data?.user_id,
        },
      },
      (data) => {
        setUserData(data.details);
      }
    );
  }, [getDetailsApi, fetchApi, token, data]);

  const updateStatus = useCallback(
    (status) => {
      fetchApi(
        {
          url: updateStatusApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: data?.id,
            role: data?.role,
            status: status,
          },
        },
        () => {
          setStatus(status);
          getDetails();
        }
      );
    },
    [updateStatusApi, fetchApi, token, data, getDetails]
  );

  useEffect(() => {
    if (userRole !== "admin") {
      navigate("/error-401");
    }
    const timer = setTimeout(() => {
      setStatus(data?.status);
      getDetails();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getDetails, navigate, userRole, data]);

  return (
    <div className="tw-grid tw-grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half tw-col-span-5 lg:tw-col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        {isLoading && <Loader />}
        {error && <Error />}
        {!isLoading && !error && (
          <div className="tw-grid tw-grid-cols-3 tw-m-4 tw-p-4 tw-shadow-md tw-rounded-md">
            {userData &&
              userData.map((data) => (
                <div key={data.email} className="tw-border-r tw-pe-1">
                  <img
                    src={data.logo ? data.logo : FavIcon}
                    alt="user-img"
                    width={150}
                    className="tw-h-[150px] tw-rounded-full tw-p-1 tw-mb-3 tw-border tw-border-slate-400"
                  />
                  <p className="tw-font-semibold">
                    Name :
                    <span className="tw-ms-1 tw-font-medium tw-uppercase">
                      {data.name}
                    </span>
                  </p>
                  <p className="tw-font-semibold">
                    Role :
                    <span className="tw-ms-1 tw-font-medium">
                      {data.role === "f@!3A"
                        ? "Startup"
                        : data.role === "R$7s2"
                        ? "Mentor"
                        : "Vendor"}
                    </span>
                  </p>
                  <p className="tw-font-semibold">
                    Email :
                    <span className="tw-ms-1 tw-font-medium">{data.email}</span>
                  </p>
                  <p className="tw-font-semibold">
                    Phone :
                    <span className="tw-ms-1 tw-font-medium">{data.phone}</span>
                  </p>
                </div>
              ))}
            <div className="tw-col-span-2 tw-p-2">
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-items-center tw-mt-3 md:tw-mt-0 tw-mb-2">
                <h4 className="tw-font-medium tw-order-2 md:tw-order-1">
                  Current Status :
                  <span
                    className={`
                    tw-ps-2
                      ${
                        status === "0"
                          ? "tw-text-orange-500"
                          : status === "1"
                          ? "tw-text-green-500"
                          : "tw-text-red-500"
                      }`}
                  >
                    {status === "0"
                      ? "Pending"
                      : status === "1"
                      ? "Completed"
                      : "Rejected"}
                  </span>
                </h4>
                <div className="tw-flex tw-items-center tw-order-1 md:tw-order-2">
                  <label
                    htmlFor="status-filter"
                    className="tw-hidden md:tw-inline-block tw-mb-0 tw-pe-1 tw-font-medium tw-text-sm"
                  >
                    Change Status :
                  </label>
                  <select
                    htmlFor="status-filter"
                    id="status-filter"
                    className="tw-border tw-shadow tw-p-1 tw-rounded tw-text-sm"
                    value={status}
                    onChange={(e) => updateStatus(e.target.value)}
                  >
                    <option value="0">Pending</option>
                    <option value="1">Completed</option>
                    <option value="-1">Rejected</option>
                  </select>
                </div>
              </div>
              <p className="tw-font-semibold">
                Requested Amount :
                <span className="tw-ms-1 tw-font-medium">
                  {data?.role === "f@!3A"
                    ? "₹" + data?.withdraw_amount
                    : "₹" + data?.withdraw_amount * (1 - 0.1)}
                </span>
              </p>
              <h4 className="tw-font-semibold tw-mt-2 tw-mb-1">Bank Details</h4>
              {userData ? (
                userData[0]?.bank_details?.length > 0 ? (
                  userData[0]?.bank_details.map((bank) => (
                    <div
                      key={bank._id}
                      className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-mt-2 tw-rounded-md tw-bg-gray-200"
                    >
                      <div>
                        <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                          Bank :
                          <span className="tw-ps-2 tw-font-medium tw-whitespace-normal tw-uppercase">
                            {bank.bank_name} Bank
                          </span>
                        </p>
                        <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                          Account Holder :
                          <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                            {bank.name}
                          </span>
                        </p>
                        <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                          Account Number :
                          <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                            {bank.account_number}
                          </span>
                        </p>
                        <p className="tw-font-semibold tw-text-xs md:tw-text-sm tw-leading-relaxed">
                          IFSC :
                          <span className="tw-ps-2 tw-font-medium tw-whitespace-normal">
                            {bank.ifsc}
                          </span>
                        </p>
                      </div>
                      <ImportImage
                        imageName={bank.bank_name}
                        width={45}
                        alt={bank.bank_name}
                      />
                    </div>
                  ))
                ) : (
                  <p>No Bank Found. Ask user to update bank details.</p>
                )
              ) : (
                <p className="tw-text-red-500 tw-font-medium">
                  Sorry, Something went wrong
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestDetails;
