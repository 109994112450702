import React from "react";
import { Link } from "react-router-dom";
import {
  AddUserIcon,
  BellIcon,
  CircleExclamationIcon,
  ClipBoardIcon,
  CommentIcon,
  DashboardIcon,
  GearIcon,
  RocketIcon,
  RupeeIcon,
  UserGraduateIcon,
  UserIcon,
  UserTieIcon,
  VideoOnIcon,
} from "../../icons/icons";

export const InvestorSidebar = ({ currPath, activeStyle, regularStyle }) => {
  return (
    <ul className="tw-p-3 tw-pt-4">
      <Link
        to="/investor-dashboard"
        className={
          currPath === "#/investor-dashboard" ? activeStyle : regularStyle
        }
      >
        <DashboardIcon
          fill={currPath === "#/investor-dashboard" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Dashboard
        </span>
      </Link>
      <Link
        to="/investor-profile"
        className={
          currPath === "#/investor-profile" ? activeStyle : regularStyle
        }
      >
        <UserIcon
          fill={currPath === "#/investor-profile" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Profile
        </span>
      </Link>
      <Link
        to="/startups"
        className={
          currPath === "#/startups" || currPath === "#/view-startup"
            ? activeStyle
            : regularStyle
        }
      >
        <RocketIcon
          fill={
            currPath === "#/startups" || currPath === "#/view-startup"
              ? "#fff"
              : "#999999"
          }
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Startups
        </span>
      </Link>
      <Link
        to="/mentors"
        className={
          currPath === "#/mentors" || currPath === "#/view-mentor"
            ? activeStyle
            : regularStyle
        }
      >
        <UserGraduateIcon
          fill={
            currPath === "#/mentors" || currPath === "#/view-mentor"
              ? "#fff"
              : "#999999"
          }
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Mentors
        </span>
      </Link>
      <Link
        to="/investors"
        className={
          currPath === "#/investors" || currPath === "#/view-investor"
            ? activeStyle
            : regularStyle
        }
      >
        <UserTieIcon
          fill={
            currPath === "#/investors" || currPath === "#/view-investor"
              ? "#fff"
              : "#999999"
          }
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Investors
        </span>
      </Link>
      <Link
        to="/chat"
        className={currPath === "#/chat" ? activeStyle : regularStyle}
      >
        <CommentIcon fill={currPath === "#/chat" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">Chat</span>
      </Link>
      {/* <Link
        to="/meetings"
        className={currPath === "#/meetings" ? activeStyle : regularStyle}
      >
        <VideoOnIcon
          fill={currPath === "#/meetings" ? "#fff" : "#999999"}
          width="18px"
          height="18px"
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Meetings
        </span>
      </Link> */}
      <Link
        to="/investor-connections"
        className={
          currPath === "#/investor-connections" ? activeStyle : regularStyle
        }
      >
        <AddUserIcon
          fill={currPath === "#/investor-connections" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Connections
        </span>
      </Link>
      <Link
        to="/investor-investments"
        className={
          currPath === "#/investor-investments" ? activeStyle : regularStyle
        }
      >
        <RupeeIcon
          fill={currPath === "#/investor-investments" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Investments
        </span>
      </Link>
      <Link
        to="/notifications"
        className={currPath === "#/notifications" ? activeStyle : regularStyle}
      >
        <BellIcon fill={currPath === "#/notifications" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Notifications
        </span>
      </Link>
      <Link
        to="/tickets"
        className={currPath === "#/tickets" ? activeStyle : regularStyle}
      >
        <ClipBoardIcon fill={currPath === "#/tickets" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Tickets
        </span>
      </Link>
      <Link
        to="/settings"
        className={currPath === "#/settings" ? activeStyle : regularStyle}
      >
        <GearIcon fill={currPath === "#/settings" ? "#fff" : "#999999"} />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">
          Settings
        </span>
      </Link>
      <Link
        to="/faq"
        className={currPath === "#/faq" ? activeStyle : regularStyle}
      >
        <CircleExclamationIcon
          fill={currPath === "#/faq" ? "#fff" : "#999999"}
        />
        <span className="tw-ps-2 tw-tracking-normal tw-font-medium">FAQ</span>
      </Link>
    </ul>
  );
};
