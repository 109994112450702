const NotiType = (type, userRole) => {
  switch (type) {
    case "connections":
      return userRole === "f@!3A"
        ? "/startup-connections"
        : userRole === "R$7s2"
        ? "/mentor-connections"
        : "/investor-connections";
    case "subscription":
      return userRole === "f@!3A" ? "/subscription" : "/vendor-plan";
    case "investments":
      return userRole === "f@!3A"
        ? "/startup-investments"
        : "/investor-investments";
    case "profile":
      return "/startup-profile";
    case "market":
      return userRole === "f@!3A" ? "/my-projects" : "/my-bids";
    case "reviews":
      return "/reviews";
    case "admin":
      return "/#";
    default:
      return "/error-500";
  }
};

export default NotiType;
